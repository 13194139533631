import React from 'react';

const Logo = ({ className }) => (
    <svg viewBox="0 0 76 96" className={className}>
        <path
            fillRule="evenodd"
            d="M47,24.1425162 L47,13.6151842 L14,30.0208984 L14,68.3873801 L21.0176235,64.8986187 L21.0176235,67.164481 C19.3402304,67.9660411 
            16.8137741,69.2220508 13.4451657,70.8967304 C12.7804006,71.2272136 12,70.7436646 12,70.0012821 L12,29.4012821 
            C12,29.0216856 12.2149253,28.6748173 12.5548343,28.5058339 L47.5548343,11.1058339 L47.5548343,11.1058339 C48.2195994,10.7753507 
            49,11.2588997 49,12.0012821 C49,17.1612158 49,20.8612112 49,23.1012683 L47,24.1425162 Z M35.0668376,57.8115329 L46.8584052,51.9494393 
            L46.8584052,39.6444425 L48.9164409,39 C48.8584052,41.9979273 48.8584052,46.5284108 48.8584052,52.5690556 C48.8584052,52.9486521 
            48.6434799,53.2955205 48.3035708,53.4645038 C42.0579573,56.569466 37.6234336,58.7740577 35,60.078279 L35.0668376,57.8115329 Z 
            M62.1096686,22.2091036 C63.4391987,21.5481372 65,22.5152351 65,24 L65,64.6 C65,65.359193 64.5701494,66.0529297 63.8903314,66.3908964 
            L28.8903314,83.7908964 C27.5608013,84.4518628 26,83.4847649 26,82 L26,41.4 C26,40.640807 26.4298506,39.9470703 27.1096686,39.6091036 
            L62.1096686,22.2091036 Z M30,42.6392326 L30,78.772196 L61,63.3607674 L61,27.227804 L30,42.6392326 Z"
        />
    </svg>
);

export default Logo;
