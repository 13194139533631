import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames/bind';
import { Accordion, Box, CheckBox, Text, ThemeContext } from 'grommet/es6';
import { Configure, FormClose, Resources } from 'grommet-icons';
import { experimentalModeAllowed } from '../../config';
import NavMenuBox from './NavMenuBox';
import NavAccordionPanel from './NavAccordionPanel';
import {
  activePanelSelector,
  getActivePanels,
  lastMicroappSelector,
} from '../reducer';
import style from './NavMenu.scss';
import NavMenuTenant from './NavMenuTenant';
import { getMicroappIcon } from '../../router/knownRoutes';
import { iconSize } from '../constants';
import {
  experimentalSelector,
  tourToolSelector,
} from '../../bootstrapper/reducer';
import { isSiteAdminSelector } from '../../user/reducer';
import {
  setExperimental,
  setTourTool,
} from '../../experimental/actionCreators';
import { updateActivePanels } from '../actionCreators';

const cx = classnames.bind(style);

const MicroappSelector = ({
  microappMap,
  settings,
  resources,
  goToMicroapp,
  close,
  loading,
}) => {
  const lastMicroapp = useSelector(lastMicroappSelector);
  const experimental = useSelector(experimentalSelector);
  const isSiteAdmin = useSelector(isSiteAdminSelector);
  const tourTool = useSelector(tourToolSelector);
  const activePanelStore = useSelector(activePanelSelector);
  const dispatch = useDispatch();
  const panelKey = 'MicroappSelector';
  const panels = [];

  const toggleTourTool = () => {
    dispatch(setTourTool(!tourTool));
    window.location.reload();
  };

  panels.push(
    <NavMenuBox className={cx('title')} key="title">
      <Box direction="column">
        <Text>Main Menu</Text>
        <NavMenuTenant />
      </Box>
      <Box onClick={close}>
        <FormClose visibility="hidden" />
      </Box>
    </NavMenuBox>
  );

  Object.entries(microappMap).forEach(([microapp, data], index) => {
    const tourTag =
      (!lastMicroapp && index === 0) || lastMicroapp === microapp
        ? 'nav-app-product'
        : null;

    panels.push(
      <NavMenuBox
        onClick={() => goToMicroapp(microapp)}
        key={data.title}
        data-tour-step={tourTag}
      >
        {getMicroappIcon(microapp)}
        <Text margin={{ left: 'small' }}>{data.title}</Text>
      </NavMenuBox>
    );
  });

  if (Object.keys(microappMap).length === 0) {
    panels.push(
      <NavMenuBox className={cx('non-selectable')}>
        {loading ? 'Loading...' : 'No microapps'}
      </NavMenuBox>
    );
  }

  const activePanels = getActivePanels(activePanelStore, panelKey);

  return (
    <ThemeContext.Extend
      value={{
        checkBox: {
          color: style.checkBoxColor,
        },
      }}
    >
      <Accordion
        className={cx('container')}
        onActive={(activeIndexes) => {
          dispatch(updateActivePanels(panelKey, activeIndexes));
        }}
        activeIndex={activePanels}
      >
        {panels}
        <NavAccordionPanel
          title="Resources"
          icon={<Resources size={iconSize} />}
          options={resources}
          active={activePanels.includes(panels.length)}
          parentPanelKey={panelKey}
          onNavigate={close}
          dataTourStep="nav-resources"
        />
        <NavAccordionPanel
          title="InfoSight Administration"
          icon={<Configure size={iconSize} />}
          options={settings}
          active={activePanels.includes(panels.length + 1)}
          parentPanelKey={panelKey}
          onNavigate={close}
          dataTourStep="nav-administration"
        />
        {experimentalModeAllowed ? (
          <NavMenuBox justify="between" className={cx('non-selectable')}>
            <div>Experimental Mode</div>
            <CheckBox
              toggle
              checked={experimental}
              onClick={() => dispatch(setExperimental(!experimental))}
            />
          </NavMenuBox>
        ) : null}
        {experimentalModeAllowed && isSiteAdmin ? (
          <NavMenuBox justify="between" className={cx('non-selectable')}>
            <div>Tour Dev Tool</div>
            <CheckBox toggle checked={tourTool} onClick={toggleTourTool} />
          </NavMenuBox>
        ) : null}
      </Accordion>
    </ThemeContext.Extend>
  );
};

MicroappSelector.propTypes = {
  microappMap: PropTypes.objectOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      elements: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          url: PropTypes.string,
        })
      ).isRequired,
    })
  ).isRequired,
  settings: PropTypes.array.isRequired,
  resources: PropTypes.array.isRequired,
  goToMicroapp: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default MicroappSelector;
