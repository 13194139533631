import { ExtensionPoint } from '@infosight/shell-api/lib/core';
import { buildUrl } from '@infosight/elmer/dist/utils/url';
import reducer, { ADD, REMOVE } from './reducer';
import KNOWN_ROUTES from '../../router/knownRoutes';

// TODO remove appId=shell workaround when all domains are extracted
function addRouter({ url, router, exact, appId }) {
  if (appId !== 'shell') {
    if (!KNOWN_ROUTES[appId]) {
      console.error(
        `\n **********\n * Microapp "${appId}" has attempted to add routes to the portal, but is not a known microapp.` +
          '\n * Please go to portal-frontend/src/router/knownRoutes.js and add your microapp routes to the list\n **********'
      );
      return;
    }
    if (!KNOWN_ROUTES[appId].includes(buildUrl(url))) {
      console.error(`${url} is not a known, valid route for ${appId}`);
      return;
    }
  }

  this.props.dispatch({
    type: ADD,
    payload: { url: buildUrl(url), router, exact, appId },
  });
}

// TODO remove appId=shell workaround when all domains are extracted
function removeRouter({ url, appId }) {
  if (
    appId !== 'shell' &&
    (!KNOWN_ROUTES[appId] || !KNOWN_ROUTES[appId].includes(buildUrl(url)))
  ) {
    return;
  }

  this.props.dispatch({ type: REMOVE, payload: { url: buildUrl(url), appId } });
}

export const id = ExtensionPoint.Router;
export const commands = { addRouter, removeRouter };
export { reducer };
