import React, { Component } from 'react';
import PropTypes from 'prop-types';
import autobind from 'react-autobind';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { IconContext } from 'react-icons';
import { trackEvent } from '@infosight/shell-api/lib/Analytics';
import { ThemeProvider } from '@infosight/elmer/dist/components/ThemeProvider';
import { blacklistQueryParameterCoercion } from '@infosight/elmer/dist/components/Router';
import { ErrorBoundary } from '@infosight/elmer/dist/page';
import initializeElmerInfrastructure from '@infosight/elmer/dist/infrastructure';
import { APP_BASE_URL } from '../config';
import ExtensionOrchestrator from '../extensibility/orchestrator/ExtensionOrchestrator';
import { getMicroappManifest } from '../extensibility/orchestrator/actionCreators';
import SearchManager from '../search/components/SearchManager';
import TenancyRouter from '../iam/TenancyActionResolver/TenancyRouter';
import AppContainer from './AppContainer';
import { loadExperimental, loadTourTool } from './actionCreators';
import { Analytics } from '../user';
import HistoryListener from './HistoryListener';
import TourGuide from '../tour/components/TourGuide'; // eslint-disable-line import/no-named-as-default
import { setAppReady } from '../extensibility/bootstrapper/actionCreators';
import { appIsReadySelector } from '../extensibility/bootstrapper/reducer';
import TourDeveloperTool from '../tour/components/TourDeveloperTool';

export default class Bootstrapper extends Component {
  constructor(props) {
    super(props);
    autobind(this);
    blacklistQueryParameterCoercion('scope.id');
    initializeElmerInfrastructure();
  }

  componentDidMount() {
    const { store } = this.props;
    Analytics.init(store);
    store.dispatch(loadExperimental());
    store.dispatch(loadTourTool());
    store.dispatch(getMicroappManifest());
  }

  onExtensionOrchestratorLoad() {
    // This "app ready" value was previous set in this component's state and passed to direct children. Moving it to redux enables idiomatic
    // use deeper in the component tree.
    // Note that this action creator is inconsistent with others in the app because the react-redux Provider is a child of this component,
    // so we can't use the connect() and bindActionCreators() functions.
    setAppReady()(this.props.store.dispatch);

    // Since we can't use the connect() function, we must manually trigger an update
    this.forceUpdate();

    trackEvent({ event: 'shell.Portal Loaded' });
  }

  render() {
    const { store } = this.props;

    let appReady = false;
    try {
      appReady = appIsReadySelector(store.getState());
    } catch (e) {
      // eslint-disable-line no-empty
    }

    return (
      <ThemeProvider>
        <IconContext.Provider value={{ style: { verticalAlign: 'middle' } }}>
          <ErrorBoundary>
            <Provider store={store}>
              <BrowserRouter basename={APP_BASE_URL}>
                <>
                  <HistoryListener />
                  <ExtensionOrchestrator
                    onLoad={this.onExtensionOrchestratorLoad}
                  />
                  <SearchManager />
                  {appReady && <TourGuide />}
                  {appReady && <TourDeveloperTool />}
                  {appReady && (
                    <TenancyRouter render={() => <AppContainer />} />
                  )}
                </>
              </BrowserRouter>
            </Provider>
          </ErrorBoundary>
        </IconContext.Provider>
      </ThemeProvider>
    );
  }
}

Bootstrapper.propTypes = {
  store: PropTypes.object.isRequired,
};
