import { getStartedActionType } from '@infosight/elmer/dist/utils/redux';
import { ExtensionPoint } from '@infosight/shell-api/lib/core';
import { AUTHORIZE_NEXT_TENANT } from '../iam/constants';
import { extensionPointSelector } from '../extensibility';
import { microappsSelector } from '../extensibility/orchestrator/reducer'; // eslint-disable-line
import { inventorySelector } from '../inventory/reducer';
import { experimentalSelector } from '../bootstrapper/reducer';
import { tourUserPropertiesSelector } from '../iam/reducer';
import { toursSelector } from '../extensibility/tour/reducer';
import { restructureNavData } from './utils';
import { getMicroappHome } from '../router/knownRoutes';
import { loadState, saveState } from '../util/localStorage';
import {
  ACTIVE_PANELS,
  MENU_CONTEXT,
  NAV_MODAL_OPEN,
  navContexts,
  RESET_ACTIVE_PANELS,
  RESET_MENU,
  NAV_CLOSE_ON_OUTSIDE_CLICK,
} from './constants';

const initialState = {
  modalOpen: false,
  viewContext: {
    context: navContexts.BASE,
    microapp: null,
  },
  lastMicroapp: null,
  activePanels: {},
  closeOnOutsideClick: true,
};

const ACTION_HANDLERS = {
  [MENU_CONTEXT]: (state, { payload }) => ({
    ...state,
    viewContext: payload,
    lastMicroapp: payload.microapp || state.lastMicroapp,
  }),
  [NAV_MODAL_OPEN]: (state, { payload }) => ({
    ...state,
    modalOpen: payload,
  }),
  [getStartedActionType(AUTHORIZE_NEXT_TENANT)]: (state) => ({
    ...state,
    viewContext: {
      context: navContexts.BASE,
      microapp: null,
    },
  }),
  [ACTIVE_PANELS]: (state, { payload }) => ({
    ...state,
    activePanels: { ...payload },
  }),
  [RESET_ACTIVE_PANELS]: (state) => ({
    ...state,
    activePanels: {},
  }),
  [RESET_MENU]: (state, { payload }) => ({
    ...state,
    modalOpen: true,
    activePanels: {},
    lastMicroapp: (payload && payload.lastMicroapp) || null,
    viewContext: {
      context: navContexts.BASE,
      microapp: null,
    },
  }),
  [NAV_CLOSE_ON_OUTSIDE_CLICK]: (state, { payload }) => ({
    ...state,
    closeOnOutsideClick: payload,
  }),
};

export default function reducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}

// Selectors
export const baseSelector = (state) => state.navMenu;

export const contextSelector = (state) => {
  return baseSelector(state) && baseSelector(state).viewContext;
};

export const lastMicroappSelector = (state) => {
  return baseSelector(state) && baseSelector(state).lastMicroapp;
};

export const modalOpenSelector = (state) => {
  return baseSelector(state) && baseSelector(state).modalOpen;
};

export const closeOnOutsideClickSelector = (state) => {
  return baseSelector(state) && baseSelector(state).closeOnOutsideClick;
};

export const navDataSelector = (state) => {
  const {
    infrastructure,
    resources,
    settings,
    dashboards,
  } = extensionPointSelector(state, ExtensionPoint.PrimaryNav);
  const microappManifest = microappsSelector(state) || [];
  const { inventory } = inventorySelector(state) || null;
  const tourUserProperties = tourUserPropertiesSelector(state);
  const tours = toursSelector(state);
  const experimentalMode = experimentalSelector(state);
  return restructureNavData({
    infrastructure,
    dashboards,
    settings,
    resources,
    microappManifest,
    inventory,
    tourUserProperties,
    tours,
    experimentalMode,
  });
};

export const accessibleMicroappsSelector = (state) => {
  const microapps = navDataSelector(state).microapps || {};
  return Object.keys(microapps);
};

export const microappHomeSelector = (state) => (appId) => {
  const { inventory } = inventorySelector(state) || null;
  const presence = !!(
    inventory &&
    inventory[appId] &&
    inventory[appId].presence
  );
  const inventoryError = !!(
    inventory &&
    inventory[appId] &&
    inventory[appId].status &&
    inventory[appId].status.statusCode !== 0
  );
  return getMicroappHome(appId, presence, inventoryError);
};

// Currently we never manually delete this data, because every redux state change overwrites it.
export const saveNavMenu = (navMenu) => {
  saveState({ ...loadState(), navMenu });
};

export const activePanelSelector = (state) => {
  return baseSelector(state) && baseSelector(state).activePanels;
};

export const getActivePanels = (activePanelStore, key) => {
  return activePanelStore[key] || [];
};
