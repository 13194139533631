import React from 'react';
import { Switch, Route } from 'react-router-dom';
import TenancyActionResolver from './TenancyActionResolver'; // eslint-disable-line import/no-named-as-default

// This file may look useless since it renders the same thing no matter which Route is picked,
// but it is important because descendant files rely on /org/:tenantId being their match.path.
const TenancyRouter = (props) => {
  return (
    <Switch>
      <Route
        path="/org/:tenantId"
        render={(routeProps) => (
          <TenancyActionResolver {...props} {...routeProps} />
        )}
      />
      <Route
        render={(routeProps) => (
          <TenancyActionResolver {...props} {...routeProps} />
        )}
      />
    </Switch>
  );
};

export default TenancyRouter;
