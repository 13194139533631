import shortid from 'shortid';
import { getStore } from '../../../util/storeProvider';
import {
  officialDemoSelector,
  demoRolePropertiesSelector,
  iamSessionSelector,
} from '../../../iam/reducer';

/**
 * For some reason, the standard NR PageAction.session value is not always available. So this makes sure there is always some value we can use
 */
const sessionId = shortid();

/**
 * @see https://docs.newrelic.com/attribute-dictionary?attribute_name=&events_tids%5B%5D=8307
 * These attributes names are also default attribute names.
 * In most cases, you may not need to set these attributes if they capture the same information you need.
 *
 * This array was created by copying the table directly from docs into Excel,then copying that column here and using multiline-editing to convert to an object.
 */
export const defaultAttributes = [
  'actionName',
  'appId',
  'appName',
  'asn',
  'asnLatitude',
  'asnLongitude',
  'asnOrganization',
  'browserHeight',
  'browserWidth',
  'city',
  'countryCode',
  'currentUrl',
  'deviceType',
  'name',
  'pageUrl',
  'referrerUrl',
  'regionCode',
  'session',
  'timeSinceLoad',
  'timestamp',
  'userAgentName',
  'userAgentOS',
  'userAgentVersion',
];

const trackEvent = (event, attributes = {}) => {
  const cleanAttributes = Object.entries(attributes).reduce(
    (memo, [key, value]) => {
      const blacklisted = defaultAttributes.some((it) => it === key);
      if (process.env.NODE_ENV === 'development') {
        console.assert(
          !blacklisted,
          `attribute "${key}" in event "${event}" is a New Relic PageAction attribute. Choose a different name.`
        );
      }
      return blacklisted ? memo : { ...memo, [key]: value };
    },
    {}
  );

  const attrs = { ...cleanAttributes, infosight_session: sessionId };
  window.newrelic.addPageAction(event, attrs);

  if (
    process.env.NODE_ENV === 'development' ||
    process.env.NODE_ENV === 'ap1'
  ) {
    console.debug(`New Relic tracked event "${event}"`, attrs);
  }
};

// This is strictly used for tracking users of project ignite
const trackPageView = ({ path }) => {
  const state = getStore().getState();

  if (!path || !officialDemoSelector(state)) {
    return;
  }

  const { demoRole } = demoRolePropertiesSelector(state);
  const session = iamSessionSelector(state);

  const pathRegex = /^\/org\/:tenantId/;
  const infosightPage = path.replace(pathRegex, '');
  const igniteEvent = {
    event: 'shell.Ignite PageView',
    attributes: {
      role: demoRole,
      infosight_page: infosightPage,
      user_name: session.userName,
    },
  };

  trackEvent(igniteEvent.event, igniteEvent.attributes);
};

export const trackIgniteAccess = () => {
  const state = getStore().getState();

  if (!officialDemoSelector(state)) {
    return;
  }

  const { demoRole } = demoRolePropertiesSelector(state);
  const session = iamSessionSelector(state);

  const event = 'shell.Using Project Ignite';
  const attributes = { role: demoRole, user_name: session.userName };

  trackEvent(event, attributes);
};

/**
 * If the newrelic object is not available, this provider is useless
 * @returns {boolean}
 */
const init = () => !!window.newrelic;

export default { trackEvent, trackPageView, init, name: 'New Relic Browser' };
