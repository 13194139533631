import React, { Component } from 'react';
import PropTypes from 'prop-types';
import autobind from 'react-autobind';
import { findIndex, isFunction } from 'underscore';
import getDisplayName from 'recompose/getDisplayName';
import wrapDisplayName from 'recompose/wrapDisplayName';

/**
 *
 * HOC which returns a {Table}  which can persist column sizes.
 *
 *  @param {!string} tableId - Unique ID of the table whose column widths are being persisted
 *  @param {!Function} getPersistedColumnSizes - ({ tableId }) => Should return {Array<{id, value}>}
 *       A function which fetches the saved sizes of the columns for the given table
 *  @param {!Function} persistColumnSizes - ({tableId, {Array<{id, value}>}}) => Save {Array<{id, value}>}
 *      A function which saves the resized column widths
*/
const createSizePersistentTable = (TableComponent, { getPersistedColumnSizes, persistColumnSizes }) => {
    if (!isFunction(getPersistedColumnSizes) || !isFunction(persistColumnSizes)) {
        return null;
    }

    /**
     * Updates the column widths present in the current state with the new values
     * @param {Array<{id, value}>}
     * @param {Array<{id, value}>}
     * @returns {Array<{id, value}>}
     */
    const mergeColumnWidths = (currentState, resizedColumns) =>
        resizedColumns.reduce((memo, resizedColumn) => {
            const indexInCurrentState = findIndex(currentState,
                columnInState => columnInState.id === resizedColumn.id);

            // Is this column not persisted
            if (indexInCurrentState === -1) {
                memo.push(resizedColumn);
            } else {
                // Update the column width with the new value
                memo.push({
                    ...currentState[indexInCurrentState],
                    value: resizedColumn.value,
                });
            }

            return memo;
        }, []);

    class ResizableTable extends Component {
        constructor(props) {
            super(props);
            autobind(this);
            const { tableId } = props;
            this.state = {
                resizedColumns: getPersistedColumnSizes({ tableId }),
            };
        }

        /**
         * Call back function which handles the column resize of the table
         * It does the following:
         *  1. Gets the current column width from the Local state of this Component
         *  2. Merges the newly resized column with exisiting resized columns
         *  3. Calls the supplied function to persist the new sizes of the columns
         *
         * @param {{id, value}} resizedColumn - The column which was resized
         */
        handleColumnResize(resizedColumn) {
            const { tableId } = this.props;
            const { resizedColumns: persistedSizes } = this.state;
            const resizedColumns = mergeColumnWidths(persistedSizes || [], resizedColumn);

            // Update local state with new sizez
            this.setState({ resizedColumns });

            /* Persist immediately instead of optimizing it do at UnMount to avoid
               unnecessary complication of page load/reload hooks */
            persistColumnSizes({ tableId, resizedColumns });
        }

        render() {
            return (
                <TableComponent
                    {...this.props}
                    resized={this.state.resizedColumns}
                    onResizedChange={this.handleColumnResize}
                />
            );
        }
    }

    ResizableTable.displayName = wrapDisplayName(
        TableComponent,
        getDisplayName(ResizableTable)
    );

    ResizableTable.propTypes = {
        tableId: PropTypes.string.isRequired,
    };

    return ResizableTable;
};

export default createSizePersistentTable;
