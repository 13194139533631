import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router';
import hoist from 'hoist-non-react-statics';
import { matchWithQueryParams } from './util';

/**
 * HOC that inject query parameters into the `match.params` hash
 * @param WrappedComponent
 * @param includePathParams {boolean} Include path parameters in match.params
 */
export default function withQueryParams(WrappedComponent, { includePathParams = true } = {}) {
    const Query = ({ wrappedComponentRef, coerce, ...props }) => (
        <Route
            render={({ match, location, ...routeProps }) => {
                const updatedMatch = matchWithQueryParams(match, location, coerce, includePathParams);
                return <WrappedComponent {...routeProps} {...props} location={location} match={updatedMatch} ref={wrappedComponentRef} />;
            }}
        />
    );

    Query.propTypes = {
        /**
         * Ref that will be passed to the component
         */
        wrappedComponentRef: PropTypes.func,

        /**
         * False disables type coercion
         */
        coerce: PropTypes.bool,
    };

    Query.defaultProps = {
        coerce: true,
    };

    return hoist(Query, WrappedComponent);
}
