import { actionsFor } from '@infosight/elmer/dist/utils/redux';
import postIAMRequest from '../services/postIAMRequest';
import {
  LOAD_PREFERENCES,
  DISABLE_DIALOG,
  SET_NOTIFICATION,
  ACCEPT_EULA,
  GET_LANDING_PAGE,
} from './constants';
import Request from '../services/Request';

// There is no begin load action because this should only ever be called once
export const loadPreferences = () => (dispatch) => {
  const { dispatchStart, dispatchSuccess } = actionsFor(
    LOAD_PREFERENCES,
    dispatch
  );

  dispatchStart();

  new Request()
    .slash('user', 'preferences')
    .get()
    .then(({ data }) => {
      dispatchSuccess(data);
    });
};

const savePreferences = (getState) => {
  const currentPrefs = { ...getState().preferences.preferences };
  new Request().slash('user', 'preferences').put(currentPrefs);
};

export const disableDialog = (dialogId) => (dispatch, getState) => {
  // Because all preferences for a user are saved in one bulk action,
  // we update state BEFORE saving so that multiple different preference can
  // be saved in a row without losing data.
  dispatch({ type: DISABLE_DIALOG, payload: dialogId });
  // TODO: Handle error. Tougher and probably less valuable because of the above comment.
  savePreferences(getState);
};

export const setNotification = (notification) => (dispatch, getState) => {
  // Because all preferences for a user are saved in one bulk action,
  // we update state BEFORE saving so that multiple different preference can
  // be saved in a row without losing data.
  dispatch({ type: SET_NOTIFICATION, payload: notification });
  // TODO: Handle error.
  savePreferences(getState);
};

export const setNotifications = (notifications) => (dispatch, getState) => {
  // Because all preferences for a user are saved in one bulk action,
  // we update state BEFORE saving so that multiple different preference can
  // be saved in a row without losing data.
  notifications.forEach((notification) => {
    dispatch({ type: SET_NOTIFICATION, payload: notification });
  });
  // TODO: Handle error.
  savePreferences(getState);
};

export const acceptEula = (key) => (dispatch, getState) => {
  // Because all preferences for a user are saved in one bulk action,
  // we update state BEFORE saving so that multiple different preference can
  // be saved in a row without losing data.
  dispatch({ type: ACCEPT_EULA, payload: key });
  // TODO: Handle error.
  savePreferences(getState);
};

export function landingPageProperties(properties) {
  const page = properties
    .filter((obj) => {
      return obj.urn === 'urn:shell:landing_page:url';
    })
    .map((obj) => {
      return {
        url: obj.value,
      };
    });
  return page.length ? page.pop() : undefined;
}

export function fetchLandingPage() {
  return async (dispatch) => {
    // eslint-disable-next-line
    const { dispatchStart, dispatchSuccess, dispatchError } = actionsFor(
      GET_LANDING_PAGE,
      dispatch
    );

    dispatchStart();

    try {
      const {
        // eslint-disable-next-line no-unused-vars
        data: { errors, data },
      } = await postIAMRequest({
        query: `{
                  currentSession {
                    user {
                      properties {
                        urn
                        value
                      }
                    }
                  }
                }`,
      });

      if (errors) {
        dispatchError(errors);
        return;
      }

      const page = landingPageProperties(data.currentSession.user.properties);
      dispatchSuccess(page);
    } catch (e) {
      dispatchError(e);
    }
  };
}

export function setLandingPage(url, orgId) {
  return async (dispatch) => {
    const { dispatchStart, dispatchSuccess, dispatchError } = actionsFor(
      GET_LANDING_PAGE,
      dispatch
    );

    dispatchStart();

    try {
      const {
        data: { urlErrors, data },
      } = await postIAMRequest({
        query: `mutation {
                  updateUserProperty(urn: "urn:shell:landing_page:url", value: "${url}") {
                    properties {
                      urn
                      value
                    }
                  }
                }`,
      });

      const {
        data: { errors: orgErrors },
      } = await postIAMRequest({
        query: `mutation {
                  updateUserProperty(urn: "urn:shell:landing_page:org", value: "${orgId}") {
                    properties {
                      urn
                      value
                    }
                  }
                }`,
      });

      if (urlErrors || orgErrors) {
        dispatchError({ urlErrors, orgErrors });
        return;
      }

      dispatchSuccess(
        landingPageProperties(data.updateUserProperty.properties)
      );
    } catch (e) {
      dispatchError(e);
    }
  };
}
