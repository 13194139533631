import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import BEM from 'src/utils/bem';
import { Spinner } from 'src/components/Spinner';
import AdaptiveNode from './AdaptiveNode';
import { hasOptions } from './util';
import StaticBold from '../StaticBold/StaticBold';

const bem = new BEM('elmer-NavBar-Menu');

const renderTitle = (nodeType, title) => {
    switch (nodeType) {
    case 'title':
        return title;
    case 'subtitle':
        return <small>{title}</small>;
    case 'external':
        return <StaticBold>{title}<i className="fa fa-external-link" /></StaticBold>;
    default:
        return <StaticBold>{title}</StaticBold>;
    }
};

const Menu = ({ className, options, depth, closeMenu, style, loading, ...props }) => (
    <ul {...props} className={classnames(className, bem.valueOf())} style={style}>
        {options.filter(x => x.enabled !== false).map(({
            id,
            nodeType,
            divider,
            containerClassName,
            beacon,
            childMenuClassName,
            options: submenu,
            collapseHeader,
            onClick,
            url,
            title,
            ...nodeProps
        }, i, arr) => {
            const hasSiblings = arr.length > 1;
            const itemBem = bem.chain().e('item');
            return (
                <li
                    key={id}
                    data-menu-item={id}
                    className={classnames(
                        'flex-container align-stretch flex-dir-column',
                        { [bem.e('divider')]: divider },
                        containerClassName,
                        bem.e('submenu')
                    )}
                >
                    <AdaptiveNode
                        {...nodeProps}
                        className={itemBem.valueOf()}
                        titleClassName={classnames(itemBem.m('title').valueOf(), { hide: !hasSiblings && collapseHeader !== false })}
                        linkClassName={classnames(itemBem.m('link').valueOf())}
                        href={typeof url === 'function' ? url() : url}
                        onClick={typeof onClick === 'function' ? e => onClick(e, closeMenu) : closeMenu}
                        nodeType={nodeType}
                        beacon={beacon}
                    >
                        {renderTitle(nodeType, title)}
                    </AdaptiveNode>
                    {hasOptions(submenu) && (
                        <Menu
                            {...nodeProps}
                            className={classnames({ [bem.m('no-sibling-menus')]: !hasSiblings }, childMenuClassName)}
                            options={submenu}
                            depth={depth + 1}
                            closeMenu={closeMenu}
                        />
                    )}
                </li>
            );
        })}
        {loading && (
            <li className={classnames('flex-container align-stretch flex-dir-column', bem.e('spinner'))}>
                <Spinner size="small" color="white" />
            </li>
        )}
    </ul>
);

Menu.propTypes = {
    options: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        title: PropTypes.node.isRequired,
        url: PropTypes.string,
        enabled: PropTypes.bool,
        options: PropTypes.arrayOf(PropTypes.object),

        /**
         * Explicitly choose a link type, or leave empty to detect
         * external - Plain anchor styled as an external link
         * internal - React-Router link
         * plain - Plain anchor
         * title - Non-link plain text
         * subtitle - Non-link plain text that's more of a group heading TODO what's the use case?
         * manual - Render anything else you want
         */
        nodeType: PropTypes.oneOf(['external', 'internal', 'plain', 'title', 'function', 'subtitle', 'manual']),

        /**
         * Should the menu header collapse if there are no sibling menus? Defaults to true.
         */
        collapseHeader: PropTypes.bool,

        /**
         * Class to apply to this node's child `Menu` element
         */
        childMenuClassName: PropTypes.string,

        /**
         * Class to apply to this node's container element
         */
        containerClassName: PropTypes.string,
        beacon: PropTypes.shape({
            enabled: PropTypes.bool,
            onClick: PropTypes.func,
            anchor: PropTypes.string,
        }),
    })).isRequired,
    depth: PropTypes.number,
    closeMenu: PropTypes.func,

    /**
     * Show loading indicator when we expect more menu options to become available
     */
    loading: PropTypes.bool,
};

Menu.defaultProps = {
    depth: 0,
};

export default Menu;
