import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import BEM from 'src/utils/bem';

const bem = new BEM('elmer-Tag');

const Tag = ({ className, title, onClick }) => (
    <button onClick={onClick} className={classnames(className, bem.valueOf())}>
        {title}
    </button>
);

Tag.propTypes = {
    title: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
};

export default Tag;
