import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { StaticBold } from '@infosight/elmer/dist/components/StaticBold';
import classnames from 'classnames/bind';
import { ObjectName } from '@infosight/elmer/dist/infrastructure';
import style from './style.scss';

const cx = classnames.bind(style);

const DefaultHitNameRenderer = ({ name, label }) => (
  <>
    <ObjectName icon={false} name={name} />
    {label && <small>{label}</small>}
  </>
);

DefaultHitNameRenderer.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.node,
};

const Hit = ({ renderer, url, closeMenu, ...props }) => (
  <Link to={url} onClick={closeMenu} className={cx('hit')}>
    <StaticBold>{renderer(props)}</StaticBold>
  </Link>
);

Hit.propTypes = {
  hitId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  name: PropTypes.string.isRequired,
  renderer: PropTypes.func,
  url: PropTypes.string.isRequired,
  closeMenu: PropTypes.func.isRequired,
  label: PropTypes.node,
};

Hit.defaultProps = {
  renderer: DefaultHitNameRenderer,
};

export default Hit;
