import { microappData } from '../../router/knownRoutes';

export const getBundleId = (appId) => {
  if (['storeserv', 'storeonce', 'sfr', 'rmc'].includes(appId)) {
    return 'oculus';
  }
  return appId;
};

// Current groups:
// ["wellness", "nimble", "appinsights", "infosightlabs", "vmware"]
// ["vmware"]
// ["storeserv", "storeonce", "oculus", "sfr", "rmc," "storeservlabs"]
// ["proliant"]
// ["prostack", "dhcisizingtool"]
// ["simplivity-wellness", "simplivity-analytics", "simplivity"]
// ["bigdata"]
// ["hyperv"]
// ["sample"]
//
// We may want to consider grouping changes such as proliant and prostack together or making vmware a core microapp
const exclusiveGroups = (() => {
  const groupsMap = {};
  Object.entries(microappData).forEach(
    ([appId, { parent, isCore, replicatedIn, forcedLoadIn }]) => {
      // core microapps need to be added to all the lists
      // since those list may not be created yet we add them at the end
      if (isCore) return;

      const groupId = getBundleId(parent || appId);
      // we build a map where every microapp is under its parent;
      let existingGroup = groupsMap[groupId] || [];
      groupsMap[groupId] = [...existingGroup, appId];

      const containerList = [...(replicatedIn || []), ...(forcedLoadIn || [])];

      if (containerList && containerList.length) {
        containerList.forEach((container) => {
          existingGroup = groupsMap[container] || [];
          groupsMap[container] = [...existingGroup, appId];
        });
      }
    }
  );

  // add core apps to every list since they are always loaded
  Object.entries(microappData).forEach(([appId, { isCore }]) => {
    if (isCore) {
      Object.keys(groupsMap).forEach((groupId) => {
        groupsMap[groupId] = [...groupsMap[groupId], appId];
      });
    }
  });

  return Object.values(groupsMap);
})();

export const checkReloadRequired = (appId, orchestrator) => {
  const loading = [appId];
  Object.entries(orchestrator).forEach(([orchAppId, { status }]) => {
    if (status === 'loading' || status === 'loaded') {
      loading.push(orchAppId);
    }
  });

  // go through all the loading microapps and confirm they are a sub set of one group
  // there is fundamentally no way to tell if a replicated app should unload the group its replicated in if it is selected alone
  // but this is a small case and the replicated feature would not work without it
  let isSubset;
  exclusiveGroups.forEach((group) => {
    isSubset =
      isSubset ||
      loading.filter((app) => group.includes(app)).length === loading.length;
  });

  return !isSubset;
};
