/* eslint-disable import/prefer-default-export */
import React from 'react';
import { actionsFor } from '@infosight/elmer/dist/utils/redux';
import { addBanner, removeBanner } from '@infosight/shell-api/lib/Banner';
import { isEqual } from 'underscore';
import axios from 'axios';
import MaintenanceBanner from './MaintenanceBanner';
import { FETCH_MAINTENANCE } from './constants';
import { MICROAPP_MAINTENANCE_URL } from '../config';

export function fetchMaintenance() {
  return (dispatch, getState) => {
    const { dispatchStart, dispatchSuccess, dispatchError } = actionsFor(
      FETCH_MAINTENANCE,
      dispatch
    );

    // Only reset loading/loaded once
    dispatchStart();

    const runFetch = async () => {
      try {
        const maint = await axios.get(MICROAPP_MAINTENANCE_URL);

        if (!isEqual(getState().maintenance.maintenance, maint.data)) {
          dispatchSuccess(maint.data);
          const id = 'MAINTENANCE';
          if (maint.data.length > 0) {
            addBanner({
              id,
              appId: id,
              severity: 'warning',
              content: <MaintenanceBanner microapps={maint.data} />,
            });
          } else {
            removeBanner({ id, appId: id });
          }
        }
      } catch (e) {
        dispatchError(e);
      }
    };

    // run immediately
    runFetch();

    // poll every 5 minutes for maintenance mode
    setInterval(runFetch, 5 * 60 * 1000);
  };
}
