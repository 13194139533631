import PropTypes from 'prop-types';
import styled, { keyframes } from 'styled-components';
import { anchorOffsets, pulseWidthPx, beaconDiameterPx, left, top, right, bottom } from './style';

const namedOffsets = {
    small: beaconDiameterPx * 1.15,
    medium: beaconDiameterPx * 1.3,
    large: beaconDiameterPx * 1.75,
};
namedOffsets.default = namedOffsets.small;

const resolveSide = (sideValue) => {
    if (typeof sideValue === 'number') {
        return sideValue;
    } else if (typeof sideValue === 'string') {
        return namedOffsets[sideValue];
    }

    return undefined;
};

/**
 * Explode the convenience offset value to a Left-Top-Right-Bottom array of offsets for each side.
 */
const explodeOffset = (offset) => {
    if (offset && typeof offset === 'string') {
        const namedOffSet = namedOffsets[offset];
        if (namedOffSet) {
            return {
                [left]: namedOffSet,
                [top]: namedOffSet,
                [right]: namedOffSet,
                [bottom]: namedOffSet,
            };
        }
    }

    if (typeof offset === 'number') {
        return {
            [left]: offset,
            [top]: offset,
            [right]: offset,
            [bottom]: offset,
        };
    }

    if (offset && typeof offset === 'object') {
        return {
            [left]: resolveSide(offset[left]),
            [top]: resolveSide(offset[top]),
            [right]: resolveSide(offset[right]),
            [bottom]: resolveSide(offset[bottom]),
        };
    }

    return {
        [left]: namedOffsets.default,
        [top]: namedOffsets.default,
        [right]: namedOffsets.default,
        [bottom]: namedOffsets.default,
    };
};

/**
 * Explode the offset and anchor props into CSS position values that can be spread as CSS positioning rules
 * @return {{left: number|undefined, top: number|undefined, right: number|undefined, bottom: number|undefined}}
 */
export const resolvePositioning = (offset, anchor) => {
    const offsetPixelsPerSide = explodeOffset(offset);
    const positions = (anchorOffsets[anchor] || []);
    return positions.reduce((memo, side) => {
        const sideOffset = offsetPixelsPerSide[side];
        return sideOffset === undefined ? memo : { ...memo, [side]: `${-sideOffset}px` };
    }, {});
};

const pulseAnimation = keyframes`
    0% {
        box-shadow: 0 0 0 0 rgba(1, 169, 130, 0.6);
    }

    66% {
        box-shadow: 0 0 0 ${pulseWidthPx}px rgba(253, 111, 255, 0);
    }
`;

// TODO this should be updated to use the function form of button.attrs when we upgrade styled-components@5
/* eslint-disable max-len */
const Beacon = styled.button.attrs({ 'data-test': 'elmer-Beacon' })`
    height: ${beaconDiameterPx}px;
    width: ${beaconDiameterPx}px;
    background: rgb(1,169,130);
    background: radial-gradient(circle, rgba(1,169,130,1) 0%, rgba(1,169,130,1) 27.9%, rgba(255,255,255,0) 28%, rgba(255,255,255,0) 53%, rgba(1,169,130,1) 53.1%, rgba(1,169,130,1) 100%);
    border-radius: 50%;
    animation: ${pulseAnimation} 3s infinite;
    cursor: pointer;
    position: relative;
    display: flex;
    ${props => resolvePositioning(props.offset, props.anchor)}
`;

Beacon.propTypes = {
    anchor: PropTypes.string,
    offset: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
};

export default Beacon;
