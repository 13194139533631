import { actionsFor } from '@infosight/elmer/dist/utils/redux';
import { RESET, FETCH_INVENTORY } from './constants';
import postNavRequest from '../services/postNavRequest';
import { getStore } from '../util/storeProvider';
import { activeTenantSelector } from '../iam/reducer';
import { microappsSelector } from '../extensibility/orchestrator/reducer';
import { getMicroappManifest } from '../extensibility/orchestrator/actionCreators';
import { microappData } from '../router/knownRoutes';

export const reset = () => ({ type: RESET });

function getTenant() {
  const store = getStore();
  const state = store.getState();
  return activeTenantSelector(state);
}

const adjustInventory = (inventory) => {
  const adjustedInventory = {};

  // Strip out the status value, and just return total.  If status indicates failure, default the total value
  // to non-zero value to err on the side of caution to load the microapp
  Object.entries(inventory).forEach(([microapp, data]) => {
    if (data.status.statusCode !== 0) {
      const tenant = getTenant();
      console.error(
        `Failed to retrieve tenant (${
          tenant ? tenant.name : 'unknown'
        })'s inventory data for ${microapp} microapp, defaulting to presence of devices`
      );
      // For AppInsights, we do not want to load it if one of the components failed to retrieve unless
      // presence is true which indicates that one of the components has a presence even though other(s) failed
      adjustedInventory[microapp] =
        data.presence === false && microapp === 'appinsights'
          ? { presence: false }
          : { presence: true };
    } else {
      adjustedInventory[microapp] = { presence: data.presence };
    }
  });

  return adjustedInventory;
};

/**
 * Builds a GraphQL inventory result object with fallback values for presence and status
 * @param {array} appNames A list of app names to include in the result object
 * @returns {boolean}
 */
export const buildFallbackInventoryQueryResult = (appNames) => {
  const inventory = appNames.reduce((obj, appName) => {
    return {
      ...obj,
      [appName]:
        appName === 'appinsights'
          ? { presence: false, status: { statusCode: 1 } }
          : { presence: true, status: { statusCode: 1 } },
    };
  }, []);
  return {
    inventory,
    rawInventory: {},
  };
};

/**
 * Builds a GraphQL inventory query for the supplied application names
 * @param {array} A list of app names to include in the query
 * @returns {string}
 */
export const buildInventoryQuery = (appNames) => {
  const appQuery = appNames.map(
    (appName) => `${appName} { presence, status { statusCode, description } }`
  );
  return `{ transactionId inventory { ${appQuery.join(' ')} } }`;
};

export const fetchInventory = () => async (dispatch) => {
  const { dispatchStart, dispatchSuccess } = actionsFor(
    FETCH_INVENTORY,
    dispatch
  );

  dispatchStart();

  // Using selector lazily because latest state is needed after dispatch completes
  const getMicroapps = () => microappsSelector(getStore().getState());
  if (!getMicroapps()) {
    await getMicroappManifest()(dispatch);
  }

  const manifestAppNames = getMicroapps().map((app) => app.id);
  const appNames = Object.keys(microappData).filter(
    (key) =>
      microappData[key].getInventory &&
      manifestAppNames.includes(microappData[key].manifestKey || key)
  );

  try {
    const { data } = await postNavRequest({
      query: buildInventoryQuery(appNames),
    });

    const { inventory } = data.data;

    dispatchSuccess({
      inventory: adjustInventory(inventory),
      rawInventory: inventory,
    });
  } catch (e) {
    // In the event of an error retrieving the inventory data from nav-backend, err on
    // the side of caution and load all microapps.
    const tenant = getTenant();
    console.error(
      `Failed to retrieve tenant (${
        tenant ? tenant.name : 'unknown'
      })'s inventory data, defaulting to positive presence for all microapps`
    );

    dispatchSuccess(buildFallbackInventoryQueryResult(appNames));
  }
};
