import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import Async from '@infosight/elmer/dist/components/Async';
import { UnauthorizedPage } from '@infosight/elmer/dist/page';

// eslint-disable-next-line react/prop-types
export const Renderer = ({ error, loading, redirect, render }) => {
  // eslint-disable-line react/prop-types
  if (error) {
    return (
      <UnauthorizedPage message="There was a problem verifying your access to this organization." />
    );
  }

  return (
    <>
      {redirect && <Redirect to={redirect} />}
      <Async loading={!!loading} render={render} />
    </>
  );
};

export class TenancyActionExecutor extends Component {
  componentDidUpdate({ action }) {
    if (this.props.action !== action) {
      this.executeAction(this.props.action);
    }
  }

  executeAction(action) {
    if (!action) {
      return;
    }

    const { tenantId } = action;

    // Switch to a new tenant
    if (tenantId && !this.props.loadingNextTenantAuthorization) {
      this.props.switchTenant(tenantId);
    }
  }

  render() {
    const {
      errorNextTenantAuthorization,
      loadingNextTenantAuthorization,
      action,
      render,
    } = this.props;
    return (
      <Renderer
        error={errorNextTenantAuthorization}
        render={render}
        redirect={action && action.location}
        loading={loadingNextTenantAuthorization || (action && action.tenantId)}
      />
    );
  }
}

TenancyActionExecutor.propTypes = {
  switchTenant: PropTypes.func.isRequired,
  action: PropTypes.shape({
    location: PropTypes.shape({
      pathname: PropTypes.string,
    }),
    tenantId: PropTypes.string.isRequired,
    justLoadedBootstrap: PropTypes.bool,
    noop: PropTypes.bool,
  }),
  render: PropTypes.func.isRequired,
  loadingNextTenantAuthorization: PropTypes.bool.isRequired,
  errorNextTenantAuthorization: PropTypes.bool,
};

export default TenancyActionExecutor;
