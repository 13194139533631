import React, { Component } from 'react';
import PropTypes from 'prop-types';
import autobind from 'react-autobind';
import { Tag } from '../../../Tag';

class TextTag extends Component {
    constructor(props) {
        super(props);
        autobind(this);
    }

    onClick() {
        const { filter, removeFilter } = this.props;
        if (typeof removeFilter === 'function') {
            removeFilter({ filter });
        }
    }

    render() {
        const { filter: { title, id, value } } = this.props;
        /* Fallback to id if the title of the Column is unknown (Its a known limitation of Table)
           If the user uses a page URL directly which has filters, then filterTags shows id instead of the Column Title
         */
        return <Tag title={`${title || id} contains "${value}"`} onClick={this.onClick} />;
    }
}

TextTag.propTypes = {
    filter: PropTypes.shape({
        title: PropTypes.string,
        id: PropTypes.string,
        value: PropTypes.string,
    }),

    removeFilter: PropTypes.func.isRequired,
};

export default TextTag;
