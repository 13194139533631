import React, { Component } from 'react';
import PropTypes from 'prop-types';
import autobind from 'react-autobind';
import classnames from 'classnames';
import BEM from 'src/utils/bem';
import { TableContext } from '../hoc/withTableState';
import Filter from '../filters/Filter';
import { findColumn } from '../util/util';
import SortArrowIcon from './SortArrowIcon';


const bem = new BEM('elmer-Table-ColumnHeader');

class ColumnHeader extends Component {
    constructor(props) {
        super(props);
        autobind(this);

        this.sortCallback = undefined; // Call back to update the State
        this.currentSortOrder = undefined; // Current Sort Order value from State
    }

    /**
     * Function which does the 3-Way sort toggle
     * If the current sort order is Ascending, it toggles to Descending
     * If the current sort order is Descending, it toggles to No Sort
     * If the column is not sorted, then sort it in Ascending Order
     *
     */
    sortClickHandler() {
        const { id } = this.props;
        let newSortOrder;
        switch (this.currentSortOrder) {
        // Toggle Ascending to Descending
        case false: newSortOrder = true;
            break;

            // Toggle From Descending to NO Sort
        case true: newSortOrder = undefined;
            break;

            // Default to Ascending
        default: newSortOrder = false;
        }

        // Callback to Update HOC's (withTableState) state
        if (this.sortCallback) {
            this.sortCallback({
                id,
                desc: newSortOrder,
            });
        }
    }

    render() {
        const { title,
            id,
            enableWrapping,
            headerClassName,
            toolTip,
            isSortable,
            FilterComponent = null,
            HeaderComponent } = this.props;

        const filterTitle = typeof HeaderComponent !== 'string' ? title : HeaderComponent;
        const headerTooltip = toolTip || (typeof HeaderComponent !== 'string' ? null : HeaderComponent);

        return (
            <TableContext.Consumer>
                { ({ filteredColumns, handleFilterChange, sortedColumns, handleSortChange }) => {
                    let filterNode = null;
                    let sortNode = null;
                    if (FilterComponent) {
                        const filteredColumn = findColumn(filteredColumns, id).column;
                        filterNode = (<Filter
                            overlay={FilterComponent}
                            onChange={handleFilterChange}
                            filter={{ id, title: filterTitle, value: filteredColumn.value }}
                        />);
                    }

                    if (isSortable) {
                        const sortedColumn = findColumn(sortedColumns, id).column;
                        const sortOrder = sortedColumn.desc;
                        this.currentSortOrder = sortOrder;
                        this.sortCallback = handleSortChange;
                        sortNode = <SortArrowIcon {...this.props} desc={sortOrder} />;
                    }

                    return (
                        <div // eslint-disable-line
                            className={classnames(bem.valueOf(), 'flex-container flex-dir-row align-middle expanded')}
                            onClick={this.sortClickHandler}
                        >
                            <div
                                className={classnames(headerClassName, 'flex-child-auto',
                                    { 'ellipsis-on-overflow': !enableWrapping, 'wrap-on-overflow': enableWrapping })}
                                id={id}
                                title={headerTooltip}
                            >
                                { typeof HeaderComponent === 'function' ? HeaderComponent({ ...this.props }) : HeaderComponent }
                            </div>
                            { filterNode }
                            { sortNode }
                        </div>);
                }}
            </TableContext.Consumer>
        );
    }
}

ColumnHeader.propTypes = {
    /**
     * Id of the Column
     */
    id: PropTypes.string,

    /**
     * Title/label of the Column
     */
    title: PropTypes.string,

    HeaderComponent: PropTypes.oneOfType([PropTypes.string, PropTypes.func, PropTypes.element]).isRequired,

    isSortable: PropTypes.bool.isRequired,

    /**
     * FilterComponent which has to be rendered in Filter Popup
     */
    FilterComponent: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),

    headerClassName: PropTypes.oneOf(['text-center', 'text-right', 'text-left']),

    enableWrapping: PropTypes.bool,

    toolTip: PropTypes.string,
};

ColumnHeader.defaultProps = {
    enableWrapping: false,
    headerClassName: 'text-left',
};

export default ColumnHeader;
