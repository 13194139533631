import { LIVEAGENT_ONLINE } from './constants';

const initialState = {
  liveagentOnline: false,
};

const ACTION_HANDLERS = {
  [LIVEAGENT_ONLINE]: (state, { payload }) => ({
    ...state,
    liveagentOnline: payload,
  }),
};

export default function reducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}

// Selectors
const baseSelector = (state) => state.resources;
export const liveagentOnlineSelector = (state) =>
  baseSelector(state).liveagentOnline;
