export function loadState(initialState = {}) {
  try {
    const serializedState = window.localStorage.getItem('portalReduxState');
    if (serializedState === null) {
      return initialState;
    }
    const storedState = JSON.parse(serializedState);
    const combinedState = {
      ...initialState,
      ...storedState,
    };
    // We need to merge one level deeper because we are cherry-picking data out at one level deeper
    Object.keys(initialState).forEach((reducerKey) => {
      if (storedState[reducerKey]) {
        combinedState[reducerKey] = {
          ...initialState[reducerKey],
          ...storedState[reducerKey],
        };
      }
    });
    return combinedState;
  } catch (err) {
    // This could happen if there is badly formatted JSON data or non-JSON strings in localstorage.
    // Usually best practice to wrap JSON.parse in a try catch but no need to do anything with the errors.
    return initialState;
  }
}

export function saveState(state) {
  try {
    window.localStorage.setItem('portalReduxState', JSON.stringify(state));
  } catch (err) {
    // Do nothing on write error
  }
}
