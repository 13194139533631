import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { microappsSelector } from '../bootstrapper/reducer';
import LoadMicroapp from './LoadMicroapp';
import BannerErrorBoundary from '../../util/BannerErrorBoundary';

/* eslint-disable react/prefer-stateless-function */
/**
 * Class component so we can get a ref
 * This is the piece that knows how to find all extensions and start them.
 * TODO Isolate extensions such that a failure in one does not kill all extensions
 */
class ExternalExtensions extends Component {
  render() {
    return (
      <>
        {/* IAM is essentially part of portal-frontend masquerading as a microapp.
        IAM nav shows up in the always visible InfoSight Administration section.
        It ALWAYS needs to be loaded and so we explicitly load it here.
        Note that iam will still not be loaded if it is not included in
        your microapp manifest file. */}
        <LoadMicroapp appId="iam" />
        {this.props.microapps.map(({ appId, MicroappComponent }) => (
          <BannerErrorBoundary
            key={appId}
            bannerId={`orchestratorErrorBanner${appId}`}
            content={`The "${appId}" app has catastrophically failed. Please try again later.`}
          >
            <MicroappComponent />
          </BannerErrorBoundary>
        ))}
      </>
    );
  }
}

ExternalExtensions.propTypes = {
  microapps: PropTypes.arrayOf(
    PropTypes.shape({
      appId: PropTypes.string.isRequired,
      MicroappComponent: PropTypes.func.isRequired,
    })
  ),
};

const mapStateToProps = (state) => ({ microapps: microappsSelector(state) });

// https://react-redux.js.org/api/connect#forwardref-boolean
export default connect(mapStateToProps, undefined, undefined, {
  forwardRef: true,
})(ExternalExtensions);
