import React, { Component } from 'react';
import SearchExtensions from '../../search/extensions';
import UserExtensions from '../../user/extensions';
import ResourceExtensions from '../../resources/extensions';
import MiscellaneousPageExtension from '../../landing/extensions';
import NavFeatureTourExtension from '../../bootstrapper/NavFeatureTourExtension';

/* eslint-disable react/prefer-stateless-function */
/**
 * Class component so we can get a ref
 */
class InternalExtensions extends Component {
  render() {
    return (
      <>
        <ResourceExtensions />
        <SearchExtensions />
        <UserExtensions />
        <MiscellaneousPageExtension />
        <NavFeatureTourExtension />
      </>
    );
  }
}

export default InternalExtensions;
