
/**
 * https://developer.mozilla.org/en-US/docs/Web/API/Web_Storage_API/Using_the_Web_Storage_API
 *
 * Browsers that support localStorage will have a property on the window object named localStorage.
 * However, for various reasons, just asserting that property exists may throw exceptions.
 * If it does exist, that is still no guarantee that localStorage is actually available,
 * as various browsers offer settings that disable localStorage. So a browser may support localStorage,
 * but not make it available to the scripts on the page. One example of that is Safari,
 * which in Private Browsing mode gives us an empty localStorage object with a quota of zero, effectively making it unusable.
 * However, we might still get a legitimate QuotaExceededError, which only means that we've used up all available storage space,
 * but storage is actually available. Our feature detect should take these scenarios into account.
 *
 * @param {!string} type - type of storage avaialble on browser's window object like localStorage, SessionStorage + whatever exists or comes in future
 */
const isStorageAvailable = (type) => {
    let storage;
    try {
        storage = window[type];
        const x = '__storage_test__';
        storage.setItem(x, x);
        storage.removeItem(x);
        return true;
    } catch (e) {
        return e instanceof DOMException && (
            // everything except Firefox
            e.code === 22 ||
            // Firefox
            e.code === 1014 ||
            // test name field too, because code might not be present
            // everything except Firefox
            e.name === 'QuotaExceededError' ||
            // Firefox
            e.name === 'NS_ERROR_DOM_QUOTA_REACHED') &&
            // acknowledge QuotaExceededError only if there's something already stored
            storage.length !== 0;
    }
};

export default isStorageAvailable;
