import { findIndex, findWhere } from 'underscore';
import {
  createReducersForAction,
  getInitialState,
} from '@infosight/elmer/dist/utils/redux';

import {
  LOAD_PREFERENCES,
  DISABLE_DIALOG,
  SET_NOTIFICATION,
  ACCEPT_EULA,
  GET_LANDING_PAGE,
} from './constants';

const initialState = {
  ...getInitialState('preferences'),
  ...getInitialState('landingPage'),
  preferences: {
    dialogs: [],
    acceptedEulas: [],
    notifications: {},
    landingPage: null,
  },
};

const upsertById = (arr, obj) => {
  const idx = findIndex(arr, obj.id);
  if (idx > -1) {
    return [...arr.slice(0, idx), obj, arr.slice(idx + 1)];
  }
  return [...arr, obj];
};

const ACTION_HANDLERS = {
  ...createReducersForAction({
    type: LOAD_PREFERENCES,
    stateKey: 'preferences',
  }),
  [DISABLE_DIALOG]: (state, { payload: id }) => ({
    ...state,
    preferences: {
      ...state.preferences,
      dialogs: upsertById(state.preferences.dialogs, { id, visible: false }),
    },
  }),
  [SET_NOTIFICATION]: (state, { payload: { type, subtype, value } }) => ({
    ...state,
    preferences: {
      ...state.preferences,
      notifications: {
        ...state.notifications,
        [type]: !subtype
          ? value
          : {
              ...state.notifications[type],
              [subtype]: value,
            },
      },
    },
  }),
  [ACCEPT_EULA]: (state, { payload: eula }) => ({
    ...state,
    preferences: {
      ...state.preferences,
      acceptedEulas: [...state.acceptedEulas, eula],
    },
  }),
  ...createReducersForAction({
    type: GET_LANDING_PAGE,
    stateKey: 'landingPage',
  }),
};

export default function reducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}

const baseSelector = (state) => state.preferences;
export const alreadyFetchedPreferences = (state) =>
  baseSelector(state).loadingPreferences ||
  baseSelector(state).loadedPreferences;

// Merge in the initial state so that the individual preferences don't need to check for null
const preferencesSelector = (state) => ({
  ...initialState.preferences,
  loadingPreferences: baseSelector(state).loadingPreferences,
  loadedPreferences: baseSelector(state).loadedPreferences,
  ...baseSelector(state).preferences,
});

export const getNotification = (state, { type, subtype }) => {
  const { notifications } = preferencesSelector(state);
  if (!notifications) {
    return undefined;
  }

  return subtype ? (notifications[type] || {})[subtype] : notifications[type];
};

export const isDialogEnabled = (state, id) => {
  const pref = findWhere(preferencesSelector(state).dialogs, { id });
  return (
    preferencesSelector(state).loadedPreferences && (!pref || pref.visible)
  );
};

export const isEulaAccepted = (state, key) => {
  const { acceptedEulas } = preferencesSelector(state);
  return acceptedEulas && acceptedEulas.includes(key);
};

export const landingPageSelector = (state) => ({
  loadingLandingPage: baseSelector(state).loadingLandingPage,
  loadedLandingPage: baseSelector(state).loadedLandingPage,
  ...baseSelector(state).landingPage,
});
