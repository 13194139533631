import { URL_UPDATE, TITLE_UPDATE } from './constants';

export function updateURL(path, action, title) {
  return {
    type: URL_UPDATE,
    payload: {
      path,
      action,
      title,
    },
  };
}

export function updateTitle(title) {
  return {
    type: TITLE_UPDATE,
    payload: {
      title,
    },
  };
}
