import {
  applyMiddleware,
  createStore as createReduxStore,
  combineReducers,
} from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import { batchDispatchMiddleware } from 'redux-batched-actions';
import { setStore } from './storeProvider';
// Reducers
import extensibility from '../extensibility/reducer';
import user, { initialState as userInitialState } from '../user/reducer';
import bulletins from '../bulletins/reducer';
import search from '../search/reducer';
import preferences from '../preferences/reducer';
import bootstrapper from '../bootstrapper/reducer'; // TODO get rid of this after refactoring experimental mode
import inventory from '../inventory/reducer';
import iam from '../iam/reducer';
import maintenance from '../maintenance/reducer';
import resources from '../resources/reducer';
import navMenu from '../primaryNav/reducer';
import registration from '../registration/reducer';
import { saveState, loadState } from './localStorage';

const makeRootReducer = (asyncReducers) =>
  combineReducers({
    extensibility,
    user,
    bulletins,
    search,
    preferences,
    bootstrapper,
    inventory,
    iam,
    maintenance,
    resources,
    navMenu,
    registration,
    ...asyncReducers,
  });

export const injectReducers = (store, reducers) => {
  store.replaceReducer(
    makeRootReducer(Object.assign(store.asyncReducers, reducers))
  );
};

// eslint-disable-next-line import/prefer-default-export
export const createStore = (initialState = {}) => {
  const store = createReduxStore(
    makeRootReducer(),
    // initialState is merged with state loaded from localStorage
    loadState({ user: userInitialState, ...initialState }),
    composeWithDevTools({ name: 'shell' })(
      applyMiddleware(thunk, batchDispatchMiddleware)
    )
  );

  store.subscribe(() => {
    // choose which reducers to save here
    // be sure to include initialState of those reducers above in loadState
    saveState({
      user: {
        historyList: store.getState().user.historyList,
      },
    });
  });

  store.asyncReducers = {};

  setStore(store);

  return store;
};
