import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

/**
 * Secondary (right) side of nav
 */
const NavBarSecondary = ({ className, children }) => (
    <ul className={classnames(className, 'flex-container top-bar-right')}>
        {children}
    </ul>
);

NavBarSecondary.propTypes = {
    children: PropTypes.node.isRequired,
};

export default NavBarSecondary;
