import React from 'react';
import PrimaryNavExtension from './PrimaryNavExtension';
import ProliantPrimaryNavExtension from './external/ProliantPrimaryNavExtension';
import NimblePrimaryNavExtension from './external/NimblePrimaryNavExtension';
import SimplivityAnalyticsPrimaryNavExtension from './external/SimplivityAnalyticsPrimaryNavExtension';
import OculusPrimaryNavExtension from './external/OculusPrimaryNavExtension';
import IrisPrimaryNavExtension from './external/IrisPrimaryNavExtension';

import RouterExtension from './RouterExtension';

const ResourcesExtensions = () => (
  <>
    <PrimaryNavExtension />
    <ProliantPrimaryNavExtension />
    <NimblePrimaryNavExtension />
    <SimplivityAnalyticsPrimaryNavExtension />
    <OculusPrimaryNavExtension />
    <IrisPrimaryNavExtension />
    <RouterExtension />
  </>
);

export default ResourcesExtensions;
