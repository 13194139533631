import { ExtensionPoint } from '@infosight/shell-api/lib/core';
import { actionNamespacer } from '@infosight/elmer/dist/utils/redux';

const ns = actionNamespacer(`EXTENSIONS/${ExtensionPoint.Search}`);

export const ADD = ns('ADD');
export const REMOVE = ns('REMOVE');
export const RESET = ns('RESET');

const initialState = {};

const ACTION_HANDLERS = {
  [ADD]: (state, { payload: { id, provider } }) => ({
    ...state,
    [id]: provider,
  }),
  [REMOVE]: (state, { payload: { id } }) => ({ ...state, [id]: null }),
  [RESET]: () => initialState,
};

export default (state = initialState, action) => {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
};
