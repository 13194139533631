import React from 'react';
import { Paragraph } from 'grommet';
import {
  closeNavMenuFormTour,
  resetNavMenuForTour,
} from '../primaryNav/actionCreators';

export default function createNewNavTour() {
  return {
    appId: 'shell',
    name: 'New Nav Tour',
    id: 'shell_new_nav_tour',
    version: 1,
    hideBeacon: true,
    shepherdOptions: {
      defaultStepOptions: {
        arrow: true,
      },
      events: {
        complete: () => {
          closeNavMenuFormTour();
        },
        cancel: () => {
          closeNavMenuFormTour();
        },
      },
      steps: [
        {
          title: 'Improved Navigation',
          text: (
            <>
              <Paragraph fill>
                InfoSight has new and improved navigation. Familiarize yourself
                with the reorganized content for your products.
              </Paragraph>
            </>
          ),
          hideBeacon: true,
        },
        {
          title: 'Navigation Menu',
          text: (
            <>
              <Paragraph>
                Access the main menu wherever you are in InfoSight.
              </Paragraph>
            </>
          ),
          attachTo: {
            element: '[data-tour-step="nav-button"]',
            on: 'bottom',
          },
          canClickTarget: false,
          buttons: 'forward-only',
        },
        {
          // KET: beforeShowPromise strategy taken from https://github.com/shipshapecode/shepherd/issues/343
          beforeShowPromise() {
            return new Promise(function (resolve) {
              function waitForElement() {
                const resourcesElement = window.top.document.querySelector(
                  '[data-tour-step="nav-app-product"]'
                );
                if (resourcesElement) {
                  resolve();
                } else {
                  resetNavMenuForTour();
                  setTimeout(waitForElement, 200);
                }
              }

              waitForElement();
            });
          },
          title: 'Products',
          text: (
            <>
              <Paragraph fill>
                Select a product or family for their InfoSight content and
                resources.
              </Paragraph>
            </>
          ),
          attachTo: {
            element: '[data-tour-step="nav-app-product"]',
            on: 'right',
          },
          canClickTarget: false,
          buttons: 'forward-only',
        },
        {
          beforeShowPromise() {
            return new Promise(function (resolve) {
              function waitForElement(clickAppButton = false) {
                const resourcesElement = window.top.document.querySelector(
                  '[data-tour-step="nav-application"]'
                );
                if (resourcesElement) {
                  resolve();
                } else {
                  if (clickAppButton) {
                    const appElement = window.top.document.querySelector(
                      '[data-tour-step="nav-app-product"]'
                    );
                    appElement.click();
                  }
                  setTimeout(waitForElement, 200);
                }
              }
              waitForElement(true);
            });
          },
          title: 'Product Menu',
          text: (
            <>
              <Paragraph fill>
                This is the menu for a product or family of products.
                Product/Family name and your organization are listed at the top,
                product/family specific pages are listed below in the menu with
                related resources listed at the bottom.
              </Paragraph>
            </>
          ),
          attachTo: {
            element: '[data-tour-step="nav-application"]',
            on: 'right',
          },
          canClickTarget: false,
          buttons: 'forward-only',
        },
        {
          title: 'Main Menu',
          text: (
            <>
              <Paragraph fill>Click to return to the main menu.</Paragraph>
            </>
          ),
          attachTo: {
            element: '[data-tour-step="nav-main-menu"]',
            on: 'bottom',
          },
          canClickTarget: false,
          buttons: 'forward-only',
        },
        {
          beforeShowPromise() {
            return new Promise(function (resolve) {
              function waitForElement(clickAppButton = false) {
                const resourcesElement = window.top.document.querySelector(
                  '[data-tour-step="nav-resources"]'
                );
                if (resourcesElement) {
                  resolve();
                } else {
                  if (clickAppButton) {
                    const appElement = window.top.document.querySelector(
                      '[data-tour-step="nav-main-menu"]'
                    );
                    appElement.click();
                  }
                  setTimeout(waitForElement, 200);
                }
              }
              waitForElement(true);
            });
          },
          title: 'Resources',
          text: (
            <>
              <Paragraph fill>
                Access documentation, tools, and other resources here.
              </Paragraph>
            </>
          ),
          attachTo: {
            element: '[data-tour-step="nav-resources"]',
            on: 'right',
          },
          canClickTarget: false,
          buttons: 'forward-only',
        },
        {
          title: 'Administration',
          text: (
            <>
              <Paragraph fill>
                Access organization, user management, and other tools here.
              </Paragraph>
            </>
          ),
          attachTo: {
            element: '[data-tour-step="nav-administration"]',
            on: 'right',
          },
          canClickTarget: false,
          buttons: 'forward-only',
        },
        {
          title: 'Close',
          text: (
            <>
              <Paragraph fill>
                Close the navigation menu to return to the InfoSight page you
                were on.
              </Paragraph>
            </>
          ),
          attachTo: {
            element: '[data-tour-step="nav-exit"]',
            on: 'bottom',
          },
          canClickTarget: false,
          buttons: 'forward-only',
        },
      ],
    },
  };
}
