import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames/bind';
import { Box, Text, Accordion, AccordionPanel } from 'grommet/es6';
import { FormUp, FormDown } from 'grommet-icons';
import { useSelector, useDispatch } from 'react-redux';
import NavMenuBox from './NavMenuBox';
import NavMenuLink from './NavMenuLink';
import style from './NavMenu.scss';
import { updateActivePanels } from '../actionCreators';
import { activePanelSelector, getActivePanels } from '../reducer';

const cx = classnames.bind(style);

const NavAccordionPanel = ({
  title,
  icon,
  options,
  active,
  nested,
  onNavigate,
  parentPanelKey,
  dataTourStep,
}) => {
  const panelKey = `${parentPanelKey}-${title}`;
  const activePanelStore = useSelector(activePanelSelector);
  const activePanels = getActivePanels(activePanelStore, panelKey);
  const dispatch = useDispatch();
  return (
    <div className={cx('accordion-panel')} data-tour-step={dataTourStep}>
      <AccordionPanel
        header={
          <NavMenuBox
            justify="between"
            pad={
              nested
                ? { left: 'large', right: 'medium' }
                : { horizontal: 'medium' }
            }
            className={cx('menu-pulldown', {
              'active-menu-pulldown': active,
              subelement: nested,
            })}
          >
            {icon ? (
              <Box direction="row" align="center">
                {icon}
                <Text margin={{ left: 'small' }}>{title}</Text>
              </Box>
            ) : (
              <span>{title}</span>
            )}
            {active ? <FormUp /> : <FormDown />}
          </NavMenuBox>
        }
      >
        <Accordion
          className={cx('container')}
          onActive={(activeIndexes) => {
            dispatch(updateActivePanels(panelKey, activeIndexes));
          }}
          activeIndex={activePanels}
        >
          {options.map((data, i) => {
            return data.options ? (
              <NavAccordionPanel
                key={`${data.id} - ${data.title}`}
                title={data.title}
                icon={data.icon}
                options={data.options}
                active={activePanels.includes(i)}
                onNavigate={onNavigate}
                nested
                parentPanelKey={panelKey}
              />
            ) : (
              <NavMenuLink
                key={`${data.id} - ${data.title}`}
                pad={{ left: nested ? '70px' : 'large', right: 'medium' }}
                className={cx('subelement')}
                onNavigate={onNavigate}
                {...data}
              />
            );
          })}
        </Accordion>
      </AccordionPanel>
    </div>
  );
};

NavAccordionPanel.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.node,
  options: PropTypes.array.isRequired,
  active: PropTypes.bool.isRequired,
  nested: PropTypes.bool,
  onNavigate: PropTypes.func,
  parentPanelKey: PropTypes.string.isRequired,
  dataTourStep: PropTypes.string,
};

NavAccordionPanel.defaultProps = {
  icon: undefined,
  nested: false,
  onNavigate: () => {},
};

export default NavAccordionPanel;
