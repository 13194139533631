import { combineReducers } from 'redux';
import orchestrator from './orchestrator/reducer';
import { id as primaryNavId, reducer as primaryNav } from './primaryNav';
import { id as searchId, reducer as search } from './search';
import { id as routerId, reducer as router } from './router';
import { id as bootstrapperId, reducer as bootstrapper } from './bootstrapper';
import { id as bannerId, reducer as banner } from './banner';
import { id as homeId, reducer as home } from './home';
import { id as tourId, reducer as tour } from './tour';

const reducers = {
  orchestrator,
  [primaryNavId]: primaryNav,
  [searchId]: search,
  [routerId]: router,
  [bootstrapperId]: bootstrapper,
  [bannerId]: banner,
  [homeId]: home,
  [tourId]: tour,
};

export default combineReducers(reducers);
