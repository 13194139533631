import { Component } from 'react';
import PropTypes from 'prop-types';
import autobind from 'react-autobind';
import { withRouter } from 'react-router-dom';
import { APP_BASE_URL } from '../config';

/**
 * Partial vanilla implementation of jQuery.on() based on https://stackoverflow.com/a/23978597
 * Traverse DOM from event target up to parent, searching for selector.
 */
function passedThrough(event, selector) {
  let currentNode = event.target;

  while (currentNode) {
    if (currentNode.matches(selector)) {
      return currentNode;
    }
    if (currentNode !== event.currentTarget && currentNode !== document.body) {
      currentNode = currentNode.parentNode;
    } else {
      return false;
    }
  }

  return false;
}

/**
 * Adapt raw anchors to behave as history aware links similar to react-router's <Link> component.
 * The <a> must have the 'history-aware' class to have this behavior applied.
 *
 * This should be used only for links are rendered outside of the React tree.
 * For example, links in Highcharts tooltips or from legacy BackboneJS pages.
 *
 * See https://github.com/ReactTraining/react-router/blob/master/packages/react-router-dom/modules/Link.js
 */
class HistoryAwareLinkAdapter extends Component {
  constructor(props) {
    super(props);
    autobind(this);
  }

  componentDidMount() {
    document.body.addEventListener('click', this.redirect);
  }

  componentWillUnmount() {
    // AppContainer can be remounted when redirecting from /tenant/id/... urls to /... urls.
    document.body.removeEventListener('click', this.redirect);
  }

  redirect(event) {
    const target = passedThrough(event, 'a[href].history-aware');
    if (target) {
      event.preventDefault();

      const { history } = this.props;
      const href = target.getAttribute('href');

      console.assert(
        href.startsWith(APP_BASE_URL),
        `href ${href} did not start with ${APP_BASE_URL}`
      );

      const route =
        APP_BASE_URL === '/' ? href : href.replace(APP_BASE_URL, '');
      history.push(route);
    }
  }

  render() {
    return null;
  }
}

HistoryAwareLinkAdapter.propTypes = {
  // Injected by withRouter
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default withRouter(HistoryAwareLinkAdapter);
