import React from 'react';
import PropTypes from 'prop-types';
import { IconAdaptor } from '@infosight/elmer/dist/components/IconAdaptor';
import { User as GrommetUser } from 'grommet-icons';
import { Menu } from '@infosight/elmer/dist/components/NavBar';
import Item from './Item';

const UserItem = ({ options }) => (
  <Item
    menuOptions={options}
    title={() => (
      <div
        title="User Menu"
        style={{
          height: '24px',
        }}
      >
        <IconAdaptor size="small" type="grommet">
          <GrommetUser color="currentColor" />
        </IconAdaptor>
      </div>
    )}
    renderContent={Menu}
    sort={false}
  />
);

UserItem.propTypes = {
  options: PropTypes.object,
};

export default UserItem;
