import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Shepherd from 'shepherd.js';
import { activeTourSelector } from '../../extensibility/tour/reducer';
import { stopTourAction } from '../../extensibility/tour/actionCreators';
import { appIsReadySelector } from '../../extensibility/bootstrapper/reducer';
import createShepherdTour from '../createShepherdTour';

export const TourGuide = ({ activeTour, stopTourAction: stopTour }) => {
  useEffect(
    () => {
      if (activeTour) {
        const shepherdTour = createShepherdTour(activeTour, stopTour);
        shepherdTour.start();
      }

      return () => {
        if (Shepherd.activeTour) {
          Shepherd.activeTour.cancel();
        }
      };
    },
    [activeTour, stopTour],
    stopTour
  );

  return null;
};

TourGuide.propTypes = {
  activeTour: PropTypes.object,
  stopTourAction: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  activeTour: appIsReadySelector(state) ? activeTourSelector(state) : null,
});

export default connect(mapStateToProps, { stopTourAction })(TourGuide);
