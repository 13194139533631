import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import BEM from 'src/utils/bem';

const bem = new BEM('elmer-Tags');

/**
 * Styling container for a collection of [Tag](#Tag) elements
 */
const Tags = ({ className, children }) => <div className={classnames(className, bem.valueOf())}>{children}</div>;

Tags.propTypes = {
    children: PropTypes.node.isRequired,
};

export default Tags;
