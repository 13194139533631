/**
 * Special queue to make sure starting one tour while another is in progress does not destroy the user experience
 */
export default class ActiveTourQueue {
  constructor(elements) {
    this.elements = Object.freeze(elements || []);
  }

  add(element) {
    let elements = [...this.elements];
    const existingElementIndex = elements.indexOf(element);
    if (existingElementIndex === 0) {
      // It's the first element, which means it's _probably_ visible now, so don't move it
      return this;
    }
    if (existingElementIndex > 0) {
      // Remove it from it's current position
      elements = elements.filter((x) => x !== element);
    }

    // Add the element to the end of the queue
    return new ActiveTourQueue(elements.concat(element));
  }

  peek() {
    return this.elements[0];
  }

  remove(element) {
    let elements = [...this.elements];
    if (elements.includes(element)) {
      // Remove it from it's current position
      elements = elements.filter((x) => x !== element);
    }

    // Add the element to the end of the queue
    return new ActiveTourQueue(elements);
  }
}
