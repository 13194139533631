import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

/**
 * Primary (left) side of nav
 */
const NavBarTitle = ({ className, children }) => (
    <li className={classnames(className, 'top-bar-title flex-container')}>
        {children}
    </li>
);

NavBarTitle.propTypes = {
    children: PropTypes.node.isRequired,
};

export default NavBarTitle;
