import { actionNamespacer } from '@infosight/elmer/dist/utils/redux';

const ns = actionNamespacer('SEARCH');

export const REGISTER_SUBJECTS = ns('REGISTER_SUBJECTS');
export const ADD_SEARCH_RESULT = ns('ADD_SEARCH_RESULT');
export const RESET = ns('RESET');

export const START_SEARCH = ns('START_SEARCH');
export const RESET_SEARCH = ns('RESET_SEARCH');

export const STATES = {
  AWAITING_REQUEST: 'AWAITING_REQUEST',
  AWAITING_RESULTS: 'AWAITING_RESULTS',
  RECEIVED_RESULTS: 'RECEIVED_RESULTS',
  ERROR: 'ERROR',
};
