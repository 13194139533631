import slugify from 'slugify';
import { ENQUEUE_TOUR, DEQUEUE_TOUR } from './reducer';

export const generateId = (appId, name) =>
  `${appId}_${slugify(name, {
    lower: true,
    strict: true,
    remove: /[*+~.,/\\<>'"!:@]/g,
  })}`;

export const startTourAction = ({ appId, name }) => (dispatch) => {
  dispatch({ type: ENQUEUE_TOUR, payload: { id: generateId(appId, name) } });
};

export const stopTourAction = ({ appId, name }) => (dispatch) => {
  dispatch({ type: DEQUEUE_TOUR, payload: { id: generateId(appId, name) } });
};
