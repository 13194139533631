import React from 'react';
import PropTypes from 'prop-types';
import { Box } from 'grommet';
import BEM from 'src/utils/bem';
import OverlayTrigger from '../OverlayTrigger';

const bem = new BEM('elmer-PopoverTrigger');

/**
 * Popovers are basically heavyweight [Tooltips](#Tooltip).
 * This component adds base styling and proxies props to [OverlayTrigger](#OverlayTrigger)
 */
const PopoverTrigger = ({ children, popover, ...props }) => (
    <OverlayTrigger
        trigger="hover"
        {...props}
        overlay={<Box elevation="small" background="white" pad="small">{popover}</Box>}
    >
        <div className={bem.e('container')}>{children}</div>
    </OverlayTrigger>
);

PopoverTrigger.propTypes = {
    popover: PropTypes.element.isRequired,
    children: PropTypes.node,
    popperProps: PropTypes.object,
};

export default PopoverTrigger;
