import {
  createReducersForAction,
  createSelector,
  getInitialState,
} from '@infosight/elmer/dist/utils/redux';
import { FETCH_MAINTENANCE } from './constants';

const initialState = {
  ...getInitialState('maintenance'),
};

const ACTION_HANDLERS = {
  ...createReducersForAction({
    type: FETCH_MAINTENANCE,
    stateKey: 'maintenance',
  }),
};

export default function reducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}

const baseSelector = (state) => state.maintenance;
export const maintenanceSelector = createSelector(baseSelector, 'maintenance');
