import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import GlobalBanner, {
  WARNING,
} from '@infosight/elmer/dist/components/GlobalBanner';
import { shouldShowWelcomePageSelector } from '../selectors';

const WelcomeBanner = ({ visible, isOnWelcomePage }) => (
  <>
    {visible && (
      <GlobalBanner
        severity={WARNING}
        content={
          <>
            There are no systems associated with your user profile.
            {!isOnWelcomePage && (
              <>
                <strong>
                  &nbsp;<Link to="/home">Click here</Link>
                </strong>{' '}
                to get started.
              </>
            )}
          </>
        }
      />
    )}
  </>
);

WelcomeBanner.propTypes = {
  visible: PropTypes.bool,
  isOnWelcomePage: PropTypes.bool,
};

const mapStateToProps = (state, { location }) => ({
  visible: shouldShowWelcomePageSelector(state),
  // For new users, the landing page is the same as the home/welcome page, and they both key off of shouldShowWelcomePage
  isOnWelcomePage:
    location.pathname.endsWith('home') || location.pathname.endsWith('landing'),
});

export default withRouter(connect(mapStateToProps)(WelcomeBanner));
