import { ExtensionPoint } from '@infosight/shell-api/lib/core';
import reducer, { ADD, REMOVE } from './reducer';

function addSubject(opts) {
  this.props.dispatch({ type: ADD, payload: opts });
}

function removeSubject(opts) {
  this.props.dispatch({ type: REMOVE, payload: opts });
}

export const id = ExtensionPoint.Search;
export const commands = { addSubject, removeSubject };
export { reducer };
