import deepGet from 'utils-deep-get';
import {
  createReducersForAction,
  createSelector,
  getInitialState,
} from '@infosight/elmer/dist/utils/redux';
import { createSelector as createMemoizedSelector } from 'reselect';
import {
  UPDATE_SESSION,
  UPDATE_SESSION_SUCCEEDED,
  FETCH_RECENT_TENANTS,
  SEARCH_TENANTS,
  RESET_TENANT_SELECTOR,
  SET_TENANT_COUNT,
  MAX_VISIBLE_TENANT_COUNT,
  FETCH_CURRENT_USER_TENANTS,
  AUTHORIZE_NEXT_TENANT,
  UPDATE_USER_PROPERTIES_SUCCEEDED,
  FETCH_OFFICIAL_DEMO_TENANTS,
  OPT_IN_USER_PROPERTY_PREFIX,
  FETCH_CURRENT_USER_USER_TENANTS,
} from './constants';
import { convertSessionToTenant } from './services';
import { TOUR_USER_PROPERTY_PREFIX } from '../tour/constants';
import googleAnalytics from '../user/services/analytics/googleAnalyticsProvider';
import { DEMO_ROLE_URN } from '../demo/constants';
import { isOfficialDemoTenant } from '../demo/util';

const initialState = {
  ...getInitialState('recentTenants'),
  ...getInitialState('currentUserTenants'),
  ...getInitialState('currentUserUserTenants'),
  ...getInitialState('primaryTenant'),
  ...getInitialState('tenantSearchHits'),
  ...getInitialState('nextTenantAuthorization'),
  ...getInitialState('officialDemoTenants'),
  ...getInitialState('session'),
  tenantCount: 0,
  activeTenant: null,
};

const ACTION_HANDLERS = {
  ...createReducersForAction({
    type: FETCH_RECENT_TENANTS,
    stateKey: 'recentTenants',
  }),
  ...createReducersForAction({
    type: FETCH_CURRENT_USER_TENANTS,
    stateKey: 'currentUserTenants',
  }),
  ...createReducersForAction({
    type: FETCH_CURRENT_USER_USER_TENANTS,
    stateKey: 'currentUserUserTenants',
  }),
  ...createReducersForAction({
    type: SEARCH_TENANTS,
    stateKey: 'tenantSearchHits',
  }),
  ...createReducersForAction({
    type: AUTHORIZE_NEXT_TENANT,
    stateKey: 'nextTenantAuthorization',
  }),
  ...createReducersForAction({
    type: FETCH_OFFICIAL_DEMO_TENANTS,
    stateKey: 'officialDemoTenants',
  }),
  [RESET_TENANT_SELECTOR]: (state) => ({
    ...state,
    ...getInitialState('recentTenants'),
    ...getInitialState('tenantSearchHits'),
  }),
  [SET_TENANT_COUNT]: (state, { payload }) => ({
    ...state,
    tenantCount: payload || 0,
  }),
  ...createReducersForAction({ type: UPDATE_SESSION, stateKey: 'session' }),
  [UPDATE_SESSION_SUCCEEDED]: (state, { payload }) => {
    googleAnalytics.setDimensions(payload.oculusRoles, payload.userId);
    return {
      ...state,
      session: payload,
      loadingSession: false,
      loadedSession: true,
      errorSession: false,
    };
  },
  [UPDATE_USER_PROPERTIES_SUCCEEDED]: (state, { payload: properties }) => {
    // If it doesn't exist, it's too early in the app for this action to have been realistically fired, or there is something else wrong.
    // This saves me from the madness of having to deeply check each level
    if (!deepGet(state, 'session.user.properties')) {
      return state;
    }

    return {
      ...state,
      session: {
        ...state.session,
        user: {
          ...state.session.user,
          properties,
        },
      },
    };
  },
};

export default function reducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}

const baseSelector = (state) => state.iam;

export const sessionSelector = createSelector(baseSelector, 'session');
export const recentTenantsSelector = createSelector(
  baseSelector,
  'recentTenants'
);
export const currentUserTenantsSelector = createSelector(
  baseSelector,
  'currentUserTenants'
);
export const currentUserUserTenantsSelector = createSelector(
  baseSelector,
  'currentUserUserTenants'
);
export const nextTenantAuthorizationSelector = createSelector(
  baseSelector,
  'nextTenantAuthorization'
);
export const officialDemoTenantsSelector = createSelector(
  baseSelector,
  'officialDemoTenants'
);

/**
 * Gets the actual session object out of the standard scheme generated by out redux utils
 */
export const iamSessionSelector = (state) => sessionSelector(state).session;

/**
 * The Customer's Name is their email domain
 */
export const customerNameSelector = (state) => {
  const session = iamSessionSelector(state);
  const username = session && session.userName;
  if (!username) {
    return username;
  }

  const [, domain] = username.split('@');
  return domain;
};
export const activeTenantSelector = createMemoizedSelector(
  iamSessionSelector,
  (session) => convertSessionToTenant(session)
);

// TODO: this will be replaced with item to check against officialDemoTenantsSelector rather than hardcoded value
export const officialDemoSelector = (state) => {
  return isOfficialDemoTenant(activeTenantSelector(state));
};

export const primaryTenantSelector = () => undefined;
export const tenantSearchHitsSelector = createSelector(
  baseSelector,
  'tenantSearchHits'
);

const tenantCountSelector = (state) => baseSelector(state).tenantCount;
const currentUserUserTenantsCountSelector = (state) => {
  const { currentUserUserTenants } = currentUserUserTenantsSelector(state);
  return (currentUserUserTenants && currentUserUserTenants.length) || 0;
};

export const tenantSelectorVisibilitySelector = createMemoizedSelector(
  tenantCountSelector,
  currentUserUserTenantsCountSelector,
  (tenantCount, currentUserUserTenantsCount) => ({
    showSearch: tenantCount > MAX_VISIBLE_TENANT_COUNT,
    showMyOrgs: currentUserUserTenantsCount > 0,
    showRecentTenants: tenantCount > MAX_VISIBLE_TENANT_COUNT,
    showCurrentUserTenants: tenantCount <= MAX_VISIBLE_TENANT_COUNT,
    showOfficialDemoTenants: true,
    enabled: tenantCount > 1,
  })
);

export const userPropertiesSelector = (state) => {
  const session = iamSessionSelector(state);
  return (session && session.user && session.user.properties) || null;
};

const createMemoizedUserPropertiesSelector = (prefix, lambda) => {
  return createMemoizedSelector(userPropertiesSelector, (properties) => {
    if (!properties) {
      return null;
    }

    return properties.reduce((memo, { urn, value }) => {
      if (urn.startsWith(prefix)) {
        lambda(memo, urn, value);
      }
      return memo;
    }, {});
  });
};

export const tourUserPropertiesSelector = createMemoizedUserPropertiesSelector(
  TOUR_USER_PROPERTY_PREFIX,
  (memo, urn, value) => {
    try {
      memo[urn.replace(TOUR_USER_PROPERTY_PREFIX, '')] = JSON.parse(value);
    } catch (e) {
      console.warn(`Expected JSON, got ${value}`);
    }
  }
);

export const demoRolePropertiesSelector = createMemoizedUserPropertiesSelector(
  DEMO_ROLE_URN,
  (memo, urn, value) => {
    try {
      memo.demoRole = value;
    } catch (e) {
      console.warn(`Failed getting demo role`);
    }
  }
);

const featureOptInSelector = createMemoizedUserPropertiesSelector(
  OPT_IN_USER_PROPERTY_PREFIX,
  (memo, urn, value) => {
    const feature = urn.replace(OPT_IN_USER_PROPERTY_PREFIX, '');
    try {
      if (!memo.optIn) {
        memo.optIn = {};
      }
      memo.optIn[feature] = value === 'true';
    } catch (e) {
      console.warn(`Failed getting opt in value for feature: ${feature}`);
    }
  }
);

export const navOptInSelector = (state) => {
  const featureOptIn = featureOptInSelector(state);
  return featureOptIn && featureOptIn.optIn && featureOptIn.optIn.navigation;
};
