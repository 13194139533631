import { REQUEST_SCOPE, REQUEST_SCOPE_ENDED, ADD_PRODUCT_INTERFACE } from './constants';

const initialState = {
    targetView: null,
    productInterfaces: {},
};

const ACTION_HANDLERS = {
    [REQUEST_SCOPE]: (state, { payload }) => ({ ...state, targetView: payload }),
    [REQUEST_SCOPE_ENDED]: state => ({ ...state, targetView: null }),
    [ADD_PRODUCT_INTERFACE]: (state, { payload }) => ({ ...state, productInterfaces: { ...state.productInterfaces, [payload.id]: payload } }),
};

export default function infrastructureReducer(state = initialState, action) {
    const handler = ACTION_HANDLERS[action.type];
    return handler ? handler(state, action) : state;
}

const baseSelector = state => state;
export const targetViewSelector = state => baseSelector(state).targetView;
export const productInterfacesSelector = state => baseSelector(state).productInterfaces;
