import { command, ExtensionPoint } from '@infosight/shell-api/lib/core';
import { UserContextChangeReason } from '@infosight/shell-api/lib/UserProfile';
import {
  actionsFor,
  getSuccessActionType,
} from '@infosight/elmer/dist/utils/redux';
import { batchActions } from 'redux-batched-actions';
import { sortBy } from 'underscore';
import {
  UPDATE_SESSION,
  SET_TENANT_COUNT,
  FETCH_RECENT_TENANTS,
  FETCH_OFFICIAL_DEMO_TENANTS,
  SEARCH_TENANTS,
  RESET_TENANT_SELECTOR,
  FETCH_CURRENT_USER_TENANTS,
  AUTHORIZE_NEXT_TENANT,
  UPDATE_SESSION_SUCCEEDED,
  FETCH_CURRENT_USER_USER_TENANTS,
} from './constants';
import { RESET as RESET_INVENTORY } from '../inventory/constants';
import { GET_LANDING_PAGE } from '../preferences/constants';
import { landingPageProperties } from '../preferences/actionCreators';
import { RESET_ACTIVE_PANELS } from '../primaryNav/constants';
import refreshUserContext from '../bootstrapper/refreshUserContext';
import postIAMRequest from '../services/postIAMRequest';
import { trackIgniteAccess } from '../user/services/analytics/newRelicBrowserProvider';

const removeAppLoadingInterstitial = () => {
  // Fade out and remove the loading dots
  const initialInterstitial = document.getElementById('initial-interstitial');
  if (initialInterstitial) {
    initialInterstitial.classList.add('exit');
  }
  window.setTimeout(() => {
    const node = document.getElementById('initial-interstitial');
    if (node && node.parentNode) {
      node.parentNode.removeChild(node);
    }
  }, 2500);
};

/**
 * Since we can't access a secure cookie's content, make a call to fetch the contents or create a new cookie
 */
export function fetchIamSession() {
  return async (dispatch) => {
    const { dispatchStart, dispatchError } = actionsFor(
      UPDATE_SESSION,
      dispatch
    );
    dispatchStart();

    // we want this done asynchronously and simultaneously with the IAM call
    dispatch(refreshUserContext());

    try {
      const {
        data: { errors, data },
      } = await postIAMRequest({
        query: `
                {
                    currentSession {
                        identityProvider
                        providerId
                        userId
                        userName
                        tenantId
                        tenantName
                        userRole
                        emailVerification
                        userRealm
                        claimTags
                        oculusRoles
                        oculusGroups
                        demoOnlyAccess
                        eulaAccepted
                        authMode
                        user {
                            properties {
                                urn
                                value
                            }
                        }
                        userTenant {
                            role
                            properties {
                                urn
                                value
                            }
                        }
                    }
                    currentUserEstimatedTenantCount
                }`,
      });

      removeAppLoadingInterstitial();

      if (errors) {
        dispatchError(errors);
        return;
      }

      const { currentSession, currentUserEstimatedTenantCount } = data;

      const ssoMode =
        currentSession.authMode &&
        currentSession.authMode.toLowerCase() === 'sso';

      if (currentSession.emailVerification !== 'VERIFIED') {
        window.location.assign('/app/verifyemail');
        return;
      }

      if (!currentSession.eulaAccepted) {
        window.location.assign('/app/termsofuse');
        return;
      }

      if (ssoMode) {
        // This block of oculus-specific code has to be here instead of in portal-oculus-frontend because we need to compute the oculusRoles for the
        // googleAnalyticsProvider's customDimensions, in addition to everything portal-oculus-frontend needs the oculusRoles for.
        const oculusRolePrefix = 'urn:productfamily:oculus:role:';

        if (currentSession.oculusGroups) {
          currentSession.oculusRoles = currentSession.oculusGroups
            .filter((group) => group.startsWith(oculusRolePrefix))
            .map((group) => group.slice(oculusRolePrefix.length));
          if (currentSession.oculusRoles.length === 0) {
            if (currentSession.userRealm === 'INTERNAL') {
              currentSession.oculusRoles = ['support'];
            } else if (currentSession.userRealm === 'EXTERNAL') {
              currentSession.oculusRoles = ['customer'];
            }
          }
        }

        currentSession.oculusRoles = currentSession.oculusRoles || [];
      }

      const actions = [
        // Store the JWT contents
        { type: UPDATE_SESSION_SUCCEEDED, payload: currentSession },

        // Set the tenant count to determine whether or not to show tenant selector
        { type: SET_TENANT_COUNT, payload: currentUserEstimatedTenantCount },

        // Set the landing page to start the loading process
        {
          type: GET_LANDING_PAGE,
          payload: landingPageProperties(currentSession.user.properties),
        },
      ];

      dispatch(batchActions(actions));

      command(
        ExtensionPoint.UserProfile,
        'userContextChange',
        UserContextChangeReason.Bootstrap
      );
    } catch (e) {
      dispatchError(e);
    }
  };
}

export function fetchRecentTenants() {
  return async (dispatch) => {
    const { dispatchStart, dispatchSuccess, dispatchError } = actionsFor(
      FETCH_RECENT_TENANTS,
      dispatch
    );

    dispatchStart();

    try {
      const { data } = await postIAMRequest({
        query:
          '{ currentUserMruTenants { tenant { id name description claims { urn } } lastAccess } }',
      });
      const merged = data.data.currentUserMruTenants.map(
        ({ tenant, lastAccess }) => ({
          ...tenant,
          timestamp: lastAccess,
        })
      );

      dispatchSuccess(sortBy(merged, 'timestamp').reverse());
    } catch (e) {
      dispatchError(e);
    }
  };
}

export function fetchCurrentUserTenants() {
  return async (dispatch) => {
    const { dispatchStart, dispatchSuccess, dispatchError } = actionsFor(
      FETCH_CURRENT_USER_TENANTS,
      dispatch
    );

    dispatchStart();

    try {
      const { data } = await postIAMRequest({
        query: '{ currentUserTenants { id name description claims { urn } } }',
      });

      dispatchSuccess(sortBy(data.data.currentUserTenants, 'name'));
    } catch (e) {
      dispatchError(e);
    }
  };
}

export function fetchCurrentUserUserTenants() {
  return async (dispatch) => {
    const { dispatchStart, dispatchSuccess, dispatchError } = actionsFor(
      FETCH_CURRENT_USER_USER_TENANTS,
      dispatch
    );

    dispatchStart();

    try {
      const { data } = await postIAMRequest({
        query:
          '{ currentUserUserTenants { tenant{ id name description } role } }',
      });

      dispatchSuccess(data.data.currentUserUserTenants);
    } catch (e) {
      dispatchError(e);
    }
  };
}

export function fetchOfficialDemoTenants() {
  return async (dispatch) => {
    const { dispatchStart, dispatchSuccess, dispatchError } = actionsFor(
      FETCH_OFFICIAL_DEMO_TENANTS,
      dispatch
    );

    dispatchStart();

    try {
      const { data } = await postIAMRequest({
        query: '{ officialDemoTenants { id name description } }',
      });
      dispatchSuccess(
        sortBy(
          data.data.officialDemoTenants.map((tenant) => {
            // TODO: highlighting here doesnt work for searches need to rethink how we do the highlighting
            return { ...tenant, highlighted: true };
          })
        ),
        'name'
      );
    } catch (e) {
      dispatchError(e);
    }
  };
}

export function searchTenants({ query }) {
  return async (dispatch) => {
    const { dispatchStart, dispatchSuccess, dispatchError } = actionsFor(
      SEARCH_TENANTS,
      dispatch
    );

    dispatchStart();
    if (query && query.length > 0) {
      try {
        const { data } = await postIAMRequest({
          query: `
                            query($searchTerm: String!) {
                              currentUserTenantSearch(searchTerm: $searchTerm) {
                                id
                                name
                                description
                                claims { urn }
                              }
                            }`,
          variables: { searchTerm: query },
        });

        dispatchSuccess(sortBy(data.data.currentUserTenantSearch, 'name'));
      } catch (e) {
        dispatchError(e);
      }
    } else {
      dispatchSuccess(null);
    }
  };
}

export function resetTenantSelector() {
  return { type: RESET_TENANT_SELECTOR };
}

const getSession = async (tenantId) => {
  const {
    data: { data, errors },
  } = await postIAMRequest({
    query: `
        mutation ($tenantId: ID) {
          setTenant(tenantId: $tenantId) {
            identityProvider
            userId
            userName
            tenantId
            tenantName
            userRole
            userRealm
            claimTags
            oculusRoles
            demoOnlyAccess
            authMode
            user {
                properties {
                    urn
                    value
                }
            }
          }
        }`,
    variables: { tenantId },
  });
  if (errors && errors.length) {
    throw { error: errors }; // eslint-disable-line no-throw-literal
  }

  const ssoMode =
    data.setTenant.authMode && data.setTenant.authMode.toLowerCase() === 'sso';

  if (!ssoMode) {
    return data.setTenant;
  }

  // This block of oculus-specific code has to be here instead of in portal-oculus-frontend because we need to compute the oculusRoles for the
  // googleAnalyticsProvider's customDimensions, in addition to everything portal-oculus-frontend needs the oculusRoles for.
  const oculusRolePrefix = 'urn:productfamily:oculus:role:';

  if (data.setTenant.oculusGroups) {
    data.setTenant.oculusRoles = data.setTenant.oculusGroups
      .filter((group) => group.startsWith(oculusRolePrefix))
      .map((group) => group.slice(oculusRolePrefix.length));
    if (data.setTenant.oculusRoles.length === 0) {
      if (data.setTenant.userRealm === 'INTERNAL') {
        data.setTenant.oculusRoles = ['support'];
      } else if (data.setTenant.userRealm === 'EXTERNAL') {
        data.setTenant.oculusRoles = ['customer'];
      }
    }
  }

  data.setTenant.oculusRoles = data.setTenant.oculusRoles || [];

  return data.setTenant;
};

/**
 * Intelligently handle tenant switching based on the current -> next state.
 * @param {string} tenantId
 */
export function switchTenant(tenantId) {
  return async (dispatch) => {
    const { dispatchStart, dispatchError } = actionsFor(
      AUTHORIZE_NEXT_TENANT,
      dispatch
    );
    const {
      dispatchStart: dispatchSessionStart,
      dispatchError: dispatchSessionError,
    } = actionsFor(UPDATE_SESSION, dispatch);
    dispatchStart();
    dispatchSessionStart();

    try {
      const iamSession = await getSession(tenantId);

      // we need to wait for the cookie to be updated before we can fetch the inventory
      dispatch({ type: RESET_INVENTORY });
      dispatch(refreshUserContext());

      dispatch(
        batchActions([
          { type: UPDATE_SESSION_SUCCEEDED, payload: iamSession },
          { type: getSuccessActionType(AUTHORIZE_NEXT_TENANT), payload: true },
          { type: RESET_ACTIVE_PANELS },
        ])
      );

      command(
        ExtensionPoint.UserProfile,
        'userContextChange',
        UserContextChangeReason.Tenant
      );

      trackIgniteAccess();
      dispatch(refreshUserContext());
    } catch (ex) {
      dispatchError(ex);
      dispatchSessionError(ex);
    }
  };
}
