import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { buildUrl } from '@infosight/elmer/dist/utils/url';
import axios from '../../services/axios';
import { setMicroappLoadFailure, setMicroappLoading } from './actionCreators';
import { hasSiteAccessSelector } from '../../user/reducer';
import { sessionSelector } from '../../iam/reducer';
import { env } from '../../config';
import { microappsSelector, orchestratorDataSelector } from './reducer';
import { checkReloadRequired, getBundleId } from './utils';

// Set microapp public paths at runtime
// https://webpack.js.org/guides/public-path/#on-the-fly
const publicPaths = {};
// Prefer a getter function, rather than just expose the publicPaths map at `window.publicPaths`.
// This is documented and exposed to microapps in Shell API rather than using this directly to prevent confusion in devs.
// This may also allow us to abstract this away from devs in @infosight/microapp-scripts
const getPublicPath = (id) => publicPaths[id];
window.__shell__getPublicPath = getPublicPath; // eslint-disable-line no-underscore-dangle

const buildSafeUrl = (...paths) => {
  const unsafeUrl = buildUrl(...paths);
  return paths[0].startsWith('http') ? unsafeUrl.slice(1) : unsafeUrl;
};

const buildUrlWithPublicPath = (id, url) =>
  url.startsWith(getPublicPath(id))
    ? url
    : buildSafeUrl(getPublicPath(id), url);

const getManifest = async (id, manifestUrl, assetsPath) => {
  // Load the manifest which gives us the main js and css to load
  const { data } = await axios.get(manifestUrl);

  if (data.entryVersion === '2') {
    const { manifest, path } = data;
    publicPaths[id] = `${buildSafeUrl(path)}/`;
    return manifest;
  }
  if (!data.entryVersion) {
    // Legacy npm-based microapps
    // Set the public path for the microapp, which knows how to resolve it
    publicPaths[id] = `${buildSafeUrl(assetsPath)}/`;
    return data;
  }

  throw new Error(
    `Microapp: ${id} is not supported by this version of the Shell`
  );
};

const addMicroappScriptTag = (url) =>
  new Promise((resolve, reject) => {
    const el = document.createElement('script');
    el.async = true;
    el.src = url;
    el.type = 'text/javascript';
    el.onload = () => {
      console.debug(`Loaded js: ${url}`);
      resolve();
    };
    el.onerror = (e) => {
      console.error(`Error loading js: ${url} from ${e.target.src}`);
      reject(e);
    };
    document.body.appendChild(el);
  });

const addMicroappCssLinkTag = (url, name) => {
  const el = document.createElement('link');
  el.href = url;
  el.rel = 'stylesheet';
  el.type = 'text/css';
  el.onload = () => {
    console.debug(`Loaded css for microapp: ${name}`);
  };
  el.onerror = (e) => {
    console.error(
      `Error loading css for microapp: ${name} from ${e.target.href}`
    );
  };
  document.head.appendChild(el);
};

const LoadMicroapp = ({ appId, children }) => {
  const microapps = useSelector(microappsSelector);
  const { loadedSession } = useSelector(sessionSelector);
  const hasSiteAccess = useSelector(hasSiteAccessSelector);
  const orchestrator = useSelector(orchestratorDataSelector);
  const dispatch = useDispatch();
  // There is a special case for some legacy microapps where 3 microapps are actually in the same bundle.
  // We handle it with a hard coded getBundleId function but bundling microapps should NOT be done for any other microapps
  // eslint-disable-next-line no-param-reassign
  appId = getBundleId(appId);

  const microappStatus = orchestrator[appId] && orchestrator[appId].status;
  const reloadRequired = checkReloadRequired(appId, orchestrator);
  if (reloadRequired) {
    window.location.reload();
  }

  useEffect(() => {
    if (!microapps) {
      return;
    }

    const microapp = microapps.find(
      ({ id: microappsListAppId }) => microappsListAppId === appId
    );

    if (!microapp) {
      return;
    }
    const { id, experimental, url } = microapp;

    // TODO: confirm with Will if this has any use or if it is a dead option
    const excludeExperimental = env === 'prod' || env === 'beta';
    if (experimental && excludeExperimental) {
      console.debug(`Ignoring experimental microapp ${id}.`);
      return;
    }

    // The asset manifests are manifests each generated by their respective microapp using the webpack manifest plugin.
    // This is different from the microapp manifest which is a list of all microapps and their urls.
    let manifestUrl = buildUrl('asset-manifest.json');
    manifestUrl = buildSafeUrl(url, manifestUrl);

    const loadScriptsFromManifest = async () => {
      // TODO: delay checking for loadedSession until later.
      if (
        microappStatus === 'loading' ||
        microappStatus === 'loaded' ||
        !loadedSession
      ) {
        return;
      }

      dispatch(setMicroappLoading(id));

      const manifest = await getManifest(id, manifestUrl, url);

      Object.keys(manifest)
        .filter(
          (key) =>
            (key.startsWith('static/css') || key === 'main.css') &&
            key.endsWith('css')
        )
        .forEach((key) =>
          addMicroappCssLinkTag(buildUrlWithPublicPath(id, manifest[key]), id)
        );

      // For new CRA 3 based microapps which have a new asset-manifest structure, we use entrypoints.
      // Otherwise we look for main.js.
      // https://github.com/facebook/create-react-app/blob/master/CHANGELOG.md#new-structure-in-asset-manifestjson
      // and https://github.com/facebook/create-react-app/pull/7721
      const entrypoints = manifest.entrypoints || [manifest['main.js']];
      entrypoints.forEach((entrypoint) => {
        const jsFile = buildUrlWithPublicPath(id, entrypoint);
        // possibly preload here
        if (!hasSiteAccess && !jsFile.includes('nimble')) {
          console.debug(`Ignoring file ${jsFile} due to limited site-access.`);
          return;
        }
        addMicroappScriptTag(jsFile).catch(() =>
          setMicroappLoadFailure(jsFile)
        );
      });
    };

    loadScriptsFromManifest();
  }, [
    appId,
    microapps,
    loadedSession,
    hasSiteAccess,
    dispatch,
    microappStatus,
  ]);

  return children;
};

LoadMicroapp.propTypes = {
  appId: PropTypes.string.isRequired,
  children: PropTypes.node,
};

LoadMicroapp.defaultProps = {
  children: null,
};

export default LoadMicroapp;
