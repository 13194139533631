import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classnames from 'classnames/bind';
import { ErrorBoundary } from '@infosight/elmer/dist/page';
import MenuTrigger from '../../iam/TenantSelector/MenuTrigger'; // eslint-disable-line import/no-named-as-default
import TenantSelector from '../../iam/TenantSelector/TenantSelector'; // eslint-disable-line import/no-named-as-default
import ViewMyOrgs from '../../iam/TenantSelector/ViewMyOrgs';
import { tenantSelectorVisibilitySelector } from '../../iam/reducer';
import Item from './Item';
import style from './PrimaryNavBar.scss';

const cx = classnames.bind(style);

const TenantSelectorItem = ({ enabled }) => {
  const initialShowModal = false;
  const [showMyOrgsModal, setShowMyOrgsModal] = useState(initialShowModal);
  const [passedSearchQuery, setPassedSearchQuery] = useState();

  if (!enabled) {
    return null;
  }

  return (
    <>
      <div className={cx('separator')} />
      <Item
        menuOptions
        title={<MenuTrigger />}
        renderContent={() => (
          <ErrorBoundary>
            <TenantSelector
              setShowMyOrgsModal={setShowMyOrgsModal}
              setPassedSearchQuery={setPassedSearchQuery}
            />
          </ErrorBoundary>
        )}
        id="tenant-selector"
        placement="bottom-end"
      />
      {showMyOrgsModal && (
        <ViewMyOrgs
          setShowMyOrgsModal={setShowMyOrgsModal}
          passedSearchQuery={passedSearchQuery}
          setPassedSearchQuery={setPassedSearchQuery}
        />
      )}
    </>
  );
};

TenantSelectorItem.propTypes = {
  enabled: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  ...tenantSelectorVisibilitySelector(state),
});

export default connect(mapStateToProps)(TenantSelectorItem);
