/**
 * @module url
 */

import { compact } from 'underscore';

// TODO Unit test
/**
 * Trim slashes from the beginning and end of all arguments (path components)
 * remove empty slots in resulting array pre-pend and join with '/' for now,
 * each path component should be normalized (ie, not contain stray double slashes)
 * @return {string}
 * @memberof url
 */
export function buildUrl(...args) {
    return `/${compact(args.map(item => (item ? item.toString().replace(/^\/|\/$/g, '') : undefined))).join('/')}`;
}
