import {
  USER_TOUR_STATUS,
  TOUR_USER_PROPERTY_PREFIX,
  INTERACTION,
} from './constants';
import { getStore } from '../util/storeProvider';
import postIAMRequest from '../services/postIAMRequest';
import Analytics from '../user/services/analytics';
import { triggerTourStatusUpdated } from './services';
import { UPDATE_USER_PROPERTIES_SUCCEEDED } from '../iam/constants';

export async function updateTourStatusProperty({ id, action }) {
  try {
    const {
      data: { data },
    } = await postIAMRequest({
      query: `
                mutation updateUserProperty($urn: String!, $value: String) {
                    updateUserProperty(urn: $urn, value: $value) {
                        properties { urn value }
                    }
                }`,
      variables: {
        urn: TOUR_USER_PROPERTY_PREFIX + id,
        value: action
          ? JSON.stringify({
              action,
              timestamp: new Date(Date.now()).toISOString(),
            })
          : null,
      },
    });

    getStore().dispatch({
      type: UPDATE_USER_PROPERTIES_SUCCEEDED,
      payload: data.updateUserProperty.properties,
    });
    triggerTourStatusUpdated();
  } catch (e) {
    console.warn(
      `Error saving user preference for Tour. id=${id} action=${action}`,
      e
    );
  }
}

export function trackStart({ appId, name, id, version }) {
  updateTourStatusProperty({ id, action: USER_TOUR_STATUS.STARTED });
  Analytics.trackEvent(INTERACTION.STARTED, {
    microappId: appId,
    name,
    id,
    version,
  });
}

export function trackHidden({ appId, name, id, version }) {
  updateTourStatusProperty({ id, action: USER_TOUR_STATUS.HIDDEN });
  Analytics.trackEvent(INTERACTION.HIDDEN, {
    microappId: appId,
    name,
    id,
    version,
  });
}

export function trackSnooze({ appId, name, id, version }) {
  updateTourStatusProperty({ id, action: USER_TOUR_STATUS.SNOOZED });
  Analytics.trackEvent(INTERACTION.SNOOZED, {
    microappId: appId,
    name,
    id,
    version,
  });
}

export function trackEarlyExit({ appId, name, id, version, stepTitle }) {
  updateTourStatusProperty({ id, action: USER_TOUR_STATUS.EARLY_EXIT });
  Analytics.trackEvent(INTERACTION.EARLY_EXIT, {
    microappId: appId,
    name,
    id,
    version,
    stepTitle,
  });
}

export function trackCompleted({ appId, name, id, version }) {
  updateTourStatusProperty({ id, action: USER_TOUR_STATUS.COMPLETED });
  Analytics.trackEvent(INTERACTION.COMPLETED, {
    microappId: appId,
    name,
    id,
    version,
  });
}
