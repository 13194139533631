import React from 'react';
import PropTypes from 'prop-types';
import { Box } from 'grommet';
import classnames from 'classnames/bind';
import style from './style.scss';

const cx = classnames.bind(style);

const mapIterations = (iterations, mapper) =>
  [...Array(iterations).keys()].map(mapper);

const Stepper = ({ stepCount, currentStepIndex }) => (
  <Box gap="xsmall" direction="row" data-test="tour-step-footer-stepper">
    {mapIterations(stepCount, (index) => {
      // Don't show the dot for the first step, which is always an intro step
      if (index === 0) {
        return null;
      }
      return (
        <div
          key={`dot${index}`}
          className={cx('dot', { active: index === currentStepIndex })}
          data-testid="Stepper"
        />
      );
    })}
  </Box>
);

Stepper.propTypes = {
  stepCount: PropTypes.number.isRequired,
  currentStepIndex: PropTypes.number.isRequired,
};
export default Stepper;
