import { ExtensionPoint } from '@infosight/shell-api/lib/core';
import { actionNamespacer } from '@infosight/elmer/dist/utils/redux';
import { createSelector } from 'reselect';
import { extensionPointSelector } from '../selectors';

const ns = actionNamespacer(`EXTENSIONS/${ExtensionPoint.Banner}`);

export const ADD = ns('ADD');
export const REMOVE = ns('REMOVE');
export const RESET = ns('RESET');

const initialState = {};

const ACTION_HANDLERS = {
  [ADD]: (state, { payload: { appId, id }, payload: banner }) => ({
    ...state,
    [appId]: { ...(state[appId] || {}), [id]: banner },
  }),
  [REMOVE]: (state, { payload: { appId, id } }) => {
    if (!state[appId]) {
      return state;
    }

    return { ...state, [appId]: { ...state[appId], [id]: undefined } };
  },
  [RESET]: () => initialState,
};

export default (state = initialState, action) => {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
};

const baseSelector = (state) =>
  extensionPointSelector(state, ExtensionPoint.Banner);
export const bannersSelector = createSelector(baseSelector, (appIndex) =>
  Object.entries(appIndex).reduce((memo, [, appBannerIndex]) => {
    // eslint-disable-line no-unused-vars
    if (!appBannerIndex) {
      return memo;
    }

    return Object.entries(appBannerIndex).reduce(
      (memo2, [, banner]) => (banner ? [...memo2, banner] : memo2),
      memo
    ); // eslint-disable-line no-unused-vars
  }, [])
);
