export const REQUEST_SCOPE = 'REQUEST_SCOPE';
export const REQUEST_SCOPE_ENDED = 'REQUEST_SCOPE_ENDED';
export const ADD_PRODUCT_INTERFACE = 'ADD_PRODUCT_INTERFACE';

export const VIEW_TYPES = {
    DETAIL: null,
    CARD: 'dashboards',
    CHART: 'charts',
    TABLE: 'list',
};
