/* eslint-disable import/prefer-default-export */
import { DEMO_ROLE_URN } from './constants';
import postIAMRequest from '../services/postIAMRequest';
import { getStore } from '../util/storeProvider';
import { UPDATE_USER_PROPERTIES_SUCCEEDED } from '../iam/constants';

export const setDemoRole = async (role) => {
  try {
    const {
      data: { errors, data },
    } = await postIAMRequest({
      query: `mutation {
                updateUserProperty(urn: "${DEMO_ROLE_URN}", value: "${role}") {
                  properties {
                    urn
                    value
                  }
                }
              }`,
    });

    if (errors) {
      console.warn(`Error saving demo role user preference. role=${role}`);
      return;
    }

    getStore().dispatch({
      type: UPDATE_USER_PROPERTIES_SUCCEEDED,
      payload: data.updateUserProperty.properties,
    });
  } catch (e) {
    console.warn(`Error saving demo role user preference. role=${role}`, e);
  }
};
