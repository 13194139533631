import { logOut } from '@infosight/shell-api/lib/UserProfile';
import { env } from '../config';

const navigationUrl = () => {
  const prefix = 'https://navigation';
  const suffix = 'infosight.hpe.com/graphql';
  switch (env) {
    case 'prod':
    case 'beta':
      return `${prefix}.portal.${env}.${suffix}`;
    case 'ap1':
    case 'ap2':
    case 'dev':
      return `${prefix}-${env}.portal.${env}.${suffix}`;
    case 'local':
      return '/navigation/graphql'; // This will proxied by webpack-dev-server
    default:
      return `${prefix}-dev.dev.${suffix}`;
  }
};

export default async function postNavRequest(payload) {
  console.log(`Sending nav-backend to URL ==> ${navigationUrl()}`);
  console.log(`Sending nav-backend with cookie ==> ${document.cookie}`);
  const resp = await fetch(navigationUrl(), {
    method: 'POST',
    credentials: 'include',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
      Accept: 'application/json',
    },
    body: JSON.stringify(payload),
  });
  if (resp.status === 401) {
    console.log(`resp came back with 401`);
    logOut();
  }
  console.log(`resp came back with ${resp.status}`);
  const data = await resp.json();
  console.log(`data is ${data}`);
  return { data };
}
