import { logOut } from '@infosight/shell-api/lib/UserProfile';

export default async function postIAMRequest(payload) {
  const resp = await fetch('/InfoSight/api/iam/graphql', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
      Accept: 'application/json',
    },
    body: JSON.stringify(payload),
  });
  if (resp.status === 401) {
    logOut();
  }
  const data = await resp.json();
  return { data };
}
