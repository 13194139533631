import React from 'react';
import PropTypes from 'prop-types';
import { ERROR, INFO, SUCCESS, WARNING } from './constants';

const map = {
    [SUCCESS]: 'fa fa-check',
    [INFO]: 'fa fa-info-circle',
    [WARNING]: 'fa fa-exclamation-triangle',
    [ERROR]: 'fa fa-exclamation-circle',
};

const Icon = ({ severity }) => (<i className={map[severity]} />);

Icon.propTypes = {
    severity: PropTypes.oneOf([ERROR, INFO, SUCCESS, WARNING]),
};

export default Icon;
