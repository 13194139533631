import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { userHasViewedTour, startTour } from '@infosight/shell-api/lib/Tour';
import { handleEvent, ExtensionPoint } from '@infosight/shell-api/lib/core';
import AnchoredBeacon from './AnchoredBeacon';

/**
 * Decorator for any UI component that conditionally renders a TourBeacon if the tour is available and should be offered
 * to the user.
 */
const TourBeacon = ({ children, tour, onClick, anchor, ...stackProps }) => {
    const [shouldOfferTour, setShouldOfferTour] = useState(false);

    async function setTourStatus() {
        try {
            // Throws if the tour is invalid
            setShouldOfferTour(!await userHasViewedTour(tour));
        } catch (e) {
            console.warn(e);
        }
    }

    useEffect(() => {
        async function runEffect() {
            await setTourStatus();
        }

        runEffect();
        return handleEvent(ExtensionPoint.Analytics, 'onTourStatusUpdated', setTourStatus);
    }, [tour && tour.appId, tour && tour.name]);

    const allowForceEnabled = process.env.NODE_ENV === 'development' && stackProps.force === true;
    if (!shouldOfferTour && !allowForceEnabled) {
        return children;
    }

    return (
        <AnchoredBeacon
            {...stackProps}
            onClick={(e) => {
                if (typeof onClick === 'function') {
                    onClick(e);
                }

                return startTour(tour);
            }}
            anchor={anchor}
            data-test="TourBeacon"
        >
            {children}
        </AnchoredBeacon>
    );
};

TourBeacon.propTypes = {
    /**
     * The host element
     */
    children: PropTypes.node.isRequired,

    /**
     * These fields are part of Shell API's Tour config object, so for convenience, you can pass that object.
     */
    tour: PropTypes.shape({
        appId: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
    }).isRequired,

    /**
     * @param {Event} e
     */
    onClick: PropTypes.func,

    /**
     * Point at which the beacon should be anchored to props.children
     */
    anchor: PropTypes.oneOf([
        'center',
        'left',
        'right',
        'top',
        'bottom',
        'top-left',
        'bottom-left',
        'top-right',
        'bottom-right',
    ]),

    /**
     * Offset from the host anchoring object. Offsets are always positive in the direction that points away from the
     * host object. For example, and offset of 5 would move the beacon 5 pixels further away from the host.
     *
     * Named offsets (small, medium, large) apply standard offsets and are generally your simplest option for
     * consistency. You may also provide one numeric offset or specific numeric offsets for each direction. In any
     * case, Only the sides are are relevant to the props.anchor value are used; the other are ignored even if you pass
     * them in.
     * 
     * **Examples**
     * * Named offsets: "small", "medium", "large"
     * * Numeric offset: 27, -15
     * * Explicit offsets: { bottom: 'small', right: 'large' }, { bottom: 15, right: 'large' }
     * 
     */
    offset: PropTypes.oneOfType([
        PropTypes.oneOf(['small', 'medium', 'large']),
        PropTypes.number,
        PropTypes.shape({
            top: PropTypes.oneOfType([
                PropTypes.oneOf(['small', 'medium', 'large']),
                PropTypes.number,
            ]),
            bottom: PropTypes.oneOfType([
                PropTypes.oneOf(['small', 'medium', 'large']),
                PropTypes.number,
            ]),
            left: PropTypes.oneOfType([
                PropTypes.oneOf(['small', 'medium', 'large']),
                PropTypes.number,
            ]),
            right: PropTypes.oneOfType([
                PropTypes.oneOf(['small', 'medium', 'large']),
                PropTypes.number,
            ]),
        }),
    ]),
};

TourBeacon.defaultProps = {
    anchor: 'top-left',
    offset: 'small',
};

export default TourBeacon;
