import { noop, pick } from 'underscore';
import URI from 'urijs';
import Request from '../../../services/Request';

/**
 * Values included with each hit
 */
let baseSettings;

/**
 * Some segments are just noise, so they should be removed
 */
const pathReplacements = [/\/tenant\/:tenantId/];

const trackPageView = ({ path, name, secondaryName }) => {
  if (!path) {
    return;
  }

  new Request()
    .central()
    .slash('accesslog', 'entry')
    .post(
      URI.buildQuery({
        tabName: pathReplacements.reduce(
          (memo, replacement) => memo.replace(replacement, ''),
          path
        ),
        subTabName: secondaryName,
        tabContent: name,
        args: baseSettings,
      })
    );
};

const trackEvent = noop;

const init = (sessionSettings) => {
  baseSettings = URI.buildQuery(pick(sessionSettings, 'realm'));
  return true;
};

export default { trackEvent, trackPageView, init, name: 'Access Log' };
