import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames/bind';
import { isObject, sortBy, compact } from 'underscore';
import { Dropdown } from '@infosight/elmer/dist/components/NavBar';
import { connect } from 'react-redux';
import { isLoadingMicroappsSelector } from '../../extensibility/orchestrator/reducer';
import style from './Item.scss';
import { USER_TOUR_STATUS } from '../../tour/constants';
import { tourUserPropertiesSelector } from '../../iam/reducer';
import { toursSelector } from '../../extensibility/tour/reducer';
import { createTourBeacons } from '../utils';

const cx = classnames.bind(style);

/**
 * Sort menu options by sorting preferred options to the top in the designated order, then sorting any remaining items after those.
 * @param options {object[]}
 * @param preferentialSortIds {string[]} Collection of preferentially sorted option ids.
 * @param sortRemnantBy {string} Property of option object by which non-preferential options should be sorted.
 * @return {object[]} The sorted `options` array.
 */
export function preferentialSort(
  options,
  preferentialSortIds,
  sortRemnantBy = 'title'
) {
  if (!options || !options.length || !Array.isArray(preferentialSortIds)) {
    return options;
  }

  const mutable = [...options];

  // First, sort the preferred items to the top
  const sorted = mutable.reduce(
    (sortedOptions, value, currentIndex, unsortedOptions) => {
      if (!value) {
        return sortedOptions;
      }

      const preferredIndex = preferentialSortIds.indexOf(value.id);

      // Is this a preferred option? If so, add it at the defined index then remove it from `unsortedOptions`
      if (preferredIndex > -1) {
        sortedOptions[preferredIndex] = value;
        unsortedOptions[currentIndex] = undefined;
      }

      return sortedOptions;
    },
    []
  );

  // Then sort the non-preferred options and remove falsy elements
  return compact(sorted.concat(sortBy(mutable, sortRemnantBy)));
}

const Item = ({
  id,
  title,
  renderContent,
  menuOptions,
  preferentialSortIds,
  sortByProperty,
  sort,
  loadingMicroapps,
  spinnerOnPendingMicroapps,
  tourUserProperties,
  tours,
  ...dropdownOptions
}) => {
  let options;

  // The menu options are pre-wrangled, such as the infrastructure menu
  if (Array.isArray(menuOptions)) {
    options = menuOptions;
  } else if (isObject(menuOptions)) {
    options = Object.keys(menuOptions)
      .map((key) => menuOptions[key])
      .filter((x) => x)
      .reduce((memo, value) => memo.concat(value), []);

    // Early return because this menu has no real options, so it won't be displayed
    if (!options.length) {
      return null;
    }

    if (sort) {
      options = Array.isArray(preferentialSortIds)
        ? preferentialSort(options, preferentialSortIds, sortByProperty)
        : sortBy(options, sortByProperty);
    }
  } else if (!menuOptions) {
    // Consumer explicitly expects the item not to render
    return null;
  }

  if (
    !options &&
    !menuOptions &&
    !spinnerOnPendingMicroapps &&
    !loadingMicroapps
  ) {
    return null;
  }

  options = Array.isArray(options)
    ? createTourBeacons(options, tourUserProperties, tours)
    : options;

  return (
    <Dropdown
      {...dropdownOptions}
      domId={id}
      trigger={
        typeof title === 'function' ||
        (typeof title === 'object' && typeof title.type === 'function')
          ? React.createElement(title)
          : title
      }
      triggerClassName={classnames(cx('trigger'), 'text-center')}
      triggerActiveClassName={cx('trigger', 'active')}
      renderDropdown={renderContent}
      className="flex-container align-stretch"
      options={options}
      loading={spinnerOnPendingMicroapps && loadingMicroapps}
    />
  );
};

Item.propTypes = {
  /**
   * Content rendered in the nav bar
   */
  title: PropTypes.oneOfType([
    PropTypes.elementType,
    PropTypes.node,
    PropTypes.func,
  ]).isRequired,

  /**
   * Id of the Menu Item
   */
  id: PropTypes.string,

  /**
   * Function that returns a react node to render as the content of the dropdown
   * @return React.Node
   */
  renderContent: PropTypes.func.isRequired,

  /**
   * Convenience prop for Menu or Meganav renderers
   * If this is an object, it has ___ shape
   * If this is `true`, the item is rendered with the expectation that props.renderContent will not be a Menu or Meganav
   *
   * Otherwise, this item is not rendered
   *
   * TODO split this into separate props.
   */
  menuOptions: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.bool,
    PropTypes.array,
  ]),

  /**
   * Sort menu options by sorting preferred options to the top in the designated order, then sorting any remaining items after those.
   */
  preferentialSortIds: PropTypes.arrayOf(PropTypes.string.isRequired),

  /**
   * Property of options object to sort by. This is used after `preferentialSortIds`.
   */
  sortByProperty: PropTypes.string,

  /**
   * Should the options be sorted? If `false`, it is expected that the options are already sorted correctly.
   */
  sort: PropTypes.bool,

  /**
   * Show a loading indicator when microapps are being loaded. This informs the user there may be more menu options coming.
   */
  spinnerOnPendingMicroapps: PropTypes.bool,

  // Passed by mapStateToProps
  loadingMicroapps: PropTypes.bool,

  tourUserProperties: PropTypes.objectOf(
    PropTypes.shape({
      action: PropTypes.oneOf(Object.values(USER_TOUR_STATUS)).isRequired,
    }).isRequired
  ),

  tours: PropTypes.objectOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
    })
  ),
};

Item.defaultProps = {
  sort: true,
  sortByProperty: 'title',
  spinnerOnPendingMicroapps: false,
};

const mapStateToProps = (state) => ({
  loadingMicroapps: isLoadingMicroappsSelector(state),
  tourUserProperties: tourUserPropertiesSelector(state),
  tours: toursSelector(state),
});

export default connect(mapStateToProps)(Item);
