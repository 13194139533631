import React from 'react';
import PrimaryNavExtension from './PrimaryNavExtension';

const SearchExtensions = () => (
  <>
    <PrimaryNavExtension />
  </>
);

export default SearchExtensions;
