import { omit, pick, isString, isObject } from 'underscore';
import { param, deparam as baseDeparam } from 'node-qs-serialization';
import URI from 'urijs';
import { matchPath } from 'react-router';
import parseQuery from './parseQuery';

/**
 * Workaround react-router's use of colons as path parameters by URL encoding the colon.
 * To avoid "ugly" urls, don't encode the entire path
 * @param path
 * @returns {string}
 */
const encodePathSegmentsForReactRouter = path => path.replace(/:/g, encodeURIComponent(':'));

/**
 * Remove leading ? and #. Also URL decode for debugging convenience
 * @param search
 * @return {string}
 */
export function sanitizeSearch(search) {
    if (!search) {
        return '';
    }

    let innerSearch = search;
    if (innerSearch.startsWith('?')) {
        innerSearch = search.slice(1);
    }

    if (innerSearch.startsWith('#')) {
        innerSearch = search.slice(1);
    }

    return window.decodeURI(innerSearch);
}

const coercionBlacklist = [];

/**
 * Some query parameters should never be coerces into primitives. These will always be passed as strings.
 * Example is portal's scope params
 */
export function blacklistQueryParameterCoercion(...parameters) {
    parameters.forEach((parameter) => {
        if (!coercionBlacklist.includes(parameter)) {
            coercionBlacklist.push(parameter);
        }
    });
}

/**
 * Extends node-qs-serialization.deparam with the ability to blacklist some query parameter from coercion
 * @param params
 * @param coerceTypes
 * @return {{}}
 */
export const deparam = (params, coerceTypes) => {
    if (coerceTypes === false || !coercionBlacklist.length) {
        return baseDeparam(sanitizeSearch(params), coerceTypes);
    }

    const coerced = omit(baseDeparam(sanitizeSearch(params), true), ...coercionBlacklist);
    const nonCoerced = pick(baseDeparam(sanitizeSearch(params), false), ...coercionBlacklist);
    return { ...coerced, ...nonCoerced };
};

/**
 * Serialize search (query) parameters
 * @param params
 * @return {string}
 */
export function buildSearch(params) {
    return `?${param(params)}`;
}

/**
 * Create URIjs instance from a React-Router Location
 * @param location
 * @return {URI}
 */
export function createURIFromLocation(location) {
    if (!location) {
        return undefined;
    }

    const { pathname, search } = location;
    const uri = new URI('');

    if (isString(pathname)) {
        uri.pathname(pathname);
    }

    if (isObject(search)) {
        uri.setQuery(search);
    } else if (isString(search)) {
        // This seems asinine, but if a string search is passed, URIjs will just encode the whole thing
        uri.setQuery(deparam(search, false));
    }

    return uri;
}

/**
 * Merges query parameters into a react-router
 * [previousLocation](https://reacttraining.com/react-router/web/api/previousLocation) that can be used as the
 * `props.to` option for components such as `Link` and `NavLink`
 * @param {object} previousLocation
 * @param {object} targetLocation
 * @param {Boolean} exact
 * @param {Boolean} coerceTypes - See https://github.com/edwardsmit/node-qs-serialization
 * @param {Object|String} targetLocation.search Hash of query parameters to merge instead of a string.
 */
export function mergeQueryParameters(previousLocation, targetLocation, { coerceTypes = true, exact = false } = {}) {
    let targetParams = targetLocation.search || {};
    if (typeof targetLocation.search === 'string') {
        targetParams = deparam(targetLocation.search, coerceTypes);
    }

    const keys = Object.keys(targetParams);
    const previousParams = exact ? {} : deparam(previousLocation.search, coerceTypes);

    const isUndefined = (value, key) => keys.includes(key) && targetParams[key] === undefined;
    const search = buildSearch({ ...omit(previousParams, isUndefined), ...omit(targetParams, isUndefined) });

    return { ...previousLocation, ...targetLocation, search };
}

// TODO options should be a hash since I added another one
export function matchWithQueryParams(match, location, coerce = true, includePathParams = true) {
    let params = parseQuery(location.search, coerce);
    if (includePathParams) {
        params = { ...match.params, ...params };
    }

    return { ...match, params };
}

/**
 * Preserves a whitelist of queries from match params for an href being used
 * in an anchor or NavLink
 * @param {string | object} href
 * @param {object} [location](https://reacttraining.com/react-router/web/api/location)
 * @param {string[]} preserveQueriesArray
 */
export function preserveQueries(href, location, preserveQueriesArray) {
    if (!preserveQueriesArray) {
        return href;
    }

    const params = parseQuery(location.search);
    const isObjectMode = typeof href === 'object';
    const uri = isObjectMode ? createURIFromLocation(href) : new URI(href);
    preserveQueriesArray.forEach((queryKey) => {
        if (params[queryKey]) {
            uri.addSearch(queryKey, params[queryKey]);
        }
    });

    if (isObjectMode) {
        return { ...href, search: uri.search(), pathname: encodePathSegmentsForReactRouter(uri.pathname()) };
    }

    return encodePathSegmentsForReactRouter(uri.toString());
}

export const coalesce = (...args) => args.find(x => typeof x !== 'undefined');

/**
 * Build a react-router `Link`-compatible location object that handles merge/replace/ignorance of path and search (query) portions of the URL
 *
 * @param {string|object} to
 * @param {string|object} to.search
 * @param {object} location [Location](https://reacttraining.com/react-router/web/api/location)
 * @param {Boolean} coerceTypes Should data types be detected and coerced? When false, all values are strings.
 *                              See [node-qs-serialization]{@link https://github.com/edwardsmit/node-qs-serialization}
 * @param {Boolean} replaceQuery Replace the query with the new location.search value
 * @param {Boolean} exact Maintained for backwards compatibility, but prefer more explicit options. This should not have a default value.
 *                        When true, this is a shortcut for `replaceQuery=true` and `replacePath=true`.
 *                        When false, this is a shortcut for `mergeQuery=true` and `replacePath=false`.
 * @param {string[]} preservedQueryParameters List of query parameters that should be preserved in the target location
 * @return {*}
 */
export function buildTargetLocation({
    to: targetDescriptor,
    location,
    coerceTypes = true,
    replaceQuery = false,
    exact,
    preservedQueryParameters,
}) {
    // Backwards compatibility mode
    if (exact !== undefined) {
        const target = mergeQueryParameters(location, targetDescriptor, { exact, coerceTypes });
        return exact ? { ...targetDescriptor, search: target.search } : target;
    }

    let targetLocation = targetDescriptor;
    if (typeof targetDescriptor === 'string') {
        const { path, query } = URI.parse(targetDescriptor);
        targetLocation = { pathname: path, search: query };
    }

    const { search } = mergeQueryParameters(location, targetLocation, { exact: replaceQuery, coerceTypes });
    const target = { ...targetLocation, pathname: targetLocation.pathname || location.pathname, search };
    return preservedQueryParameters ? preserveQueries(target, location, preservedQueryParameters) : target;
}

/**
 * Compare locations to determine if the to (target) location matches the from (current) location
 * @param {{ pathname: string, search: string }} location
 * @param {{ pathname?: string, search?: string|object }} targetDescriptor
 * @param {Boolean} matchQueryExactly
 * @param {Boolean} matchIgnoreQuery
 * @param {Boolean} matchPathExactly
 * @param {Boolean} matchIgnorePath
 * @param {Boolean} matchAllExactly
 * @param {Boolean} strict
 * @param {Boolean} exact
 *
 * @return {Boolean}
 */
export function isMatch({
    location: from,
    to: targetDescriptor,
    matchQueryExactly,
    matchIgnoreQuery,
    matchPathExactly,
    matchIgnorePath,
    matchAllExactly,
    strict = false,

    /**
     * @deprecated
     */
    exact,
}) {
    if (!from || !targetDescriptor || !from.pathname) {
        return false;
    }

    let targetLocation = targetDescriptor;
    if (typeof targetDescriptor === 'string') {
        const { path, query } = URI.parse(targetDescriptor);
        targetLocation = { pathname: path, search: query };
    }

    let queryIsMatch = false;
    let pathIsMatch = false;

    if (matchIgnorePath) {
        pathIsMatch = true;
    } else {
        pathIsMatch = !!matchPath(from.pathname, {
            strict,
            path: targetLocation.pathname || from.pathname,
            exact: coalesce(matchAllExactly, matchPathExactly, exact, false),
        });
    }

    if (matchIgnoreQuery) {
        queryIsMatch = true;
    } else {
        const target = buildTargetLocation({ to: targetLocation, location: from, exact: coalesce(matchAllExactly, matchQueryExactly, exact, false) });
        queryIsMatch = sanitizeSearch(target.search) === sanitizeSearch(from.search);
    }

    return pathIsMatch && queryIsMatch;
}
