import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import BEM from 'src/utils/bem';
import Fixed from './Fixed';
import Scrollable from './Scrollable';

const bem = new BEM('elmer-ScrollContainer');

/**
 * Creates a vertically scrollable container with the fixed height of its parent.
 * `ScrollContainer.Fixed` creates a fixed (sticky) container, usually used for headers and footers.
 * `ScrollContainer.Scrollable` creates a... scrollable div.
 * 
 * TODO Can this be used for horizontal scrolling as well if that use case comes up (tables?
 */
const ScrollContainer = ({ className, children, ...props }) => (
    <div {...props} className={classnames(className, bem.valueOf())}>
        {children}
    </div>
);

ScrollContainer.propTypes = {
    children: PropTypes.node.isRequired,
};

ScrollContainer.Fixed = Fixed;
ScrollContainer.Scrollable = Scrollable;

export default ScrollContainer;

