import React, { Component } from 'react';
import PropTypes from 'prop-types';
import autobind from 'react-autobind';
import classnames from 'classnames';
import { Manager, Target, Popper, Arrow } from 'react-popper';
import { isFunction } from 'underscore';
import BEM from 'src/utils/bem';
import OutsideClickWrapper from '../OutsideClickWrapper'; // eslint-disable-line import/no-named-as-default

const bem = new BEM('elmer-OverlayTrigger');

/**
 * Wrapper around [react-popper](https://github.com/souporserious/react-popper) to hide boilerplate around showing/hiding overlays.
 * This is more of a mid-level component used to build anything else that needs an overlay.
 * You can always use react-popper directly if needed.
 */
class OverlayTrigger extends Component {
    constructor(props) {
        super(props);
        autobind(this);

        this.state = { showOverlay: false };
    }

    // TODO write tests for this
    onMouseOut(e) {
        if (this.overlayRef && (e.relatedTarget === this.overlayRef || this.overlayRef.contains(e.relatedTarget))) {
            return;
        }

        if (this.targetRef && (e.relatedTarget === this.targetRef || this.targetRef.contains(e.relatedTarget))) {
            return;
        }

        this.hide();
    }

    onCloseOverlay() {
        const { closeOverlay } = this.props;
        if (typeof closeOverlay === 'function') {
            closeOverlay();
        }
    }

    getHandlers() {
        const { trigger } = this.props;

        return {
            manual: {},
            hover: {
                onMouseOver: this.show,
                onMouseOut: this.onMouseOut,
            },
            focus: {
                onFocus: this.show,
                onBlur: this.hide,
            },
            click: {
                onClick: this.toggle,
            },
        }[trigger];
    }

    handleClickOutside() {
        this.setState({ showOverlay: false }, this.onCloseOverlay);
    }

    toggle() {
        this.setState(({ showOverlay }) => ({ showOverlay: !showOverlay }));
    }

    show() {
        this.setState({ showOverlay: true });
    }

    hide() {
        this.setState({ showOverlay: false });
    }

    renderOverlay() {
        const { overlay, trigger, showOverlay, hideOverlayOnOutsideClick, arrowProps, arrow } = this.props;
        const visible = (trigger === 'manual' && showOverlay) || (trigger !== 'manual' && this.state.showOverlay);
        if (!visible) {
            return null;
        }

        const content = (
            <React.Fragment>
                {isFunction(overlay) ? overlay({ hide: this.hide }) : overlay}
                {arrow && <Arrow {...arrowProps} className={classnames(bem.e('arrow'), arrowProps && arrowProps.className)} />}
            </React.Fragment>
        );

        if (trigger === 'hover') {
            // After the mouse moves from the target to the overlay, we need a mouseOut handler to hide the overlay on the overlay
            return <div onMouseOut={this.onMouseOut} onBlur={this.onMouseOut}>{content}</div>;
        }

        if ((trigger === 'click' || trigger === 'manual') && hideOverlayOnOutsideClick) {
            // If user clicks outside the overlay, close it
            return <OutsideClickWrapper handleClickOutside={this.handleClickOutside}>{content}</OutsideClickWrapper>;
        }

        return content;
    }

    render() {
        const { managerProps, popperProps, targetProps, children } = this.props;
        return (
            <Manager {...managerProps}>
                <Target
                    {...targetProps}
                    {...this.getHandlers()}
                    innerRef={(ref) => {
                        this.targetRef = ref;
                        if (targetProps && typeof targetProps.ref === 'function') {
                            targetProps.innerRef(ref);
                        }
                    }}
                >
                    {children}
                </Target>
                <Popper
                    {...popperProps}
                    className={classnames(bem.e('popper'), popperProps && popperProps.className)}
                    innerRef={(ref) => {
                        this.overlayRef = ref;
                        if (popperProps && typeof popperProps.ref === 'function') {
                            popperProps.innerRef(ref);
                        }
                    }}
                >
                    {this.renderOverlay()}
                </Popper>
            </Manager>
        );
    }
}

OverlayTrigger.propTypes = {
    managerProps: PropTypes.object,
    popperProps: PropTypes.object,
    targetProps: PropTypes.object,
    arrowProps: PropTypes.object,

    /**
     * Content of Target element.
     * You can also set `props.targetProps.component` and pass additional props there to avoid a wrapper element
     */
    children: PropTypes.node,

    trigger: PropTypes.oneOf(['manual', 'click', 'hover', 'focus']).isRequired,

    /**
     * If `props.trigger` is manual, this is used to manually show/hide the overlay
     */
    showOverlay: PropTypes.bool,

    /**
     * Use with `props.trigger="manual"` to close the overlay
     */
    closeOverlay: PropTypes.func,

    /**
     * Automatically hide the overlay if the user clicks outside the overlay
     */
    hideOverlayOnOutsideClick: PropTypes.bool,

    /**
     * Overlay content. If a function is used, one objects is passed with:
     *  - `hide()` Callback to hide the overlay
     */
    overlay: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,

    /**
     * Show the little arrow/pip/whatever you want to call it be used?
     */
    arrow: PropTypes.bool,
};

OverlayTrigger.defaultProps = {
    hideOverlayOnOutsideClick: true,
    arrow: true,
};

export default OverlayTrigger;
