import { isString, isFunction } from 'underscore';
import { getSuccessActionType, getStartedActionType, getErrorActionType } from './actionTypeGenerators';

// TODO need some cleanup for this
const counters = {};

const logDroppedResponses = (tracer, type, baseType) => {
    console.debug(`Skipped store update for action: "${type}". Waiting for request #${counters[baseType]}; this is #${tracer}.`);
};

/**
 * @typedef {object} ActionsForResult
 * 
 * @property {function} dispatchStart
 * Create action used to indicate an API request will start.
 * 
 * 
 * @property {function} dispatchSuccess
 * Create action used to update store after successful API call.
 * Returns the payload so you can use this in a promise chain.
 * 
 * 
 * @property {function} dispatchError
 * Create action used by store after an unsuccessful API call
 * Returns the payload (error) so you can use this in a promise chain.
 */

/**
 * Generates a standard set of action creators for a typical REST API call
 * @param {string} type Base action name
 * @param {function} dispatch
 * @param {object} [opts]
 * @param {boolean} [opts.takeLatestForType] Prevent out-of-order in-flight-requests by dropping all responses that are not for the latest request
 * @return {ActionsForResult} Object containing functions
 * @memberof redux
 */
export default function actionsFor(type, dispatch, { takeLatestForType = true } = {}) {
    if (!isString(type)) {
        throw new Error('"type" argument is required and should be a string');
    }

    if (!isFunction(dispatch)) {
        throw new Error('"dispatch" argument is required and should be Store#dispatch (Redux)');
    }

    if (takeLatestForType) {
        counters[type] = counters[type] || 0;
        counters[type] += 1;
    }

    const tracer = counters[type];

    return {
        dispatchStart: () => dispatch({ type: getStartedActionType(type) }),
        dispatchSuccess(payload) {
            if (takeLatestForType && tracer !== counters[type]) {
                logDroppedResponses(tracer, getSuccessActionType(type), type);
                return undefined;
            }

            dispatch({ type: getSuccessActionType(type), payload });
            return payload;
        },
        dispatchError(error) {
            if (takeLatestForType && tracer !== counters[type]) {
                logDroppedResponses(tracer, getErrorActionType(type), type);
                return undefined;
            }

            console.error(error);
            dispatch({ type: getErrorActionType(type), payload: error, error: true });
            return error;
        },
    };
}
