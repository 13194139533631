import React, { Component } from 'react';
import PropTypes from 'prop-types';
import autobind from 'react-autobind';
import { contains } from 'underscore';
import { Text, Box } from 'grommet';
import { FILTER_ACTION } from '../../../hoc/withTableState';
import { Button } from '../../../../Button';
import Checkbox from './Checkbox';

export class CheckBoxFilter extends Component {
    constructor(props) {
        super(props);
        autobind(this);
        
        // Initialize the checkboxes using the current filter value
        const { filter: { value } } = props;
        if (Array.isArray(value) && value.length) {
            this.selectedCheckboxes = new Set(value);
        } else {
            this.selectedCheckboxes = new Set();
        }
    }
    
    handleCheckboxChange(label) {
        if (this.selectedCheckboxes.has(label)) {
            this.selectedCheckboxes.delete(label);
        } else {
            this.selectedCheckboxes.add(label);
        }
    }

    hasFilterValueChanged(updatedCheckBoxList) {
        const { filter } = this.props;
        const oldFilterValueList = filter && filter.value;
        if (!oldFilterValueList && updatedCheckBoxList) {
            return true;
        }

        if (oldFilterValueList.length !== updatedCheckBoxList.length) {
            return true;
        }
        
        return updatedCheckBoxList.some(checkBox => !contains(oldFilterValueList, checkBox));
    }
    
    updateFilter() {
        const { onChange, filter, togglePopup } = this.props;

        // get all values that are checked
        const updatedCheckBoxList = Array.from(this.selectedCheckboxes);

        if (updatedCheckBoxList === null || updatedCheckBoxList === undefined || !updatedCheckBoxList.length) {
            // If the updated filter value is null/undefined or empty then remove the filter
            onChange({ filter, filterAction: FILTER_ACTION.REMOVE });
        } else if (this.hasFilterValueChanged(updatedCheckBoxList)) {
            onChange({ filter: { ...filter, value: updatedCheckBoxList } });
        }
        
        if (typeof togglePopup === 'function') {
            togglePopup();
        }
    }

    render() {
        const { checkboxItems, filter: { title } } = this.props;
        return (
            <Box direction="column">
                <Text size="small">{title}</Text>
                <Box className="checkbox" margin={{ top: 'xsmall' }}>
                    {checkboxItems.map(checkbox => (
                        <Checkbox 
                            label={checkbox.label} 
                            value={checkbox.value}
                            isChecked={this.selectedCheckboxes.has(checkbox.label)}
                            handleCheckboxChange={this.handleCheckboxChange} 
                        />))}
                </Box>
                <Box align="end" margin={{ top: 'xsmall' }}>
                    <Button
                        label="Apply"
                        onClick={() => this.updateFilter()}
                    />
                </Box>
            </Box>
        );
    }
}

CheckBoxFilter.propTypes = {
    filter: PropTypes.shape({
        id: PropTypes.string,
        title: PropTypes.string,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),

    /**
     * List of Checkbox items
     */
    checkboxItems: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string,
        label: PropTypes.string.isRequired,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired, 
    })).isRequired,

    /**
     * Function to handle the change in Filter Value.
     * It updates the filter value in table state
     */
    onChange: PropTypes.func.isRequired,

    /**
     * Expected to be passed from Parent to close the Popup when user hits `Esc` key
     */
    togglePopup: PropTypes.func.isRequired,
};

export default CheckBoxFilter;
