import React, { useState } from 'react';
import { Box, Text, TextInput, Button, Select } from 'grommet';
import PropTypes from 'prop-types';
import { FormClose } from 'grommet-icons';
import classnames from 'classnames/bind';
import { setDemoRole } from './actionCreators';
import roleMenuItemArray from './util';
import style from './DemoRoleDialog.scss';

const cx = classnames.bind(style);

const DemoRoleDialog = ({ currentRole, close }) => {
  const [selectedRole, setSelectedRole] = useState(null);

  // Custom role is only set when user selects 'Other'
  const [customRole, setCustomRole] = useState(null);
  const customRoleSelections = ['Other (Please Specify)'];

  return (
    <Box round="xsmall" pad="medium" gap="small" width="medium">
      <Box direction="row" justify="between">
        <Text size="large" weight="bold" alignSelf="start">
          Select Role
        </Text>
        <Box alignSelf="end" onClick={close}>
          <FormClose />
        </Box>
      </Box>
      <Text>For internal demo usage tracking only</Text>
      <Box direction="row">
        <Text color="red" margin={{ right: 'xxsmall' }}>
          &#42;
        </Text>
        <Text>Job Role</Text>
      </Box>
      <Box round="xsmall" className={cx('role-select')} border>
        <Select
          placeholder="Select your role"
          options={roleMenuItemArray(currentRole, customRoleSelections)}
          onChange={(selection) => {
            setSelectedRole(selection.value);
          }}
        />
      </Box>
      {selectedRole && customRoleSelections.includes(selectedRole) && (
        <TextInput
          className={cx('other-role-input')}
          size="medium"
          placeholder="Job role or title"
          value={customRole || ''}
          onChange={(event) => {
            setCustomRole(event.target.value);
          }}
        />
      )}
      <Box direction="row" alignContent="end" justify="end">
        <Button
          label="Continue"
          primary
          disabled={!selectedRole}
          margin={{ horizontal: 'small' }}
          onClick={() => {
            setDemoRole(customRole || selectedRole);
          }}
        />
        <Button
          className={cx('cancel-button')}
          label="Cancel"
          onClick={close}
        />
      </Box>
    </Box>
  );
};

DemoRoleDialog.propTypes = {
  currentRole: PropTypes.string,
  close: PropTypes.func.isRequired,
};

export default DemoRoleDialog;
