import { hpe } from 'grommet-theme-hpe';

// TODO Extract this to a separate package OR just use the base theme and take the larger font that comes with it (preferred)
//  Then we don't need ThemeProvider at all
export default {
    ...hpe,
    global: {
        ...hpe.global,
        font: { size: 16 },
    },
};
