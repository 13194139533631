import React from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { ExtensionPoint } from '@infosight/shell-api/lib/core';
import { ErrorBoundary } from '@infosight/elmer/dist/page';
import { Spinner } from '@infosight/elmer/dist/components/Spinner';
import { buildUrl } from '@infosight/elmer/dist/utils/url';
import { extensionPointSelector } from '../extensibility';
import LoadMicroapp from '../extensibility/orchestrator/LoadMicroapp';
import KNOWN_ROUTES from './knownRoutes';
import SlowRedirect from './SlowRedirect';

// TODO Sort by specificity to try avoiding conflicts. Such as the one between /storeserv and /storeserv/new when /storeserv/new is last.
// Can use Spring or some other service framework for inspiration
// https://docs.spring.io/spring/docs/current/javadoc-api/org/springframework/web/util/pattern/PathPattern.html
const routeMapSelector = createSelector(
  (state) => extensionPointSelector(state, ExtensionPoint.Router),
  (routeMap) => {
    // Initial state contains any known routes. We expect microapps to eventually configure these URLs,
    // but we need to render an interstitial/placeholder to enable deep-linking and avoid redirecting to the default page.
    const knownRoutes = Object.entries(KNOWN_ROUTES).reduce(
      (memo, [appId, routes]) =>
        routes.reduce(
          (innerMemo, route) => ({
            ...innerMemo,
            [buildUrl(route)]: {
              url: buildUrl(route),
              appId,
              router: () => (
                <LoadMicroapp appId={appId}>
                  <Spinner />
                </LoadMicroapp>
              ),
            },
          }),
          memo
        ),
      {}
    );

    // real route keys overwrite placeholder knownRoute keys and
    // then we keep the route values that remain
    return Object.values({ ...knownRoutes, ...routeMap }).filter(
      (x) => x && x.router
    );
  }
);

const Router = ({ routes, match }) => (
  <Switch>
    {routes.map(({ url, router, exact, appId }) => (
      <Route
        key={url}
        path={buildUrl(match.path, url)}
        exact={exact}
        render={(props) => (
          // add tiny top padding to prevent margin collapsing
          // which creates a scroll bar we don't want
          <div
            data-app-id={appId}
            style={{ height: '100%', paddingTop: '.05px' }}
          >
            <ErrorBoundary>{React.createElement(router, props)}</ErrorBoundary>
          </div>
        )}
      />
    ))}
    {/* we want to visit on the home page after a tenant redirect and the landing page when entering the app */}
    <Redirect
      exact
      from={buildUrl(match.path, '/')}
      to={buildUrl(match.url, '/home')}
    />
    <Route render={() => <SlowRedirect to={buildUrl(match.url, '/home')} />} />
  </Switch>
);

Router.propTypes = {
  routes: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string.isRequired,

      /**
       * Router component
       */
      router: PropTypes.elementType.isRequired,

      exact: PropTypes.bool,
    })
  ),
  match: PropTypes.shape({
    path: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  }),
};

const mapStateToProps = (state) => ({ routes: routeMapSelector(state) });
export default withRouter(connect(mapStateToProps)(Router));
