import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { addMenu, removeMenu } from '@infosight/shell-api/lib/PrimaryNav';
import { AuthorizationEvaluator } from '../../../user';
import { isInternalUserSelector } from '../../../user/reducer';

const IrisPrimaryNavExtension = () => {
  const isInternalUser = useSelector(isInternalUserSelector);
  useEffect(() => {
    const getResources = () => {
      const menu = {
        id: 'iris', // Don't change the id properties without updating the nav sorting
        title: 'IRIS',
        depth: 1,
        options: AuthorizationEvaluator.filter([
          {
            id: 'passport-email-verification',
            title: 'Passport Email Verification',
            url: '/iris/email-verification',
            access: {
              custom: () => isInternalUser,
            },
            nodeType: 'internal',
          },
          {
            id: 'add-resseller',
            title: 'Add Resseller to Nimble Demo Tenant',
            url: '/iris/reseller-demo-tenant',
            access: {
              custom: () => isInternalUser,
            },
          },
        ]),
      };
      return menu.options.length ? [menu] : null;
    };
    // Everything is always here so we can react to changes
    const extension = {
      menuId: 'resources',
      id: 'iris',
      content: getResources(),
    };
    if (extension.content) {
      addMenu(extension);
    } else {
      removeMenu(extension);
    }
  }, [isInternalUser]);
  return null;
};
export default IrisPrimaryNavExtension;
