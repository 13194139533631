import { createSelector as createMemoizedSelector } from 'reselect';
import {
  createReducersForAction,
  createSelector,
  getInitialState,
} from '@infosight/elmer/dist/utils/redux';
import { FETCH_INVENTORY, FETCH_INVENTORY_SUCCEEDED, RESET } from './constants';
import { microappData } from '../router/knownRoutes';
import googleAnalytics from '../user/services/analytics/googleAnalyticsProvider';

const initialState = {
  ...getInitialState('inventory'),
};

const ACTION_HANDLERS = {
  [RESET]: () => initialState,
  ...createReducersForAction({
    type: FETCH_INVENTORY,
    stateKey: 'inventory',
  }),
  // Overwrite the success reducer for GA
  [FETCH_INVENTORY_SUCCEEDED]: (state, { payload }) => {
    googleAnalytics.setSystemCountDimensions(payload.inventory);
    return {
      ...state,
      ...payload,
      loadingInventory: false,
      loadedInventory: true,
      errorNimbleArrayCount: false,
    };
  },
};

export default function reducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}

const baseSelector = (state) => state.inventory;

export const inventorySelector = createSelector(baseSelector, 'inventory');
export const rawInventorySelector = createSelector(
  baseSelector,
  'rawInventory'
);

export const allSystemPresenceSelector = createMemoizedSelector(
  inventorySelector,
  ({ nimble, storeserv, storeonce, proliant, simplivity }) => ({
    storeserv: (storeserv && storeserv.presence) || false,
    simplivity: (simplivity && simplivity.presence) || false,
    proliant: (proliant && proliant.presence) || false,
    nimble: (nimble && nimble.presence) || false,
    storeonce: (storeonce && storeonce.presence) || false,
  })
);

/**
 * The title mapping is here to make our lives easier when it's time to add more systems - no need to update two repos.
 */
export const allSystemPresenceUISelector = createMemoizedSelector(
  allSystemPresenceSelector,
  (index) => {
    const titles = {
      storeserv: 'HPE Primera & HPE 3PAR StoreServ',
      simplivity: 'HPE SimpliVity OmniStack',
      proliant: 'HPE Servers',
      nimble: microappData.nimble.title,
      storeonce: 'HPE StoreOnce',
      unknown: 'unknown',
    };

    return Object.entries(index).reduce((memo, [key, value]) => {
      const name = titles[key] || titles.unknown;
      // eslint-disable-next-line no-param-reassign
      memo[name] = memo[name] || value;
      return memo;
    }, {});
  }
);
