import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames/bind';
import { connect } from 'react-redux';
import { ExtensionPoint } from '@infosight/shell-api/lib/core';
import {
  AdaptiveNode,
  NavBarPrimary,
  NavBarSecondary,
  NavBar,
  NavBarTitle,
} from '@infosight/elmer/dist/components/NavBar';
import { Logo } from '@infosight/elmer/esm/components/NavBar';
import { extensionPointSelector } from '../../extensibility';
import {
  isLoadingMicroappsSelector,
  microappsSelector,
} from '../../extensibility/orchestrator/reducer';
import { inventorySelector } from '../../inventory/reducer';
import TenantSelectorItem from './TenantSelectorItem';
import SearchItem from './SearchItem';
import WhatsNewItem from './WhatsNewItem';
import UserItem from './UserItem';
import style from './PrimaryNavBar.scss';
import { experimentalSelector } from '../../bootstrapper/reducer';
import { getEnvLabel } from '../utils';
import { navDataSelector } from '../reducer';
import NavMenu from './NavMenu';

const cx = classnames.bind(style);

const PrimaryNavBar = ({
  className,
  extension: { user, search },
  loadingInventory,
  loadedInventory,
  isLoadingMicroapps,
  navData: { microapps, settings, resources },
}) => {
  const navRef = useRef();

  // TODO remove this and fix elmer when we deploy sandboxing
  useEffect(() => {
    const { head } = document;

    const styleElement = document.createElement('style');

    // I do not like java and styles
    // I do not like combining files
    //
    // I do not like it here or there
    // I do not like it anywhere
    //
    // I would prefer to throw a fit
    // But instead i must write this
    //
    // I will not write without complaint
    // Dear god reader show some retraint
    styleElement.innerHTML = `
      .elmer-SecondaryNavBar__Title__name {
        font-weight: bold;
        border-right: 1px solid white;
        padding-right: 14px;
        padding-left: 5px;
      }
      .elmer-SecondaryNavBar__Title {
        padding-right: 0px;
      }
      `;

    head.appendChild(styleElement);

    return () => {
      head.removeChild(styleElement);
    };
  }, []);

  return (
    <div ref={navRef}>
      <NavBar className={classnames(className, cx('navbar'))}>
        <NavBarPrimary>
          <NavBarTitle>
            <NavMenu
              microapps={microapps}
              resources={resources}
              settings={settings}
              navRef={navRef}
              loading={
                !loadedInventory || loadingInventory || isLoadingMicroapps
              }
            />
            <AdaptiveNode href="/home">
              <div
                className={classnames(
                  'flex-dir-row flex-container align-middle',
                  cx('title-container')
                )}
              >
                <Logo className={cx('logo')} />
                <div
                  className={classnames(
                    'flex-container align-middle',
                    cx('title')
                  )}
                >
                  HPE InfoSight
                  {getEnvLabel(window.location.host) && (
                    <sup>{getEnvLabel(window.location.host)}</sup>
                  )}
                </div>
              </div>
            </AdaptiveNode>
          </NavBarTitle>
        </NavBarPrimary>

        <NavBarSecondary>
          <SearchItem options={search} />
          <WhatsNewItem />
          <UserItem options={user} />
          <TenantSelectorItem />
        </NavBarSecondary>
      </NavBar>
    </div>
  );
};

PrimaryNavBar.propTypes = {
  extension: PropTypes.shape({
    search: PropTypes.object,
    user: PropTypes.object,
  }).isRequired,
  navData: PropTypes.objectOf(
    PropTypes.shape({
      microapps: PropTypes.object,
      settings: PropTypes.array,
      resources: PropTypes.array,
    })
  ),
  loadingInventory: PropTypes.bool.isRequired,
  loadedInventory: PropTypes.bool.isRequired,
  isLoadingMicroapps: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  extension: extensionPointSelector(state, ExtensionPoint.PrimaryNav),
  microappManifest: microappsSelector(state),
  experimental: experimentalSelector(state),
  ...inventorySelector(state),
  isLoadingMicroapps: isLoadingMicroappsSelector(state),
  navData: navDataSelector(state),
});
export default connect(mapStateToProps)(PrimaryNavBar);
