import React, { useState } from 'react';
import { Layer } from 'grommet';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  demoRolePropertiesSelector,
  sessionSelector,
  officialDemoSelector,
} from '../iam/reducer';
import DemoRoleDialog from './DemoRoleDialog';

const DemoRole = ({ demoRole, loadedSession, officialDemoTenant }) => {
  const [modalOpen, setModalOpen] = useState(true);
  if (!loadedSession) {
    return null;
  }

  if (officialDemoTenant) {
    return (
      <>
        {!demoRole && modalOpen && (
          <Layer>
            <DemoRoleDialog
              currentRole={demoRole}
              close={() => setModalOpen(false)}
            />
          </Layer>
        )}
      </>
    );
  }

  return null;
};

DemoRole.propTypes = {
  demoRole: PropTypes.string,
  loadedSession: PropTypes.bool,
  officialDemoTenant: PropTypes.bool,
};

DemoRole.defaultProps = {
  demoRole: null,
};

const mapStateToProps = (state) => ({
  ...demoRolePropertiesSelector(state),
  ...sessionSelector(state),
  officialDemoTenant: officialDemoSelector(state),
});

export default connect(mapStateToProps)(DemoRole);
