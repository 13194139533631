import { createSelector } from 'reselect';
import { compact } from 'underscore';
import { ExtensionPoint } from '@infosight/shell-api/lib/core';
import { actionNamespacer } from '@infosight/elmer/dist/utils/redux';
import { extensionPointSelector } from '../selectors';

const ns = actionNamespacer(`EXTENSIONS/${ExtensionPoint.Bootstrapper}`);

export const REGISTER = ns('REGISTER');
export const RESET = ns('RESET');
export const SET_APP_READY = ns('SET_APP_READY');

const initialState = {
  appReady: false,
  apps: {},
};

const ACTION_HANDLERS = {
  [REGISTER]: (state, { payload }) => ({
    ...state,
    apps: { ...state.apps, [payload.appId]: payload },
  }),
  [SET_APP_READY]: (state) => ({ ...state, appReady: true }),
  [RESET]: () => initialState,
};

export default (state = initialState, action) => {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
};

const baseSelector = (state) =>
  extensionPointSelector(state, ExtensionPoint.Bootstrapper);
export const microappsSelector = createSelector(baseSelector, (microapps) =>
  compact(Object.values(microapps.apps))
);
export const appIsReadySelector = (state) => baseSelector(state).appReady;
