import React from 'react';
import PrimaryNavExtension from './PrimaryNavExtension';
import RouterExtension from './RouterExtension';

const UserExtensions = () => (
  <>
    <PrimaryNavExtension />
    <RouterExtension />
  </>
);

export default UserExtensions;
