import React, { Component } from 'react';
import PropTypes from 'prop-types';
import autobind from 'react-autobind';
import { Box } from 'grommet';
import actionsFor from '@infosight/elmer/dist/utils/redux/actionsFor';
import Button from '@infosight/elmer/dist/components/Button';
import { connect } from 'react-redux';
import { USER_TOUR_STATUS } from '../constants';
import { UPDATE_SESSION, UPDATE_SESSION_SUCCEEDED } from '../../iam/constants';
import postIAMRequest from '../../services/postIAMRequest';
import { updateTourStatusProperty } from '../interactionTracker';
import { ENQUEUE_TOUR, toursSelector } from '../../extensibility/tour/reducer';
import { tourUserPropertiesSelector } from '../../iam/reducer';
import { generateId } from '../../extensibility/tour/actionCreators';

function resetTourAndUpdateSession(id) {
  return async (dispatch) => {
    const { dispatchStart, dispatchError } = actionsFor(
      UPDATE_SESSION,
      dispatch
    );
    dispatchStart();
    await updateTourStatusProperty({ id, action: null });

    try {
      const {
        data: { data },
      } = await postIAMRequest({
        query: `
                {
                    currentSession {
                        identityProvider
                        userId
                        userName
                        tenantId
                        tenantName
                        userRole
                        userRealm
                        claimTags
                        oculusRoles
                        user {
                            properties {
                                urn
                                value
                            }
                        }
                    }
                }`,
      });

      dispatch({
        type: UPDATE_SESSION_SUCCEEDED,
        payload: data.currentSession,
      });
    } catch (e) {
      dispatchError(e);
    }
  };
}

/**
 * Installs itself to window.__shell__devTools.tour so devs have access to all members and props of the instance
 *
 * If shell:devtool:tours in Local Storage is 'true', it will also render a UI at the top of the screen
 */
class TourDeveloperTool extends Component {
  constructor(props) {
    super(props);
    autobind(this);
  }

  componentDidMount() {
    try {
      const enableDevTool =
        localStorage && localStorage.getItem('shell:devtool:tours');
      this.renderDevTool = enableDevTool && JSON.parse(enableDevTool);
    } catch (e) {
      this.renderDevTool = false;
    }

    this.installDevTool();
  }

  installDevTool() {
    // eslint-disable-next-line no-underscore-dangle
    window.__shell__devTools = Object.assign(window.__shell__devTools || {}, {
      tour: this,
    });
  }

  // eslint-disable-next-line class-methods-use-this
  help() {
    console.log(`
Members of window.__shell__devTools.tour:

resetTour - Reset a tour's status store in IAM user properties
    resetTour({ appId, name })
    resetTour(id)

startTour - Force a tour to start
    startTour({ appId, name })
    startTour(id)

props.tourUserProperties - Index of user status for each tour. The key is the Tour ID

props.tours - Index of configs for each tour. The key is the Tour ID
    `);
  }

  /**
   * @resetTour resetTour
   * @description Reset a tour's status store in IAM user properties
   * @param {{appId: string, name: string}|string} opts An object containing the appId and name OR the generated tour id
   * @example `window.__shell__devTools.tour.resetTour({ appId: 'iam', name: 'Org Management' })`
   * @example `window.__shell__devTools.tour.resetTour('iam_org-management')`
   */
  resetTour(opts) {
    let id;
    if (typeof opts === 'string') {
      id = opts;
    } else if (opts.appId) {
      id = generateId(opts.appId, opts.name);
    } else {
      const error =
        'An object containing { appId, name } OR a tour ID string is required.';
      throw error;
    }

    resetTourAndUpdateSession(id)(this.props.dispatch);
  }

  /**
   * @method startTour
   * @description Start a tour programatically
   * @param {{appId: string, name: string}|string} opts An object containing the appId and name OR the generated tour id
   * @example `window.__shell__devTools.tour.startTour({ appId: 'iam', name: 'Org Management' })`
   * @example `window.__shell__devTools.tour.startTour('iam_org-management')`
   */
  startTour(opts) {
    let id;
    if (typeof opts === 'string') {
      id = opts;
    } else if (opts.appId) {
      id = generateId(opts.appId, opts.name);
    } else {
      const error =
        'An object containing { appId, name } OR a tour ID string is required.';
      throw error;
    }

    this.props.dispatch({ type: ENQUEUE_TOUR, payload: { id } });
  }

  render() {
    if (!this.renderDevTool) {
      return null;
    }

    const { tourUserProperties, tours } = this.props;
    return (
      <Box background="red" data-testid="tour-developer-tools">
        <Box pad="medium" round="small" background="white" margin="small">
          <h3>Developer Tool: Feature Tours</h3>
          {tours && (
            <Box gap="small">
              {Object.entries(tours).map(([id]) => {
                const { action, timestamp } =
                  (tourUserProperties && tourUserProperties[id]) || {};
                return (
                  <Box key={id} gap="small" direction="row" align="center">
                    <Button onClick={() => this.resetTour(id)}>Reset</Button>
                    <Button onClick={() => this.startTour(id)}>Start</Button>
                    <pre>{JSON.stringify({ id, action, timestamp })}</pre>
                  </Box>
                );
              })}
            </Box>
          )}
        </Box>
      </Box>
    );
  }
}

TourDeveloperTool.propTypes = {
  tours: PropTypes.objectOf(PropTypes.object),
  tourUserProperties: PropTypes.objectOf(
    PropTypes.shape({
      action: PropTypes.oneOf(Object.values(USER_TOUR_STATUS)).isRequired,
      timestamp: PropTypes.string.isRequired, // ISO-8601
    }).isRequired
  ),
  dispatch: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  tourUserProperties: tourUserPropertiesSelector(state),
  tours: toursSelector(state),
});

export default connect(mapStateToProps)(TourDeveloperTool);
