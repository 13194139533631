import GlobalBanner from './GlobalBanner';
import GlobalBannerList from './GlobalBannerList';
import {
    INFO,
    SUCCESS,
    WARNING,
    ERROR,
} from './constants';

/**
 * @deprecated
 */
export default GlobalBanner;

export { GlobalBanner, GlobalBannerList, INFO, SUCCESS, WARNING, ERROR };
