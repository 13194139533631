import { Component } from 'react';
import PropTypes from 'prop-types';
import ReactClickOutside from 'react-click-outside';

export class OutsideClickWrapper extends Component {
    handleClickOutside(e) {
        if (this.shouldHandleOutsideClickEvent(e)) {
            this.props.handleClickOutside(e);
        }
    }

    shouldHandleOutsideClickEvent(e) {
        const { shouldHandleOutsideClickEvent } = this.props;
        if (typeof shouldHandleOutsideClickEvent === 'function') {
            return shouldHandleOutsideClickEvent(e);
        }

        return true;
    }

    render() {
        return this.props.children;
    }
}

OutsideClickWrapper.propTypes = {
    children: PropTypes.element.isRequired,
    handleClickOutside: PropTypes.func.isRequired,

    /**
     * Callback when an outside click is detected.
     *
     * Example: This is useful on occasion when the menu is open, but a feature tour is in progress.
     *   If the user clicks the "Next" button in the tour, they have technically "clicked outside" the open menu,
     *   so by default it would be closed. This prop is an escapte hatch to keep the menu open.
     *
     * @callback
     * @param {Event} event
     * @return {Boolean} true if the outside click event should be handled, which will close the menu.
     */
    shouldHandleOutsideClickEvent: PropTypes.func,
};

export default ReactClickOutside(OutsideClickWrapper);
