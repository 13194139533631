/**
 * BEM class name builder
 */
export default class BEM {
    static nextValue(bem) {
        return bem.chainable ? bem : bem.valueOf();
    }

    constructor(blockOrValues) {
        if (typeof blockOrValues === 'object') {
            const { block, element, modifier, chainable = false } = blockOrValues;
            Object.assign(this, { block, element, modifier, chainable });
        } else if (typeof blockOrValues === 'string') {
            this.block = blockOrValues;
        } else {
            throw new Error('contructor argument must be a string or object');
        }
    }

    /**
     * Create a new instance from this instance
     * @param constructorOptions
     * @return {BEM}
     */
    cloneWith(constructorOptions) {
        return new BEM({ ...this, ...constructorOptions });
    }

    /**
     * Shortcut to get the class name
     * @return {string} this.valueOf()
     */
    b() {
        return this.valueOf();
    }

    /**
     * Add an element segmemt
     * @param element
     * @return {BEM|string} Returns this instance if chained (using this.chain()), or this.valueOf()
     */
    e(element) {
        return BEM.nextValue(this.cloneWith({ element }));
    }

    /**
     * Add a modifier segment
     * @param modifier
     * @return {BEM|string} Returns this instance if chained (using this.chain()), or this.valueOf()
     */
    m(modifier) {
        return BEM.nextValue(this.cloneWith({ modifier }));
    }

    /**
     * Creates class name represented by this instance
     * @return {string}
     */
    valueOf() {
        const { block, element, modifier } = this;
        let className = block;
        if (element) {
            className = `${className}__${element}`;
        }

        if (modifier) {
            className = `${className}--${modifier}`;
        }

        return className;
    }

    /**
     * Chain function calls instead of immediately returning the class
     * @return {BEM}
     */
    chain() {
        return BEM.nextValue(this.cloneWith({ chainable: true }));
    }
}
