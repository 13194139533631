/* eslint-disable prefer-destructuring */
/**
 * Base path that is "owned" by client-side routing
 */
export const APP_BASE_URL = process.env.APP_BASE_URL;

/**
 * Base path to API
 */
export const API_BASE_URL = process.env.API_BASE_URL || '/';

/**
 * Base path of server. Mostly a patch for nimble things like the KCS API proxy and to differentiate nimble and SFRM requests
 */
export const BASE_URL = process.env.SERVER_BASE_URL;

/**
 * Min width of the app. Useful for some highcharts charts
 * @type {number}
 */
export const MIN_WIDTH_PIXELS = 1280;

export const MICROAPP_MANIFEST_URL = process.env.MICROAPP_MANIFEST_URL;
export const MICROAPP_MAINTENANCE_URL = process.env.MICROAPP_MAINTENANCE_URL;

// Since the core environments are well-known, just derive it from the host
const detectEnvironment = () => {
  switch (window.location.host) {
    case 'infosight.hpe.com':
      return 'prod';
    case 'beta.infosight.hpe.com':
      return 'beta';
    case 'dev.infosight.hpe.com':
      return 'dev';
    case 'ap1.infosight.hpe.com':
      return 'ap1';
    default:
      break;
  }

  // Best effort at detecting partner environments like sv1, nl1, and cloud.test7
  if (window.location.host.endsWith('.infosight.hpe.com')) {
    return 'partner';
  }

  // Otherwise, assume local developer environments
  return 'local';
};

export const env = detectEnvironment();

// Since environments where we care about core analytics are well-known, enable based on the the environment
export const analyticsEnabled = (() => {
  switch (env) {
    case 'prod':
    case 'beta':
    case 'dev':
    case 'ap1':
      return process.env.ANALYTICS_ENABLED;
    case 'local': // This case is separate to call it out as special - analytics are rarely enabled for local envs.
      return process.env.ANALYTICS_ENABLED;
    default:
      return false;
  }
})();

export const experimentalModeAllowed = (() => {
  switch (env) {
    case 'prod':
      return false;
    default:
      return true;
  }
})();
