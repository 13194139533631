import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

/**
 * Primary (left) side of nav
 */
const NavBarPrimary = ({ className, children }) => (
    <ul className={classnames(className, 'flex-container top-bar-left')}>
        {children}
    </ul>
);

NavBarPrimary.propTypes = {
    children: PropTypes.node.isRequired,
};

export default NavBarPrimary;
