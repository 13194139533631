import { isString, findWhere } from 'underscore';
import deepGet from 'utils-deep-get';
import { getStore } from '../../../util/storeProvider';
import { userWithIAMSelector } from '../../reducer';
import {
  AUTHORITIES,
  PERMISSION_TARGET,
  SITE_ADMIN_ROLE,
} from '../../constants';
import { sessionSelector } from '../../../iam/reducer';

/* eslint-disable no-use-before-define */
const anyOfAuthorities = (selectors, user) =>
  selectors.some((authorities) => evaluateAuthorities(authorities, user));
const allOfAuthorities = (selectors, user) =>
  selectors.every((authorities) => evaluateAuthorities(authorities, user));
/* eslint-enable no-use-before-define */

const getSessionUser = () => userWithIAMSelector(getStore().getState());
const hasAuthority = (user, authority) => {
  // For a couple common authorities, we have a non-bootstrap based equivalent
  if (authority === AUTHORITIES.SITE_ACCESS && user && !!user.session) {
    return true;
  }
  // eslint-disable-next-line no-use-before-define
  if (authority === AUTHORITIES.SITE_ADMIN && hasRole(user, SITE_ADMIN_ROLE)) {
    return true;
  }

  // Always fallback to the Nimble authorities
  return user && user.authorities && user.authorities.includes(authority);
};

/**
 * Evaluate various forms of a selector against a user's authorities.
 * See unit tests for simplified realistic examples
 * TLDR: Nested arrays represent AND ("all of") operations until the last array, which is an OR ("any of") operation
 *
 * @param authorities {string} Implicit INCLUDES operation: Returns true when the user has this authority
 * @param authorities {string[]} Implicit OR operation: Returns true when the user has at least one of the authorities (OR operation)
 * @param authorities {string[][]} Implicit AND operation: Returns true when the user has at least one of authorities in each 2nd dimension element
 * @param authorities {{$or: []} Explicit OR operation: Returns true when any of the selectors in the array is true
 * @param authorities {{$and: []} Explicit AND operation: Returns true when all of the selectors in the array is true
 * @param authorities {{$not: {}} Explicit AND operation: Returns true when all of the selectors in the array is true
 * @param authorities {undefined} Returns true
 * @param user
 *
 * @default false
 */
const evaluateAuthorities = (authorities, user) => {
  if (!authorities) {
    return true;
  }

  if (isString(authorities)) {
    return hasAuthority(user, authorities);
  }

  if (authorities.$or && Array.isArray(authorities.$or)) {
    return anyOfAuthorities(authorities.$or, user);
  }

  if (authorities.$and && Array.isArray(authorities.$and)) {
    return allOfAuthorities(authorities.$and, user);
  }

  if (authorities.$not) {
    return !evaluateAuthorities(authorities.$not, user);
  }

  if (Array.isArray(authorities)) {
    // multi-dimensional arrays are implicitly representations of $and
    const isMultiDimensionalArray = authorities.some(Array.isArray);
    return isMultiDimensionalArray
      ? allOfAuthorities(authorities, user)
      : anyOfAuthorities(authorities, user);
  }

  return false;
};

/* eslint-disable no-use-before-define */
const anyOfRoles = (roles, user) =>
  roles.some((role) => evaluateRoles(role, user));
/* eslint-enable no-use-before-define */

const hasRole = (user, role) =>
  user &&
  user.session &&
  user.session.oculusRoles &&
  user.session.oculusRoles.includes(role);
const evaluateRoles = (roles, user) => {
  if (!roles) {
    return true;
  }

  if (isString(roles)) {
    return hasRole(user, roles);
  }

  if (Array.isArray(roles)) {
    return anyOfRoles(roles, user);
  }

  return false;
};

const hasPermission = (user, permission) =>
  Array.isArray(user.permissions) && !!findWhere(user.permissions, permission);
const isPermission = (permissionLike) => {
  if (!permissionLike) {
    return false;
  }

  const { privilege } = permissionLike;
  return privilege && isString(privilege);
};

/* eslint-disable no-use-before-define */
const anyOfPermissions = (selectors, user) =>
  selectors.some((permissions) => evaluatePermissions(permissions, user));
const allOfPermissions = (selectors, user) =>
  selectors.every((permissions) => evaluatePermissions(permissions, user));
/* eslint-enable no-use-before-define */

/* eslint-disable max-len */
/**
 * Evaluate various forms of a selector against a user's permissions.
 * See unit tests for simplified realistic examples
 * TLDR: Nested arrays represent AND ("all of") operations until the last array, which is an OR ("any of") operation
 *
 * @param permissions {string} Implicit INCLUDES operation: Returns true when the user has this permission regardless of target
 * @param permissions {{ privilege, targetType, targetId }} Implicit INCLUDES operation: Returns true when the user has this permission
 * @param permissions {Array<{ privilege, targetType, targetId }>} Implicit OR operation: Returns true when the user has at least one of the permissions (OR
 *     operation)
 * @param permissions {Array<Array<{ privilege, targetType, targetId }>>} Implicit AND operation: Returns true when the user has at least one of permissions in
 *     each 2nd dimension element
 * @param permissions {{$or: []} Explicit OR operation: Returns true when any of the selectors in the array is true
 * @param permissions {{$and: []} Explicit AND operation: Returns true when all of the selectors in the array is true
 * @param permissions {{$not: {}} Explicit AND operation: Returns true when all of the selectors in the array is true
 * @param permissions {undefined} Returns true
 * @param user
 *
 * @default false
 */
/* eslint-enable max-len */
const evaluatePermissions = (permissions, user) => {
  if (!permissions) {
    return true;
  }

  if (isString(permissions)) {
    return hasPermission(user, { privilege: permissions });
  }

  if (permissions.$or && Array.isArray(permissions.$or)) {
    return anyOfPermissions(permissions.$or, user);
  }

  if (permissions.$and && Array.isArray(permissions.$and)) {
    return allOfPermissions(permissions.$and, user);
  }

  if (permissions.$not) {
    return !evaluatePermissions(permissions.$not, user);
  }

  if (Array.isArray(permissions)) {
    // multi-dimensional arrays are implicitly representations of $and
    const isMultiDimensionalArray = permissions.some(Array.isArray);
    return isMultiDimensionalArray
      ? allOfPermissions(permissions, user)
      : anyOfPermissions(permissions, user);
  }

  if (isPermission(permissions)) {
    const { privilege, targetType, targetId } = permissions;
    const permission = { privilege, targetType };

    if (targetId && targetId !== '*') {
      permission.targetId = targetId;
    }

    if (targetId === PERMISSION_TARGET.ACTIVE_TARGET_ID) {
      const session = sessionSelector(getStore().getState());
      const claims = deepGet(session, 'session.claimTags');
      if (claims) {
        const nimbleSfdcClaimTag = claims.find((x) =>
          x.startsWith('urn:nimble:')
        );
        if (nimbleSfdcClaimTag) {
          permission.targetId = nimbleSfdcClaimTag.replace('urn:nimble:', '');
        }
      }
    }

    return (
      hasPermission(user, permission) ||
      hasPermission(user, {
        privilege,
        targetType,
        targetId: PERMISSION_TARGET.GLOBAL_TARGET_ID,
      })
    );
  }

  return false;
};

const evaluateCustom = (custom, user) => {
  if (!custom) {
    return true;
  }

  if (typeof custom === 'function') {
    return !!custom(user);
  }

  return false;
};

/* eslint-disable no-use-before-define */
const anyOf = (selectors, user) =>
  selectors.some((selector) => evaluate(selector, user));
const allOf = (selectors, user) =>
  selectors.every((selector) => evaluate(selector, user));
/* eslint-enable no-use-before-define */

const evaluate = (selector, user) => {
  // Should not evaluate if undefined
  if (selector === undefined) {
    return true;
  }

  // Otherwise falsy values evaluate to false
  if (!selector || !user) {
    return false;
  }

  if (selector.$or && Array.isArray(selector.$or)) {
    return anyOf(selector.$or, user);
  }

  if (selector.$and && Array.isArray(selector.$and)) {
    return allOf(selector.$and, user);
  }

  // 1-Dimensional arrays represent implicit OR operations
  // 2-Dimensional arrays represent implicit AND operations between each element in the 1-dimension
  if (Array.isArray(selector)) {
    const isMultiDimensionalArray = selector.some(Array.isArray);
    return isMultiDimensionalArray
      ? allOf(selector, user)
      : anyOf(selector, user);
  }

  if (typeof selector === 'object') {
    return (
      evaluateAuthorities(selector.authorities, user) &&
      evaluateRoles(selector.sfrRoles, user) &&
      evaluatePermissions(selector.permissions, user) &&
      evaluateCustom(selector.custom, user)
    );
  }

  return true;
};

const AuthorizationEvaluator = {
  /**
   * Evalute a user against a selector set
   * @param {{access: Object}} selector
   * @param user
   * @return {boolean}
   */
  evaluateUser(selector, user) {
    if (!selector) {
      console.error('Cannot evaluate falsy selector'); // This is to get a stack trace
      return false;
    }

    // This is stripped from production bundle
    if (process.env.NODE_ENV === 'development') {
      if (user.god) {
        return true;
      }
    }

    return evaluate(selector.access, user);
  },

  /**
   * Shortcut evaluateUser that retrieves the user from the store
   * @param selector
   * @return {boolean}
   */
  evaluate(selector) {
    return AuthorizationEvaluator.evaluateUser(selector, getSessionUser());
  },

  /**
   * Evaluate each object in a list
   * @param list
   * @param user
   */
  filter(list, user) {
    return list.filter((selector) =>
      AuthorizationEvaluator.evaluateUser(selector, user || getSessionUser())
    );
  },
};

export default AuthorizationEvaluator;
