// Shims/Polyfills
import 'core-js/stable';
import 'regenerator-runtime/runtime';

import '@webcomponents/custom-elements';
/* eslint-disable import/first */
import './util/clarityIconsShim';

// App Styles
// TODO I want to get rid of this in favor of explicit SVG icon usage but multiple microapps use it.
// We should avoid using this in Shell and Elmer
import '@infosight/elmer/dist/style.css';

import React from 'react';
import ReactDOM from 'react-dom';

// Backward-comapatibility shim for microapps still using grommet2.beta5
import '@infosight/styled-components-custom/styled-components-3.4.10';

// Create shared styled-components instance for microapps
import '@infosight/styled-components-custom/styled-components-5.0.1';

import Bootstrapper from './bootstrapper/Bootstrapper';
import { createStore } from './util/store';

import { fetchIamSession } from './iam/actionCreators';

const store = createStore();
// We want this to happen as quickly as possible because it blocks the entire UI from loading
store.dispatch(fetchIamSession());

ReactDOM.render(
  <Bootstrapper store={store} />,
  document.getElementById('main')
);
