/**
 * Finds column object and its index (position) in the given Column List Objects
 * @param {Array} columnList Array to search for
 * @param {String} columnId
 * @returns {object} column { columnIndex, columnObject}
 */
export const findColumn = (columnList = [], columnId) => {
    const columnIndex = columnList.findIndex(columnObject => columnObject.id === columnId);
    return columnIndex === -1 ? { columnIndex, column: {} } : { columnIndex, column: columnList[columnIndex] };
};

/*
Early to condense this methods as we are going to introduce new type of filters
which might require object serialization
*/
export const buildFiltersFromQueryParameters = (params) => {
    if (!params || !params.filter) {
        return null;
    }
    
    const { filter } = params;

    // Filter Object to array
    const filteredColumnNames = Object.keys(filter);
    const filters = filteredColumnNames.reduce((memo, columnName) =>
        memo.concat({
            id: columnName,
            value: filter[columnName],
        }), []);
    return filters;
};

export const buildSortFromQueryParameters = (params) => {
    if (!params || !params.sort) {
        return null;
    }
    const { sort } = params;
    // Sort Object to array
    const sortedColumnNames = Object.keys(sort);
    const sortedColumns = sortedColumnNames.reduce((memo, columnName) =>
        memo.concat({
            id: columnName,
            desc: sort[columnName],
        }), []);
    return sortedColumns;
};


/*
Too early to condense this methods as we are going to introduce new type of filters
which might require object serialization
*/
export const toFilterQueryParams = (filtered) => {
    // Array to Object
    const parameters = filtered.reduce((memo, filteredColumn) => {
        const query = { [filteredColumn.id]: filteredColumn.value };
        return { ...memo, ...query };
    }, {});
    return parameters;
};

export const toSortQueryParams = (sorted) => {
    // Array to Object
    const parameters =
        sorted.reduce((memo, sortedColumn) => {
            const query = { [sortedColumn.id]: sortedColumn.desc };
            return { ...memo, ...query };
        }, {});

    return parameters;
};

/**
 * Default Filter Method for Table
 * Checks for Case insensitive string match
 * @param {filter} filter - Object representing the filter object
 * @param filter.id {string} - id of the column
 * @param filter.value {string} - filter value
 * @param {row} - Object representing the row of the table
 * @returns {boolean} true if the row matches filter criteria
 */
export const filterMethod = (filter = {}, row) => {
    const { id, value } = filter;
    const rowValue = String(row[id]).toLowerCase();
    if (Array.isArray(value)) {
        return value.some(v => rowValue === String(v).toLowerCase());
    }
    return row[id] !== undefined ? rowValue.indexOf(String(value).toLowerCase()) !== -1 : true;
};

/**
 * Default sort method for Table
 * Modified version of react-table's defaultSortMethod to handle the case where we need to
 * distinguish null and zeros.
 * @param a {string}
 * @param b {string}
 * @param desc {boolean} true if Descending Order, else false
 * @returns {number} 1, -1 or 0
 */
export const sortMethod = (a, b, desc) => {
    let newA = a;
    let newB = b;

    // force null and undefined to the bottom
    newA = newA === null || newA === undefined ? '' : newA;
    newB = newB === null || newB === undefined ? '' : newB;

    // force any string values to lowercase
    newA = typeof newA === 'string' ? newA.toLowerCase() : newA;
    newB = typeof newB === 'string' ? newB.toLowerCase() : newB;

    // if asc order, show nulls at the bottom
    if (!desc) {
        // Return either 1 or -1 to indicate a sort priority
        if (newA === '') {
            return 1;
        }
        if (newB === '') {
            return -1;
        }
    }

    // need to distinguish between nulls and zeros, so that nulls show at the bottom
    if (newA === '' && newB === 0) {
        return -1;
    }

    if (newB === '' && newA === 0) {
        return 1;
    }

    if (newA > newB) {
        return 1;
    }
    if (newA < newB) {
        return -1;
    }
    // returning 0, undefined or any falsey value will use subsequent sorts or the index as a tiebreaker
    return 0;
};
