import { pick, flatten } from 'underscore';
import { createSelector as createMemoizedSelector } from 'reselect';
import { getErrorActionType, getStartedActionType, getSuccessActionType } from './actionTypeGenerators';

/**
 * Get the standard keys from a base key
 * @param stateKey
 * @return {{loadingKey: string, loadedKey: string}}
 * @private
 */
function getKeys(stateKey) {
    const baseKey = `${stateKey.charAt(0).toUpperCase()}${stateKey.slice(1)}`;

    return {
        valueKey: stateKey,
        loadingKey: `loading${baseKey}`,
        loadedKey: `loaded${baseKey}`,
        errorKey: `error${baseKey}`,
    };
}

/**
 * Get the standard keys from a base key
 * @param stateKey
 * @private
 */
function getKeysArray(stateKey) {
    return Object.values(getKeys(stateKey));
}

/**
 * Create a selector that returns a subset of keys in a field in state. The resulting object is intended to be used
 * with the spread operator.
 * @param baseSelector
 * @param keys
 * @memberof redux
 */
export function createSelector(baseSelector, ...keys) {
    const stateKeys = flatten(keys.map(getKeysArray));
    return createMemoizedSelector(baseSelector, selectedState => pick(selectedState, ...stateKeys));
}

/**
 * Shortcut to generate the initial state of a reducer for a specific member
 * @param stateKey
 * @memberof redux
 * @private
 */
export function getInitialState(stateKey) {
    const { loadingKey, loadedKey, errorKey } = getKeys(stateKey);

    return {
        [stateKey]: undefined,
        [loadingKey]: false,
        [loadedKey]: false,
        [errorKey]: false,
    };
}

/**
 * Create standard reducers for an asynchronously fetched piece of state.
 * The return object should be spread/merged into a "reducer" object
 * @param type
 * @param stateKey
 * @return {*}
 * @memberof redux
 */
export default function createReducersForAction({ type, stateKey }) {
    const { loadingKey, loadedKey, errorKey } = getKeys(stateKey);

    return {
        [getStartedActionType(type)]: state => ({
            ...state,
            [stateKey]: null,
            [loadingKey]: true,
            [loadedKey]: false,
            [errorKey]: false,
        }),
        [getSuccessActionType(type)]: (state, { payload }) => ({
            ...state,
            [stateKey]: payload,
            [loadingKey]: false,
            [loadedKey]: true,
            [errorKey]: false,
        }),
        [getErrorActionType(type)]: (state, { payload: error }) => ({
            ...state, // TODO should I remove the reference at "stateKey"?
            error, // TODO do something with the error, like flash notification. Shouldn't be in store
            [loadingKey]: false,
            [loadedKey]: false,
            [errorKey]: true,
        }),
    };
}
