import React from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Heading, Grommet, Paragraph } from 'grommet';
import { theme as elmerTheme } from '@infosight/elmer/dist/components/ThemeProvider';
import { Close } from 'grommet-icons';
import classnames from 'classnames/bind';
import style from './style.scss';
import { FOOTER_TYPE } from '../constants';
import NavButton from './NavButton';
import Stepper from './Stepper';

const cx = classnames.bind(style);

const FooterBox = (props) => (
  <Box
    {...props}
    direction="row"
    gap="small"
    align="center"
    data-test="footer"
    fill="horizontal"
    pad={{ top: 'small' }}
  />
);

const Callout = ({
  title,
  footer,
  body,
  steps,
  currentStepIndex,
  goToNext,
  goToPrevious,
  cancel,
  complete,
  snooze,
  onAcceptTour,
}) => (
  <Grommet theme={elmerTheme}>
    <Box
      background="white"
      pad="medium"
      className={cx('callout')}
      direction="column"
      justify="between"
      data-test="tour-step"
      data-step-index={
        currentStepIndex
      } /* Here for test automation selectors */
    >
      <Box
        justify="between"
        direction="row"
        align="center"
        data-test="header"
        margin={{ bottom: 'small' }}
      >
        <Heading
          level={3}
          size="22px"
          weight="bold"
          color="brand"
          className="no-margin inherit-color"
        >
          {title}
        </Heading>
        <Button
          icon={<Close size="22px" color="dark-2" />}
          onClick={cancel}
          plain
        />
      </Box>
      <Box overflow="auto" flex="shrink">
        <Paragraph fill>{body}</Paragraph>
      </Box>
      {footer === FOOTER_TYPE.INTRO && (
        <FooterBox justify="end">
          <Button
            label="Take a Tour"
            primary
            onClick={onAcceptTour}
            data-test="start-tour"
          />
          <Button
            label="Maybe Later"
            onClick={snooze}
            color="dark-2"
            data-test="snooze-tour"
          />
        </FooterBox>
      )}
      {footer === FOOTER_TYPE.BIDIRECTIONAL && (
        <FooterBox justify="between">
          <NavButton direction="back" onClick={goToPrevious} />
          <Stepper stepCount={steps} currentStepIndex={currentStepIndex} />
          <NavButton direction="next" primary onClick={goToNext} />
        </FooterBox>
      )}
      {footer === FOOTER_TYPE.FORWARD_ONLY && (
        <FooterBox justify="between">
          <NavButton direction="back" onClick={goToPrevious} disabled />
          <Stepper stepCount={steps} currentStepIndex={currentStepIndex} />
          <NavButton direction="next" primary onClick={goToNext} />
        </FooterBox>
      )}
      {footer === FOOTER_TYPE.CONCLUSION && (
        <FooterBox justify="between">
          <NavButton direction="back" onClick={goToPrevious} />
          <Stepper stepCount={steps} currentStepIndex={currentStepIndex} />
          <Button
            label="Finish"
            onClick={complete}
            primary
            data-test="conclude-tour"
          />
        </FooterBox>
      )}
      {footer === FOOTER_TYPE.CONCLUSION_ONLY && (
        <FooterBox justify="between">
          <NavButton direction="back" onClick={goToPrevious} disabled />
          <Stepper stepCount={steps} currentStepIndex={currentStepIndex} />
          <Button
            label="Finish"
            onClick={complete}
            primary
            data-test="conclude-tour"
          />
        </FooterBox>
      )}
    </Box>
  </Grommet>
);

Callout.propTypes = {
  title: PropTypes.string.isRequired,
  body: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  footer: PropTypes.oneOf(Object.values(FOOTER_TYPE)).isRequired,
  goToNext: PropTypes.func.isRequired,
  goToPrevious: PropTypes.func.isRequired,
  onAcceptTour: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired,
  complete: PropTypes.func.isRequired,
  snooze: PropTypes.func.isRequired,
  steps: PropTypes.number.isRequired,
  currentStepIndex: PropTypes.number.isRequired,
};

export default Callout;
