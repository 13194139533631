import { ExtensionPoint } from '@infosight/shell-api/lib/core';
import { actionNamespacer } from '@infosight/elmer/dist/utils/redux';

const ns = actionNamespacer(`EXTENSIONS/${ExtensionPoint.PrimaryNav}`);

export const ADD = ns('ADD');
export const REMOVE = ns('REMOVE');
export const RESET = ns('RESET');

const initialState = {};

const ACTION_HANDLERS = {
  [ADD]: (state, { payload: { id, content, menuId } }) => ({
    ...state,
    [menuId]: { ...state[menuId], [id]: content },
  }),
  [REMOVE]: (state, { payload: { id, menuId } }) => ({
    ...state,
    [menuId]: { ...state[menuId], [id]: null },
  }),
  [RESET]: () => initialState,
};

export default (state = initialState, action) => {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
};
