import { createSelector } from 'reselect';
import { flatten, mapObject } from 'underscore';
import {
  ADD_SEARCH_RESULT,
  REGISTER_SUBJECTS,
  STATES,
  START_SEARCH,
  RESET,
  RESET_SEARCH,
} from './constants';
import { UPDATE_SESSION_SUCCEEDED } from '../iam/constants';

const initialState = {
  query: null,
  queryOptions: {},
  subjects: {},
  results: {},
  states: {},
};

function addSearchResult(state, { payload }) {
  const { id } = payload;
  return {
    ...state,
    results: {
      ...state.results,
      [id]: { hits: payload.results, error: payload.error },
    },
    states: { ...state.states, [id]: payload.state || STATES.RECEIVED_RESULTS },
  };
}

const ACTION_HANDLERS = {
  [UPDATE_SESSION_SUCCEEDED]: () => initialState,
  [RESET]: () => initialState,
  [REGISTER_SUBJECTS]: (state, { payload }) => ({
    ...state,
    subjects: payload,
    results: {},
    states: mapObject(payload, () => STATES.AWAITING_REQUEST),
  }),
  [ADD_SEARCH_RESULT]: addSearchResult,
  [START_SEARCH]: (
    state,
    { payload: { query, queryOptions, affectedSubjects } }
  ) => {
    const newState = {
      ...state,
      query,
      queryOptions: queryOptions || {},
    };

    return affectedSubjects.reduce(
      (memo, { id, state: thisState }) =>
        addSearchResult(memo, {
          payload: { id, state: thisState || STATES.AWAITING_RESULTS },
        }),
      newState
    );
  },
  [RESET_SEARCH]: (state) => ({
    ...state,
    query: null,
    queryOptions: {},
    results: Object.keys(state.results).reduce(
      (memo, key) => ({ ...memo, [key]: null }),
      {}
    ),
  }),
};

export default function reducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}

// Selectors
export const baseSelector = (state) => state.search;
const statesSelector = (state) => baseSelector(state).states;
const querySelector = (state) => baseSelector(state).query;
const resultsSelector = (state) => baseSelector(state).results;
export const subjectsSelector = (state) => baseSelector(state).subjects;

/**
 * When any subject is still crunching, the spinner should be displayed
 */
export const isLoadingSelector = createSelector(statesSelector, (states) =>
  Object.values(states).some((x) => x === STATES.AWAITING_RESULTS)
);

/**
 * Determines if all subjects have submit results and no of them have hits.
 */
export const noHitsSelector = createSelector(
  statesSelector,
  querySelector,
  (states, query) => {
    const allNoOps = Object.values(states).every(
      (x) => x === STATES.AWAITING_REQUEST || x === STATES.ERROR
    );
    const hasQuery = query && query !== '';
    return hasQuery && allNoOps;
  }
);

/**
 * Builds the structure the presentation components need to keep the JSX looking sensible... this complexity has to be somewhere
 */
export const hitsGroupedByTopicSelector = createSelector(
  subjectsSelector,
  resultsSelector,
  statesSelector,
  (subjects, results, states) => {
    /**
     * Sort results by visibleOrder, then id.
     * A lower "visibleOrder" value is sorted higher in the result set.
     * When visibleOrder values are equal, an "id" sort is used to ensure a deterministic order
     * @param a
     * @param b
     * @return {number}
     */
    const sortComparer = (a, b) => {
      const isNullOrUndefined = (x) =>
        x === null || x === undefined || x.visibleOrder === undefined;

      if (
        a.visibleOrder === b.visibleOrder ||
        (isNullOrUndefined(a) && isNullOrUndefined(b))
      ) {
        return a.id < b.id ? -1 : 1;
      }

      if (isNullOrUndefined(a)) {
        return 1;
      }

      if (isNullOrUndefined(b)) {
        return -1;
      }

      if (a.visibleOrder < b.visibleOrder) {
        return -1;
      }

      return 1;
    };

    const hitsBySubject = Object.entries(states)

      // Find subjects that have results
      .filter(([id, subjectState]) => subjectState === STATES.RECEIVED_RESULTS) // eslint-disable-line no-unused-vars
      .sort(([a], [b]) => b.localeCompare(a))

      // Package stuff needed to build array of hits
      .map(([id]) => ({
        subject: subjects[id],
        hits: results[id] && results[id].hits,
      }))

      // Exclude that have no real results
      .filter((x) => x.hits && x.hits.length)

      // Build a 3-dimensional array of hits
      .map(({ subject, hits }) => {
        const hitsIndexedByTopic = hits.reduce((index, hit) => {
          if (!hit) {
            return index;
          }

          const topic = subject.topics[hit.topic];
          if (!topic) {
            return index;
          }

          // eslint-disable-next-line no-param-reassign
          index[topic.id] = index[topic.id] || { subject, topic, hits: [] };
          index[topic.id].hits.push({
            topicId: topic.id,
            hitId: hit.id,
            name: hit.name,
            url: hit.url,
            label: hit.label,
            renderer: topic.hitRenderer || subject.hitRenderer || undefined,
          });

          return index;
        }, {});

        return Object.values(hitsIndexedByTopic).sort((a, b) =>
          sortComparer(a.topic, b.topic)
        );
      });

    // Flatten down to two dimensions, where there is an inner array per Topic
    return flatten(hitsBySubject);
  }
);
