export const left = 'left';
export const right = 'right';
export const top = 'top';
export const bottom = 'bottom';

// These values are in here instead of Beacon.js so they can be mocked in tests
export const beaconDiameterPx = 14;
export const pulseWidthPx = beaconDiameterPx * 1.1;

export const anchorOffsets = {
    center: [],
    left: [left],
    right: [right],
    top: [top],
    bottom: [bottom],
    'top-left': [top, left],
    'bottom-left': [bottom, left],
    'top-right': [top, right],
    'bottom-right': [bottom, right],
};

export const sideIndex = {
    [left]: 0,
    [top]: 1,
    [right]: 2,
    [bottom]: 3,
};
