import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import autobind from 'react-autobind';
import { withRouter } from 'react-router-dom';
import { compact } from 'underscore';
import { addMenu, removeMenu } from '@infosight/shell-api/lib/PrimaryNav';
import { onUserContextChange } from '@infosight/shell-api/lib/UserProfile';
import { buildUrl } from '@infosight/elmer/dist/utils/url';
import { AuthorizationEvaluator, AUTHORITIES } from '../../user';
import { isInternalUserFromSession } from '../../user/reducer';
import { experimentalSelector } from '../../bootstrapper/reducer';
import { experimentalModeAllowed } from '../../config';
import { microappData } from '../../router/knownRoutes';
import Experimental from '../../experimental';

class PrimaryNavExtension extends Component {
  constructor(props) {
    super(props);
    autobind(this);
  }

  componentDidMount() {
    this.handleUpdate();
    this.unsubscribe = onUserContextChange(this.handleUpdate);
  }

  componentDidUpdate(prevProps) {
    if (this.props.experimentalEnabled === prevProps.experimentalEnabled) {
      return;
    }
    this.handleUpdate();
  }

  componentWillUnmount() {
    if (this.unsubscribe) {
      this.unsubscribe();
    }
  }

  // eslint-disable-next-line class-methods-use-this
  getSettings({ match, experimentalEnabled }) {
    const options = AuthorizationEvaluator.filter(
      compact([
        {
          id: 'terms',
          title: 'Terms of Use',
          url: '/app/termsofuse/view',
          nodeType: 'external',
        },
        {
          id: 'userBulletins',
          title: 'Bulletins',
          url: '/activeBulletins',
        },
        experimentalModeAllowed
          ? {
              id: 'experimental',
              title: <Experimental />,
              nodeType: 'manual',
            }
          : null,
        {
          id: 'admin',
          title: 'Administration',
          options: AuthorizationEvaluator.filter([
            {
              id: 'bulletins',
              title: 'Bulletin Management',
              url: '/settings/admin/bulletins',
              access: {
                authorities: [AUTHORITIES.SITE_ADMIN],
              },
            },
            {
              id: 'authLogin',
              title: 'Developer Login Page',
              url: '/auth/login',
              nodeType: 'external',
              access: {
                sfrRoles: ['admin'],
              },
            },
            {
              id: 'assetDetails',
              title: 'HPE Storage Asset Details',
              url: buildUrl(match.url, '/infrastructure/general/assetDetails'),
              nodeType: 'internal',
              access: {
                sfrRoles: ['admin'],
              },
            },
            {
              id: 'columnDesc',
              title: 'HPE Storage Column Descriptions',
              url: buildUrl(match.url, '/infrastructure/general/columnDesc'),
              nodeType: 'internal',
              access: {
                sfrRoles: ['admin'],
              },
            },
            experimentalEnabled
              ? {
                  id: 'userRegistration',
                  title: 'Nimble User Registration',
                  url: buildUrl('registration/nimble'),
                  nodeType: 'internal',
                }
              : null,
          ]),
          access: {
            custom: (user) => isInternalUserFromSession(user.session),
          },
          nodeType: 'title',
          collapseHeader: false,
        },
        {
          id: 'nimbleUserRegistration',
          title: `${microappData.nimble.title} User Registration`,
          url: '/registration/nimble',
        },
      ])
    ).filter(
      ({ options: innerOptions }) => !innerOptions || innerOptions.length
    );

    return options.length ? options : null;
  }

  handleUpdate() {
    // TODO this stuff needs to be passed in from something whether its via an extension or passed down via props.
    const externals = {
      match: this.props.match,
      experimentalEnabled: this.props.experimentalEnabled,
    };

    // Everything is always here so we can react to changes
    [
      {
        menuId: 'settings',
        id: 'welcome',
        content: this.getSettings(externals),
      },
    ].forEach((extension) => {
      if (extension.content) {
        addMenu(extension);
      } else {
        removeMenu(extension);
      }
    });
  }

  render() {
    return null;
  }
}

PrimaryNavExtension.propTypes = {
  match: PropTypes.object.isRequired,
  experimentalEnabled: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  experimentalEnabled: experimentalSelector(state),
});

export default connect(mapStateToProps)(withRouter(PrimaryNavExtension));
