import { Component, lazy } from 'react';
import autobind from 'react-autobind';
import { addRouter } from '@infosight/shell-api/lib/Router';
import { MENUOPTIONS } from '../constants';

const SupportRouter = lazy(() => import('../components/SupportRouter'));

class RouterExtension extends Component {
  constructor(props) {
    super(props);
    autobind(this);
  }

  componentDidMount() {
    addRouter({
      url: MENUOPTIONS.SUPPORT.URL,
      router: SupportRouter,
      exact: false,
      appId: MENUOPTIONS.RESOURCES.ID,
    });
  }

  render() {
    return null;
  }
}

export default RouterExtension;
