import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames/bind';
import { Share } from 'grommet-icons';
import { AdaptiveNode } from '@infosight/elmer/dist/components/NavBar';
import { Box } from 'grommet/es6';
import NavMenuBox from './NavMenuBox';
import style from './NavMenu.scss';

const cx = classnames.bind(style);

const orgPrefixRe = /^\/org\/[^/]*\//;
const isActive = (url) => {
  const noOrgPathname = window.location.pathname.replace(orgPrefixRe, '/');
  return (
    window.location.pathname.startsWith(url) || noOrgPathname.startsWith(url)
  );
};

const NavMenuLink = ({
  id,
  url,
  title,
  onClickRoute,
  nodeType,
  qualifier,
  onNavigate,
  beacon,
  ...boxProps
}) => {
  let displayVal = title || id;
  let qualifierText =
    displayVal && qualifier && qualifier === 'dashboard' ? ' Dashboard' : '';
  if (title.includes('Appinsights')) {
    displayVal = displayVal.replace('Appinsights', '');
    qualifierText = '';
  }
  const linkText = `${displayVal}${qualifierText}`;

  return (
    <AdaptiveNode key={id} href={url} nodeType={nodeType} beacon={beacon}>
      <NavMenuBox
        {...boxProps}
        className={classnames(
          cx({
            element: true,
            active: isActive(url),
          }),
          boxProps.className
        )}
        onClick={() => {
          if (onClickRoute) {
            onClickRoute();
          }
          onNavigate();
        }}
      >
        {nodeType && nodeType === 'external' ? (
          <Box direction="row" align="center">
            <Box pad={{ right: 'small' }}>{linkText}</Box>
            <Share size="16px" color={style.textColor} />
          </Box>
        ) : (
          <span>{linkText}</span>
        )}
      </NavMenuBox>
    </AdaptiveNode>
  );
};

NavMenuLink.propTypes = {
  // Can overwrite any NavMenuBox props
  ...NavMenuBox.propTypes,

  id: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  title: PropTypes.string,
  nodeType: PropTypes.string,
  qualifier: PropTypes.string,
  onNavigate: PropTypes.func,
  beacon: PropTypes.object,
};

NavMenuBox.defaultProps = {
  title: '',
  nodeType: undefined,
  qualifier: undefined,
  onNavigate: () => {},
  beacon: undefined,
};

export default NavMenuLink;
