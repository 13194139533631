import { actionNamespacer } from '@infosight/elmer/dist/utils/redux';

export const MENUOPTIONS = {
  INFOSIGHT: {
    ID: 'infosight',
    TITLE: 'HPE InfoSight',
  },
  GETTING_STARTED: {
    ID: 'getting_started',
    TITLE: 'HPE InfoSight Getting Started Guide',
    URL: 'http://www.hpe.com/support/InfoSight-GSG-en',
  },
  USER_GUIDE: {
    ID: 'user_guide',
    TITLE: 'HPE InfoSight User Guide',
    URL: 'http://www.hpe.com/support/InfoSight-UG-en',
  },
  SUPPORT: {
    ID: 'support',
    TITLE: 'InfoSight Support',
    URL: '/resources/support',
  },
  YAMMER: {
    ID: 'support',
    TITLE: 'Yammer',
    URL:
      'https://www.yammer.com/hpe.com/#/threads/inGroup?type=in_group&feedId=13772137',
  },
  STORAGE_SECURITY: {
    ID: 'storage_security',
    TITLE: 'HPE InfoSight for Storage Security',
    URL: 'https://assets.ext.hpe.com/is/content/hpedam/a00067516enw',
  },
  SERVERS_SECURITY: {
    ID: 'servers_security',
    TITLE: 'HPE InfoSight Servers Security',
    URL:
      'https://support.hpe.com/hpesc/public/docDisplay?docLocale=en_US&docId=emr_na-a00105907en_us',
  },
  INFOSIGHT_SERVICE_STATUS: {
    ID: 'infosight_service_status',
    TITLE: 'InfoSight Service Status',
    URL: 'https://status.infosight.hpe.com',
  },
  RESOURCES: {
    ID: 'resources',
    URL: '/resources',
  },
};

export const SUPPORTPAGE = {
  HEADING: 'HPE InfoSight Support',
};

const ns = actionNamespacer('RESOURCES');

export const LIVEAGENT_ONLINE = ns('LIVEAGENT_ONLINE');
