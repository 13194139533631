import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import BEM from 'src/utils/bem';

const bem = new BEM('elmer-GlobalBanner');

const Navigate = ({ direction, onClick }) => (
    <button className={bem.e('navigate').valueOf()} onClick={onClick}>
        <i className={classnames('fa', `fa-angle-${direction}`)} />
    </button>
);

Navigate.propTypes = {
    direction: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
};

export default Navigate;
