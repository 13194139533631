import React, { Component } from 'react';
import PropTypes from 'prop-types';
import autobind from 'react-autobind';
import { noop } from 'underscore';
import getDisplayName from 'recompose/getDisplayName';
import wrapDisplayName from 'recompose/wrapDisplayName';
import { withQueryParams, buildTargetLocation } from 'src/components/Router';
import {
    buildFiltersFromQueryParameters,
    buildSortFromQueryParameters,
    toFilterQueryParams,
    toSortQueryParams,
} from '../util/util';

/**
 * Types of possible filter actions
 */
export const FILTER_ACTION = {
    ADD: 'ADD',
    UPDATE: 'UPDATE',
    REMOVE: 'REMOVE',
    REMOVE_ALL: 'REMOVE_ALL',
};

export const TableContext = React.createContext({
    handleSortChange: noop,
    sortedColumns: [],
    handleFilterChange: noop,
    filteredColumns: [],
});

/**
 * HOC which adds Sorting, Filtering, Pagination & URL Sync capabilities to the Table Component Passed
 * 
 * Ideally a component should be either Controlled or UnControlled Component, but due to the workflows that
 * have to be supported by {Table}, there is no Single Source of Truth as the Filters/Sort criteria can be changed 
 * both externally (through props) and internally (through embedded filters in the Column Header), hence this will be a hybrid component
 * 
 * @param {ReactNode} TableComponent
 */
const withTableState = (TableComponent) => {
    class TableContainer extends Component {
        constructor(props) {
            super(props);
            autobind(this);
            
            const { match: { params }, page, pageSize, dataOffset, defaultSorted, defaultFiltered, syncUrl } = props;
            
            // when url sync is enabled, URL takes precedence over defaultSorted/defaultFiltered 
            const sortedColumns = (syncUrl && buildSortFromQueryParameters(params)) || defaultSorted;
            const filteredColumns = (syncUrl && buildFiltersFromQueryParameters(params)) || defaultFiltered;
            
            this.state = {
                sorted: sortedColumns,
                filtered: filteredColumns,
                dataOffset,
                page,
                pageSize,
                // These are added to the state, so that they can be used for prop comparision in getDerivedStateFromProps method
                prevFilteredProp: props.filtered, // eslint-disable-line react/no-unused-state
                prevSortedProp: props.sorted, // eslint-disable-line react/no-unused-state
            };
        }

        static getDerivedStateFromProps(props, state) {
            /*
             * In most situations, the state of the Table is updated only through column headers (both Filter & Sort criteria)
             * But in certain cases, Table has to accept Filter or Sort criteria as a prop through the Parent.
             * In such cases we have to use the props instead of the internal state (props takes precedence over internal state)
             * 
             * In the React tree any prop change of the parent can cause this function to be executed, so to avoid
             * unnecessary re-renders check if the props (filter & sort in this case) have changed instead of blindly updating state
             * 
             * getDerivedStateFromProps is an advanced feature and also not recommended. As this component
             * has to behave as both Controlled and Uncontrolled resorted to this approach. Use caution while updating this.
             */
            if (props.filtered !== state.prevFilteredProp || props.sorted !== state.prevSortedProp) {
                const nextState = {
                    prevFilteredProp: props.filtered,
                    prevSortedProp: props.sorted,
                };

                if (props.filtered !== state.prevFilteredProp) {
                    nextState.filtered = props.filtered;
                }
                
                if (props.sorted !== state.prevSortedProp) {
                    nextState.sorted = props.sorted;
                }
                
                return nextState;
            }
            return null;
        }

        componentDidUpdate() {
            const { syncUrl } = this.props;
            const { filtered, sorted } = this.state;
            
            if (syncUrl) {
                if (sorted) {
                    this.updateUrl({ parameterName: 'sort', parameters: toSortQueryParams(sorted) });
                }

                if (filtered) {
                    this.updateUrl({ parameterName: 'filter', parameters: toFilterQueryParams(filtered) });
                }
            }
        }


        onPageChange({ dataOffset }) {
            this.setState(({ dataOffset: prevDataOffset, page }) => ({
                dataOffset, page: dataOffset < prevDataOffset ? page - 1 : page + 1,
            }));
        }

        updateUrl({ parameterName, parameters }) {
            const { location } = this.props;
            const to = buildTargetLocation({
                location,
                to: { search: { [parameterName]: parameters } },
                replaceQuery: false,
                coerceTypes: false,
            });

            if (to.search !== location.search) {
                this.props.history.replace(to);
            }
        }

        handleSortChange({ id, desc }) {
            const newSortedColumns = desc !== undefined ? [{ id, desc }] : [];
            this.setState(() => ({ sorted: newSortedColumns }));
        }

        handleFilterChange({ filter = {}, filterAction }) {
            const { filtered } = this.state;
            let newFilteredColumns;

            switch (filterAction) {
            case FILTER_ACTION.REMOVE:
                newFilteredColumns = filtered.filter(column => column.id !== filter.id);
                break;

            case FILTER_ACTION.REMOVE_ALL: newFilteredColumns = [];
                break;

            // Add or Update
            default:
            {
                // Remove old filter and add new one
                newFilteredColumns = filtered.filter(column => column.id !== filter.id);
                newFilteredColumns.push(filter);
            }
            }
            
            this.setState(() => ({ filtered: newFilteredColumns, page: 0, dataOffset: 0 }));
        }
        
        render() {
            const { filtered, sorted, dataOffset, page, pageSize } = this.state;
            // Move all context values to state to avoid consumer re render
            const contextValues = {
                handleFilterChange: this.handleFilterChange,
                filteredColumns: filtered,
                handleSortChange: this.handleSortChange,
                sortedColumns: sorted,
            };
            return (
                <TableContext.Provider value={contextValues}>
                    <TableComponent
                        {...this.props}
                        filtered={filtered}
                        sorted={sorted}
                        dataOffset={dataOffset}
                        page={page}
                        pageSize={pageSize}
                        onPageChange={this.onPageChange}
                    />
                </TableContext.Provider>
            );
        }
    }

    TableContainer.displayName = wrapDisplayName(
        TableComponent,
        getDisplayName(TableContainer)
    );

    TableContainer.propTypes = {
        /**
         * Unique Id for the Table.
         * Ex Use Case: It will be used to persist resized column widths of the table.
         */
        tableId: PropTypes.string,

        /**
         * Updates URL when a sort or filter is changed.
         * Defaults to true
         */
        syncUrl: PropTypes.bool,

        dataOffset: PropTypes.number,

        /** Number of rows in a page */
        pageSize: PropTypes.number,

        /**
         * @ignore
         * Current Page of the table.
         * Most likely any table would start with first page, so defaults to 0
         * */
        page: PropTypes.number,

        /**
         * Default column to be sorted and its sort order
         */
        defaultSorted: PropTypes.arrayOf(PropTypes.shape({
            /**
             * Id of the column to be sorted
             */
            id: PropTypes.string.isRequired,

            /**
             * Is this column sorted in Descending order
             */
            desc: PropTypes.bool,
        })),

        /**
         * Columns to be sorted and its sort order
         */
        sorted: PropTypes.arrayOf(PropTypes.shape({
            /**
             * Id of the column to be sorted
             */
            id: PropTypes.string.isRequired,

            /**
             * Is this column sorted in Descending order
             */
            desc: PropTypes.bool,
        })),

        /**
         * Default column to be filtered and the filter value
         */
        defaultFiltered: PropTypes.arrayOf(PropTypes.shape({
            /**
             * Id of the column to be filtered
             */
            id: PropTypes.string.isRequired,

            /**
             * Title of the column
             * Often times the underlying data field ie., accessor/id of the Column and
             * the actual visible header of the Column are different. So use this to get
             * proper Filter Tag Name
             */
            title: PropTypes.string,
            
            /**
             * Value of the filter
             */
            value: PropTypes.string,
        })),

        /**
         * Columns to be filtered and the filter value
         */
        filtered: PropTypes.arrayOf(PropTypes.shape({
            /**
             * Id of the column to be filtered
             */
            id: PropTypes.string.isRequired,

            /**
             * Title of the column
             * Often times the underlying data field ie., accessor/id of the Column and
             * the actual visible header of the Column are different. So use this to get
             * proper Filter Tag Name
             */
            title: PropTypes.string,

            /**
             * Value of the filter
             */
            value: PropTypes.string,
        })),
        
        /**
         * @ignore
         * Injected by withQueryParams
         */
        match: PropTypes.shape({
            params: PropTypes.object,
        }),

        /**
         * @ignore
         * Injected by withQueryParams
         */
        location: PropTypes.shape({
            search: PropTypes.string,
        }),

        /**
         * @ignore
         * Injected by withQueryParams
         */
        history: PropTypes.shape({
            replace: PropTypes.func.isRequired,
        }),
    };

    TableContainer.defaultProps = {
        syncUrl: true,
        dataOffset: 0,
        pageSize: 50,
        page: 0,
        defaultSorted: [],
        defaultFiltered: [],
    };

    return withQueryParams(TableContainer);
};

export default withTableState;
