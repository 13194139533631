import { actionNamespacer } from '@infosight/elmer/dist/utils/redux';

const ns = actionNamespacer('USER');
export const URL_UPDATE = ns('URL_UPDATE');
export const TITLE_UPDATE = ns('TITLE_UPDATE');
// How many recent pages we keep stored at a time
export const URL_LOOKBACK = 40;

// remove this once it is no longer used in reducer and googleAnalyticsProvider
export const INTERNAL_ROLES = ['admin', 'support', 'engineering', 'sales'];
export const INTERNAL_REALM = 'INTERNAL';
export const SITE_ADMIN_ROLE = 'admin';

export const AUTHORITIES = {
  // Based on com.nimblestorage.infosight.auth.UserRealm
  REALM_INTERNAL: 'Realm.Internal',
  REALM_INTERNAL_NIMBLE: 'Realm.Internal.Nimble',
  REALM_INTERNAL_HPE: 'Realm.Internal.HPE',
  REALM_EXTERNAL: 'Realm.External',

  // Based on com.nimblestorage.infosight.auth.ApplicationAuthority
  SITE_ACCESS: 'Site.Access',
  SITE_ADMIN: 'Site.Admin',
  SITE_SIGNED_NDA: 'Site.SignedNda',
  DOCUMENTATION_KB_ARTICLE_READ: 'Documentation.KbArticle.Read',
  VIRTUAL_ARRAY_ACCESS: 'VirtualArray.Access',
  VIRTUAL_ARRAY_ADMIN: 'VirtualArray.Admin',
  STORESERV_SYSTEM_GLOBAL: 'StoreServ.System._global',
  ADDRESS_ADMIN: 'Address.Admin',

  // Based on com.nimblestorage.infosight.auth.ProductFamily
  PRODUCT_FAMILY_NIMBLE: 'ProductFamily.Nimble',
  PRODUCT_FAMILY_STORESERV: 'ProductFamily.StoreServ',
  PRODUCT_FAMILY_STOREONCE: 'ProductFamily.StoreOnce',

  // Synthesized based on data in initial user object loaded from /api/bootstrap
  TENANT_ACCESS_MULTIPLE: 'Tenants.Multiple.Access',

  // Synthesized
  ATTRIBUTE_NIMBLE_RESELLER: 'nimble.isResellerEquivalent',
  ATTRIBUTE_NIMBLE_SUPPORT_PROVIDER: 'nimble.isSupportProvider',

  SUPPORT_CASE: 'Support.Case',
};

// Based on com.nimblestorage.infosight.auth.permissions.Privilege
export const PRIVILEGES = {
  FEATURE_CORE_ACCESS: 'Feature.Core.Access',
  FEATURE_VMVISION_ACCESS: 'Feature.VMVision.Access',
  FEATURE_SUPPORT_CASE_MANAGE: 'Feature.SupportCase.Manage',
  ACCESS: 'Access',
  ADMIN: 'Admin',
};

// Based on com.nimblestorage.infosight.auth.permissions.PermissionHandler
export const PERMISSION_TARGET = {
  NIMBLE_TENANT: 'Nimble.Customer',
  GLOBAL_TARGET_ID: '_global',
  ACTIVE_TARGET_ID: '_active',
};

export const nimbleTenantAdminAccess = {
  permissions: [
    {
      privilege: PRIVILEGES.ADMIN,
      targetType: PERMISSION_TARGET.NIMBLE_TENANT,
      targetId: PERMISSION_TARGET.ACTIVE_TARGET_ID,
    },
  ],
};
