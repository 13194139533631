import { REMOVE } from './reducer';

/**
 *
 * @param opts
 * @param {string} opts.appId
 * @param {string} opts.id
 * @return {function(*): *}
 */
// eslint-disable-next-line import/prefer-default-export
export const removeBanner = (opts) => (dispatch) =>
  dispatch({ type: REMOVE, payload: opts }); // eslint-disable-line import/prefer-default-export
