import React from 'react';
import PropTypes from 'prop-types';
import { isFunction } from 'underscore';
import classnames from 'classnames';
import BEM from 'src/utils/bem';

const bem = new BEM('elmer-Table-Actions');

function Actions({ children }) {
    const childNodes = isFunction(children) ? children() : children;
    return childNodes ? <div className={classnames(bem.valueOf())}>{childNodes}</div> : null;
}

Actions.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.element,
    ]),
};

export default Actions;
