import { useEffect } from 'react';
import { trackEvent } from '@infosight/shell-api/lib/Analytics';
import { useSelector } from 'react-redux';
import { iamSessionSelector } from '../iam/reducer';
import { rawInventorySelector } from './reducer';

const InventoryReporter = () => {
  const session = useSelector(iamSessionSelector);
  // dont use adjustedInventory, dont want false positives being added
  // this is susceptible to microapp errors but in aggragate should be ok
  const { rawInventory: inventory } = useSelector(rawInventorySelector);
  useEffect(() => {
    if (session && inventory) {
      const { userName, userId, tenantId, tenantName } = session;
      const presentMicroapps = Object.keys(inventory).filter(
        (appId) => inventory[appId].presence
      );
      const domain = userName && userName.split('@')[1];
      trackEvent({
        event: 'general.inventory',
        attributes: {
          userName,
          userId,
          domain,
          tenantId,
          tenantName,
          microapps: presentMicroapps,
        },
      });
    }
  }, [session, inventory]);

  return null;
};

export default InventoryReporter;
