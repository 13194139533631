import { createContext } from 'react';
import { createStore } from 'redux';
import { devToolsEnhancer } from 'redux-devtools-extension';
import { connect as rrConnect } from 'react-redux';
import reducer from './reducer';
import pkg from '../../package.json';

const NAME = `${pkg.name}-infrastructure@${pkg.version}`;

export function createLocalStore() {
    return createStore(reducer, {}, devToolsEnhancer({ name: NAME }));
}

export function initializeStore(existingStore) {
    return existingStore || createLocalStore();
}

/**
 * Create a custom context and connect fn so this store is transparent to consumers.
 * https://react-redux.js.org/using-react-redux/accessing-store#understanding-context-usage
 * 
 * Older versions of Elmer used a "store key" to distinguish this store from other stores,
 * but this has since been deprecated and deleted from react-redux so it now uses a custom context.
 * 
 * Since the Provider being used by each instance of elmer is different, old versions can
 * continue using store key and new versions will use context going forward.
 */
export function initializeContext() {
    return createContext(null);
}

export function getStore() {
    return window.elmerInfrastructure && window.elmerInfrastructure.store;
}

export function getContext() {
    return window.elmerInfrastructure && window.elmerInfrastructure.context;
}

export function connect(mapStateToProps, mapDispatchToProps, mergeProps, options = {}) {
    return rrConnect(mapStateToProps, mapDispatchToProps, mergeProps, { ...options, context: getContext() });
}
