import React from 'react';
import PropTypes from 'prop-types';
import { IconAdaptor } from '@infosight/elmer/dist/components/IconAdaptor';
import { Search as GrommetSearch } from 'grommet-icons';
import BannerErrorBoundary from '../../util/BannerErrorBoundary';
import Search from '../../search/components/Search';
import Item from './Item';

const SearchItem = ({ options }) => (
  <Item
    id="search"
    menuOptions={(options && options.enabled) || false}
    title={() => (
      <div
        title="Search"
        style={{
          height: '24px',
        }}
      >
        <IconAdaptor size="small" type="grommet">
          <GrommetSearch color="currentColor" />
        </IconAdaptor>
      </div>
    )}
    renderContent={(props) => (
      <BannerErrorBoundary
        bannerId="searchErrorBanner"
        content="Global search had a catastrophic failure. Please try again later."
      >
        <Search {...props} />
      </BannerErrorBoundary>
    )}
  />
);

SearchItem.propTypes = {
  options: PropTypes.shape({
    enabled: PropTypes.bool,
  }),
};

export default SearchItem;
