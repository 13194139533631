const cacheWrapper = (func) => {
  const keyCache = {};
  return (key) => {
    let value;
    if (keyCache[key]) {
      value = keyCache[key];
    } else {
      value = func(key);
      keyCache[key] = value;
    }
    return value;
  };
};

export default cacheWrapper;
