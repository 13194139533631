import React from 'react';
import { useSelector } from 'react-redux';
import { Announce } from 'grommet-icons';
import classnames from 'classnames/bind';
import { Link } from 'react-router-dom';
import { IconAdaptor } from '@infosight/elmer/dist/components/IconAdaptor';
import IconStack from './IconStack';
import { activeWhatsNewSelector } from '../../bulletins/reducer';
import style from './Item.scss';

const cx = classnames.bind(style);

const WhatsNewItem = () => {
  const { activeWhatsNew } = useSelector(activeWhatsNewSelector);

  return (
    <div className="flex-container align-stretch">
      <Link
        title="What's New"
        to="/whatsNew"
        className={classnames(
          'flex-container align-center flex-dir-column',
          cx({ trigger: true })
        )}
      >
        <IconStack
          color="ok"
          value={activeWhatsNew ? activeWhatsNew.length : 0}
        >
          <IconAdaptor size="small" type="grommet">
            <Announce color="currentColor" />
          </IconAdaptor>
        </IconStack>
      </Link>
    </div>
  );
};

export default WhatsNewItem;
