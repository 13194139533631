import React from 'react';
import PropTypes from 'prop-types';
import SortAscIcon from 'react-icons/lib/fa/sort-asc';
import SortDescIcon from 'react-icons/lib/fa/sort-desc';
import SortIcon from 'react-icons/lib/fa/sort';
import classnames from 'classnames';
import BEM from '../../../utils/bem';
import IconAdaptor from '../../IconAdaptor/IconAdaptor';


const bem = new BEM('elmer-Table-ColumnHeader');

const SortArrowIcon = ({ className, desc }) => {
    let sortIcon;
    switch (desc) {
    case false: sortIcon = <SortAscIcon />;
        break;

    case true: sortIcon = <SortDescIcon />;
        break;

    default: sortIcon = <SortIcon />;
    }
    return (
        <div
            className={classnames(bem.e('sort-icon'), className)}
            role="button"
            tabIndex={0}
        >
            <IconAdaptor size="xsmall" type="svg">
                {sortIcon}
            </IconAdaptor>
        </div>);
};

SortArrowIcon.propTypes = {
    className: PropTypes.string,
    // is it sorted in Descending Order
    desc: PropTypes.oneOf([undefined, true, false]),
};

SortArrowIcon.defaultProps = {
    desc: undefined,
};

export default SortArrowIcon;
