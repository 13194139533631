import { Component } from 'react';
import PropTypes from 'prop-types';
import { addBanner } from '@infosight/shell-api/lib/Banner';

class BannerErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch() {
    addBanner({
      id: this.props.bannerId,
      appId: this.props.appId, // this is the appId of the banner creator (AKA "core") not the app that threw the error
      severity: this.props.severity,
      content: this.props.content,
    });
  }

  render() {
    const { hasError } = this.state;
    return hasError ? null : this.props.children;
  }
}

BannerErrorBoundary.propTypes = {
  bannerId: PropTypes.string.isRequired,
  appId: PropTypes.string,
  severity: PropTypes.string,
  content: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

BannerErrorBoundary.defaultProps = {
  appId: 'core',
  severity: 'error',
};

export default BannerErrorBoundary;
