import { buildUrl } from '@infosight/elmer/dist/utils/url';
import { withQueryParams } from '@infosight/elmer/dist/components/Router';
import { APP_BASE_URL } from '../config';

function LegacyHashRouteAdapter(props) {
  if (props.location.hash) {
    if (props.location.hash.indexOf('#documentation') > -1) {
      const url = buildUrl(APP_BASE_URL, '/resources/nimble/docs');

      let queryString = '';
      if (props.location.hash.indexOf('?') > -1) {
        // if it's a hash url, the query string will be considered part of it :/
        queryString = props.location.hash.substr(
          props.location.hash.indexOf('?')
        );
      }
      window.location.href = `${url}${queryString}`;
    }
  }

  return null;
}

export default withQueryParams(LegacyHashRouteAdapter);
