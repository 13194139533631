import {
  createReducersForAction,
  createSelector,
  getInitialState,
} from '@infosight/elmer/dist/utils/redux';

import {
  FETCH_BULLETINS,
  FETCH_ACTIVE_BULLETINS,
  RESET,
  DISMISS_BULLETIN,
  DISMISS_ALL_BULLETINS,
  FETCH_WHATS_NEW,
  FETCH_ACTIVE_WHATS_NEW,
  DISMISS_ALL_WHATS_NEW,
} from './constants';

const initialState = {
  ...getInitialState('bulletins'),
  ...getInitialState('activeBulletins'),
  ...getInitialState('whatsNew'),
  ...getInitialState('activeWhatsNew'),
};

const ACTION_HANDLERS = {
  [RESET]: (state) => ({ ...state, ...getInitialState('bulletins') }),
  [DISMISS_ALL_BULLETINS]: (state) => ({ ...state, activeBulletins: [] }),
  [DISMISS_BULLETIN]: (state, { payload: id }) => {
    if (state.activeBulletins) {
      const activeBulletins = state.activeBulletins.filter((e) => e.id !== id);
      return { ...state, activeBulletins };
    }
    return state;
  },
  ...createReducersForAction({ type: FETCH_BULLETINS, stateKey: 'bulletins' }),
  ...createReducersForAction({
    type: FETCH_ACTIVE_BULLETINS,
    stateKey: 'activeBulletins',
  }),
  [DISMISS_ALL_WHATS_NEW]: (state) => ({ ...state, activeWhatsNew: [] }),
  ...createReducersForAction({ type: FETCH_WHATS_NEW, stateKey: 'whatsNew' }),
  ...createReducersForAction({
    type: FETCH_ACTIVE_WHATS_NEW,
    stateKey: 'activeWhatsNew',
  }),
};

export default function reducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}

const baseSelector = (state) => state.bulletins;
export const bulletinsSelector = createSelector(baseSelector, 'bulletins');
export const activeBulletinsSelector = createSelector(
  baseSelector,
  'activeBulletins'
);
export const activeBulletinsCountSelector = (state) =>
  baseSelector(state).activeBulletins
    ? baseSelector(state).activeBulletins.length
    : 0;

export const whatsNewSelector = createSelector(baseSelector, 'whatsNew');
export const activeWhatsNewSelector = createSelector(
  baseSelector,
  'activeWhatsNew'
);
