import React, { Component } from 'react';
import PropTypes from 'prop-types';
import autobind from 'react-autobind';
import classnames from 'classnames';
import AngleRightIcon from 'react-icons/lib/fa/angle-right';
import AngleLeftIcon from 'react-icons/lib/fa/angle-left';
import { IconAdaptor } from '../IconAdaptor';

const DEFAULT_NUMBER_PER_PAGE = 50;

/**
 * Provides pagination for tables, but no reason it can't be used elsewhere.
 * The current implementation is based on UX's
 * [table pagination spec](https://confluence.lab.nimblestorage.com/display/UXPatternLibrary/Tables).
 */
class Pagination extends Component {
    constructor(props) {
        super(props);
        autobind(this);
    }

    onPage(event) {
        event.preventDefault();
        const { currentTarget } = event;
        const { dataOffset } = this.props;

        const disabled = currentTarget.hasAttribute('data-disabled')
            && currentTarget.getAttribute('data-disabled') !== 'false';
        if (disabled) {
            return;
        }

        let href = currentTarget.getAttribute('href');
        if (!href) {
            return;
        }

        href = href.slice(1);

        let page = this.getPageAt0BasedIndex(dataOffset);
        switch (href) {
        case 'next':
            // Don't add one because calculated.end is 1-based.
            page = this.getPageAt0BasedIndex(page.end);
            break;
        case 'previous':
            // subtract one to get to the previous page,
            // subtract another one to revert to 0-based index
            page = this.getPageAt0BasedIndex(page.start - 2);
            break;
        default:
            break;
        }

        if (typeof this.props.onPageSelect === 'function') {
            this.props.onPageSelect({ dataOffset: page.start - 1 });
        }
    }

    getPageAt0BasedIndex(dataOffset) {
        const { itemCount } = this.props;
        const numberPerPage = this.props.numberPerPage || DEFAULT_NUMBER_PER_PAGE;
        const pages = Math.ceil(itemCount / numberPerPage);
        let page = Math.floor(dataOffset / numberPerPage);

        if (page >= pages) {
            page = pages - 1;
        }

        if (page < 0) {
            page = 0;
        }

        const start = Math.min((numberPerPage * page) + 1, itemCount);
        const end = Math.min((start + numberPerPage) - 1, itemCount);
        page += 1;

        return { start, end, page };
    }

    render() {
        const { className, itemCount } = this.props;
        const { start, end, page } = this.getPageAt0BasedIndex(this.props.dataOffset);

        return (
            <div className={classnames('elmer-Pagination', className)}>
                <span className="elmer-Pagination__range">
                    <strong>{start} - {end}</strong>
                    <span className="elmer-Pagination__count"> of {itemCount}</span>
                </span>
                <ul className="pagination use-foundation" role="navigation" aria-label="Pagination">
                    <li className="pagination-previous">
                        <a
                            className={classnames({ disabled: page === 1 })}
                            href="#previous"
                            onClick={this.onPage}
                            aria-label="Previous page"
                            data-disabled={page === 1}
                        >
                            <IconAdaptor size={15} type="svg"><AngleLeftIcon /></IconAdaptor>
                        </a>
                    </li>
                    <li className="pagination-next">
                        <a
                            className={classnames({ disabled: end === itemCount })}
                            href="#next"
                            onClick={this.onPage}
                            aria-label="Next page"
                            data-disabled={end === itemCount}
                        >
                            <IconAdaptor size={15} type="svg"><AngleRightIcon /></IconAdaptor>
                        </a>
                    </li>
                </ul>
            </div>
        );
    }
}

Pagination.propTypes = {
    /**
     * callback that takes a hash with the new dataOffset
     */
    onPageSelect: PropTypes.func,

    /**
     * 0-based index of current element
     */
    dataOffset: PropTypes.number,
    numberPerPage: PropTypes.number,
    itemCount: PropTypes.number.isRequired,
};

Pagination.defaultProps = {
    dataOffset: 0,
    numberPerPage: DEFAULT_NUMBER_PER_PAGE,
};

export default Pagination;
