import React, { Component } from 'react';
import PropTypes from 'prop-types';
import autobind from 'react-autobind';
import { Text, Box, TextInput } from 'grommet';
import { FILTER_ACTION } from '../../hoc/withTableState';
import { Button } from '../../../Button';

const ENTER_KEY_CODE = 13;
const ESC_KEY_CODE = 27;

const isEmpty = value => (value === null || value === undefined || value === '');

export class TextInputFilter extends Component {
    constructor(props) {
        super(props);
        autobind(this);
    }

    componentDidUpdate() {
        const { filter: { value } } = this.props;
        if (this.input) {
            this.input.focus();
            this.input.value = isEmpty(value) ? '' : value;
        }
    }

    handleChange(event) {
        const { togglePopup } = this.props;
        switch (event.keyCode) {
        case ENTER_KEY_CODE:
            this.updateFilter(event.target.value);
            break;

        case ESC_KEY_CODE:
            if (togglePopup) {
                togglePopup();
            }
            break;
        default:
        }
    }

    updateFilter(value) {
        const { onChange, filter, togglePopup } = this.props;

        // If the updated filter value is null/undefined or empty then remove the filter
        if (isEmpty(value)) {
            onChange({ filter, filterAction: FILTER_ACTION.REMOVE });
        } else if (String(filter.value).valueOf() != String(value).valueOf()) { // eslint-disable-line eqeqeq
            // Update State with the updated filter value
            onChange({ filter: { ...filter, value } });
        }

        // TODO: debate:  With the new redesigned Header, we can now keep the Filter Popup open like in old days
        if (typeof togglePopup === 'function') {
            togglePopup();
        }
    }

    render() {
        const { filter: { title, value } } = this.props;

        return (
            <Box direction="column">
                <Text size="small">{title}</Text>
                <Box margin={{ top: 'xsmall' }}>
                    <TextInput
                        id={this.domId}
                        defaultValue={value}
                        onKeyDown={this.handleChange}
                        ref={(input) => { this.input = input; }}
                        size="small"
                        focusIndicator={false}
                    />
                </Box>
                <Box align="end" margin={{ top: 'xsmall' }}>
                    <Button
                        label="Apply"
                        onClick={() => this.updateFilter(this.input && this.input.value)}
                    />
                </Box>
            </Box>
        );
    }
}

TextInputFilter.propTypes = {
    filter: PropTypes.shape({
        id: PropTypes.string,
        title: PropTypes.string,
        value: PropTypes.string,
    }),

    /**
     * Function to handle the change in Filter Value.
     * It updates the filter value in table state
     */
    onChange: PropTypes.func.isRequired,

    /**
     * Expected to be passed from Parent to close the Popup when user hits `Esc` key
     */
    togglePopup: PropTypes.func.isRequired,
};

export default TextInputFilter;
