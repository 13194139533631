import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import BEM from '../../utils/bem';
import { Foldable } from '../../components/Foldable';

const bem = new BEM('elmer-infrastructure-ObjectName');

/**
 * Default object name renderer
 */
const ObjectName = ({ className, icon, name, inline, title }) => (
    <span className={classnames(className, bem.valueOf(), inline ? 'inline-flex-container' : 'flex-container', ' align-middle')}>
        {icon && <Foldable tag="span" className={bem.e('icon')}>{icon}</Foldable>}
        <span className="ellipsis-on-overflow" title={title || name}>{name}</span>
    </span>
);

ObjectName.propTypes = {
    name: PropTypes.node.isRequired,
    icon: PropTypes.node,
    inline: PropTypes.bool,
    title: PropTypes.string,
};

export default ObjectName;
