import { isInternalUserFromSession, userWithIAMSelector } from '../../reducer';
import { analyticsEnabled, env } from '../../../config';
import accessLog from './accesLogProvider';
import googleAnalytics from './googleAnalyticsProvider';
import newRelicBrowser from './newRelicBrowserProvider';
import { isKnownMicroappId } from '../../../router/knownRoutes';

let enabled = false;
let providers;

const isValidEvent = (event) => {
  if (!event) {
    return false;
  }
  const [microappPrefix, eventName] = event.split('.');
  return isKnownMicroappId(microappPrefix) && !!eventName;
};

const Analytics = {
  init(store) {
    const state = store.getState();

    if (analyticsEnabled) {
      const user = userWithIAMSelector(state);
      const sessionSettings = {
        username: user.username,
        realm: isInternalUserFromSession(user.session)
          ? 'internal'
          : 'external',
        environment: env,
      };

      providers = [accessLog, googleAnalytics, newRelicBrowser].reduce(
        (memo, provider) => {
          if (provider.init(sessionSettings)) {
            memo.push(provider);
          }

          return memo;
        },
        []
      );

      enabled = !!providers.length;
    }
  },

  trackEvent(event, opts) {
    if (enabled) {
      providers.forEach((provider) => {
        try {
          if (isValidEvent(event)) {
            provider.trackEvent(event, opts);
          } else {
            console.error(`Invalid event "${event}" cannot be tracked.`);
          }
        } catch (ex) {
          // Don't care
        }
      });
    }
  },

  trackPageView({ path, name, ...opts }) {
    if (enabled) {
      providers.forEach((provider) => {
        try {
          provider.trackPageView({ path, name, opts });
        } catch (ex) {
          // Don't care
        }
      });
    }
  },
};

export default Analytics;
