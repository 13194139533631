import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { getUserProfileApi } from '@infosight/shell-api/lib/UserProfile';
import ErrorMessage from './ErrorMessage';

class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false, allowDetails: true };
    }

    async componentDidMount() {
        // TODO This is terrible
        const { AuthorizationEvaluator, AUTHORITIES } = await getUserProfileApi();
        if (AuthorizationEvaluator) {
            const isExternal = AuthorizationEvaluator.evaluate({ access: { authorities: [AUTHORITIES.REALM_EXTERNAL] } });
            if (isExternal) {
                this.setState({ allowDetails: false }); // eslint-disable-line react/no-did-mount-set-state
            }
        }
    }

    // TODO send the error off to something like NewRelic or Bugsnag. Or even GA or kibana.
    componentDidCatch(error, info) {
        this.setState({ error, info, hasError: true });
    }
    
    render() {
        const { hasError, error, info, allowDetails } = this.state;
        return hasError ? <ErrorMessage error={error} info={info} allowDetails={allowDetails} /> : this.props.children;
    }
}

ErrorBoundary.propTypes = {
    children: PropTypes.node.isRequired,
};

export default ErrorBoundary;
