import { ExtensionPoint } from '@infosight/shell-api/lib/core';
import { Analytics } from '../../user';

function trackEvent({ event, attributes }) {
  Analytics.trackEvent(event, attributes);
}

function trackPage({ path, pageName, attributes }) {
  Analytics.trackPageView({ path, name: pageName, ...(attributes || {}) });
}

export const id = ExtensionPoint.Analytics;
export const commands = { trackEvent, trackPage };
