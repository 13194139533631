import React, { Component } from 'react';
import autobind from 'react-autobind';
import PropTypes from 'prop-types';
import ReactTable from 'react-table';
import classnames from 'classnames/bind';
import Async from '../Async';
import { TableViewTabs } from '../Tabs';
import { Button } from '../Button';
import Pagination from '../Pagination';
import FilterTags from './filters/FilterTags';
import { transformColumnConfig } from './util/ConfigTransformer';
import { sortMethod, filterMethod } from './util/util';
import BEM from '../../utils/bem';
import { Actions } from './actions';

const bem = new BEM('elmer-Table');

/*
   Hide extra grey header of the Table which shows up when one FieldSet of the Table has no Group Headers while
   the other FieldSet has Group Headers. Refer: IS-18087
 */
const wrapWithHideEmptyHeaderGroupStyle = getTheadGroupProps => (state) => {
    if (state && state.headerGroups && state.headerGroups.length === 1) {
        // It doesn't matter what Consumer passed in for TheadGroupProps, as we are hiding it completely
        return { style: { display: 'none' } };
    } else if (typeof getTheadGroupProps === 'function') {
        return { ...getTheadGroupProps() };
    }
    return {};
};

class Table extends Component {
    constructor(props) {
        super(props);
        autobind(this);
    }

    static getCategoriesNode({ fieldSet, categories, categoriesNode }) {
        // Nothing to render if there are no categories
        if (!categories || categories.length === 0) {
            return null;
        }

        // Is there a Custom Category Node ?
        if (categoriesNode) {
            return typeof categoriesNode === 'function' ? categoriesNode(fieldSet, categories) : categoriesNode;
        }

        // Fallback to Default Category Component
        return (<TableViewTabs tabName={fieldSet} categories={categories} />);
    }

    render() {
        const {
            data,
            loading,
            downloadCsv,
            downloadingCsv,
            onPageChange,
            page,
            pageSize,
            dataOffset,
            clientSide,
            manual,
            dataCount,
            getTheadGroupProps,
            getTrProps,
            columns,
            defaultFilterMethod,
            defaultSortMethod,
            headerNode,
            footerNode,
            tableActions,
            filterTagsNode,
            categories,
            match,
            categoriesNode,
            LoadingComponent,
            className,
        } = this.props;

        // Current selection of the category from the URL, this can be empty
        const fieldSet = match && match.params && match.params.fieldSet;
        const columnsToShow = transformColumnConfig(columns, fieldSet || (categories && categories[0]));
        const customFilterTagNode = typeof filterTagsNode === 'function' ? filterTagsNode() : filterTagsNode;
        const tableCategories = Table.getCategoriesNode({ fieldSet, categories, categoriesNode });
        const actions = downloadCsv
            ? (
                <Button
                    onClick={downloadCsv}
                    label={downloadingCsv ? 'Exporting CSV...' : 'Export CSV'}
                    disabled={downloadingCsv}
                />
            )
            : tableActions;

        return (
            <div className={classnames(className, bem.valueOf(), 'card card-no-border card-no-background card-no-top-margin card-no-bottom-margin')}>
                <Async loading={!LoadingComponent && loading}>
                    {() => (
                        <React.Fragment>
                            { typeof headerNode === 'function' ? headerNode() : headerNode }
                            {/* This "extra" div exists because of a bug in Chrome where the two children render with a very small height (9/10px) */}
                            <div>
                                {(actions || tableCategories) && (
                                    <div className={classnames('flex-container align-middle', tableCategories ? 'align-justify' : 'align-right')}>
                                        {tableCategories}
                                        <Actions>
                                            {actions}
                                        </Actions>
                                    </div>
                                )}
                                <div className="card card-no-margin card-no-border filterTagContainer">
                                    {!customFilterTagNode
                                        ? <FilterTags context="table" style={{ display: 'inline-block' }} />
                                        : customFilterTagNode
                                    }
                                </div>
                            </div>
                            <ReactTable
                                {...this.props}
                                className="-striped -highlight"
                                filterable={false}
                                sortable={false}
                                minRows={0}
                                data={data}
                                columns={columnsToShow}
                                getTheadGroupProps={wrapWithHideEmptyHeaderGroupStyle(getTheadGroupProps)}
                                getTrProps={getTrProps}
                                PaginationComponent={({ sortedData }) => {
                                    let itemCount;
                                    if (clientSide) {
                                        itemCount = (sortedData && sortedData.length) || 0;
                                    } else if (manual) {
                                        itemCount = dataCount;
                                    } else {
                                        itemCount = (data && data.length) || 0;
                                    }
                                    return (<Pagination
                                        className="flex-container align-right"
                                        itemCount={itemCount}
                                        dataOffset={dataOffset}
                                        numberPerPage={pageSize}
                                        onPageSelect={onPageChange}
                                    />);
                                }}
                                page={page}
                                defaultPageSize={pageSize}
                                defaultSortMethod={defaultSortMethod || sortMethod}
                                defaultFilterMethod={defaultFilterMethod || filterMethod}
                            />
                            { typeof footerNode === 'function' ? footerNode() : footerNode }
                        </React.Fragment>
                    )}
                </Async>
            </div>

        );
    }
}

Table.propTypes = {

    /**
     * Unique Id for the Table.
     * <dt>Ex Use Case 1: It is used to persist resized column widths of the table.</dt>
     * <dt>Ex Use Case 2: Since some of Table's HOCs provide URL sync functionality,
     * this can be used to namespace the url parameters when multiple Tables are present in the same page.</dt>
     */
    tableId: PropTypes.string,

    /**
     * Array of objects where each object represents a data row in the table.
     */
    data: PropTypes.arrayOf(PropTypes.object).isRequired,

    /**
     * Array of **{ column }** configuration objects.
     */
    columns: PropTypes.arrayOf(PropTypes.shape({

        /**
         * **column.Header** *{ string | function | element }*
         *<dt>Header of the column.</dt>
         *
         * - **function**
         *   Function that returns a valid *element* to render in the Column's header
         *   This gives you the ability to access header props.
         * - **string**
         *   Header of the column. This also acts as **default accessor** to access data from data object if an accessor is not specified.
         * - **element**
         *   a valid *element* to render in the Column's header
         */
        Header: PropTypes.oneOfType([PropTypes.func, PropTypes.string]).isRequired,

        /**
         * **column.title** *{ string }*
         *
         * - If *Header* is a {function | element}, this is required to show valid value in FilterTags.
         * - This is not required, if *Header* is a {string} or if this Column is not filterable
         */
        title: PropTypes.string,

        /**
         * **column.accessor** *{ string | function | Array }*
         * <dt>Specify how to to access data for this Column from the data object</dt>
         *
         * - **function**
         *   Function that returns value for each row of this column
         * - **string**
         *   Key (attribute) to access the column from the data object of a row
         * - **Array**
         *   react-table's default accessor will parse the input into an array and recursively flatten it.
         *    <br />Read more about it here: <a href="https://github.com/react-tools/react-table#accessors">accessors</a>.
         *    *NOTE:* This option is rarely used.
         */
        accessor: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),

        /**
         * **column.id** *{ string }*
         *
         * - A unique ID is required if the 'accessor is not a string or if you would like to override the column name used in server-side calls.
         */
        id: PropTypes.string,

        /**
         * **column.toolTip** *{ string }*
         *
         * <dt>Tooltip for the header.</dt>
         * - **string**
         *   Defaults to *Header* value if its a {string}
         */
        toolTip: PropTypes.string,

        /**
         * **column.Cell** *{ string | function | element }*
         *
         * <dt>Value to be shown in the data cell. This defaults to {accessor}'s value</dt>
         *
         * - **function**
         *   Function that returns a valid *element* to render in the data cell.
         *   This gives you the ability to access both the column's data as well as entire row's data.
         * - **string**
         * - **element**
         *   a valid *element* to render in the data cell
         */
        Cell: PropTypes.oneOfType([PropTypes.element, PropTypes.string, PropTypes.func]),

        /**
         * **column.headerClassName** *{ text-center | text-right | text-left }*
         *
         * <dt>Sets the css class name for the header of this column</dt>
         *  - **string**
         *   Defaults to *text-left *
         */
        headerClassName: PropTypes.oneOf(['text-center', 'text-right', 'text-left']),

        /**
         * **column.className** *{ string }*
         *
         * <dt>Set the css class name for the data cell of this column.
         * While this can be any string, it is recommended that you stick to
         * text-center, text-right, text-left, align-self-top, align-self-middle, align-self-bottom,
         * and align-self-stretch as possible classes</dt>
         *  - **string**
         *   Defaults to *text-left*
         */
        className: PropTypes.string,

        /**
         * **column.enableWrapping** *{ bool }*
         * <dt>Provides the flexibility to decide whether to wrap the text or add ellipsis when it overflows
         * Default behavior is to place the entire header on a single line and add ellipses when it overflows</dt>
         *
         *   - Defaults to *false*
         */
        enableWrapping: PropTypes.bool,

        /**
         * **column.sortable** *{ bool }*
         * <dt>Specify if this column is sortable or not</dt>
         *
         *  - Defaults to *true*
         */
        sortable: PropTypes.bool,

        /**
         * **column.sortMethod** *{ function }*
         * <dt>A function to sort elements of this column</dt>
         *
         *  - If a column is sortable, this defaults to case insensitive text sorting
         */
        sortMethod: PropTypes.func,

        /**
         * **column.filterable** *{ bool }*
         * <dt>A function to filter elements of this column based on value given in Filter Component</dt>
         *
         *  - Defaults to *false*
         *  - This is overridden if {Filter} is set
         */
        filterable: PropTypes.bool,

        /**
         * **column.Filter** *{ function | element }*
         * <dt>Component to be rendered in Filter Popup</dt>
         *
         * - **function**
         *   Function that returns a valid *element* to render in the Filter popup.
         *   This gives you the ability to access filter props.
         * - **element**
         *   a valid *element* to render in the Filter popup.
         * - defaults to {TextInputFilter} if filterable is set to true
         * **NOTE:** If a valid Filter is given, you no need to specify {filterable} attribute in the configuration
         */
        Filter: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),

        /**
         * **column.filterMethod** *{ function }*
         * <dt>A function to filter elements of this column</dt>
         *
         *  - If a column is filterable, this defaults to case insensitive text sorting
         */
        filterMethod: PropTypes.func,

        /**
         * **column.categories** *{ function }*
         * <dt>Table columns can be categorized and this helps to the set the categories the column.
         * A column can belong to multiple categories</dt>
         *
         */
        categories: PropTypes.arrayOf(PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.shape({
                value: PropTypes.string.isRequired,
                title: PropTypes.string.isRequired,
            }),
        ])),
    })).isRequired,

    /**
     * Columns of the table can be categorized. This represents set of Categories.
     * @param { string | categoryObject}
     */
    categories: PropTypes.arrayOf(PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({
            value: PropTypes.string.isRequired,
            title: PropTypes.string.isRequired,
        }),
    ])),

    /**
     * If this is set to true, Table will not be rendered instead it shows Spinner.
     * Helps to specify when the data is loading.
     *
     * @default false
     */
    loading: PropTypes.bool,

    /**
     * Default column to be sorted and its sort order.
     * Right now we can sort only by one Column at a time.
     * Please note to wrap it in an Array.
     */
    defaultSorted: PropTypes.arrayOf(PropTypes.shape({
        /**
         * Unique identifier of the column
         */
        id: PropTypes.string.isRequired,

        /**
         * Is this column sorted in Descending order
         */
        desc: PropTypes.bool,
    })),

    /**
     * Default column to be filtered and the filter value
     */
    defaultFiltered: PropTypes.arrayOf(PropTypes.shape({
        /**
         * Unique identifier of the column
         */
        id: PropTypes.string.isRequired,

        /**
         * Title of the column
         * Often times the underlying data field ie., accessor/id of the Column and
         * the actual visible header of the Column are different. So use this to get
         * proper Filter Tag Name
         */
        title: PropTypes.string,

        /**
         * Value of the filter
         */
        value: PropTypes.string,
    })),

    /**
     * Used as default filtering method.
     * Can be overriden with Column (Cell) level filterMethod
     * @param {filter} Filter Object
     * @param filter.id {string} id of the column
     * @param filter.title {string} Title, it will be used to show in Filter Popup and also in Filter Tags.
     *                              Set this if you need something different from id (which is very likely)
     * @param filter.value {string} filter value entered by user
     * @param {object} row, an object representing the row of the Table
     * @param {object} Column configuration object
     * @returns {boolean} true if the row value matched filter criteria, else false
     */
    defaultFilterMethod: PropTypes.func,

    /**
     * Used as default sorting method.
     * Can be overriden with Column (Cell) level sortMethod
     * @param {string} a sort candidate
     * @param {string} b sort candidate
     * @param {boolean} desc - is it need to be sorted in descending order
     * @returns {number} one of 1, -1 or 0
     */
    defaultSortMethod: PropTypes.func,

    /**
     * Function which returns component(s) or a simple set of component(s)
     * These component(s) are then passed as children to the Actions component
     */
    tableActions: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.element,
    ]),

    /** Component to override the default FilterTags. */
    filterTagsNode: PropTypes.oneOfType([PropTypes.func, PropTypes.element]),

    /** Component to override the default TableViewTabs.
     * If the type is function, then the following parameters will be passed to the function.
     * @param {string} fieldSet the default tab to be selected on display.
     * @param {array} categories the categories list passed to the table for division of columns.
     * @returns {element} The custom component element to be used in palce of default TableViewTabs.
     */
    categoriesNode: PropTypes.oneOfType([PropTypes.func, PropTypes.element]),

    /**
     * Function to download the CSV.
     * TODO: Allow consumer to pass a custom "action bar" and leave the actions to them
     */
    downloadCsv: PropTypes.func,

    /**
     * Boolean indicator for downloading data as CSV. This helps to change the Download button's state
     */
    downloadingCsv: PropTypes.bool,

    /**
     * Callback function to handle page changes
     */
    onPageChange: PropTypes.func,

    /**
     * Current Page number
     * @default 0
     */
    page: PropTypes.number,

    /**
     * No.of Rows per page
     *
     * @default 50
     */
    pageSize: PropTypes.number,

    /**
     * Index of the current page's first data row in the data
     *
     * @default 0
     */
    dataOffset: PropTypes.number,

    /**
     * Is this table client controlled ie., all the filtering, sorting is done on (client) browser.
     * If set to true, it uses resolvedState supplied by ReactTable to show the Filtered Count in Pagination
     *
     * @default false
     */
    clientSide: PropTypes.bool,

    /**
     * Is this a table which fetches data from server when sort, filtering or page changes
     */
    manual: PropTypes.bool,

    /**
     * Total count of the data rows. This is used only when manual is set to true
     */
    dataCount: PropTypes.number,

    /**
     * @ignore
     * Injected by withQueryParams
     */
    match: PropTypes.shape({
        params: PropTypes.shape({
            fieldSet: PropTypes.string,
        }),
    }),

    // Component decorators

    /**
     * An element to be added at the Top of the Table
     * This will have access to Table's Context when used with `withTableState` or Out of the Box components like ClientControlledTable, ElasticSearchTable
     */
    headerNode: PropTypes.oneOfType([PropTypes.func, PropTypes.element]),

    /**
     * An element to be added to the Bottom of the Table
     * This will have access to Table's Context when used with `withTableState` or Out of the Box components like ClientControlledTable, ElasticSearchTable
     */
    footerNode: PropTypes.oneOfType([PropTypes.func, PropTypes.element]),

    /**
     * Apply specific style to the Header Group
     * @return PropTypes.shape({})
     */
    getTheadGroupProps: PropTypes.func,

    /**
     * Apply specific row styles to the table
     * @return PropTypes.shape({})
     */
    getTrProps: PropTypes.func,

    /**
     * Custom loading component to override the default Async loading component.
     * LoadingComponent is a prop of ReactTable and it is passed as it is and it is used 
     * in conjunction with {loading} prop
     */
    LoadingComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.element]),

    className: PropTypes.string,
};

Table.defaultProps = {
    loading: false,
};

export default Table;
