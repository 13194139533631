import React from 'react';
import PropTypes from 'prop-types';
import GlobalBanner, {
  WARNING,
} from '@infosight/elmer/dist/components/GlobalBanner';
import bowser from 'bowser';

// TODO Create a page like this: https://browsers.stackoverflow.design/
//  See https://nimblejira.nimblestorage.com/browse/RP-6631 and https://nimblejira.nimblestorage.com/browse/IS-23998
//
// I started with an arbitrarily recent release in the August/September 2020 time frame (6 months ago)
// Then just verified that some features like ES Modules and CSS Grid are fully supported (can't be bleeding edge)
// This list is based on the browsers we actually see customers using
// All are desktop versions; we don't really have a phone-size use case yet.
//
// TODO Google is already phasing out User Agents. Others are probably going to follow soon,
//  but I think we're good for 2021
export const checkBrowser = (userAgent = window.navigator.userAgent) => {
  return bowser.getParser(userAgent).satisfies({
    // Recent versions can be found at https://docs.microsoft.com/en-us/deployedge/microsoft-edge-release-schedule
    // Version History: https://docs.microsoft.com/en-us/deployedge/microsoft-edge-relnote-stable-channel
    'Microsoft Edge': '>=85',

    // Version History: https://chromereleases.googleblog.com/search/label/Stable%20updates
    Chrome: '>=85',

    // Version History: https://www.mozilla.org/en-US/firefox/releases/
    Firefox: '>=81',

    // Versions Apple currently supports on the last 3 macOS versions
    Safari: '>=13',
  });
};

export const UnsupportedBrowserBanner = ({ isValidBrowser }) => {
  if (isValidBrowser) {
    return null;
  }

  return (
    <GlobalBanner
      severity={WARNING}
      nav={null}
      content={
        <span data-test="unsupported-browser">
          <strong>Your browser is not supported.</strong> HPE InfoSight uses the
          latest web technology and your browser may not support those
          technologies. Please update to the latest version of{' '}
          <a
            href="https://www.google.com/chrome/"
            rel="noreferrer noopener"
            target="_blank"
          >
            Chrome
          </a>
          ,{' '}
          <a
            href="https://www.microsoft.com/en-us/edge"
            rel="noreferrer noopener"
            target="_blank"
          >
            Edge
          </a>
          ,{' '}
          <a
            href="https://www.mozilla.org/en-US/firefox/new/"
            rel="noreferrer noopener"
            target="_blank"
          >
            Firefox
          </a>
          , or{' '}
          <a
            href="https://www.apple.com/safari/"
            rel="noreferrer noopener"
            target="_blank"
          >
            Safari
          </a>{' '}
          (macOS only) for the best experience.
        </span>
      }
    />
  );
};

UnsupportedBrowserBanner.propTypes = {
  isValidBrowser: PropTypes.bool,
};

UnsupportedBrowserBanner.defaultProps = {
  isValidBrowser: checkBrowser(),
};
