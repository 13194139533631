import React from 'react';
import PropTypes from 'prop-types';
import { StaticBold } from '@infosight/elmer/dist/components/StaticBold';
import { Box, Text } from 'grommet';
import classnames from 'classnames/bind';
import { PopoverTrigger } from '@infosight/elmer/dist/components/PopoverTrigger';
import { UserAdmin } from 'grommet-icons';
import style from './TenantMenuItem.scss';
import WithTenantAttributes from './WithTenantAttributes'; // eslint-disable-line import/no-named-as-default

const cx = classnames.bind(style);

export const TenantMenuItemContents = ({
  isActiveTenant,
  tenant: { name, description, highlighted },
  isAdmin,
}) => {
  const descriptionBox = () => {
    if (description && description.length > 60) {
      return (
        <Box className={cx('popover-box')}>
          <PopoverTrigger popover={<span>{description}</span>}>
            <Box>
              <Text className={cx('popover-text')} size="small" truncate>
                {description}
              </Text>
            </Box>
          </PopoverTrigger>
        </Box>
      );
    }
    return (
      <Text size="small" truncate>
        {description}
      </Text>
    );
  };

  return (
    <Box
      className={cx('tenant-menu-item')}
      style={{ width: '368px', padding: '5px 0px' }}
    >
      <StaticBold>
        <Box fill direction="row">
          {isAdmin && (
            <Box className={cx('popover-box')} pad={{ right: 'xsmall' }}>
              <PopoverTrigger popover={<span>Admin</span>}>
                <UserAdmin size="small" color="currentColor" />
              </PopoverTrigger>
            </Box>
          )}
          {isActiveTenant && (
            <Text size="small" className={cx('highlighted')}>
              Current
            </Text>
          )}
          {/* highlighted is a conditional class of the same name as the variable */}
          <Box className={cx({ highlighted })}>
            <Text className={cx('tenent-name')} truncate>
              {name}
            </Text>
          </Box>
        </Box>
        {description && description.length > 0 && (
          <Box fill direction="row" align="center">
            {descriptionBox()}
          </Box>
        )}
      </StaticBold>
    </Box>
  );
};

TenantMenuItemContents.propTypes = {
  isActiveTenant: PropTypes.bool,
  tenant: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string,
    highlighted: PropTypes.bool,
  }).isRequired,
  isAdmin: PropTypes.bool,
};

const TenantMenuItem = ({ tenant }) => (
  <WithTenantAttributes tenant={tenant} render={TenantMenuItemContents} />
);

TenantMenuItem.propTypes = {
  tenant: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string,
  }),
};

export default TenantMenuItem;
