import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import BEM from 'src/utils/bem';

const bem = new BEM('elmer-Button');

/**
 * Base [Button](http://foundation.zurb.com/sites/docs/button.html) component.
 * Takes all standard props for `<button>` or `<a>` tags.
 */
const BaseButton = ({
    className,
    size,
    expanded,
    color,
    disabled,
    hollow,
    href,
    uppercase,
    children,
    label,
    borderless,
    ...props
}) => {
    const component = href ? 'a' : 'button';
    let { type } = props;

    if (component === 'button') {
        type = type || 'button';
    }

    const elChildren = children || label;

    const elProps = {
        ...props,
        href,
        type,
        className: classnames(className, bem.valueOf(), size, color, 'button', {
            expanded,
            disabled,
            hollow: hollow || borderless,
            [bem.m('uppercase')]: uppercase,
            cancel: borderless,
        }),
        disabled: disabled ? true : undefined,
        'aria-disabled': disabled ? true : undefined,
    };

    return React.createElement(component, elProps, elChildren);
};

BaseButton.propTypes = {
    children: PropTypes.node,

    /**
     * [Sizing](http://foundation.zurb.com/sites/docs/button.html#sizing)
     */
    size: PropTypes.oneOf(['tiny', 'small', 'default', 'large', '']),

    /**
     * Make button 100% block element
     */
    expanded: PropTypes.bool,

    /**
     * Semantic [Color](http://foundation.zurb.com/sites/docs/button.html#coloring)
     */
    color: PropTypes.oneOf(['primary', 'secondary', 'success', 'alert', 'warning', 'dark', '']),

    /**
     * [Disabled](http://foundation.zurb.com/sites/docs/button.html#disabled-buttons)
     */
    disabled: PropTypes.bool,

    /**
     * [Hollow Style](http://foundation.zurb.com/sites/docs/button.html#hollow-style)
     */
    hollow: PropTypes.bool,

    /**
     * Create an `<a`> tag with and href attribute. Prefer [LinkButton](#LinkButton)
     */
    href: PropTypes.string,

    uppercase: PropTypes.bool,

    /**
     * Remove background and border. Use for cancel buttons or secondary modal buttons.
     */
    borderless: PropTypes.bool,

    /**
     * Sets the `<button>` contents for simple text-only cases. Use `props.children` for other cases.
     */
    label: PropTypes.string,

    /**
     * Any valid HTML5 type attribute
     */
    type: PropTypes.string,
};

BaseButton.defaultProps = {
    size: 'default',
    expanded: false,
    disabled: false,
    hollow: false,
    uppercase: true,
    borderless: false,
};

export default BaseButton;
