import { ExtensionPoint } from '@infosight/shell-api/lib/core';
import reducer, { ADD } from './reducer';
import { removeBanner as removeBannerAction } from './actionCreators';

function addBanner(opts) {
  this.props.dispatch({ type: ADD, payload: opts });
}

/**
 * Remove existing nav content
 */
function removeBanner(opts) {
  removeBannerAction(opts)(this.props.dispatch);
}

export const id = ExtensionPoint.Banner;
export const commands = { addBanner, removeBanner };
export { reducer };
