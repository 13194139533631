import React from 'react';
import PropTypes from 'prop-types';
import { Grommet } from 'grommet';
import classnames from 'classnames';
import elmer from './theme';

const ThemeProvider = ({ children, className, ...props }) => (
    <Grommet theme={elmer} className={classnames('elmer-ThemeProvider', className)} background="#E2E6EA" {...props} >
        {children}
    </Grommet>
);

ThemeProvider.propTypes = {
    children: PropTypes.oneOfType([PropTypes.node, PropTypes.element]),
};

export default ThemeProvider;
