import { Component } from 'react';
import PropTypes from 'prop-types';
import autobind from 'react-autobind';
import { noop } from 'underscore';

export default class Timer extends Component {
    constructor(props) {
        super(props);
        autobind(this);
        this.clearTimeout = noop;
    }

    componentDidMount() {
        const { timeout, onShow } = this.props;

        if (timeout) {
            const timeoutId = window.setTimeout(this.close, timeout);
            this.clearTimeout = () => window.clearTimeout(timeoutId);
        }

        if (typeof onShow === 'function') {
            onShow();
        }
    }

    close() {
        this.clearTimeout();
        this.props.onClose();
    }

    render() {
        const { children } = this.props;
        return children(this.close);
    }
}

Timer.propTypes = {
    /**
     * Notification content
     * @param {function} close
     * @return {React.Element}
     */
    children: PropTypes.func.isRequired,

    /**
     * If the user does not imperatively dismiss the notification, it will automatically be
     * closed after this number of milliseconds.
     *
     * Use `0` for no timeout.
     */
    timeout: PropTypes.number,

    /**
     * Callback when the notification appears
     */
    onShow: PropTypes.func,

    /**
     * Callback when the notification is removed
     */
    onClose: PropTypes.func,
};

Timer.defaultProps = {
    timeout: 5000,
};
