import React from 'react';
import getDisplayName from 'recompose/getDisplayName';
import wrapDisplayName from 'recompose/wrapDisplayName';
import * as primaryNav from '../primaryNav';
import * as search from '../search';
import * as userProfile from '../userProfile';
import * as router from '../router';
import * as bootstrapper from '../bootstrapper';
import * as analytics from '../analytics';
import * as config from '../config';
import * as banner from '../banner';
import * as home from '../home';
import * as tour from '../tour';

import ExtensionPoint from './ExtensionPoint';

/**
 * Create an extension point and it's data repository in a redux store
 * @private
 *
 * @param id {string} Unique versioned ID for the extension point
 * @param commands {Map<string, function>} Dictionary of commands and handlers
 * @param reducer {Function}
 * @param queries {Map<string, function>} Dictionary of queries and handlers
 * @param mapAdditionalStateToProps {function} This is a state mapper like react-redux's `mapStateToProps`
 *        that allows the XP to access other global or derived state.
 * @param initialize {function}
 * @return {ExtensionPoint}
 */
function createExtensionPoint({
  id,
  commands,
  queries,
  mapAdditionalStateToProps,
  initialize,
}) {
  if (!id) {
    console.error('Invalid extension point definition');

    // Return some component just so the rest of the app can function
    return React.Fragment;
  }

  const ExtensionPointWrapper = () => (
    <ExtensionPoint
      id={id}
      commands={commands}
      queries={queries}
      mapAdditionalStateToProps={mapAdditionalStateToProps}
      initialize={initialize}
    />
  );

  // Include the ID in the component's display name for a better debugging experience
  ExtensionPointWrapper.displayName = wrapDisplayName(
    id,
    getDisplayName(ExtensionPointWrapper)
  );

  return ExtensionPointWrapper;
}

const components = [
  primaryNav,
  search,
  userProfile,
  router,
  bootstrapper,
  analytics,
  config,
  banner,
  home,
  tour,
].map(createExtensionPoint);

const ExtensionPoints = () => (
  <>
    {components.map((XP) => (
      <XP key={XP.displayName} />
    ))}
  </>
);

export default ExtensionPoints;
