import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ExtensionPoints from './ExtensionPoints';
import InternalExtensions from './InternalExtensions';
import ExternalExtensions from './ExternalExtensions';

const ExtensionOrchestrator = ({ onLoad }) => {
  const [extensionPointsInitialized, setExtensionPointsInitialized] = useState(
    false
  );
  useEffect(() => {
    setExtensionPointsInitialized(true);
    onLoad();
  }, [onLoad, extensionPointsInitialized]);

  return (
    <>
      <ExtensionPoints />
      {extensionPointsInitialized && (
        <>
          <InternalExtensions />
          <ExternalExtensions />
        </>
      )}
    </>
  );
};

ExtensionOrchestrator.propTypes = {
  onLoad: PropTypes.func.isRequired,
};

export default ExtensionOrchestrator;
