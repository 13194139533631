import { Component } from 'react';
import PropTypes from 'prop-types';
import autobind from 'react-autobind';
import { connect } from 'react-redux';
import { addMenu } from '@infosight/shell-api/lib/PrimaryNav';
import { onUserContextChange } from '@infosight/shell-api/lib/UserProfile';
import { userSelector, isInternalDemoUserSelector } from '../reducer';
import AuthorizationEvaluator from '../services/authorizationEvaluator/authorizationEvaluator';

class PrimaryNavExtension extends Component {
  constructor(props) {
    super(props);
    autobind(this);
  }

  componentDidMount() {
    this.handleUpdate();
    this.unsubscribe = onUserContextChange(this.handleUpdate);
  }

  componentWillUnmount() {
    if (this.unsubscribe) {
      this.unsubscribe();
    }
  }

  handleUpdate() {
    const { user, isInternalDemoUser } = this.props;
    const options = AuthorizationEvaluator.filter([
      {
        id: 'username',
        title: user.username, // TODO try to get full name from HPE auth payload
        nodeType: 'title',
      },
      {
        id: 'bulletins',
        title: 'Past Bulletins',
        nodeType: 'internal',
        url: '/user/bulletins',
      },
      {
        id: 'fullAccess',
        title: 'Request Full Access',
        url: '/user/fullAccess',
        nodeType: 'internal',
        access: {
          custom: () => isInternalDemoUser,
        },
      },

      // Logout must always be last
      {
        id: 'logout',
        title: 'Log out',
        url: `${process.env.LOGIN_PATH}/login?logout`,
        nodeType: 'plain',
        divider: true,
      },
    ]);

    addMenu({ menuId: 'user', id: 'user', content: options });
  }

  render() {
    return null;
  }
}

PrimaryNavExtension.propTypes = {
  user: PropTypes.object.isRequired,
  isInternalDemoUser: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  user: userSelector(state),
  isInternalDemoUser: isInternalDemoUserSelector(state),
});

export default connect(mapStateToProps)(PrimaryNavExtension);
