import { ExtensionPoint } from '@infosight/shell-api/lib/core';
import { experimentalSelector } from '../../bootstrapper/reducer';
import { APP_BASE_URL, MIN_WIDTH_PIXELS } from '../../config';

function getConfig() {
  return {
    experimental: this.props.experimental,
    appBaseUrl: APP_BASE_URL,
    minWidthPixels: MIN_WIDTH_PIXELS,
  };
}

export const id = ExtensionPoint.Config;
export const queries = { getConfig };

export const mapAdditionalStateToProps = (state) => ({
  experimental: experimentalSelector(state),
});
