import React from 'react';
import PropTypes from 'prop-types';
import { Box, Stack, Text } from 'grommet';

const IconStack = ({ value, color, children, forceEnabled }) => {
  if (value <= 0) {
    return children;
  }

  if (forceEnabled) {
    console.log(forceEnabled, typeof value);
  }

  return (
    <Stack anchor="top-right" data-testid="iconstack">
      <Box
        fill
        direction="column"
        alignContent="center"
        justify="center"
        pad={{ top: 'small', right: 'small', bottom: 'small' }}
      >
        <Box>{children}</Box>
      </Box>
      <Box
        background={`status-${color}`}
        pad={{ horizontal: 'xsmall' }}
        round="full"
      >
        <Text size="xsmall">
          {forceEnabled && typeof value === 'undefined'
            ? '\u00A0\u00A0\u00A0'
            : value}
        </Text>
      </Box>
    </Stack>
  );
};

IconStack.propTypes = {
  children: PropTypes.node.isRequired,
  value: PropTypes.number,

  /**
   * Semantic Color
   */
  color: PropTypes.oneOf(['ok', 'warning', 'critical', 'error']),
  forceEnabled: PropTypes.bool,
};

IconStack.defaultProps = {
  color: 'warning',
  forceEnabled: false,
};

export default IconStack;
