import { ExtensionPoint } from '@infosight/shell-api/lib/core';
import reducer, { ADD, REMOVE } from './reducer';

/**
 * Add nav content to a menu
 */
function addMenu(opts) {
  this.props.dispatch({ type: ADD, payload: opts });
}

/**
 * Remove existing nav content
 */
function removeMenu(opts) {
  this.props.dispatch({ type: REMOVE, payload: opts });
}

export const id = ExtensionPoint.PrimaryNav;
export const commands = { addMenu, removeMenu };
export { reducer };
