import {
  ACTIVE_PANELS,
  NAV_MODAL_OPEN,
  RESET_MENU,
  NAV_CLOSE_ON_OUTSIDE_CLICK,
} from './constants';
import { activePanelSelector, lastMicroappSelector } from './reducer';
import { getStore } from '../util/storeProvider';

// eslint-disable-next-line import/prefer-default-export
export const updateActivePanels = (key, activeIndexes) => (
  dispatch,
  getState
) => {
  const activePanelStore = activePanelSelector(getState());
  activePanelStore[key] = activeIndexes;
  dispatch({
    type: ACTIVE_PANELS,
    payload: activePanelStore,
  });
};

export const resetNavMenuForTour = () => {
  const lastMicroapp = lastMicroappSelector(getStore().getState());
  getStore().dispatch({ type: RESET_MENU, payload: { lastMicroapp } });
  getStore().dispatch({
    type: NAV_CLOSE_ON_OUTSIDE_CLICK,
  });
};
export const closeNavMenuFormTour = () => {
  getStore().dispatch({ type: NAV_MODAL_OPEN, payload: false });
  getStore().dispatch({ type: NAV_CLOSE_ON_OUTSIDE_CLICK, payload: true });
};
