import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import autobind from 'react-autobind';
import { experimentalSelector } from '../../bootstrapper/reducer';
import { setExperimental } from '../actionCreators';

export class Experimental extends Component {
  constructor(props) {
    super(props);
    autobind(this);
  }

  shouldComponentUpdate(nextProps) {
    if (nextProps.experimental !== this.props.experimental) {
      return true;
    }

    return false;
  }

  experimentalToggle() {
    const { experimental } = this.props;
    this.props.setExperimental(!experimental);
  }

  render() {
    const { experimental } = this.props;
    return (
      <button
        className="elmer-NavBar-Menu__item elmer-NavBar-Menu__item--link text-left"
        type="button"
        onClick={this.experimentalToggle}
      >
        Turn experimental mode {experimental ? 'off' : 'on'}
      </button>
    );
  }
}

Experimental.propTypes = {
  experimental: PropTypes.bool.isRequired,
  setExperimental: PropTypes.func,
};

const mapStateToProps = (state) => ({
  experimental: experimentalSelector(state),
});

const mapDispatchToProps = {
  setExperimental,
};

export default connect(mapStateToProps, mapDispatchToProps)(Experimental);
