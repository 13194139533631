import { Component, lazy } from 'react';
import autobind from 'react-autobind';
import { addRouter } from '@infosight/shell-api/lib/Router';

const UserRouter = lazy(() => import('../components/UserRouter'));

class RouterExtension extends Component {
  constructor(props) {
    super(props);
    autobind(this);
  }

  componentDidMount() {
    addRouter({ url: 'user', router: UserRouter, exact: false, appId: 'user' });
  }

  render() {
    return null;
  }
}

export default RouterExtension;
