import { actionNamespacer } from '@infosight/elmer/dist/utils/redux';

const ns = actionNamespacer('BULLETINS');

export const FETCH_BULLETINS = ns('FETCH_BULLETINS');
export const FETCH_ACTIVE_BULLETINS = ns('FETCH_ACTIVE_BULLETINS');
export const RESET = ns('RESET');
export const DISMISS_BULLETIN = ns('DISMISS_BULLETIN');
export const DISMISS_ALL_BULLETINS = ns('DISMISS_ALL_BULLETINS');
export const FETCH_WHATS_NEW = ns('FETCH_WHATS_NEW');
export const FETCH_ACTIVE_WHATS_NEW = ns('FETCH_ACTIVE_WHATS_NEW');
export const DISMISS_ALL_WHATS_NEW = ns('DISMISS_ALL_WHATS_NEW');
