import { ExtensionPoint } from '@infosight/shell-api/lib/core';
import reducer, { REGISTER, toursSelector } from './reducer';
import { generateId, startTourAction, stopTourAction } from './actionCreators';
import {
  shouldOfferTour as doShouldOfferTour,
  validateTour,
  triggerTourStatusUpdated,
} from '../../tour/services';
import { tourUserPropertiesSelector } from '../../iam/reducer';

function registerTour(opts) {
  const { isValid, message } = validateTour(opts);
  if (!isValid) {
    throw message;
  }

  const { appId, name } = opts;
  if (message) {
    console.debug(
      `Tour validation messages for appId=${appId} name=${name}`,
      message
    );
  }

  this.props.dispatch({
    type: REGISTER,
    payload: { ...opts, id: generateId(appId, name) },
  });
  setTimeout(triggerTourStatusUpdated, 400); // Allow time for the ExtensionPoint component to render with the latest props
}

function startTour(opts) {
  startTourAction(opts)(this.props.dispatch);
}

function stopTour(opts) {
  stopTourAction(opts)(this.props.dispatch);
}

function shouldOfferTour(opts) {
  const { tours, tourUserProperties } = this.props;
  return doShouldOfferTour({ tours, tourUserProperties, tourId: opts });
}

/**
 * Implemented this way for backward-compatibility
 */
function userHasViewedTour(opts) {
  return !shouldOfferTour.call(this, opts);
}

export const id = ExtensionPoint.Tour;
export const commands = { registerTour, startTour, stopTour };
export const queries = { userHasViewedTour, shouldOfferTour };
export { reducer };

export const mapAdditionalStateToProps = (state) => ({
  tourUserProperties: tourUserPropertiesSelector(state),
  tours: toursSelector(state),
});
