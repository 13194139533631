import { command, ExtensionPoint } from '@infosight/shell-api/lib/core';
import { UserContextChangeReason } from '@infosight/shell-api/lib/UserProfile';
import { EXPERIMENTAL, TOURTOOL } from '../bootstrapper/constants';

/**
 * update the store and localStorage with the new experimental status
 */

export const setExperimental = (experimental) => (dispatch) => {
  if (localStorage) {
    localStorage.setItem('experimental', experimental);
  }
  dispatch({ type: EXPERIMENTAL, payload: experimental });
  command(
    ExtensionPoint.UserProfile,
    'userContextChange',
    UserContextChangeReason.ExperimentalMode
  );
};

export const setTourTool = (tourTool) => (dispatch) => {
  if (localStorage) {
    localStorage.setItem('shell:devtool:tours', tourTool);
  }
  dispatch({ type: TOURTOOL, payload: tourTool });
  command(
    ExtensionPoint.UserProfile,
    'userContextChange',
    // dont have a tour change reason
    UserContextChangeReason.ExperimentalMode
  );
};
