import React, { Component } from 'react';
import PropTypes from 'prop-types';
import autobind from 'react-autobind';
import { CheckBox, ThemeContext } from 'grommet';


const filterPopupCheckBox = {
    checkBox: {
        size: '16px',
        gap: 'large',
        hover: {
            border: {
                color: {
                    // TODO: This is bad but ThemeContext is deprecated now in Grommet. Figure out a way
                    light: '#01a982',
                },
            },
        },
    },
};

class Checkbox extends Component {
    constructor(props) {
        super(props);
        autobind(this);
        this.state = {
            isChecked: props.isChecked,
        };
    }

    toggleCheckBox() {
        const { handleCheckboxChange, label } = this.props;

        this.setState(({ isChecked }) => (
            {
                isChecked: !isChecked,
            }
        ));

        if (typeof handleCheckboxChange === 'function') {
            handleCheckboxChange(label);
        }
    }

    render() {
        const { label, value } = this.props;
        return (
            <ThemeContext.Extend value={filterPopupCheckBox}>
                <CheckBox
                    onChange={this.toggleCheckBox}
                    label={label}
                    name={label}
                    id={label}
                    key={label}
                    checked={this.state.isChecked}
                    value={value}
                />
            </ThemeContext.Extend>
        );
    }
}


Checkbox.propTypes = {
    label: PropTypes.string.isRequired,
    value: PropTypes.arrayOf(PropTypes.string, PropTypes.number),
    handleCheckboxChange: PropTypes.func.isRequired,
    isChecked: PropTypes.bool,
};


Checkbox.defaultProps = {
    isChecked: false,
};

export default Checkbox;
