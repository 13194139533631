import {
  createReducersForAction,
  createSelector,
  getInitialState,
} from '@infosight/elmer/dist/utils/redux';
import { FETCH_BOOTSTRAP, EXPERIMENTAL, TOURTOOL, RESET } from './constants';
import { experimentalModeAllowed } from '../config';

const initialState = {
  nav: [],
  routing: [],
  experimental: false,
  tourTool: false,
  ...getInitialState('bootstrap'),
};

const ACTION_HANDLERS = {
  [EXPERIMENTAL]: (state, { payload }) => ({ ...state, experimental: payload }),
  [TOURTOOL]: (state, { payload }) => ({ ...state, tourTool: payload }),
  [RESET]: () => initialState,
  ...createReducersForAction({ type: FETCH_BOOTSTRAP, stateKey: 'bootstrap' }),
};

export default function infrastructureReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}

const baseSelector = (state) => state.bootstrapper;
export const experimentalSelector = (state) =>
  experimentalModeAllowed && baseSelector(state).experimental;
export const tourToolSelector = (state) =>
  experimentalModeAllowed && baseSelector(state).tourTool;
export const bootstrapSelector = createSelector(baseSelector, 'bootstrap');
