import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import BEM from 'src/utils/bem';

const bem = new BEM('elmer-NavBar');

const NavBar = ({ className, children }) => (
    <div className={classnames(className, 'top-bar', bem.valueOf())}>
        {children}
    </div>
);

NavBar.propTypes = {
    children: PropTypes.node.isRequired,
};

export default NavBar;
