import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

// TODO this can be further abstracted. This dom is partially duplicated elsewhere
class ErrorMessage extends Component {
    constructor(props) {
        super(props);
        this.state = { detailsVisible: false };
    }

    render() {
        const { error, info, allowDetails } = this.props;
        return (
            <div className="card">
                <div className="card-section">
                    <div className="flex-container align-justify align-middle">
                        <h1 className="no-margin"><i className="fa fa-exclamation-triangle text-danger" /> Sorry, something went wrong.</h1>
                        {(error || info) && allowDetails && (
                            <button
                                className={classnames({ hide: false })}
                                onClick={() => this.setState(({ detailsVisible }) => ({ detailsVisible: !detailsVisible }))}
                            >
                                Detail
                            </button>
                        )}
                    </div>
                </div>
                {this.state.detailsVisible && (
                    <div className="card-section">
                        <div>
                            <h2 className="no-margin">Error: {error.message || error.toString()}</h2>
                            <pre>{error.stack}</pre>
                        </div>
                        <br />
                        <div>
                            <h2 className="no-margin">Component Stack</h2>
                            <pre>{info && info.componentStack && info.componentStack.trim()}</pre>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

ErrorMessage.propTypes = {
    error: PropTypes.node,
    info: PropTypes.node,
    allowDetails: PropTypes.bool,
};

export default ErrorMessage;
