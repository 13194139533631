import React from 'react';
import { raiseEvent, ExtensionPoint } from '@infosight/shell-api/lib/core';
import { USER_TOUR_STATUS, SNOOZE_HOURS } from './constants';
import { isKnownMicroappId } from '../router/knownRoutes';
import { generateId } from '../extensibility/tour/actionCreators';

const isTourViewed = (tourUserProperties, tourId) => {
  if (!tourUserProperties || !tourUserProperties[tourId]) {
    return false;
  }
  const snoozeDate = new Date(tourUserProperties[tourId].timestamp);
  snoozeDate.setHours(snoozeDate.getHours() + SNOOZE_HOURS);

  const tourSnoozed =
    tourUserProperties[tourId].action === USER_TOUR_STATUS.SNOOZED &&
    new Date() < snoozeDate;

  return (
    tourSnoozed ||
    tourUserProperties[tourId].action === USER_TOUR_STATUS.COMPLETED
  );
};

const isTourHidden = (tourUserProperties, tourId) => {
  if (!tourUserProperties || !tourUserProperties[tourId]) {
    return false;
  }

  return tourUserProperties[tourId].action === USER_TOUR_STATUS.HIDDEN;
};

export const shouldOfferTour = ({
  tours,
  tourUserProperties,
  tourId: { appId, name },
}) => {
  const tourId = generateId(appId, name);
  const isKnownTour = !!(tours && tours[tourId]);
  return (
    isKnownTour &&
    !isTourViewed(tourUserProperties, tourId) &&
    !isTourHidden(tourUserProperties, tourId)
  );
};

const createLogger = () => {
  const logs = [];

  return {
    warn({ location, message }) {
      logs.push({ level: 'warning', location, message });
    },
    error({ location, message }) {
      logs.push({ level: 'error', location, message });
    },
    isValid() {
      return !logs.filter((x) => x.level === 'error').length;
    },
    getValidationMessage() {
      const errors = logs.filter((x) => x.level === 'error');
      let baseMessage = '';
      if (errors.length) {
        baseMessage = `Invalid tour config - ${errors.length} errors`;
      }
      return logs.reduce(
        (memo, { location, message, level }) =>
          `${memo}\n${level.toUpperCase()} [in ${location}]: ${message}`,
        baseMessage
      );
    },
  };
};

const validateStep = ({ step, Log, index }) => {
  if (!step) {
    return;
  }

  const location = `opts.shepherdOptions.steps[${index}]`;

  const { title, text, cancelIcon, attachTo, popperOptions } = step;
  if (!title) {
    Log.error({
      location,
      message: '"title" is required and must be a string',
    });
  } else if (typeof title !== 'string') {
    Log.error({
      location,
      message: '"title" is required and must be a string',
    });
  }

  if (
    !text ||
    (typeof text !== 'string' &&
      typeof text !== 'function' &&
      !React.isValidElement(text))
  ) {
    Log.error({
      location,
      message:
        '"text" is required and must be a string, React element (JSX), or a function that returns either of those two.',
    });
  }

  if (attachTo && attachTo.element && typeof attachTo.element !== 'string') {
    Log.error({
      location: `${location}.attachTo`,
      message:
        '"element" must be a string. Passing a HTMLElement is not supported.',
    });
  }

  if (cancelIcon) {
    Log.warn({ location, message: '"cancelIcon" is not supported.' });
  }
  if (popperOptions) {
    Log.warn({ location, message: '"popperOptions" is not supported.' });
  }
};

const validateShepherdOptions = ({ shepherdOptions, Log }) => {
  const location = 'opts.shepherdOptions';

  if (!shepherdOptions) {
    Log.error({ location, message: '"shepherdOptions" is required' });
    return;
  }

  const { steps, classPrefix, tourName, useModalOverlay } = shepherdOptions;

  if (!steps || !steps.length) {
    Log.error({ location, message: '"steps" is required' });
  } else {
    const { length } = steps.filter((x) => !!x);
    if (length < 3) {
      Log.error({
        location,
        message:
          '"steps" must have at least 3 steps for a proper user experience',
      });
    }

    steps.forEach((step, index) => validateStep({ step, Log, index }));
  }

  if (classPrefix) {
    Log.warn({ location, message: '"classPrefix" is not modifiable' });
  }
  if (tourName) {
    Log.warn({ location, message: '"tourName" is not modifiable' });
  }
  if (useModalOverlay !== undefined) {
    Log.warn({ location, message: '"useModalOverlay" is not modifiable' });
  }
};

export const validateTour = (opts) => {
  const Log = createLogger();

  const location = 'opts';
  if (!opts) {
    Log.error({ location, message: 'Read the documenation in shell-api' });
  }

  const { appId, name, version, shepherdOptions } = opts;

  if (!isKnownMicroappId(appId)) {
    Log.error({ location, message: '"appId" must be your microapp\'s ID' });
  }
  if (!name || typeof name !== 'string') {
    Log.error({
      location,
      message: '"name" must be a unique human-readable name for this tour',
    });
  }
  if (version && !Number.isInteger(version)) {
    Log.warn({ location, message: '"version" should be an integer' });
  }

  validateShepherdOptions({ shepherdOptions, Log });

  return {
    isValid: Log.isValid(),
    message: Log.getValidationMessage(),
  };
};

export const triggerTourStatusUpdated = () => {
  // "Protected" event used by Elmer's TourBeacon to react to changes in status to clear beacons
  raiseEvent(ExtensionPoint.Analytics, 'onTourStatusUpdated');
};
