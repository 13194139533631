import InfrastructurePages from './pageGenerator/InfrastructurePagesContainer';
import ObjectCollectionLink from './ObjectCollectionLink';
import ObjectDetailLink from './ObjectDetailLink';
import ObjectListPopover from './ObjectListPopover';
import ObjectIcon from './ObjectIcon';
import ObjectName from './ObjectName';
import ObjectBreadcrumbItem from './ScopeSelector/ObjectBreadcrumbItem';
import DefaultScopeSelectionPane from './ScopeSelector/DefaultScopeSelectionPane';
import { ScopeProvider, withScope } from './providers/ScopeProvider';
import { ProductInterfaceProvider, withProductInterface } from './providers/ProductInterfaceProvider';
import { VIEW_TYPES } from './constants';
import ProductInterface from './ProductInterface/ProductInterface';
import { initializeStore, initializeContext } from './store';
import SelectScopeMessage from './pageGenerator/SelectScopeMessage';
import RelatedObjects from './RelatedObjects/RelatedObjects';
import DefaultObjectDetailHeader from './pageGenerator/DefaultObjectDetailHeader';
import DefaultDetailPageTitle from './pageGenerator/DefaultDetailPageTitle';
import InfrastructureRouter from './pageGenerator/InfrastructureRouter';

export {
    InfrastructurePages,
    InfrastructureRouter,
    ObjectBreadcrumbItem,
    DefaultScopeSelectionPane,
    ObjectName,
    ObjectListPopover,
    ObjectCollectionLink,
    ObjectDetailLink,
    ScopeProvider,
    withScope,
    ProductInterface,
    ProductInterfaceProvider,
    withProductInterface,
    VIEW_TYPES,
    ObjectIcon,
    SelectScopeMessage,
    RelatedObjects,
    DefaultObjectDetailHeader,
    DefaultDetailPageTitle,
};

/**
 * This should be used by the Shell and the Shell only.
 * @protected
 *
 * Since Elmer-Infrastructure is an external dependency of both the Shell and Microapps, we need to give it special treatment rather than use Shell API for
 *     messaging. This ensures that all "instances" of Elmer-Infrastructure (whether it is built into a micro app or global) have synchronous access to the
 *     same data store. It also offers a clean boundary between the responsibilities of the Shell, Shell API, and Elmer-Infrastructure. In a previous
 *     iteration, this module used Shell API functions to asynchronously fetch and sync the "registry" from the Shell. However, there was some duplication of
 *     functionality such as ProductInterface instance lookup that would make it unclear to consumers.
 */
export default function initializeFromShell() {
    window.elmerInfrastructure = window.elmerInfrastructure || {};
    window.elmerInfrastructure.store = initializeStore();
    window.elmerInfrastructure.context = initializeContext();
}
