import React from 'react';
import PropTypes from 'prop-types';

/**
 * On hover, we want the font weight to be bold which causes surrounding elements to shift because the width increases.
 * This component renders the text twice. The first is visible and responds on hover.
 * The second is invisible but has 0 height to reserve horizontal space but not consume vertical space.
 *
 * This implementation leaves control of layout to the parent.
 * Since this is more of a technique than a common component, YMMV. See Reference implementation below.
 */
const StaticBold = ({ children, parent, ...props }) => React.createElement(parent, props, (
    <React.Fragment>
        {children}
        <div style={{ height: 0, fontWeight: 'bold', visibility: 'hidden' }}>{children}</div>
    </React.Fragment>
));

StaticBold.propTypes = {
    children: PropTypes.node.isRequired,
    parent: PropTypes.oneOfType([PropTypes.func, PropTypes.string, PropTypes.symbol]),
};

StaticBold.defaultProps = {
    parent: React.Fragment,
};

/**
 * Reference implementation where the parent doesn't control the layout of it's children.
 * The parent element should be display: flex, flex-dir: column. You set props.parent=React.Fragment to control this
 * from the consumer.
 */
export const ReferenceStaticBold = props => <StaticBold parent="div" className="flex-container flex-dir-column" {...props} />;
export default StaticBold;
