import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isFunction, isObject, compact } from 'underscore';
import { getEventType, handleEvent } from '@infosight/shell-api/lib/core';
import { extensionPointSelector } from '../selectors';

class ExtensionPoint extends Component {
  constructor(props) {
    super(props);
    if (typeof props.initialize === 'function') {
      props.initialize();
    }
  }

  componentDidMount() {
    this.registerCommands();
    this.registerQueries();
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  registerCommands() {
    const { commands, id } = this.props;
    if (!commands) {
      return;
    }

    const unsubscribers = Object.entries(commands).map(([event, listener]) => {
      if (!isFunction(listener)) {
        return undefined;
      }

      return handleEvent(id, event, (data, e) => {
        if (!e.detail) {
          e.preventDefault();
          return;
        }

        try {
          // Bind listener to this component instance to give the function access to this.props
          listener.call(this, data);
        } catch (err) {
          console.error(`Error handling event ${getEventType(id, event)}`, err);
        }
      });
    });

    this.addUnsubscribers(unsubscribers);
  }

  registerQueries() {
    const { queries, id } = this.props;

    if (!queries) {
      return;
    }

    const unsubscribers = Object.entries(queries).map(([event, listener]) => {
      // Although we expect all events to flow through shell-api, check anyway
      if (!isFunction(listener)) {
        return undefined;
      }

      const handler = async (data, e) => {
        if (!e.detail || !e.detail.callback) {
          e.preventDefault();
          console.error(
            `Extension Point: ${id}, Query: ${event} - Malformed request. Missing detail.callback function.`
          );
          return;
        }

        const { callback, attributes } = e.detail;
        try {
          // Bind listener to this component instance to give the function access to this.props
          const value = await listener.call(this, data, attributes);
          callback(null, value);
        } catch (err) {
          callback(err);
        }
      };
      return handleEvent(id, event, handler);
    });

    this.addUnsubscribers(unsubscribers);
  }

  addUnsubscribers(unsubscribers) {
    this.unsubscribers = this.unsubscribers || [];
    this.unsubscribers = [...this.unsubscribers, ...compact(unsubscribers)];
  }

  unsubscribe() {
    if (Array.isArray(this.unsubscribers)) {
      this.unsubscribers.forEach((unsubscribe) => {
        if (isFunction(unsubscribe)) {
          unsubscribe();
        }
      });
    }
  }

  render() {
    return null;
  }
}

ExtensionPoint.propTypes = {
  dispatch: PropTypes.func.isRequired, // eslint-disable-line react/no-unused-prop-types
  extension: PropTypes.object, // eslint-disable-line react/no-unused-prop-types
  mapAdditionalStateToProps: PropTypes.func, // eslint-disable-line react/no-unused-prop-types
  id: PropTypes.string.isRequired,
  commands: PropTypes.objectOf(PropTypes.func.isRequired),
  queries: PropTypes.objectOf(PropTypes.func.isRequired),
  initialize: PropTypes.func,
};

const mapStateToProps = (state, { id, mapAdditionalStateToProps }) => {
  const mappedProps = {
    extension: extensionPointSelector(state, id),
  };

  if (isFunction(mapAdditionalStateToProps)) {
    const requestedProps = mapAdditionalStateToProps(state);
    if (isObject(requestedProps)) {
      Object.assign(mappedProps, requestedProps);
    }
  }

  return mappedProps;
};

export default connect(mapStateToProps)(ExtensionPoint);
