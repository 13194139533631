import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import BEM from 'src/utils/bem';
import { preserveQueries as preserveQueriesFn } from 'src/components/Router';
import AnchoredBeacon from 'src/components/TourBeacon/AnchoredBeacon';

const bem = new BEM('elmer-NavBar-AdaptiveNode');

const AdaptiveNode = ({
    className,
    children,
    linkClassName,
    titleClassName,
    href,
    nodeType,
    preserveQueries,
    location,
    beacon,
    ...props
}) => {
    // eslint-disable-next-line react/prop-types
    console.assert(!props.linkType, 'The "linkType" prop has been replaced with "nodeType"');

    const content = () => {
        let type = nodeType;

        if (!type) {
            if (!href) {
                type = 'title';
            } else if (href.toLowerCase().startsWith('http')) {
                type = 'external';
            } else {
                type = 'internal';
            }
        }

        const classes = classnames(bem.valueOf(), className, {
            [linkClassName]: linkClassName && (href || type === 'function'),
            [titleClassName]: titleClassName && (type === 'title'),
        });

        const updatedHref = preserveQueriesFn(href, location, preserveQueries);

        if (type === 'manual') {
            return children;
        }

        if (type === 'title' || type === 'subtitle') {
            return <div {...props} className={classes}>{children}</div>;
        }

        if (type === 'external') {
            return (
                <a
                    {...props}
                    rel="noopener noreferrer"
                    target="_blank"
                    className={classnames(classes, bem.m('external'))}
                    href={updatedHref}
                >
                    {children}
                </a>
            );
        }

        if (type === 'plain') {
            return <a {...props} className={classes} href={updatedHref}>{children}</a>;
        }

        return <Link {...props} className={classes} to={updatedHref}>{children}</Link>;
    };

    if (beacon && beacon.enabled) {
        const { enabled, className: beaconClassName, ...beaconProps } = beacon;
        return (
            <AnchoredBeacon
                anchor="left"
                {...beaconProps}
                className={classnames(bem.e('Hack_StaticBold_Inside_BetaGrommetStack'), beaconClassName)}
            >
                {content()}
            </AnchoredBeacon>
        );
    }

    return content();
};

AdaptiveNode.propTypes = {
    children: PropTypes.node.isRequired,
    href: PropTypes.string,
    linkClassName: PropTypes.string,
    titleClassName: PropTypes.string,

    /**
     * Explicitly choose a link type, or leave empty to detect
     * external - Plain anchor styled as an external link
     * internal - React-Router link
     * plain - Plain anchor
     * title - Non-link plain text TODO rename to "text"
     * subtitle - Non-link plain text that's more of a group heading
     * manual - Render anything else you want
     */
    nodeType: PropTypes.oneOf(['external', 'internal', 'plain', 'title', 'function', 'subtitle', 'manual']),

    /**
     * Set of queries you want maintained when changing pages
     */
    preserveQueries: PropTypes.oneOfType([PropTypes.instanceOf(Set), PropTypes.arrayOf(PropTypes.string)]),
    location: PropTypes.shape({
        search: PropTypes.string,
    }),

    /**
     * Enable a pulsing beacon on this element that indicates there is something notable here, such as a feature tour.
     */
    beacon: PropTypes.shape({
        enabled: PropTypes.bool,

        onClick: PropTypes.func,

        /**
         * Point at which the beacon should be anchored to props.children
         * @default left
         */
        anchor: PropTypes.oneOf([
            'center',
            'left',
            'right',
            'top',
            'bottom',
            'top-left',
            'bottom-left',
            'top-right',
            'bottom-right',
        ]),
    }),
};

export default AdaptiveNode;
