import React from 'react';
import PropTypes from 'prop-types';

/**
 * Based on Grommet's sizes at https://github.com/grommet/grommet/blob/master/src/scss/grommet-core/_objects.icon.scss
 */
const BASE_SIZE_PIXELS = 24;
const SIZE_PRESETS = {
    xsmall: 0.5,
    small: 1,
    medium: 1.5,
    large: 2,
    xlarge: 6,
    huge: 12,
    nav: 18 / 24,
};

/**
 * Wrap an icon element to provide standard styling. Extend as needed to bring in icons from diff libraries
 */
const IconAdaptor = ({ children, size, type, adjustment, color }) => {
    const pixels = typeof size === 'number' ? size : (SIZE_PRESETS[size] || SIZE_PRESETS.small) * BASE_SIZE_PIXELS;
    let childProps;
    let parent;

    if (type === 'font-awesome') {
        childProps = { style: { fontSize: pixels } };
        if (color) {
            childProps.style.color = color;
            childProps = { ...childProps };
        }
    } else if (type === 'clarity' || type === 'svg') {
        childProps = { size: pixels };

        if (color) {
            childProps = { ...childProps, fill: color };
        }

        if (adjustment === 'baseline') {
            let margin = 0;
            switch (size) {
            case 'xsmall':
                margin = -4;
                break;
            case 'small':
                margin = -5;
                break;
            case 'medium':
                margin = -6;
                break;
            default:
                break;
            }

            childProps.style = { marginTop: `${margin}px` };
        }
    }

    const child = React.Children.only(children);
    const childElement = React.cloneElement(child, childProps);
    return parent ? React.createElement(parent.type, parent.props, [childElement]) : childElement;
};

IconAdaptor.propTypes = {
    size: PropTypes.oneOfType([
        PropTypes.oneOf(['xsmall', 'small', 'medium', 'large', 'xlarge', 'huge', 'nav']),
        PropTypes.number,
    ]).isRequired,

    /**
     * `svg` - SVG icons
     * `clarity` - [clarity-icons](https://vmware.github.io/clarity/icons/clarity-icons)
     * `font-awesome` (font-based) [font-awesome](http://fontawesome.io/icons/)
     * `grommet` - [grommet-icons](http://grommet.io/grommet-icons/)
     */
    type: PropTypes.oneOf(['font-awesome', 'clarity', 'svg', 'grommet']),

    /**
     * Adjustment needed to align the icon with other elements.
     * "baseline" vertically translate the icon to align with with the baseline of surrounding text.
     */
    adjustment: PropTypes.oneOf(['baseline']),

    color: PropTypes.string,
};

IconAdaptor.defaultProps = {
    size: 'small',
    type: 'svg',
};

export default IconAdaptor;
