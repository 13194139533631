import React from 'react';
import classnames from 'classnames/bind';
import { Box, Text } from 'grommet/es6';
import { useSelector } from 'react-redux';
import style from './NavMenu.scss';
import { activeTenantSelector } from '../../iam/reducer';

const cx = classnames.bind(style);

const NavMenuTenant = () => {
  const tenant = useSelector(activeTenantSelector);

  return (
    <Box className={cx('tenant')} align="center" direction="row">
      <Text margin={{ right: 'xsmall' }} size="xsmall" weight="normal">
        Organization:
      </Text>
      {tenant && <Text size="xsmall"> {tenant.name} </Text>}
    </Box>
  );
};

export default NavMenuTenant;
