import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { addMenu, removeMenu } from '@infosight/shell-api/lib/PrimaryNav';
import { AuthorizationEvaluator } from '../../../user';
import { isInternalUserSelector } from '../../../user/reducer';

const ProliantPrimaryNavExtension = () => {
  const isInternalUser = useSelector(isInternalUserSelector);
  useEffect(() => {
    const getResources = () => {
      const options = AuthorizationEvaluator.filter([
        {
          id: 'proliant', // Don't change the id properties without updating the nav sorting
          title: 'Servers',
          depth: 1,
          options: AuthorizationEvaluator.filter([
            {
              // Enable only for everyone
              id: 'openSupportCase',
              title: 'Support',
              url: 'https://support.hpe.com/SCM/SCMEntry.aspx',
              nodeType: 'external',
            },
            {
              // Enable only for everyone
              id: 'gettingstartedguide',
              title: 'Getting Started',
              url: 'http://www.hpe.com/support/InfoSight-for-Servers-GSG-en',
              nodeType: 'external',
            },
            {
              // Enable only for everyone
              id: 'connect',
              title: 'Documentation',
              url: 'http://www.hpe.com/support/infosight-servers-docs',
              nodeType: 'external',
            },
            {
              // Enable only for everyone
              id: 'proliantCustomerTraining',
              title: 'Training',
              nodeType: 'external',
              url: 'http://www.hpe.com/ww/training/getcds?course=h8pf1aae',
            },
            {
              // Enable only for everyone
              id: 'softwarecenter',
              title: 'Download SPP',
              url: 'http://www.hpe.com/servers/spp/download',
              nodeType: 'external',
            },
            {
              id: 'proliantyammer',
              title: 'Yammer',
              nodeType: 'external',
              url:
                'https://www.yammer.com/hpe.com/#/threads/inGroup?type=in_group&feedId=17096503&view=all',
              access: {
                // Enable only for Employees
                custom: () => isInternalUser,
              },
            },
            {
              id: 'proliantSupportTraining',
              title: 'Support Training',
              nodeType: 'external',
              url:
                'https://content.ext.hpe.com/sites/LMS/LMS.html?deeplink=/Saba/Web_spf/HPE/common/ledetail/01122192',
              access: {
                // Enable only for Employees
                custom: () => isInternalUser,
              },
            },
            {
              id: 'proliantTroubleShootingGuide',
              title: 'Troubleshooting Guide',
              nodeType: 'external',
              url:
                'https://internal.support.hpe.com/hpesc/docDisplay?docId=a00110273en_us',
              access: {
                // Enable only for Employees
                custom: () => isInternalUser,
              },
            },
          ]),
        },
      ]);

      if (!options.length) {
        return null;
      }

      return options.length ? options : null;
    };

    // Everything is always here so we can react to changes
    const extension = {
      menuId: 'resources',
      id: 'proliant',
      content: getResources(),
    };
    if (extension.content) {
      addMenu(extension);
    } else {
      removeMenu(extension);
    }
  }, [isInternalUser]);

  return null;
};

export default ProliantPrimaryNavExtension;
