import React, { Component } from 'react';
import PropTypes from 'prop-types';
import autobind from 'react-autobind';
import { withQueryParams, buildTargetLocation } from 'src/components/Router';
import Toggle from './Toggle';

/* * Why not write AND read the value from the URL?
 * - If there is more than one instance that needs to set it's default value, it would require a lot of code
 * to consistently update and react to the URL. IE, if you return multiple Redirect (react-router.Redirect) in the
 * render tree, the last one wins.
 * - You have to deserialize the value to use it somewhere. Why do that work twice when you can just pass the value in?
 * - Separation of concerns: All this does it provide a `props.onChange` to Toggle to update the URL.
 * Setting default values and parsing the URL can be done at a "smarter" level where they belong.
 * */

/**
 * Wraps [Toggle](#toggle) to emit values to URL Query.
 * All extra props are passed to the wrapped `Toggle`
 *
 * Parse the query parameters using the `deserialize` export anywhere in the component tree and pass that value
 * to the component to set the current value.
 */
class QueryToggle extends Component {
    constructor(props) {
        super(props);
        autobind(this);
    }

    onChange(value) {
        const { location, parameter, exact } = this.props;
        const nextLocation = buildTargetLocation({
            location,
            exact,
            to: { search: { [parameter]: value } },
        });

        this.props.history.replace(nextLocation);
    }

    render() {
        const {
            match,
            location,
            history,
            parameter,
            exact,
            ...toggleProps
        } = this.props;
        return <Toggle {...toggleProps} onChange={this.onChange} />;
    }
}

QueryToggle.propTypes = {
    parameter: PropTypes.string.isRequired,
    exact: PropTypes.bool,

    // Injected by withQueryParams
    history: PropTypes.object,
    location: PropTypes.object,
    match: PropTypes.object,
};

QueryToggle.defaultProps = {
    exact: false,
};

export default withQueryParams(QueryToggle);
