import { fetchInventory } from '../inventory/actionCreators';
import { inventorySelector } from '../inventory/reducer';

const refreshUserContext = () => (dispatch, getState) => {
  const state = getState();

  const { loadingInventory, loadedInventory } = inventorySelector(state);
  if (!loadingInventory && !loadedInventory) {
    dispatch(fetchInventory());
  }
};

export default refreshUserContext;
