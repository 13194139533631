import React from 'react';
import PropTypes from 'prop-types';
import { microappTitles } from '../router/knownRoutes';

const microappTitle = (microapp) =>
  microappTitles[microapp] ? microappTitles[microapp] : microapp;

const MaintenanceBanner = ({ microapps }) => (
  <>
    The following InfoSight sections are currently undergoing maintenance:{' '}
    <strong>
      {microapps.map((microapp) => microappTitle(microapp)).join(', ')}
    </strong>
  </>
);

MaintenanceBanner.propTypes = {
  microapps: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default MaintenanceBanner;
