import React, { Component } from 'react';
import PropTypes from 'prop-types';
import autobind from 'react-autobind';
import getDisplayName from 'recompose/getDisplayName';
import wrapDisplayName from 'recompose/wrapDisplayName';

/**
 * Table which handles Sorting, Filtering and Pagination on Client (Typically using react-table itself)
 * @param {ReactNode} TableComponent
 */
const createClientControlledDataProvider = (TableComponent) => {
    class ClientControlledTable extends Component {
        constructor(props) {
            super(props);
            autobind(this);
        }

        render() {
            const { filtered, sorted, dataOffset, page, pageSize } = this.props;
            return (
                <TableComponent
                    {...this.props}
                    filtered={filtered}
                    sorted={sorted}
                    dataOffset={dataOffset}
                    page={page}
                    pageSize={pageSize}
                    clientSide
                />
            );
        }
    }

    ClientControlledTable.displayName = wrapDisplayName(
        TableComponent,
        getDisplayName(ClientControlledTable)
    );

    ClientControlledTable.propTypes = {
        tableId: PropTypes.string.isRequired,
        data: PropTypes.arrayOf(PropTypes.object),
        sorted: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.string,
            desc: PropTypes.bool,
        })),
        filtered: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.string,
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.arrayOf(PropTypes.string)]),
        })),
        dataOffset: PropTypes.number,
        page: PropTypes.number,
        pageSize: PropTypes.number,
        clientSide: PropTypes.bool,
    };

    return ClientControlledTable;
};

export default createClientControlledDataProvider;
