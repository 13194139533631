import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import autobind from 'react-autobind';
import { ExtensionPoint } from '@infosight/shell-api/lib/core';
import { addMenu, removeMenu } from '@infosight/shell-api/lib/PrimaryNav';
import { onUserContextChange } from '@infosight/shell-api/lib/UserProfile';
import { extensionPointSelector } from '../../extensibility';

class PrimaryNavExtension extends Component {
  constructor(props) {
    super(props);
    autobind(this);
  }

  componentDidMount() {
    this.handleUpdate();
    this.unsubscribe = onUserContextChange(this.handleUpdate);
  }

  /**
   * TODO This will go away when SearchManager does
   * @param prevProps
   */
  componentDidUpdate(prevProps) {
    if (prevProps.subjectConfigs !== this.props.subjectConfigs) {
      this.handleUpdate();
    }
  }

  componentWillUnmount() {
    if (this.unsubscribe) {
      this.unsubscribe();
    }
  }

  handleUpdate() {
    const hasSearch = !!Object.values(this.props.subjectConfigs).filter(
      (x) => x
    ).length;

    if (hasSearch) {
      addMenu({ menuId: 'search', id: 'enabled', content: true });
    } else {
      removeMenu({ menuId: 'search', id: 'enabled', content: false });
    }
  }

  render() {
    return null;
  }
}

PrimaryNavExtension.propTypes = {
  subjectConfigs: PropTypes.objectOf(PropTypes.object),
};

const mapStateToProps = (state) => ({
  subjectConfigs: extensionPointSelector(state, ExtensionPoint.Search),
});
export default connect(mapStateToProps)(PrimaryNavExtension);
