import React from 'react';
import PropTypes from 'prop-types';
import { Stack } from 'grommet';
import Beacon from './Beacon';
import { anchorOffsets } from './style';

/**
 * Attach a beacon to some element with the proper placement
 */
const AnchoredBeacon = ({ children, anchor, onClick, offset, ...stackProps }) => {
    let localAnchor = anchor;
    if (!anchorOffsets[anchor]) {
        localAnchor = 'top-left';
    }

    if (typeof onClick !== 'function') {
        console.warn('AnchoredBeacon.props.onClick must be a function. No beacon will be rendered');
        return children;
    }
    return (
        <Stack anchor={localAnchor} {...stackProps}>
            {children}
            <Beacon onClick={onClick} anchor={localAnchor} offset={offset} />
        </Stack>
    );
};

AnchoredBeacon.propTypes = {
    children: PropTypes.node.isRequired,

    /**
     * @param {Event} event
     */
    onClick: PropTypes.func.isRequired,

    /**
     * Grommet.Stack anchors
     */
    anchor: PropTypes.oneOf(Object.keys(anchorOffsets)),
    offset: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
};

AnchoredBeacon.defaultProps = {
    anchor: 'top-left',
    offset: 'small',
};

export default AnchoredBeacon;
