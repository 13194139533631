import React from 'react';
import PropTypes from 'prop-types';
import BEM from 'src/utils/bem';

const bem = new BEM('elmer-GlobalBanner');

const Location = ({ visibleIndex, count }) => (
    <span className={bem.e('location')}>{visibleIndex + 1} / {count}</span>
);

Location.propTypes = {
    visibleIndex: PropTypes.number,
    count: PropTypes.number,
};

export default Location;
