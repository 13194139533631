import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import autobind from 'react-autobind';
import { Menu } from '@infosight/elmer/dist/components/NavBar';
import { Spinner } from '@infosight/elmer/dist/components/Spinner';
import { IconAdaptor } from '@infosight/elmer/dist/components/IconAdaptor';
import { compact, debounce, flatten } from 'underscore';
import classnames from 'classnames/bind';
import { Text } from 'grommet';
import { FaExclamationTriangle } from 'react-icons/fa';
import {
  fetchCurrentUserTenants,
  fetchOfficialDemoTenants,
  fetchRecentTenants,
  resetTenantSelector,
  searchTenants,
} from '../actionCreators';
import {
  activeTenantSelector,
  currentUserTenantsSelector,
  officialDemoTenantsSelector,
  primaryTenantSelector,
  recentTenantsSelector,
  tenantSearchHitsSelector,
  tenantSelectorVisibilitySelector,
} from '../reducer';
import { getRootUrlForTenant } from '../services';
import TenantMenuItem from './TenantMenuItem';
import style from './TenantSelector.scss';
import SearchQueryMenuItem from './SearchQueryMenuItem';
import Analytics from '../../user/services/analytics';

const cx = classnames.bind(style);

// This is the maximum menu items to be shown
const VISIBLE_LIST_COUNT = 10;

/**
 * Goal: Whenever the tenant set changes, track the utilization of the description field of the delta.
 * - If only one changes, which is the most common case, only report the counts for that prop that changed
 * - We are not concerned about how many times the tenant selector is opened, but how many of the results have a description.
 * - If there are no changes, return null, since we don't want to track "nothing"
 */
export const getTenantsWithDescriptionViewedEventAttributes = (
  prevProps,
  currentProps
) => {
  const [orgsCount, orgsWithDescriptionCount] = [
    'tenantSearchHits',
    'currentUserTenants',
    'recentTenants',
  ].reduce(
    (memo, prop) => {
      if (
        prevProps[prop] !== currentProps[prop] &&
        currentProps[prop] &&
        currentProps[prop].length
      ) {
        // eslint-disable-next-line no-return-assign
        return [
          (memo[0] += currentProps[prop].length),
          (memo[1] += currentProps[prop].filter((x) => !!x.description).length),
        ];
      }
      return memo;
    },
    [0, 0]
  );
  return orgsCount ? { orgsCount, orgsWithDescriptionCount } : null;
};

/**
 * This is a funny-looking function to make unit testing the important part easy
 */
const trackTenantsWithDescriptionViewedEvent = (prevProps, currentProps) => {
  const attributes = getTenantsWithDescriptionViewedEventAttributes(
    prevProps,
    currentProps
  );
  if (attributes) {
    Analytics.trackEvent(
      'shell.View Organization Selector Results',
      attributes
    );
  }
};

export class TenantSelector extends Component {
  constructor(props) {
    super(props);
    autobind(this);
    this.searchRef = React.createRef();
    this.state = {
      tenantSearchQuery: '',
      // windowHeight: window.innerHeight,
    };

    // 400ms chosen based on doherty threshold https://lawsofux.com/doherty-threshold
    this.debouncedSearch = debounce(this.doSearch, 400);
  }

  componentDidMount() {
    if (
      this.props.showRecentTenants &&
      !this.props.loadedRecentTenants &&
      this.props.fetchRecentTenants
    ) {
      this.props.fetchRecentTenants();
    }

    if (
      this.props.showCurrentUserTenants &&
      !this.props.loadedCurrentUserTenants &&
      this.props.fetchCurrentUserTenants
    ) {
      this.props.fetchCurrentUserTenants();
    }

    if (
      this.props.showOfficialDemoTenants &&
      !this.props.loadedOfficialDemoTenants &&
      this.props.fetchOfficialDemoTenants
    ) {
      this.props.fetchOfficialDemoTenants();
    }
  }

  /**
   * This is a function passed down by react-popper that fixes the layout issue.
   * Before: Menu opens up and is possibly outside of the viewport (exacerbated by IE11)
   * After: Menu quickly (generally imperceptibly) updates the layout.
   */
  componentDidUpdate(prevProps) {
    if (this.props.enabled && typeof this.props.scheduleUpdate === 'function') {
      this.props.scheduleUpdate();
    }

    trackTenantsWithDescriptionViewedEvent(prevProps, this.props);
  }

  componentWillUnmount() {
    this.props.resetTenantSelector();
  }

  /**
   * When the user has more than 10 tenants, we show the most recently used
   */
  getRecentTenantsOptions() {
    const {
      recentTenants,
      loadingRecentTenants,
      loadingOfficialDemoTenants,
    } = this.props;

    const tenants = loadingOfficialDemoTenants
      ? []
      : this.removeOfficialDemoTenants(recentTenants);

    return this.getTenantOptions(
      tenants,
      loadingRecentTenants || loadingOfficialDemoTenants,
      'Recent Organizations',
      'recentOrganizations'
    );
  }

  /**
   * When the user has under 10 tenants, we show all tenants
   */
  getCurrentUserTenantsOptions() {
    const { currentUserTenants, loadingCurrentUserTenants } = this.props;

    const tenants = this.removeOfficialDemoTenants(currentUserTenants);

    return this.getTenantOptions(
      tenants,
      loadingCurrentUserTenants,
      'My Organizations',
      'myOrganizations'
    );
  }

  getOfficialDemoTenantOptions() {
    const {
      officialDemoTenants,
      loadingOfficialDemoTenants,
      loadingRecentTenants,
    } = this.props;

    if (
      loadingOfficialDemoTenants ||
      loadingRecentTenants ||
      !officialDemoTenants
    ) {
      return null;
    }

    const menuItem = this.getTenantOptions(
      officialDemoTenants,
      loadingOfficialDemoTenants,
      officialDemoTenants.length > 1 ? 'OfficialDemoTenants' : '',
      'officialDemoTenants'
    );

    return menuItem;
  }

  /* eslint-disable class-methods-use-this */
  getViewAllMyOrgs() {
    const { setShowMyOrgsModal } = this.props;
    /* eslint-disable jsx-a11y/anchor-is-valid */
    return {
      id: 'viewAllMyOrgs',
      title: (
        <a
          className="elmer-NavBar-AdaptiveNode elmer-NavBar-Menu__item elmer-NavBar-Menu__item--link" // TODO Bad, but Ill fix it later
          href="javascript:void(0)" // eslint-disable-line no-script-url
          onClick={() => setShowMyOrgsModal(true)}
        >
          Browse my organizations
        </a>
      ),
      nodeType: 'manual',
    };
    /* eslint-enable jsx-a11y/anchor-is-valid */
  }

  getSearchAllMyOrgs() {
    const { setShowMyOrgsModal, setPassedSearchQuery } = this.props;
    const { tenantSearchQuery } = this.state;
    /* eslint-disable jsx-a11y/anchor-is-valid */
    return {
      id: 'searchAllMyOrgs',
      title: (
        <a
          className="elmer-NavBar-AdaptiveNode elmer-NavBar-Menu__item elmer-NavBar-Menu__item--link" // TODO Bad, but Ill fix it later
          href="javascript:void(0)" // eslint-disable-line no-script-url
          onClick={() => {
            setShowMyOrgsModal(true);
            setPassedSearchQuery(tenantSearchQuery);
          }}
        >
          Show all search results
        </a>
      ),
      nodeType: 'manual',
    };
    /* eslint-enable jsx-a11y/anchor-is-valid */
  }
  /* eslint-enable class-methods-use-this */

  /**
   * Generates the menu options needed for links to change the tenant
   */
  getTenantOptions(tenants, loading, title, menuId) {
    const { primaryTenant } = this.props;
    const isPrimaryTenant = (id) =>
      !!(primaryTenant && primaryTenant.id === id);

    const options = compact(
      flatten(
        tenants &&
          tenants.slice(0, VISIBLE_LIST_COUNT).map((tenant) => {
            const { id } = tenant;
            if (isPrimaryTenant(id)) {
              return null;
            }

            return {
              id,
              title: <TenantMenuItem tenant={tenant} />,
              url: getRootUrlForTenant(id),
              nodeType: 'internal',
            };
          })
      )
    );

    if (primaryTenant) {
      options.unshift({
        id: primaryTenant.id,
        title: <TenantMenuItem tenant={primaryTenant} />,
        url: getRootUrlForTenant(primaryTenant.id),
        nodeType: 'internal',
      });
    }

    if (!options.length) {
      options.push({ id: 'placeholder', nodeType: 'manual', title: <i /> });
    }

    return {
      title: !loading ? (
        title
      ) : (
        <span
          className="flex-container align-justify"
          style={{ fontWeight: 'inherit' }}
        >
          <span>{title}</span>
          <Spinner size="small" color="white" />
        </span>
      ),
      options,
      id: menuId,
      nodeType: 'title',
      depth: 1,
      collapseHeader: false,
    };
  }

  /**
   * Generates the menu options that show show search results and links to switch the tenant
   */
  getSearchResultsOptions() {
    const { tenantSearchHits } = this.props;

    let results = null;
    const visibleResults =
      tenantSearchHits && tenantSearchHits.slice(0, VISIBLE_LIST_COUNT);

    if (visibleResults && visibleResults.length) {
      results = [
        {
          id: 'search-results-count',
          nodeType: 'manual',
          title: (
            <Text className={cx('search-results-special')} truncate>
              Search Results ({tenantSearchHits.length})
            </Text>
          ),
        },
        ...visibleResults.map((tenant) => ({
          id: tenant.id,
          title: <TenantMenuItem tenant={tenant} />,
          url: getRootUrlForTenant(tenant.id),
          nodeType: 'internal',
        })),
      ];
    }

    return results || [];
  }

  /**
   * Generates the menu options that show show search results and links to switch the tenant
   * OR an error message of some sort.
   */
  getSearchOptions() {
    const { tenantSearchHits, errorTenantSearchHits } = this.props;

    let results = null;
    if (errorTenantSearchHits) {
      results = [
        {
          id: 'error',
          title: (
            <span className={cx('error')}>
              <IconAdaptor color="#f04953">
                <FaExclamationTriangle />
              </IconAdaptor>{' '}
              Error searching organizations.
            </span>
          ),
          nodeType: 'title',
        },
      ];
    } else if (tenantSearchHits) {
      results = tenantSearchHits.length
        ? this.getSearchResultsOptions()
        : [
            {
              id: 'no-results',
              nodeType: 'manual',
              title: (
                <Text className={cx('search-results-special')} truncate>
                  No organizations found.
                </Text>
              ),
            },
          ];
    }

    return {
      depth: 1,
      id: 'search-tenants-results',
      title: '',
      nodeType: 'title',
      divider: true,
      containerClassName: cx('search-menu'),
      collapseHeader: false,
      options: results || [],
    };
  }

  getSearchBar() {
    const { loadingTenantSearchHits } = this.props;

    return {
      id: 'search',
      nodeType: 'manual',
      title: (
        <SearchQueryMenuItem
          loadingTenantSearchHits={loadingTenantSearchHits}
          onChange={this.searchChange}
          onKeyPress={this.keyPress}
          searchRef={this.searchRef}
        />
      ),
    };
  }

  removeOfficialDemoTenants(tenants) {
    const {
      officialDemoTenants,
      loadingOfficialDemoTenants,
      showOfficialDemoTenants,
    } = this.props;

    // Take care of no-op conditions
    if (
      !showOfficialDemoTenants ||
      loadingOfficialDemoTenants ||
      !tenants ||
      !officialDemoTenants
    ) {
      return tenants;
    }

    // remove any of the official demo tenants that are in the list.
    const tenantsFiltered = tenants.filter(
      (tenant) =>
        !officialDemoTenants.some((official) => official.id === tenant.id)
    );

    return tenantsFiltered;
  }

  searchChange(e) {
    const query = e.target.value.trim();
    this.setState(
      {
        tenantSearchQuery: query,
        showingSearchResults: query.length > 0,
      },
      () => this.debouncedSearch()
    );
  }

  keyPress(e) {
    if (e.key === 'Enter') {
      this.doSearch();
    }
  }

  doSearch() {
    const query = this.state.tenantSearchQuery;
    this.props.searchTenants({ query });
  }

  render() {
    const {
      showRecentTenants,
      showSearch,
      showMyOrgs,
      showCurrentUserTenants,
      showOfficialDemoTenants,
      closeMenu,
      tenantSearchHits,
      errorTenantSearchHits,
    } = this.props;
    const { showingSearchResults, tenantSearchQuery } = this.state;

    const options = [
      showSearch && this.getSearchBar(),
      showSearch &&
        (tenantSearchHits || errorTenantSearchHits) &&
        this.getSearchOptions(),
      ...(showingSearchResults
        ? []
        : [
            showRecentTenants && this.getRecentTenantsOptions(),
            showCurrentUserTenants && this.getCurrentUserTenantsOptions(),
            showOfficialDemoTenants && this.getOfficialDemoTenantOptions(),
          ]),
      showSearch && showMyOrgs && !tenantSearchQuery && this.getViewAllMyOrgs(),
      showSearch && tenantSearchQuery && this.getSearchAllMyOrgs(),
    ].filter((e) => e);

    return (
      <Menu
        className={cx('tenant-selector-dropdown')}
        options={options}
        style={{
          minWidth: '400px',
          // minHeight: `${this.calculateMenuHeight()}px`,
        }}
        closeMenu={closeMenu}
      />
    );
  }
}

const TenantPropType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  claims: PropTypes.arrayOf(
    PropTypes.shape({ urn: PropTypes.string.isRequired }).isRequired
  ),
});

TenantSelector.propTypes = {
  // Recent Tenants
  recentTenants: PropTypes.arrayOf(TenantPropType),
  loadedRecentTenants: PropTypes.bool,
  loadingRecentTenants: PropTypes.bool,
  fetchRecentTenants: PropTypes.func,

  // All Tenants
  currentUserTenants: PropTypes.arrayOf(TenantPropType),
  loadedCurrentUserTenants: PropTypes.bool,
  loadingCurrentUserTenants: PropTypes.bool,
  fetchCurrentUserTenants: PropTypes.func,

  // Official Demo Tenants
  officialDemoTenants: PropTypes.arrayOf(TenantPropType),
  loadedOfficialDemoTenants: PropTypes.bool,
  loadingOfficialDemoTenants: PropTypes.bool,
  fetchOfficialDemoTenants: PropTypes.func,

  // Search
  searchTenants: PropTypes.func,
  tenantSearchHits: PropTypes.arrayOf(TenantPropType),
  loadingTenantSearchHits: PropTypes.bool,
  errorTenantSearchHits: PropTypes.bool,

  // General
  resetTenantSelector: PropTypes.func,
  setShowMyOrgsModal: PropTypes.func,
  setPassedSearchQuery: PropTypes.func,
  primaryTenant: PropTypes.shape(TenantPropType),
  showSearch: PropTypes.bool,
  showMyOrgs: PropTypes.bool,
  showRecentTenants: PropTypes.bool,
  showCurrentUserTenants: PropTypes.bool,
  showOfficialDemoTenants: PropTypes.bool,
  enabled: PropTypes.bool,
  scheduleUpdate: PropTypes.func,
  closeMenu: PropTypes.func,
};

const mapStateToProps = (state) => ({
  ...recentTenantsSelector(state),
  ...tenantSearchHitsSelector(state),
  ...tenantSelectorVisibilitySelector(state),
  ...currentUserTenantsSelector(state),
  ...officialDemoTenantsSelector(state),
  activeTenant: activeTenantSelector(state),
  primaryTenant: primaryTenantSelector(state),
});

export default connect(mapStateToProps, {
  fetchRecentTenants,
  searchTenants,
  resetTenantSelector,
  fetchCurrentUserTenants,
  fetchOfficialDemoTenants,
})(TenantSelector);
