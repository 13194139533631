import classnames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import BEM from 'src/utils/bem';

const bem = new BEM('elmer-Spinner');

export default function Spinner({ className, style, size, color }) {
    const contentStyle = color ? { backgroundColor: color } : undefined;
    return (
        <div className={classnames(className, bem.m(size))} style={style}>
            <div className={bem.m('bounce1')} style={contentStyle} />
            <div className={bem.m('bounce2')} style={contentStyle} />
            <div className={bem.m('bounce3')} style={contentStyle} />
        </div>
    );
}

Spinner.propTypes = {
    /**
     * default - Use when loaading page or section content
     * small - Use for inline spinners with text or form inputs, such as search bars
     */
    size: PropTypes.oneOf(['default', 'small']),

    /**
     * Override the color. Useful with small spinners where you want the spinner to look like the surrounding content
     */
    color: PropTypes.string,
};

Spinner.defaultProps = {
    size: 'default',
};
