import React from 'react';
import { omit } from 'underscore';
import { ColumnHeader } from '../header';
import { TextInputFilter } from '../filters';


const addHeader = (column) => {
    const finalColumn = omit(column, 'sortable', 'filterable', 'Filter');
    const isSortable = column.sortable === undefined ? true : column.sortable;

    // Add default TextInputFilter if 'filterable' is set to true, but no Filter Component is not available
    const addDefaultFilterComponent = column.filterable && !column.Filter;
    
    if (typeof column.Header !== 'string' && !column.title) {
        console.warn(column.accessor, ' is missing title attribute in the configuration, filter tags will not work properly');
    }

    if (column.accessor && typeof column.accessor !== 'string' && !column.id) {
        console.error(column.Header, 'should have an id as its accessor is not a string');
    }
    
    finalColumn.Header = React.createElement(ColumnHeader, {
        title: column.title,
        HeaderComponent: column.Header,
        id: typeof column.accessor === 'string' ? column.accessor : column.id,
        toolTip: column.toolTip,
        headerClassName: column.headerClassName,
        enableWrapping: column.enableWrapping,
        isSortable,
        FilterComponent: addDefaultFilterComponent ? React.createElement(TextInputFilter) : column.Filter,
    });

    return finalColumn;
};

/**
 * If the Header is string, style it to add Filter and Sort.
 * If the Header is a Node, respect whatever the Header is set to be
 * @param {[{column}]} columns - Array of {column} configuration objects
 * @param column.Header {string | Node } Header of the Column
 * @param column.filterable {boolean} - if Set to true, show the Filter Icon in the Header
 * @param column.sortable {boolean} - if set to true or undefined, show the Sort Icon in the Header
 * @param column.Filter {Node} Component to show in when Filter Popup is clicked
 * @param column.toolTip {string} Tooltip of the Column Header
 *
 * @param fieldSet - {string} Current category of columns to show
 */
const transformColumnConfig = (columns, fieldSet) => {
    if (!columns) {
        return [];
    }

    return columns.map((column) => {
        const { categories, columns: nestedColumns } = column;
        const newColumn = { ...column };

        // Is this column a group header
        if (nestedColumns) {
            /* For convenience and brevity , Categories are set at Header Group level in Column Configuration,
            but react-table expects show/hide attribute at column level, so copy categories to nested columns */
            const nestedColumnsWithCategories = nestedColumns.map(nestedColumn => ({ ...nestedColumn, categories }));

            // Recursively transform the column config of the nested columns
            // Right now: react-table only can have one level of nested columns
            newColumn.columns = transformColumnConfig(nestedColumnsWithCategories, fieldSet);

            // Group headers are not sortable
            newColumn.sortable = false;
        }


        // Override the Column Visibility only if the Consumer didn't set it explicitly
        if (newColumn.show === undefined) {
            // Need to transform the categories to handle stuff coming both from config and manually-entered tab IDs
            const transformedCategories = categories && categories.map(category => (typeof category === 'object' ? category.value : category));

            /*
              Do we need to show this column ?
              Yes - a. If there is no field set for this Table at all
                    b. Or if categories is not set on this column which means it belongs to all Categories
                    c. or if the fieldSet (category selected) is one of the column's categories
            */
            if (!fieldSet || !categories || transformedCategories.includes(fieldSet)) {
                // Mutating column to add visibility attribute
                newColumn.show = true;
            } else {
                // Mutating column to add visibility attribute
                newColumn.show = false;
                // No need to transform/build header if this column is hidden
                return newColumn;
            }
        }
        
        return addHeader(newColumn);
    });
};

export { transformColumnConfig, addHeader };
