import {
  logOut,
  UserContextChangeReason,
} from '@infosight/shell-api/lib/UserProfile';
import { command, ExtensionPoint } from '@infosight/shell-api/lib/core';
import { actionsFor } from '@infosight/elmer/dist/utils/redux';
import Request from '../services/Request';
import { EXPERIMENTAL, TOURTOOL, FETCH_BOOTSTRAP } from './constants';

// formerly this was called buildInitialStateTree
export function processBootstrapResponse({ user, permissions }) {
  const state = { user: user || {} };
  state.user.permissions = permissions;
  return state;
}

export function fetchBootstrap() {
  // eslint-disable-line import/prefer-default-export
  return (dispatch) => {
    const { dispatchStart, dispatchSuccess, dispatchError } = actionsFor(
      FETCH_BOOTSTRAP,
      dispatch
    );
    dispatchStart();

    new Request()
      .central()
      .slash('bootstrap', 'v2')
      .get()
      .then((response) => {
        dispatchSuccess(processBootstrapResponse(response.data));
        command(
          ExtensionPoint.UserProfile,
          'userContextChange',
          UserContextChangeReason.Bootstrap
        );
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          logOut();
          return;
        }

        dispatchError(error);
        console.error(error);
      });
  };
}

export function loadExperimental() {
  return (dispatch) => {
    if (localStorage) {
      try {
        const experimental =
          localStorage.getItem('experimental') &&
          JSON.parse(localStorage.getItem('experimental'));
        dispatch({ type: EXPERIMENTAL, payload: !!experimental });
      } catch (err) {
        console.error(err);
      }
    }
  };
}

export function loadTourTool() {
  return (dispatch) => {
    if (localStorage) {
      try {
        const tourTool =
          localStorage.getItem('shell:devtool:tours') &&
          JSON.parse(localStorage.getItem('shell:devtool:tours'));
        dispatch({ type: TOURTOOL, payload: !!tourTool });
      } catch (err) {
        console.error(err);
      }
    }
  };
}
