import { buildUrl } from '@infosight/elmer/dist/utils/url';

export const getRootUrlForTenant = (id) => `/org/${id}`;

/**
 * Parse a legacy tenant URN or tenant ID into a tenant ID
 * @param {string} tenantIdOrUrn
 * @returns {string|undefined}
 */
export const parseTenantIdentifier = (tenantIdOrUrn) => {
  if (!tenantIdOrUrn) {
    return undefined;
  }

  const [prefix, type, id] = decodeURIComponent(tenantIdOrUrn).split(':');
  // Legacy URL format with `urn:infosight:00f1f80d-51e6-454e-a0bb-e74343b2acec`
  if (prefix === 'urn' && type && id) {
    return id;
  }
  if (prefix && !type && !id) {
    return prefix;
  }

  return undefined;
};

export const buildTenantScopedUrl = ({ id }, ...rest) =>
  buildUrl(getRootUrlForTenant(id), ...rest);

export const convertSessionToTenant = (session) =>
  session && session.tenantId
    ? {
        id: session.tenantId,
        name: session.tenantName,
      }
    : null;
