import React, { Component } from 'react';
import PropTypes from 'prop-types';
import autobind from 'react-autobind';
import classnames from 'classnames/bind';
import { trackEvent } from '@infosight/shell-api/lib/Analytics'; // eslint-disable-line
import { debounce } from 'underscore';
import Spinner from '@infosight/elmer/dist/components/Spinner';
import { IconAdaptor } from '@infosight/elmer/dist/components/IconAdaptor';
import { FaSearch } from 'react-icons/fa';
import { connect } from 'react-redux';
import { isLoadingSelector } from '../reducer';
import { iamSessionSelector } from '../../iam/reducer';
import { search, resetSearch } from '../actionCreators';
import Hits from './Hits';
import style from './style.scss';

const cx = classnames.bind(style);

class Search extends Component {
  constructor(props) {
    super(props);
    autobind(this);
    this.state = {
      expandedTopic: null,
      query: '',
      sentEvent: false,
    };
    this.search = debounce(this.props.search, 300);
  }

  componentDidUpdate() {
    if (typeof this.props.scheduleUpdate === 'function') {
      this.props.scheduleUpdate();
    }
  }

  componentWillUnmount() {
    this.props.resetSearch();
  }

  onChangeQuery(e) {
    this.setState({ query: e.target.value.trim() }, () => {
      // we don't auto search on 1 or 2 characters to avoid spamming the user with results
      // we still auto search on 0 to clear the search when someone deletes the value
      if (this.state.query.length > 2 || this.state.query.length === 0) {
        this.submit();
      }
    });
  }

  onEnter(e) {
    if (e.key === 'Enter') {
      this.submit();
    }
  }

  submit() {
    if (!this.state.sentEvent) {
      const { userName, userId } = this.props.session;
      trackEvent({
        event: 'search.used',
        attributes: {
          userId,
          userName,
        },
      });
      this.setState({ sentEvent: true });
    }
    this.search(this.state.query);
  }

  toggleExpansion(topicId) {
    this.setState({ expandedTopic: topicId });
  }

  render() {
    const { isLoading, closeMenu } = this.props;
    const inputId = 'scope-search';
    return (
      <div className={cx('container')}>
        <div
          className={classnames(
            'flex-container flex-dir-row align-middle',
            cx('input')
          )}
        >
          <label htmlFor={inputId}>
            <IconAdaptor size="nav" type="svg">
              <FaSearch />
            </IconAdaptor>
          </label>
          <input
            id={inputId}
            type="text"
            ref={(el) => el && el.focus()}
            value={this.state.query}
            onChange={this.onChangeQuery}
            onKeyPress={this.onEnter}
            autoComplete="off"
          />
          {isLoading && <Spinner size="small" color="white" />}
        </div>
        <Hits
          expandedTopic={this.state.expandedTopic}
          toggleExpansion={this.toggleExpansion}
          closeMenu={closeMenu}
        />
      </div>
    );
  }
}

Search.propTypes = {
  closeMenu: PropTypes.func,
  scheduleUpdate: PropTypes.func,
  isLoading: PropTypes.bool.isRequired,
  session: PropTypes.shape({
    userId: PropTypes.string.isRequired,
    userName: PropTypes.string.isRequired,
  }).isRequired,
  search: PropTypes.func,
  resetSearch: PropTypes.func,
};

const mapStateToProps = (state) => ({
  isLoading: isLoadingSelector(state),
  session: iamSessionSelector(state),
});

export default connect(mapStateToProps, { search, resetSearch })(Search);
