import { registerTour } from '@infosight/shell-api/lib/Tour';
import { useEffect } from 'react';
import { withRouter } from 'react-router';
import createNewNavTour from '../tour/createNewNavTour';

const NavFeatureTourExtension = () => {
  useEffect(() => {
    registerTour(createNewNavTour());
  }, []);

  return null;
};

export default withRouter(NavFeatureTourExtension);
