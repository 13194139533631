import axios from '../../services/axios';
import { SET_MICROAPP_STATUS, FETCH_MICROAPP_MANIFEST } from './reducer';
import { MICROAPP_MANIFEST_URL } from '../../config';

const timers = {};
const TIMEOUT_MS = 60000;

// TODO add some mechanism to inform the user that an expected microapp did not load
export const setMicroappLoadFailure = (appId, reason) => (dispatch) => {
  // Clear existing timer
  if (timers[appId]) {
    window.clearTimeout(timers[appId]);
  }

  dispatch({
    type: SET_MICROAPP_STATUS,
    payload: { appId, status: 'error', reason },
  });
};

export const setMicroappLoadSuccess = (appId) => (dispatch) => {
  // Clear existing timer
  if (timers[appId]) {
    window.clearTimeout(timers[appId]);
  }

  dispatch({
    type: SET_MICROAPP_STATUS,
    payload: { appId, status: 'loaded' },
  });
};

export const setMicroappLoading = (appId) => (dispatch) => {
  if (timers[appId]) {
    window.clearTimeout(timers[appId]);
  }

  dispatch({
    type: SET_MICROAPP_STATUS,
    payload: { appId, status: 'loading' },
  });

  // Set timer in case loading never fails in a way that throws an error.
  timers[appId] = window.setTimeout(() => {
    // Remove timer id
    timers[appId] = undefined;

    // Update status
    dispatch(setMicroappLoadFailure(appId, `Timeout after ${TIMEOUT_MS}ms`));
  }, TIMEOUT_MS);
};

export const getMicroappManifest = () => async (dispatch) => {
  try {
    const { data: microapps } = await axios.get(MICROAPP_MANIFEST_URL);
    const oculus =
      microapps && microapps.filter((app) => app.id === 'oculus')[0];

    if (oculus) {
      //  the oculus microapps comes as one so we must add the sub apps to the manifest
      ['storeserv', 'storeonce', 'sfr', 'rmc'].forEach((oculusApp) =>
        microapps.push({ id: oculusApp, url: oculus.url })
      );
    }
    dispatch({ type: FETCH_MICROAPP_MANIFEST, payload: microapps });
  } catch (e) {
    console.log('Failed to retrieve the microapp manifest');
    dispatch({ type: FETCH_MICROAPP_MANIFEST, payload: [] });
  }
};
