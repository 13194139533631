import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classnames from 'classnames/bind';
import { TourBeacon } from '@infosight/elmer/dist/components/TourBeacon';
import { FaCaretDown } from 'react-icons/fa';
import style from './MenuTrigger.scss';
import { activeTenantSelector } from '../reducer';

const cx = classnames.bind(style);

export const MenuTrigger = ({ tenant }) => {
  const name = tenant ? tenant.name : 'Select an organization';
  return (
    <div className="text-left">
      <TourBeacon
        anchor="left"
        tour={{ appId: 'iam', name: 'Org Management' }}
        offset={-85}
        onClick={(e) => e.stopPropagation()}
      >
        <div className={cx({ title: true })}>Organization</div>
      </TourBeacon>
      <div className="flex-container align-middle align-right">
        <div
          className={classnames('ellipsis-on-overflow', cx({ name: true }))}
          title={name}
        >
          {name}
        </div>
        <FaCaretDown className={cx('caret')} />
      </div>
    </div>
  );
};

MenuTrigger.propTypes = {
  tenant: PropTypes.shape({
    name: PropTypes.string,
  }),
};

const mapStateToProps = (state) => ({
  tenant: activeTenantSelector(state),
});
export default connect(mapStateToProps)(MenuTrigger);
