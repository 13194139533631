import React from 'react';
import PropTypes from 'prop-types';
import { QueryToggle } from '../Toggle';

const TableViewTabs = ({ className, tabName, categories }) => {
    // Need to transform the categories to handle stuff coming both from collectionconfig builders and manually-entered tab IDs
    const transformedCategories =
        categories.map(category => (typeof category === 'object' ? category : {
            value: category,
            title: category.charAt(0).toUpperCase() + category.slice(1),
        }));

    return (
        <QueryToggle
            parameter="fieldSet"
            value={(tabName || transformedCategories[0].value)}
            className={className}
        >
            {transformedCategories.map(({ value: fieldSet, title }) => (
                <button key={fieldSet} value={fieldSet}>{title}</button>
            ))}
        </QueryToggle>);
};

TableViewTabs.propTypes = {
    /**
     * Set of categories the column belongs to
     */
    categories: PropTypes.arrayOf(PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({
            value: PropTypes.string.isRequired,
            title: PropTypes.string.isRequired,
        }),
    ])),
    tabName: PropTypes.string,
    className: PropTypes.string,
};

export default TableViewTabs;
