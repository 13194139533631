import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames/bind';
import { ObjectName } from '@infosight/elmer/dist/infrastructure';
import Hit from './Hit';
import style from './style.scss';

const cx = classnames.bind(style);

// TODO add button styled as anchor to elmer
/* eslint-disable no-script-url, jsx-a11y/anchor-is-valid */
const TopicHits = ({
  subject,
  hits,
  topic: { singularName, pluralName, icon, id, subtitle },
  toggleExpansion,
  visibleCount,
  closeMenu,
}) => {
  const nameLabel = `${hits.length} ${
    hits.length > 1 ? pluralName : singularName
  }`;
  const visibleResults = visibleCount ? hits.slice(0, visibleCount) : hits;
  const invisibleResults = visibleCount ? hits.slice(visibleCount) : [];
  const hasHiddenResults = invisibleResults.length > 0;

  return (
    <div className={classnames('row expanded', cx('topic'))}>
      <div
        className="flex-container flex-dir-column column medium-8"
        style={{ fontWeight: 'inherit' }}
      >
        <ObjectName name={nameLabel} icon={icon} />
        <small>{subtitle || subject.name}</small>
      </div>
      <div className="column medium-16">
        {visibleResults.map((hit) => (
          <div key={hit.hitId}>
            <Hit {...hit} closeMenu={closeMenu} />
          </div>
        ))}
        {hasHiddenResults && (
          <a
            className={cx('hit')}
            href="javascript:void(0)"
            onClick={() => toggleExpansion(id)}
          >
            {' '}
            Show {invisibleResults.length} more...{' '}
          </a>
        )}
      </div>
    </div>
  );
};
/* eslint-enable no-script-url, jsx-a11y/anchor-is-valid */

TopicHits.propTypes = {
  subject: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }).isRequired,
  hits: PropTypes.arrayOf(
    PropTypes.shape({
      hitId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
    })
  ).isRequired,
  topic: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    singularName: PropTypes.string.isRequired,
    pluralName: PropTypes.string.isRequired,
    icon: PropTypes.node,
    subtitle: PropTypes.node,
  }),

  /**
   * @param topicId
   */
  toggleExpansion: PropTypes.func.isRequired,
  visibleCount: PropTypes.number.isRequired,
  closeMenu: PropTypes.func.isRequired,
};

export default TopicHits;
