import { ExtensionPoint } from '@infosight/shell-api/lib/core';
import { getStore } from '../../util/storeProvider';
import {
  userWithIAMSelector,
  AUTHORITIES,
  PRIVILEGES,
  PERMISSION_TARGET,
  AuthorizationEvaluator,
} from '../../user';
import { activeTenantSelector } from '../../iam/reducer';
import { fetchIamSession } from '../../iam/actionCreators';
import { allSystemPresenceUISelector } from '../../inventory/reducer';

// TODO this is a stopgap solution.
// When we have a standard authn/z solution, this should be updated to allow each microapp to handle auth as needed.
// This UserProfileApi will no longer need to exist
const UserProfileApi = {
  AUTHORITIES,
  PRIVILEGES,
  PERMISSION_TARGET,
  AuthorizationEvaluator,
};

function getUserProfile() {
  return this.props.user;
}

function getUserProfileApi() {
  return UserProfileApi;
}

function getActiveTenant() {
  return this.props.tenant;
}

/**
 * @protected This should only be used by the IAM microapp to show system counts on the Delete Tenant view
 */
function getTenantInventorySummary() {
  return this.props.inventorySummary;
}

function logOut() {
  let redirectTo = '';
  if (window.location.pathname) {
    redirectTo = window.location.search
      ? `${window.location.pathname}${window.location.search}`
      : window.location.pathname;
  }
  const postLoginUrl =
    redirectTo && `&redirectTo=${encodeURIComponent(redirectTo)}`;

  return window.location.assign(
    `${process.env.LOGIN_PATH}/login?logout${postLoginUrl || ''}`
  );
}

function refreshSession() {
  getStore().dispatch(fetchIamSession());
}

export const id = ExtensionPoint.UserProfile;
export const queries = {
  getUserProfile,
  getUserProfileApi,
  getActiveTenant,
  getTenantInventorySummary,
};
export const commands = { logOut, refreshSession };

export const mapAdditionalStateToProps = (state) => ({
  user: userWithIAMSelector(state),
  tenant: activeTenantSelector(state),
  inventorySummary: allSystemPresenceUISelector(state),
});
