import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import classnames from 'classnames/bind';
import { Modal } from '@curo/curo-design-system';
import { Search } from 'grommet-icons';
import { debounce } from 'underscore';
import {
  currentUserUserTenantsSelector,
  tenantSearchHitsSelector,
} from '../reducer';
import { searchTenants, resetTenantSelector } from '../actionCreators';
import MyOrgsList from './MyOrgsList';
import style from './MyOrgsList.scss';

const cx = classnames.bind(style);

const ViewMyOrgs = ({
  setShowMyOrgsModal,
  passedSearchQuery,
  setPassedSearchQuery,
}) => {
  const dispatch = useDispatch();

  const onClose = () => {
    setShowMyOrgsModal(false);
    setPassedSearchQuery(null);
    dispatch(resetTenantSelector());
  };

  const { currentUserUserTenants, loadingCurrentUserUserTenants } = useSelector(
    currentUserUserTenantsSelector
  );

  const [currentUserUserTenantsById, setCurrentUserUserTenantsById] = useState(
    []
  );

  useEffect(() => {
    if (currentUserUserTenants) {
      const utById = {};
      currentUserUserTenants.forEach((ut) => {
        utById[ut.tenant.id] = ut;
      });
      setCurrentUserUserTenantsById(utById);
    }
  }, [currentUserUserTenants, setCurrentUserUserTenantsById]);

  const { tenantSearchHits, loadingTenantSearchHits } = useSelector(
    tenantSearchHitsSelector
  );

  const [formattedTenantSearchHits, setFormattedTenantSearchHits] = useState(
    []
  );

  useEffect(() => {
    if (tenantSearchHits) {
      setFormattedTenantSearchHits(
        tenantSearchHits.map(
          (tenant) =>
            currentUserUserTenantsById[tenant.id] || { tenant, role: '' }
        )
      );
    }
  }, [
    tenantSearchHits,
    currentUserUserTenantsById,
    setFormattedTenantSearchHits,
  ]);

  const [doingSearch, setDoingSearch] = useState(!!passedSearchQuery);
  const [tenantSearchQuery, setTenantSearchQuery] = useState(passedSearchQuery);

  const doSearch = () => {
    const query = tenantSearchQuery.trim();
    dispatch(searchTenants({ query }));
  };

  const debouncedSearch = debounce(doSearch, 400);

  const onChange = (event) => {
    const searchQuery = event.target && event.target.value;
    if (searchQuery) {
      setDoingSearch(true);
      debouncedSearch();
    } else {
      setDoingSearch(false);
    }
    setTenantSearchQuery(searchQuery);
  };

  const content = (
    <MyOrgsList
      data={
        (doingSearch ? formattedTenantSearchHits : currentUserUserTenants) || []
      }
      loading={
        doingSearch ? loadingTenantSearchHits : loadingCurrentUserUserTenants
      }
    />
  );

  // we are using dataTestId in a super hacky manner to override the curo styles
  return (
    <Modal
      dataTestId="my-orgs-modal"
      a11yTitle="Select an organization"
      title="Select an organization"
      closeAction={onClose}
    >
      {!doingSearch && (
        <div className={cx('browse-message')}>
          Browse the organizations you are a member of or search for
          organizations.
        </div>
      )}
      <div
        className={classnames(
          'flex-container flex-dir-row align-middle',
          cx('search')
        )}
      >
        <div className="flex-child-grow" style={{ position: 'relative' }}>
          <input
            className={cx('search-input')}
            placeholder="Search"
            type="text"
            value={tenantSearchQuery}
            onChange={onChange}
            id="my-org-search"
          />
        </div>
        <label htmlFor="my-org-search">
          <Search size="medium" color="currentColor" />
        </label>
      </div>
      {content}
    </Modal>
  );
};

ViewMyOrgs.propTypes = {
  setShowMyOrgsModal: PropTypes.func.isRequired,
  passedSearchQuery: PropTypes.string,
  setPassedSearchQuery: PropTypes.func.isRequired,
};

export default ViewMyOrgs;
