import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import BEM from 'src/utils/bem';

const bem = new BEM('elmer-ScrollContainer');

const Fixed = ({ className, children, ...props }) => (
    <div {...props} className={classnames(className, bem.e('fixed'))}>
        {children}
    </div>
);

Fixed.propTypes = {
    children: PropTypes.node.isRequired,
};

export default Fixed;
