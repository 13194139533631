import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'grommet';
import { FormNext, FormPrevious } from 'grommet-icons';
import styled from 'styled-components';

const buttonSize = '42px';
const RoundButton = styled(Button)`
  padding: 0;
  width: ${buttonSize};
  height: ${buttonSize};
  border-width: 3px;
  border-radius: 100% !important; // override elmer+foundation's overriding these styles
`;

const NavButton = ({ direction, disabled, onClick, ...props }) => {
  let icon;
  let color;
  if (direction === 'next') {
    icon = <FormNext size="32px" color="white" />;
    color = 'brand';
  } else {
    icon = <FormPrevious size="32px" color="dark-2" />;
    color = 'dark-2';
  }

  if (!disabled) {
    return (
      <RoundButton
        {...props}
        icon={icon}
        onClick={!disabled && onClick}
        color={color}
        data-testid={`NavButton ${direction}-button`}
        plain={false}
      />
    );
  }

  return <div />;
};

NavButton.propTypes = {
  direction: PropTypes.oneOf(['next', 'back']),
  disabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

export default NavButton;
