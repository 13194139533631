import axios from 'axios';
import { buildUrl } from '@infosight/elmer/dist/utils/url';
import { logOut } from '@infosight/shell-api/lib/UserProfile';
import { FlashNotification } from '@infosight/elmer/dist/components/FlashNotification';
import { BASE_URL } from '../config';

const axiosInstance = axios.create();

const errorInterceptor = (error) => {
  const { response } = error;
  // The config.url bit here prevents the sfrm requests from redirecting.
  // This is a short term solution until the Nimble local backend has the SFR login screen.
  if (
    response &&
    response.status === 401 &&
    response.config.url.indexOf(buildUrl(BASE_URL)) === 0
  ) {
    document.cookie = `SESSION=;expires=${new Date().getTime() - 1}`;
    logOut();
  } else if (
    response &&
    response.status === 408 &&
    response.config.url.indexOf(buildUrl(BASE_URL)) === 0
  ) {
    FlashNotification.error(
      `Internal Timeout Error -
            An internal service failed to fulfill your request.
            Please try again after few minutes. Please contact support if the problem persists.`,
      { timeout: 0 }
    );
  }

  return Promise.reject(error);
};

axiosInstance.interceptors.response.use(
  (response) => response,
  errorInterceptor
);

export default axiosInstance;
