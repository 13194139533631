import React, { Component } from 'react';
import PropTypes from 'prop-types';
import autobind from 'react-autobind';
import { Manager, Target, Popper, Arrow } from 'react-popper';
import FilterIcon from 'react-icons/lib/fa/filter';
import BEM from 'src/utils/bem';
import classnames from 'classnames';
import { IconAdaptor } from '../../IconAdaptor';
import OutsideClickWrapper from '../../OutsideClickWrapper';

const bem = new BEM('elmer-Table-Filter-Popup');

/**
 * Component which renders the given Component in a Popup
 */
class Filter extends Component {
    static stopPropagation(event) {
        // prevent clicks inside the filter dropdown itself from also changing sort
        event.stopPropagation();
    }

    constructor(props) {
        super(props);
        autobind(this);

        this.state = {
            showPopup: false,
        };
    }

    handleClickOutside(event) {
        if (this.state.showPopup) {
            this.togglePopup(event);
        }
    }

    togglePopup(event) {
        this.setState(({ showPopup }) => ({ showPopup: !showPopup }));

        // prevent multiple toggles from one click
        if (event) {
            event.stopPropagation();
        }
    }

    render() {
        const { className, overlay, mouseEnter, mouseOut } = this.props;
        const { showPopup } = this.state;
        const overlayProps = { togglePopup: this.togglePopup, ...this.props };

        return (
            <Manager
                className={classnames(className, bem.valueOf(), 'text-left')}
                onClick={Filter.stopPropagation}
                onMouseEnter={mouseEnter}
                onMouseOut={mouseOut}
                onBlur={mouseOut}
            >
                <Target
                    component="button"
                    onClick={this.togglePopup}
                    style={{ zIndex: 3 }}
                >
                    <div className={classnames(bem.e('filter-icon'))}>
                        <IconAdaptor size="xsmall" type="svg"><FilterIcon /></IconAdaptor>
                    </div>
                </Target>
                { showPopup ?
                    <Popper
                        placement="bottom-start"
                        modifiers={{
                            arrow: {
                                enabled: true,
                            },
                            offset: {
                                enabled: true,
                                offset: '0px, 3px',
                            },
                        }}
                        className={bem.e('popper')}
                    >
                        <OutsideClickWrapper handleClickOutside={this.handleClickOutside}>
                            {
                                /**
                                 * To abstract how the out of the box Filter Components
                                 * work (in other words, consumers should not be forced to
                                 * add internal props of the Filter Component in their table config)
                                 * we are cloning the element and passing the required props of the
                                 * out of the Filter Components
                                 * */
                                React.cloneElement(typeof overlay === 'function' ?
                                    overlay(overlayProps) : overlay, overlayProps)
                            }
                        </OutsideClickWrapper>
                        <Arrow className={bem.e('arrow')} />
                    </Popper> : null }
            </Manager>
        );
    }
}

Filter.propTypes = {
    overlay: PropTypes.oneOfType([PropTypes.func, PropTypes.element]).isRequired,
    className: PropTypes.string,
    mouseEnter: PropTypes.func,
    mouseOut: PropTypes.func,
    onChange: PropTypes.func.isRequired, // eslint-disable-line react/no-unused-prop-types
    filter: PropTypes.shape({
        id: PropTypes.string,
        title: PropTypes.string,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object, PropTypes.array]),
    }).isRequired,
};

export default Filter;
