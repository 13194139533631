import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import BEM from '../../utils/bem';

const bem = new BEM('elmer-Foldable');

/**
 * When this component has no DOM to render, it hides itself.
 * 
 * Use case: You have some component that gets children from an external source, such as a function passed in via props.
 * If that function returns null, by default React wouldn't render anything. However, sometimes the container is
 * used to add layout styling that is undesirable when it is empty. `Foldable` makes it easy to avoid that.
 * 
 * [ObjectName](#ObjectName) is an example of how this is used.
 */
const Foldable = ({ className, children, tag: Tag }) => (
    <Tag className={classnames(className, bem.valueOf())}>{children}</Tag>
);

Foldable.propTypes = {
    /**
     * DOM element used to wrap children
     */
    tag: PropTypes.string,
    children: PropTypes.element.isRequired,
};

Foldable.defaultProps = {
    tag: 'span',
};

export default Foldable;
