import React from 'react';
import PropTypes from 'prop-types';

const UnauthorizedPage = ({ children, message }) => (
    <div className="card">
        <div className="card-section">
            <div className="flex-container align-justify align-middle">
                <h1 className="no-margin"><i className="fa fa-exclamation-triangle text-danger" /> {message}</h1>
            </div>
            <div className="flex-container align-justify align-middle">
                <p>If you have reached this page immediately after logging in, please update your landing page on the <a href="/home">Home page</a>.</p>
            </div>
            {children && <div className="card-section">{children}</div>}
        </div>
    </div>
);

UnauthorizedPage.propTypes = {
    children: PropTypes.node,
    message: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
};

UnauthorizedPage.defaultProps = {
    message: 'You are not authorized to view this page.',
};

export default UnauthorizedPage;
