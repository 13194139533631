import { ExtensionPoint } from '@infosight/shell-api/lib/core';
import { actionNamespacer } from '@infosight/elmer/dist/utils/redux';
import { createSelector } from 'reselect';
import { extensionPointSelector } from '../selectors';
import ActiveTourQueue from './activeTourQueue';
import { appIsReadySelector } from '../bootstrapper/reducer';

const ns = actionNamespacer(`EXTENSIONS/${ExtensionPoint.Tour}`);

export const REGISTER = ns('ADD');
export const RESET = ns('RESET');
export const ENQUEUE_TOUR = ns('ENQUEUE_TOUR');
export const DEQUEUE_TOUR = ns('DEQUEUE_TOUR');
export const DEQUEUE_ALL_TOURS = ns('DEQUEUE_ALL_TOURS');

const initialState = {
  tours: {},
  activeTourQueue: new ActiveTourQueue(),
};

const ACTION_HANDLERS = {
  [REGISTER]: (state, { payload: tour }) => ({
    ...state,
    tours: { ...state.tours, [tour.id]: tour },
  }),
  [RESET]: () => initialState,
  [ENQUEUE_TOUR]: (state, { payload: { id } }) => ({
    ...state,
    activeTourQueue: state.activeTourQueue.add(id),
  }),
  [DEQUEUE_TOUR]: (state, { payload: { id } }) => ({
    ...state,
    activeTourQueue: state.activeTourQueue.remove(id),
  }),
  [DEQUEUE_ALL_TOURS]: (state) => ({
    ...state,
    activeTourQueue: new ActiveTourQueue(),
  }),
};

export default (state = initialState, action) => {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
};

const baseSelector = (state) =>
  extensionPointSelector(state, ExtensionPoint.Tour);
export const toursSelector = (state) => baseSelector(state).tours;

export const activeTourSelector = createSelector(
  appIsReadySelector,
  toursSelector,
  (state) => baseSelector(state).activeTourQueue.peek(),
  (appIsReady, tours, activeTourId) => {
    if (appIsReady && activeTourId) {
      return tours[activeTourId];
    }

    return null;
  }
);
