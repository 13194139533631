import React from 'react';
import PropTypes from 'prop-types';
import isStorageAvailable from 'src/utils/browserStorage';
import createSizePersistentTable from './createSizePersistentTable';
import Table from '../Table';

/**
 * Save the resized column widths
 * @param {!tableId, {Array<{id, value}>}}
 */
const persistColumnSizes = ({ tableId, resizedColumns }) => {
    if (tableId && isStorageAvailable('localStorage')) {
        // Local storage cannot serialize/desrialize objects, so save data as string
        window.localStorage.setItem(tableId, JSON.stringify(resizedColumns));
    }
};

/**
 * Fetch the saved sizes of the columns for the given table
 * @param ({ !tableId })
 * @returns {Array<{id, value}> | undefined }
 */
const getPersistedColumnSizes = ({ tableId }) => {
    if (tableId && isStorageAvailable('localStorage')) {
        const persistedColumns = window.localStorage.getItem(tableId);
        // Parse the stringified data back to Array<{id, value}>
        return persistedColumns ? JSON.parse(persistedColumns) : undefined;
    }
    return undefined;
};

const ColumnSizePersistentTable = createSizePersistentTable(Table, { getPersistedColumnSizes, persistColumnSizes });

/**
 * HOC Component which saves the resized Column widths of a table in client's localStorage
 * If the localStorage is not avaialble, table will still works but it just doesn't persist the resized columns
 */
const ClientStorageSizePersistentTable = ({ tableId, namespace, ...props }) => {
    if (!tableId) {
        console.error('Invalid tableId, size of the resized columns will not be persisted');
    }
    const namespacedTableId = `${namespace}.${tableId}`;
    return <ColumnSizePersistentTable {...props} tableId={namespacedTableId} />;
};

ClientStorageSizePersistentTable.propTypes = {
    /**
     * Unique ID of the table whose column widths are being persisted
     */
    tableId: PropTypes.string.isRequired,

    /**
     * By default, Localstorage is namespaced by origin, but this helps to further nest to it elmer
     */
    namespace: PropTypes.string,
};

ClientStorageSizePersistentTable.defaultProps = {
    namespace: 'elmer.table-size-persistence',
};

export default ClientStorageSizePersistentTable;
