import { ExtensionPoint } from '@infosight/shell-api/lib/core';
import reducer, { REGISTER } from './reducer';
import {
  setMicroappLoadSuccess,
  setMicroappLoadFailure,
} from '../orchestrator/actionCreators';

const fail = (appId, message) => {
  setMicroappLoadFailure(appId, message);
  throw new Error(message);
};

async function register(opts) {
  if (!opts) {
    return;
  }

  const { appId, shouldContinue, initialize, getExtensionContainer } = opts;

  if (typeof shouldContinue === 'function') {
    if (!(await shouldContinue())) {
      return;
    }
  } else {
    console.debug(
      `"${appId}" does not have a shouldContinue() lifecycle function. Continuing to "initialize" step.`
    );
  }

  if (typeof initialize === 'function') {
    if ((await initialize()) === false) {
      return;
    }
  } else {
    console.debug(
      `"${appId}" does not have a initialize() lifecycle function. Continuing to "getExtensionContainer" step.`
    );
  }

  if (typeof getExtensionContainer !== 'function') {
    fail(
      appId,
      `"${appId}" does not have a getExtensionContainer() lifecycle function.`
    );
  }

  const MicroappComponent = await getExtensionContainer();
  if (typeof MicroappComponent !== 'function') {
    fail(appId, `"${appId}" did not return a valid React Component`);
  }

  this.props.dispatch({
    type: REGISTER,
    payload: { appId, MicroappComponent },
  });

  // Give microapp a chance to message extension points.
  // This is far simpler and more right than trying to figure out when an extension is "done" from an async messaging and separation of concerns POVs
  window.setTimeout(() => {
    this.props.dispatch(setMicroappLoadSuccess(appId));
  }, 5000);
}

export const id = ExtensionPoint.Bootstrapper;
export const commands = { register };
export { reducer };
