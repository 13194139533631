import React, { Component } from 'react';
import PropTypes from 'prop-types';
import autobind from 'react-autobind';
import classnames from 'classnames';
import BEM from 'src/utils/bem';

export default class Toggle extends Component {
    constructor(props) {
        super(props);
        autobind(this);
    }

    onClick(event) {
        const index = Number(event.target.value);
        const childAtIndex = React.Children.toArray(this.props.children)[index];
        this.props.onChange(childAtIndex.props.value);
    }

    render() {
        const { children, type } = this.props;
        let bem;
        if (type === 'tab') {
            bem = new BEM('elmer-ToggleTabs');
        } else {
            bem = new BEM('elmer-Toggle');
        }

        const kids = React.Children.map(children, (child, index) => {
            const { value, className, ...childProps } = child.props;
            const newChildClassName = classnames(className, bem.e('button'), {
                [bem.chain().e('button').m('on').valueOf()]: value === this.props.value,
                [bem.chain().e('button').m('off').valueOf()]: value !== this.props.value,
            });

            return <button {...childProps} className={newChildClassName} value={index} onClick={this.onClick} />;
        });

        return <div className={classnames(this.props.className, bem.valueOf())}>{kids}</div>;
    }
}

Toggle.propTypes = {
    /**
     * Fragment containing only `<button>` elements.
     */
    children: PropTypes.node.isRequired,

    /**
     * Current value. This should match the `props.value` of one of the child `Button` elements.
     */
    value: PropTypes.any.isRequired,

    /**
     * @param {*} value Value of selected element from `child[].props.value`
     */
    onChange: PropTypes.func.isRequired,

    type: PropTypes.oneOf(['button', 'tab']),
};

Toggle.defaultProps = {
    type: 'button',
};
