import { defer, isFunction, noop } from 'underscore';
import {
  START_SEARCH,
  STATES,
  RESET_SEARCH,
  ADD_SEARCH_RESULT,
  REGISTER_SUBJECTS,
} from './constants';
import { subjectsSelector } from './reducer';

export function resetSearch() {
  return { type: RESET_SEARCH };
}

export function submitHits({ id, hits }) {
  return { type: ADD_SEARCH_RESULT, payload: { id, results: hits } };
}

export function submitNoOp(id) {
  return {
    type: ADD_SEARCH_RESULT,
    payload: { id, results: null, state: STATES.AWAITING_REQUEST },
  };
}

export function submitError({ id, error }) {
  return {
    type: ADD_SEARCH_RESULT,
    payload: { id, error, state: STATES.ERROR },
  };
}

export function search(query, queryOptions) {
  return (dispatch, getState) => {
    const subjects = subjectsSelector(getState());

    const affectedSubjects = Object.values(subjects).map(
      ({ id, search: subjectSearch, cancel, submissionHandlers }) => {
        // Attempt to cancel any existing operations before triggering a new search
        try {
          // Surround with try catch since this comes from the microapps and could be faulty,
          // but we don't actually care about this error if it happens
          cancel();
        } catch (e) {} // eslint-disable-line no-empty

        if (query && query.length > 0) {
          // Push it on the call stack to force an async run
          // This reduces UI jank
          defer(async () => {
            try {
              await subjectSearch({
                query,
                ...queryOptions,
                submit: submissionHandlers,
              });
            } catch (ex) {
              submissionHandlers.error(ex);
            }
          });

          return { id, state: STATES.AWAITING_RESULTS };
        }

        return { id, state: STATES.AWAITING_REQUEST };
      }
    );

    // Rather than send a command per subject, batch them all so the store updates once.
    // Because the search call was deferred, the store will always be updated before search results are returned
    // This greatly reduces jank caused by useless UI renders
    dispatch({
      type: START_SEARCH,
      payload: { query, queryOptions, affectedSubjects },
    });
  };
}

export function buildSearchSubject(subject, dispatch) {
  if (!subject) {
    return null;
  }

  if (
    !subject.id ||
    isFunction(!subject.search) ||
    !subject.search ||
    !subject.search.length
  ) {
    console.warn('Invalid search subject', subject);
    return null;
  }

  const {
    id,
    search: searchFn,
    topics,
    name,
    hitRenderer,
    cancelSearch,
    destroy,
  } = subject;

  return {
    id,
    hitRenderer,
    name,
    search: searchFn,
    visibleOrder: 1,
    topics: topics.reduce((memo, topic) => {
      if (!topic || !topic.id) {
        return memo;
      }

      // eslint-disable-next-line no-param-reassign
      memo[topic.id] = topic;
      return memo;
    }, {}),
    cancel: isFunction(cancelSearch) ? cancelSearch : noop,
    destroy: isFunction(destroy) ? destroy : noop,
    submissionHandlers: {
      hits(hits) {
        dispatch(submitHits({ id, hits }));
      },
      error(error) {
        dispatch(submitError({ id, error }));
      },
      noOp() {
        dispatch(submitNoOp(id));
      },
    },
  };
}

export function registerAll(subjects) {
  return (dispatch) => {
    dispatch({
      type: REGISTER_SUBJECTS,
      payload: subjects.reduce((memo, subject) => {
        const subjectPayload = buildSearchSubject(subject, dispatch);
        if (subjectPayload) {
          // eslint-disable-next-line no-param-reassign
          memo[subjectPayload.id] = subjectPayload;
        }

        return memo;
      }, {}),
    });
  };
}
