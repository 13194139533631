let privateStore;

/**
 * Init Redux Store Provider singleton. This should only be used one during the bootstrap phase of the app
 * @param store
 * @private
 */
export const setStore = (store) => {
  if (!privateStore) {
    privateStore = store;
  }
};

export const getStore = function getStore() {
  if (!privateStore) {
    throw new Error(
      'storeProvider must be initialized. Store is not available.'
    );
  }

  return privateStore;
};
