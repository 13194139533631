import React, { Component } from 'react';
import PropTypes from 'prop-types';
import autobind from 'react-autobind';
import { Layer, Box } from 'grommet';
import { ADD_EVENT } from './constants';
import Timer from './Timer';
import ContentTemplate from './ContentTemplate';

/**
 * A terse notification that will only be displayed for a short period of time, overlaying
 * whatever the user is currently doing. These are also called "Toast" or "Growl" messages
 * This component is only used once at the root of the component tree.
 * Use the exported functions to add a new notification.
 *
 * `FlashNotification.[error|info|success|warning](message, [options])`
 *
 *
 * | Parameter       | Type              | Required | Description                                              |
 * |-----------------|-------------------|----------|----------------------------------------------------------|
 * | message         | `string` or `React.Element` | yes      |                                                          |
 * | options         | `Object`          | no       |                                                          |
 * | options.timeout | `Number` or 'none'| no       | Timeout in ms. Default is 5000                           |
 * | options.onShow  | `Function`        | no       | onShow will fire the function when the alert appears     |
 * | options.onClose | `Function`        | no       | onClose will fire the function when the alert is removed |
 *
 */

class FlashNotification extends Component {
    constructor(props) {
        super(props);
        autobind(this);

        this.state = { notifications: props.notifications || [] };
        window.top.addEventListener(ADD_EVENT, this.addNotification);
    }

    componentWillUnmount() {
        window.top.removeEventListener(ADD_EVENT, this.addNotification);
    }

    addNotification(e) {
        if (!e.detail) {
            return;
        }

        this.setState(({ notifications }) => ({ notifications: [...notifications, e.detail] }));
    }

    removeNotification(notification) {
        this.setState(({ notifications }) => ({ notifications: notifications.filter(x => x !== notification) }), notification.onClose);
    }

    render() {
        const { notifications } = this.state;
        if (!notifications || !notifications.length) {
            return null;
        }

        return (
            <Layer
                modal={false}
                full="horizontal"
                position="top"
                round={false}
                plain
                style={{ 'background-color': 'transparent', maxHeight: 'unset' }}
            >
                <Box gap="small" margin="large">
                    {notifications.map(notification => (
                        <Timer
                            key={notification.id}
                            onClose={() => this.removeNotification(notification)}
                            timeout={notification.timeout}
                            onShow={notification.onShow}
                        >
                            {close => <ContentTemplate content={notification.content} close={close} type={notification.type} />}
                        </Timer>
                    ))}
                </Box>
            </Layer>
        );
    }
}

FlashNotification.propTypes = {
    /**
     * This is only used for testing and sample code
     * @ignore
     * @private
     */
    notifications: PropTypes.arrayOf(PropTypes.shape({
        type: PropTypes.oneOf(['error', 'info', 'success', 'warning']),

        /**
         * Notification content
         */
        content: PropTypes.node.isRequired,

        /**
         * If the user does not imperatively dismiss the notification, it will automatically be
         * closed after this number of milliseconds.
         *
         * Use `0` for no timeout.
         */
        timeout: PropTypes.number,

        /**
         * Callback when the notification appears
         */
        onShow: PropTypes.func,

        /**
         * Callback when the notification is removed
         */
        onClose: PropTypes.func,
    })),
};

const proxyAlert = type => (content, { timeout = 5000, onClose, onShow } = {}) => {
    const detail = { type, timeout, onClose, onShow, content };
    window.top.dispatchEvent(new CustomEvent(ADD_EVENT, { detail }));
};

FlashNotification.error = proxyAlert('error');
FlashNotification.info = proxyAlert('info');
FlashNotification.success = proxyAlert('success');
FlashNotification.warning = proxyAlert('warning');

export default FlashNotification;
