import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  activeTenantSelector,
  primaryTenantSelector,
  currentUserUserTenantsSelector,
} from '../reducer';
import { IAM_ROLES } from '../constants';

// TODO This can be simplified; only used in one place
export const WithTenantAttributes = ({
  className,
  tenant,
  render: Renderer,
  activeTenant,
  primaryTenant,
  currentUserUserTenants,
  ...props
}) => {
  const { id, name, description } = tenant;
  const isActiveTenant = !!(
    activeTenant &&
    ((activeTenant.tenant && activeTenant.tenant.id === id) ||
      activeTenant.id === id)
  );
  const isPrimaryTenant = !!(primaryTenant && primaryTenant.id === id);

  const currentUserUserTenantsMap = {};
  if (currentUserUserTenants) {
    currentUserUserTenants.forEach((userTenant) => {
      currentUserUserTenantsMap[userTenant.tenant.id] = userTenant.role;
    });
  }
  const isAdmin = currentUserUserTenantsMap[id] === IAM_ROLES.ADMIN;

  return (
    <Renderer
      {...props}
      isActiveTenant={isActiveTenant}
      isPrimaryTenant={isPrimaryTenant}
      tenant={tenant}
      tenantId={id}
      tenantName={name}
      tenantDescription={description}
      isAdmin={isAdmin}
    />
  );
};

WithTenantAttributes.propTypes = {
  tenant: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string,
  }).isRequired,
  currentUserUserTenants: PropTypes.arrayOf(
    PropTypes.shape({
      tenant: PropTypes.shape({
        id: PropTypes.string.isRequired,
      }),
      role: PropTypes.string.isRequired,
    })
  ).isRequired,
  activeTenant: PropTypes.oneOfType([
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      description: PropTypes.string,
    }),
    PropTypes.shape({
      tenant: PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        description: PropTypes.string,
      }),
    }),
  ]),
  primaryTenant: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string,
  }),
  render: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  activeTenant: activeTenantSelector(state),
  primaryTenant: primaryTenantSelector(state),
  ...currentUserUserTenantsSelector(state),
});

export default connect(mapStateToProps)(WithTenantAttributes);
