import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import BEM from 'src/utils/bem';
import { ERROR, INFO, SUCCESS, WARNING } from './constants';
import Icon from './Icon';

const bem = new BEM('elmer-GlobalBanner');

const GlobalBanner = ({ severity, nav, content, appId }) => (
    <div className={classnames(bem.valueOf(), bem.m(`severity-${severity}`).valueOf())} data-app-id={appId}>
        <div className={bem.e('icons').valueOf()}>
            <Icon severity={severity} />
        </div>
        {nav}
        <div className={bem.e('content').valueOf()}>{content}</div>
    </div>
);

GlobalBanner.propTypes = {
    severity: PropTypes.oneOf([INFO, SUCCESS, WARNING, ERROR]),
    content: PropTypes.node.isRequired,
    nav: PropTypes.node,

    /**
     * Used by Shell to allow microapps to scope styles by adding a well-known attribute
     * @private
     * @ignore
     */
    appId: PropTypes.string,
};

GlobalBanner.defaultProps = {
    severity: INFO,
    nav: null,
};

export default GlobalBanner;
