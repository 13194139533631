import { Box } from 'grommet';
import classnames from 'classnames/bind';
import React from 'react';
import PropTypes from 'prop-types';
import style from './NavMenu.scss';

const cx = classnames.bind(style);

const NavMenuBox = ({ children, className, ...boxProps }) => {
  return (
    <Box
      direction="row"
      border={{
        color: style.borderColor,
        size: '1px',
        style: 'solid',
        side: 'bottom',
      }}
      className={`${className ? `${className} ` : ''}${cx('element')}`}
      justify="start"
      align="center"
      onClick={() => {}}
      pad={{ horizontal: 'medium' }}
      responsive={false}
      {...boxProps}
    >
      {children}
    </Box>
  );
};

NavMenuBox.propTypes = {
  /* You can include any box props */
  ...Box.propTypes,
  children: PropTypes.node,
  className: PropTypes.string,
};

export default NavMenuBox;
