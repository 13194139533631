import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import autobind from 'react-autobind';
import { Manager, Target, Popper } from 'react-popper';
import { isFunction } from 'underscore';
import FaCaret from 'react-icons/lib/fa/caret-down';
import BEM from 'src/utils/bem';
import { StaticBold } from '../StaticBold';
import OutsideClickWrapper from '../OutsideClickWrapper'; // eslint-disable-line import/no-named-as-default

const bem = new BEM('elmer-NavBar-Dropdown');

// TODO consider replacing lib/Dropdown with some variant of this since this implementation actually works with flexbox
class Dropdown extends Component {
    constructor(props) {
        super(props);
        autobind(this);
        this.state = { showDropdown: false };
    }

    onDropdownToggle() {
        this.setState(({ showDropdown }) => ({ showDropdown: !showDropdown }));
    }

    onKeyboardToggle(e) {
        let showDropdown;
        switch (e.keyCode) {
        case 13: // enter
            this.onDropdownToggle();
            break;
        case 27: // escape
            showDropdown = false;
            break;
        default:
            break;
        }

        if (showDropdown !== undefined) {
            this.setState({ showDropdown });
        }
    }

    setTarget(node) {
        this.target = node;
    }

    handleClickOutside(e) {
        let showDrop = true;
        if (this.target && !this.target.contains(e.target)) {
            showDrop = false;
        }
        this.setState({ showDropdown: showDrop });
    }

    renderOverlay({ popperProps, restProps, scheduleUpdate }) {
        const { renderDropdown, options, loading } = this.props;
        return (
            <div {...restProps} {...popperProps}>
                <OutsideClickWrapper handleClickOutside={this.handleClickOutside} shouldHandleOutsideClickEvent={this.props.shouldHandleOutsideClickEvent}>
                    {(isFunction(renderDropdown) && renderDropdown({ scheduleUpdate, options, loading, closeMenu: this.onDropdownToggle }))}
                </OutsideClickWrapper>
            </div>
        );
    }

    render() {
        const { placement, className, tag, triggerClassName, triggerActiveClassName, trigger, domId, includeCaret, divider } = this.props;
        const { showDropdown } = this.state;
        const href = 'javascript:void(0)'; // eslint-disable-line no-script-url
        const target = includeCaret
            ? (
                <div className="flex-container align-middle align-justify" style={{ fontWeight: 'inherit' }}>
                    <StaticBold parent="div" style={{ fontWeight: 'inherit' }}>{trigger}</StaticBold>
                    <div className={bem.e('trigger-icon')}><FaCaret /></div>
                </div>
            )
            : <StaticBold parent={Fragment}>{trigger}</StaticBold>;

        return (
            <Manager className={className} tag={tag}>
                <Target
                    id={domId}
                    component="a"
                    innerRef={this.setTarget}
                    href={href}
                    className={classnames('flex-container align-center flex-dir-column', triggerClassName, {
                        [triggerActiveClassName]: showDropdown,
                        [bem.e(`divider-${divider}`)]: divider,
                    })}
                    onClick={this.onDropdownToggle}
                    onKeyDown={this.onKeyboardToggle}
                >
                    {target}
                </Target>
                <Popper
                    placement={placement}
                    className={classnames('flex-container', bem.valueOf(), { hide: !this.state.showDropdown })}
                    modifiers={{
                        flip: { enabled: true, behavior: ['bottom-end', 'bottom-start'] },
                        preventOverflow: { enabled: true, padding: 0, boundariesElement: 'viewport', priority: ['right', 'left'] },
                    }}
                >
                    {showDropdown && this.renderOverlay}
                </Popper>
            </Manager>
        );
    }
}

Dropdown.propTypes = {
    placement: PropTypes.string,
    trigger: PropTypes.node.isRequired,
    triggerClassName: PropTypes.string,
    triggerActiveClassName: PropTypes.string,
    tag: PropTypes.string,

    /**
     * @param {Function} opts.closeMenu Passthrough to content to close the menu
     */
    renderDropdown: PropTypes.func,
    domId: PropTypes.string,
    includeCaret: PropTypes.bool,
    divider: PropTypes.oneOf(['left', 'right']),

    /**
     * Convenience passthrough when `props.renderDropdown` is `Menu` or `Meganav`
     * Works for arrays of objects and arrays of strings and booleans for TenantSelectorItems
     */
    options: PropTypes.arrayOf(PropTypes.oneOfType([
        PropTypes.object, // This is used for most nav menu items
        PropTypes.string, // Used for Nimble initialTenant id, name, type
        PropTypes.bool, // Used for Nimble initialTenant acmPurchased, vmImageApproved, vmVisionEnabled
    ])),

    /**
     * Show loading indicator when we expect more menu options to become available
     */
    loading: PropTypes.bool,

    /**
     * Callback when an outside click is detected.
     * @param {Event} event
     * @return {Boolean} true if the outside click event should be handled, which will close the menu.
     */
    shouldHandleOutsideClickEvent: PropTypes.func,
};

Dropdown.defaultProps = {
    placement: 'bottom',
    tag: 'div',
    includeCaret: false,
    loading: false,
};

export default Dropdown;
