import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Box, Text, Button } from 'grommet';
import { Close, Alert, CircleInformation, StatusGood, StatusWarning } from 'grommet-icons';
import { IconAdaptor } from 'src/components/IconAdaptor';
import { TYPES } from './constants';

export default class ContentTemplate extends Component {
    background() {
        switch (this.props.type) {
        case TYPES.ERROR:
            return 'status-critical';
        case TYPES.WARNING:
            return 'status-warning';
        case TYPES.SUCCESS:
            return 'status-ok';
            // TODO figure out the correct color, which should be a blue if we have one
        case TYPES.INFO:
            return 'brand';
        default:
            return 'brand';
        }
    }

    iconComponent() {
        switch (this.props.type) {
        case TYPES.ERROR:
            return Alert;
        case TYPES.WARNING:
            return StatusWarning;
        case TYPES.SUCCESS:
            return StatusGood;
        case TYPES.INFO:
            return CircleInformation;
        default:
            return null;
        }
    }

    icon() {
        const IconComponent = this.iconComponent();
        if (IconComponent) {
            return (
                <Box pad={{ right: 'small' }}>
                    <IconAdaptor type="grommet">
                        <IconComponent color={this.foregroundColor()} />
                    </IconAdaptor>
                </Box>
            );
        }

        return null;
    }

    foregroundColor() {
        return this.hasDarkBackground() ? 'white' : '#444';
    }

    hasDarkBackground() {
        switch (this.props.type) {
        case TYPES.WARNING:
            return false;
        case TYPES.INFO:
        case TYPES.ERROR:
        case TYPES.SUCCESS:
        default:
            return true;
        }
    }

    render() {
        const { content, close } = this.props;
        return (
            <Box
                pad="small"
                direction="row"
                justify="between"
                align="start"
                background={this.background()}
            >
                <Box direction="row" align="start">
                    {this.icon()}
                    <Text size="medium" color={this.foregroundColor()}>{content}</Text>
                </Box>
                <Box>
                    <Button
                        icon={<IconAdaptor type="grommet"><Close color={this.foregroundColor()} /></IconAdaptor>}
                        plain
                        type="button"
                        onClick={close}
                    />
                </Box>
            </Box>
        );
    }
}

ContentTemplate.propTypes = {
    type: PropTypes.oneOf(['error', 'info', 'success', 'warning']),

    /**
     * Notification content
     */
    content: PropTypes.node.isRequired,

    close: PropTypes.func.isRequired,
};
