import React, { Component, Suspense } from 'react';
import PropTypes from 'prop-types';
import ReactDocumentTitle from 'react-document-title';
import { trackPageView } from '@infosight/shell-api/lib/Analytics';
import ErrorBoundary from './ErrorBoundary';
import Spinner from '../components/Spinner';

/**
 * Standard top-level "Page" wrapper that encapsulates cross-cutting concerns
 */
class PageBoundary extends Component {
    componentDidMount() {
        const { title, match } = this.props;
        trackPageView({ path: match.path, pageTitle: title });
    }

    render() {
        const { title, children } = this.props;
        return (
            <ErrorBoundary>
                <ReactDocumentTitle title={title ? `${title} | HPE InfoSight` : 'HPE InfoSight | Hewlett Packard Enterprise'}>
                    <Suspense fallback={<Spinner />} >
                        {children}
                    </Suspense>
                </ReactDocumentTitle>
            </ErrorBoundary>
        );
    }
}

PageBoundary.propTypes = {
    title: PropTypes.string,
    children: PropTypes.node.isRequired,
    match: PropTypes.shape({
        path: PropTypes.string.isRequired,
    }).isRequired,
};

export default PageBoundary;

/**
 * Creates a react-router Route's render function that wraps PageBoundary
 * @param RouteComponent
 * @param title
 * @return {function(*): *}
 */
export const pageBoundaryRouteRenderer = (RouteComponent, title) => props => (
    <PageBoundary {...props} title={title}><RouteComponent {...props} /></PageBoundary>
);
