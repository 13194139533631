import { createSelector } from 'reselect';
import { actionNamespacer } from '@infosight/elmer/dist/utils/redux';
import { extensionPointSelector } from '../selectors';

const ns = actionNamespacer('EXTENSIONS/ORCHESTRATOR');

export const SET_MICROAPP_STATUS = ns('SET_MICROAPP_STATUS');
export const FETCH_MICROAPP_MANIFEST = ns('FETCH_MICROAPP_MANIFEST');
export const RESET = ns('RESET');

const initialState = {
  microapps: null,
  data: {},
};

const ACTION_HANDLERS = {
  [SET_MICROAPP_STATUS]: (state, { payload: { appId, status } }) => ({
    ...state,
    data: {
      ...state.data,
      [appId]: { status, timestamp: new Date().toISOString() },
    },
  }),
  [FETCH_MICROAPP_MANIFEST]: (state, { payload: microapps }) => ({
    ...state,
    microapps,
  }),
  [RESET]: () => initialState,
};

export default (state = initialState, action) => {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
};

const orchestratorSelector = (state) =>
  extensionPointSelector(state, 'orchestrator');

export const microappsSelector = (state) =>
  orchestratorSelector(state).microapps;

export const orchestratorDataSelector = (state) =>
  orchestratorSelector(state).data;

export const isLoadingMicroappsSelector = createSelector(
  orchestratorDataSelector,
  (microapps) => Object.values(microapps).some((x) => x.status === 'loading')
);
