/**
 * Generate the action type for an async operation that has started
 * @param {string} rootType
 * @return {string}
 * @memberof redux
 */
export const getStartedActionType = rootType => rootType;

/**
 * Generate the action type for an async operation that has successfully completed
 * @param {string} rootType
 * @return {string}
 * @memberof redux
 */
export const getSuccessActionType = rootType => `${rootType}_SUCCEEDED`;

/**
 * Generate the action rootType for an async operation that has failed
 * @param {string} rootType
 * @return {string}
 * @memberof redux
 */
export const getErrorActionType = rootType => `${rootType}_FAILED`;
