import React, { Component } from 'react';
import PropTypes from 'prop-types';
import autobind from 'react-autobind';
import { omit, isEqual } from 'underscore';
import getDisplayName from 'recompose/getDisplayName';
import wrapDisplayName from 'recompose/wrapDisplayName';

/**
 *  Table which handles Sorting, Filtering and Pagination using ES Api's
 */
const createElasticSearchDataProvider = (TableComponent) => {
    class ElasticSearchTable extends Component {
        static translateToElasticSearchFilter(columnsFiltered) {
            if (columnsFiltered && columnsFiltered.length) {
                return columnsFiltered.map((column) => {
                    if (typeof column === 'string') {
                        return column;
                    }
                    const { id, value } = column;
                    return `${id}:(${value})`;
                });
            }

            return undefined;
        }

        static translateToElasticSearchSort(columnsSorted) {
            if (columnsSorted && columnsSorted.length) {
                return columnsSorted
                    .map((column) => {
                        if (typeof column === 'string') {
                            return column;
                        }
                        const { id, desc } = column;
                        if (desc) {
                            return `${id}:desc`;
                        }
                        return `${id}:asc`;
                    })
                    .join(',');
            }

            return undefined;
        }

        /**
         * Builds the final list of columns using the filter Map
         * @param {Map} filterColumnsMap
         * @param {Object[]} filteredColumns - filtered Columns (on UI)
         * @param {string} filteredColumns[].id
         * @param {string} filteredColumns[].title
         * @param {string} filteredColumns[].value
         * @returns {Object[]} - Final list of filtered column objects
         */
        static getFilteredColumns(filterColumnsMap, filteredColumns) {
            const finalFilteredColumns = [];
            if (filteredColumns && filteredColumns.length) {
                filteredColumns.forEach((filteredColumn) => {
                    // Read the list of associated columns from the Map
                    const mappedColumns = filterColumnsMap ? filterColumnsMap.get(filteredColumn.id) : undefined;
                    if (mappedColumns) {
                        if (typeof mappedColumns === 'function') {
                            const filterString = mappedColumns(filteredColumn);
                            if (filterString) {
                                finalFilteredColumns.push(filterString);
                            }
                        } else {
                            finalFilteredColumns.push(...mappedColumns.map(column => ({
                                ...filteredColumn,
                                id: column,
                            })));
                        }
                    } else {
                        finalFilteredColumns.push(filteredColumn);
                    }
                });
            }
            return finalFilteredColumns;
        }

        /**
         * Builds the final list of columns using the Sort Map
         * @param {Map} sortColumnsMap
         * @param {Object[]} columnsSorted - Sorted Columns (on UI)
         * @param {string} columnsSorted[].id
         * @param {boolean} columnsSorted[].desc
         * @returns {Object[]} - Final list of sorted column objects
         */
        static getSortedColumns(sortColumnsMap, columnsSorted) {
            const finalSortedColumns = [];
            if (columnsSorted && columnsSorted.length) {
                columnsSorted.forEach((sortedColumn) => {
                    // Read the list of associated columns from the Map
                    const mappedColumns = sortColumnsMap ? sortColumnsMap.get(sortedColumn.id) : undefined;
                    if (mappedColumns) {
                        if (typeof mappedColumns === 'function') {
                            const sortString = mappedColumns(sortedColumn);
                            if (sortString) {
                                finalSortedColumns.push(sortString);
                            }
                        } else {
                            finalSortedColumns.push(...mappedColumns.map(column => ({
                                id: column,
                                desc: sortedColumn.desc,
                            })));
                        }
                    } else {
                        finalSortedColumns.push(sortedColumn);
                    }
                });
            }
            return finalSortedColumns;
        }

        constructor(props) {
            super(props);
            autobind(this);
        }

        componentDidMount() {
            this.fetchData(this.props);
        }

        componentDidUpdate(prevProps) {
            const { filtered, sorted, page } = prevProps;
            if (!isEqual(filtered, this.props.filtered) || !isEqual(sorted, this.props.sorted) || !isEqual(page, this.props.page)) {
                this.fetchData();
            }
        }

        fetchData() {
            const { fetchData, filtered, sorted, page, pageSize, sortColumnsMap, filterColumnsMap } = this.props;
            const finalSortedColumns = ElasticSearchTable.getSortedColumns(sortColumnsMap, sorted);
            const finalFilteredColumns = ElasticSearchTable.getFilteredColumns(filterColumnsMap, filtered);
            const start = page * pageSize;
            const sortText = ElasticSearchTable.translateToElasticSearchSort(finalSortedColumns);
            const filterArray = ElasticSearchTable.translateToElasticSearchFilter(finalFilteredColumns);

            fetchData(start, pageSize, sortText, filterArray);
        }

        render() {
            const passThroughProps = omit(this.props, ['sorted', 'filtered']);
            return (
                <TableComponent
                    {...passThroughProps}
                    manual
                />
            );
        }
    }

    ElasticSearchTable.displayName = wrapDisplayName(
        TableComponent,
        getDisplayName(ElasticSearchTable)
    );

    ElasticSearchTable.propTypes = {
        sorted: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.string,
            desc: PropTypes.bool,
        })),

        /**
         * Map of Column Ids
         * Key - {string} Id of the Column which is sorted in the UI
         * Value - {string[] or function} Array of column ids which have to be added to the actual sort criteria
         *                                or a function that will receive the UI sorted column object and as a return
         *                                we receive an elastic search sort string to be passed to elastic search
         *
         * For Tables which uses server side sorting such as ElasticSearchTable, sometimes it is needed
         * that when a particular column is sorted on the UI, the sort criteria to the underlying api
         * should include some other columns.
         *
         * Note: This list is not just the additional columns to be included in sort criteria, it is
         * the list of columns to be included in the final sort criteria
         */
        sortColumnsMap: PropTypes.instanceOf(Map),

        /**
         * Map of Column Ids
         * Key - {string} Id of the Column which is filtered in the UI
         * Value - {string[] or string[][]} Array of column ids which have to be added to the actual filter criteria
         *                                  or a function that will receive the UI filtered column object and as a return
         *                                  we receive a conditional OR or AND elastic search filter string to be passed to elastic search
         * For Tables which uses server side filtering such as ElasticSearchTable, sometimes it is needed
         * that when a particular column is filtered on the UI, the filter criteria to the underlying api
         * should include some other columns.
         *
         * Note: This list is not just the additional columns to be included in filter criteria, it is
         * the list of columns to be included in the final filter criteria
         */
        filterColumnsMap: PropTypes.instanceOf(Map),

        filtered: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.string,
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.arrayOf(PropTypes.string)]),
        })),

        page: PropTypes.number,

        pageSize: PropTypes.number,

        /**
         * Function to fetch data on paginate, sort and filter operations
         */
        fetchData: PropTypes.func,
    };

    return ElasticSearchTable;
};


export default createElasticSearchDataProvider;
