import { ExtensionPoint } from '@infosight/shell-api/lib/core';
import reducer, { ADD, REMOVE } from './reducer';

/**
 * Add a home for the landing page
 */
function addHome(opts) {
  this.props.dispatch({ type: ADD, payload: opts });
}

/**
 * Add a home from the landing page
 */
function removeHome(opts) {
  this.props.dispatch({ type: REMOVE, payload: opts });
}

export const id = ExtensionPoint.Home;
export const commands = { addHome, removeHome };
export { reducer };
