import Table from './Table';
import withTableState, { TableContext, FILTER_ACTION } from './hoc/withTableState';
import createSizePersistentTable from './hoc/createSizePersistentTable';
import ClientStorageSizePersistentTable from './hoc/ClientStorageSizePersistentTable';
import createClientControlledDataProvider from './hoc/createClientControlledDataProvider';
import createElasticSearchDataProvider from './hoc/createElasticSearchDataProvider';

import { CheckBoxFilter, TextInputFilter, ContainsStringFilter } from './filters';

const COLUMN_WIDTHS = {
    XLARGE: 194,
    LARGE: 121,
    MEDIUM: 101,
    SMALL: 83,
    XSMALL: 64,
};

/**
 *Out of the box Table which handles Sorting, Filtering and Pagination on Client
 */
const ClientControlledTable = withTableState(createClientControlledDataProvider(ClientStorageSizePersistentTable));

/**
 * Table which handles Sorting, Filtering and Pagination using ES Api's
 */
const ElasticSearchTable = withTableState(createElasticSearchDataProvider(ClientStorageSizePersistentTable));

export {
    
    COLUMN_WIDTHS,
    
    /** Out of the box Table components */
    Table,
    ClientStorageSizePersistentTable,
    ClientControlledTable,
    ElasticSearchTable,
    
    /** Higher Order Components to help create custom Table */
    createSizePersistentTable,
    createClientControlledDataProvider,
    createElasticSearchDataProvider,
    withTableState,

    /**
     * @deprecated use TextInputFilter
     */
    ContainsStringFilter,
    TextInputFilter,
    CheckBoxFilter,
    FILTER_ACTION,
    
    /** Filter and Sort states can be accessed from this Context */
    TableContext,
};
