import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { ExtensionPoint } from '@infosight/shell-api/lib/core';
import { extensionPointSelector } from '../../extensibility';
import { registerAll } from '../actionCreators';

// TODO Refactor search to be a more idiomatic search target.
// Try to get rid of the disparate locations for search config in redux. I'm leaving this terrible name as motivation to refactor
class SearchManager extends Component {
  componentDidMount() {
    this.registerSubjects();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.extension !== this.props.extension) {
      this.registerSubjects();
    }
  }

  registerSubjects() {
    this.props.registerAll(Object.values(this.props.extension));
  }

  render() {
    return null;
  }
}

SearchManager.propTypes = {
  extension: PropTypes.objectOf(PropTypes.object).isRequired,
  registerAll: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  extension: extensionPointSelector(state, ExtensionPoint.Search),
});
export default connect(mapStateToProps, { registerAll })(SearchManager);
