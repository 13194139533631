import React, { Component } from 'react';
import autobind from 'react-autobind';
import classnames from 'classnames';
import { noop } from 'underscore';
import BEM from 'src/utils/bem';
import { Tags } from '../../Tag';
import { TextTag, TextListTag } from './tags';
import { TableContext, FILTER_ACTION } from '../hoc/withTableState';

const bem = new BEM('elmer-Table-Filter-Tags');

// TODO: Enhance this component to handle all Filters not just ContainsStringFilter
class FilterTags extends Component {
    constructor(props) {
        super(props);
        autobind(this);
        this.filterChangeHandler = noop;
    }

    onClick({ filter }) {
        this.filterChangeHandler({ filter, filterAction: FILTER_ACTION.REMOVE });
    }

    onClickClear(event) {
        event.preventDefault();
        this.filterChangeHandler({ filterAction: FILTER_ACTION.REMOVE_ALL });
    }

    render() {
        const { className } = this.props;
        return (
            <TableContext.Consumer>
                {
                    ({ handleFilterChange, filteredColumns = [] }) => {
                        this.filterChangeHandler = handleFilterChange;
                        return filteredColumns.length > 0 ? (
                            <Tags className={classnames(className, bem.valueOf())}>
                                <h6 className="no-margin" style={{ display: 'inline' }}>Filters: </h6>
                                {
                                    filteredColumns.map((filter) => {
                                        // TODO: Add better Component resolving logic
                                        if (Array.isArray(filter.value)) {
                                            return <TextListTag key={filter.id} filter={filter} removeFilter={this.onClick} />;
                                        }
                                        return <TextTag key={filter.id} filter={filter} removeFilter={this.onClick} />;
                                    })
                                }
                                <a // eslint-disable-line jsx-a11y/anchor-is-valid
                                    href="javascript:void(0)" // eslint-disable-line no-script-url
                                    onClick={this.onClickClear}
                                > Clear all
                                </a>
                            </Tags>
                        ) : null;
                    }
                }
            </TableContext.Consumer>
        );
    }
}

export default FilterTags;

