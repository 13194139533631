import React, { Component } from 'react';
import autobind from 'react-autobind';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { addBanner } from '@infosight/shell-api/lib/Banner';
import BEM from '@infosight/elmer/dist/utils/bem';
import {
  ERROR,
  INFO,
  SUCCESS,
  WARNING,
} from '@infosight/elmer/dist/components/GlobalBanner/constants';
import GlobalBanner from '@infosight/elmer/dist/components/GlobalBanner/GlobalBanner';
import Navigate from '@infosight/elmer/dist/components/GlobalBanner/Navigate';
import Location from '@infosight/elmer/dist/components/GlobalBanner/Location';
import { bannersSelector } from '../extensibility/banner/reducer';
import { removeBanner } from '../extensibility/banner/actionCreators';

const bem = new BEM('elmer-GlobalBanner');

// This file is a clone of Elmer's GlobalBannerList except cleaned up a bit, merged with the local Banners file, and with error handling built in.
// https://github.hpe.com/infosight/elmer/blob/master/src/components/GlobalBanner/GlobalBannerList.js

// React error handling is not yet supported by functional components, so this remains a class component
class Banners extends Component {
  constructor(props) {
    super(props);
    autobind(this);

    this.state = {
      index: 0,
      error: false,
      errorId: null,
    };
  }

  static getDerivedStateFromError() {
    return { error: true };
  }

  // All the React documentation says to *almost* never use this function.
  // I believe this is an exception. The limitations of getDerivedStateFromError
  // make it very difficult to clear an error without using this function.
  static getDerivedStateFromProps(props, state) {
    // If you have previously recorded a banner with an error
    if (
      state.error &&
      state.errorId &&
      (!props.banners ||
        !props.banners.some((banner) => banner.id === state.errorId))
    ) {
      return {
        error: false,
        errorId: null,
      };
    }
    // when the error first shows up, record the id of the broken banner.
    // we would much rather do this at the time of the error, but getDerivedStateFromError
    // does not have access to component props or state.
    if (state.error && !state.errorId) {
      const index = Math.min(state.index, props.banners.length - 1);
      return {
        errorId: props.banners[index].id,
      };
    }
    return null;
  }

  componentDidCatch() {
    const banner = this.currentBanner();

    addBanner({
      id: `bannerRenderingError${banner.appId}`,
      appId: 'core',
      severity: 'warning',
      content: `The "${banner.appId}" app tried to display a banner and failed. Please try again later.`,
    });
    this.dismiss(banner.id, banner);
  }

  currentBanner() {
    const { banners } = this.props;
    if (!banners || !banners.length) {
      return null;
    }

    const index = Math.min(this.state.index, banners.length - 1);
    return banners[index] || null;
  }

  moveTo(i) {
    this.setState({ index: i });
  }

  dismiss() {
    const banner = this.currentBanner();
    if (!banner) {
      return;
    }

    if (typeof banner.onDismiss === 'function') {
      try {
        // It doesn't matter if this is async; just run it.
        banner.onDismiss(banner.id);
      } catch (e) {} // eslint-disable-line no-empty
    }

    this.props.removeBanner(banner);
  }

  render() {
    const { banners } = this.props;
    if (!banners || !banners.length || this.state.error) {
      return null;
    }

    const index = Math.min(this.state.index, banners.length - 1);
    const banner = banners[index];

    if (!banner) {
      return null;
    }

    const showNext = () => {
      const nextIndex = (index + 1) % banners.length;
      this.moveTo(nextIndex);
    };

    const showPrevious = () => {
      const previousIndex = index - 1 >= 0 ? index - 1 : banners.length - 1;
      this.moveTo(previousIndex);
    };

    return (
      <GlobalBanner
        {...banner}
        nav={
          <div className={bem.e('nav').valueOf()}>
            <button
              className={bem.e('dismiss').valueOf()}
              onClick={this.dismiss}
              type="button"
            >
              Acknowledge
            </button>
            <Navigate direction="left" onClick={showPrevious} />
            <Location count={banners.length} visibleIndex={index} />
            <Navigate direction="right" onClick={showNext} />
          </div>
        }
      />
    );
  }
}

Banners.propTypes = {
  banners: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      appId: PropTypes.string.isRequired,
      severity: PropTypes.oneOf([INFO, SUCCESS, WARNING, ERROR]),
      content: PropTypes.node,
    })
  ),
  removeBanner: PropTypes.func.isRequired, // added by connect function
};

const mapStateToProps = (state) => ({
  banners: bannersSelector(state),
});

export default connect(mapStateToProps, { removeBanner })(Banners);
