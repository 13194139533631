const demoRoles = [
  'Solution Architect',
  'Technical Marketing Engineer',
  'Product Manager',
  'Sales Specialist/Consultant',
  'Account Support Manager',
  'Technology Solution Evangelist',
  'Engineer',
];

const demoTenantId = '00000000-0000-0000-0000-000000000007';

const roleMenuItemArray = (currentRole, customRoles) => {
  return demoRoles
    .filter((role) => {
      return role !== currentRole;
    })
    .concat(customRoles)
    .map((role) => {
      return role;
    });
};

export const isOfficialDemoTenant = (tenant) => {
  return tenant && tenant.id === demoTenantId;
};

export default roleMenuItemArray;
