import React from 'react';
import PropTypes from 'prop-types';
import FaCaretDown from 'react-icons/lib/fa/caret-down';
import BaseButton from './BaseButton';

const propsByContext = {
    dark: {
        color: 'dark',
    },
    secondary: {
        color: 'secondary',
    },
    hollow: {
        hollow: true,
    },
    cancel: {
        hollow: true,
        borderless: true,
    },
};

const Button = ({ context, dropdown, label, children, ...props }) => (
    <BaseButton {...props} {...propsByContext[context]}>
        {children}
        {label}
        {dropdown && <span> <FaCaretDown /></span>}
    </BaseButton>
);

Button.propTypes = {
    /**
     * Context, or type, of button
     */
    context: PropTypes.oneOf(['default', 'dark', 'secondary', 'hollow', 'cancel']),

    /**
     * Sets the `<button>` contents for simple text-only cases. Use `props.children` for other cases.
     */
    label: PropTypes.string,

    children: PropTypes.node,
    uppercase: PropTypes.bool,
    dropdown: PropTypes.bool,
};

Button.defaultProps = {
    context: 'default',
    uppercase: false,
    dropdown: false,
};

export default Button;
