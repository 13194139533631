import {
  actionNamespacer,
  getSuccessActionType,
} from '@infosight/elmer/dist/utils/redux';

const ns = actionNamespacer('IAM');

export const UPDATE_SESSION = ns('UPDATE_SESSION');
export const UPDATE_SESSION_SUCCEEDED = getSuccessActionType(UPDATE_SESSION);
export const FETCH_RECENT_TENANTS = ns('FETCH_RECENT_TENANTS');
export const FETCH_CURRENT_USER_TENANTS = ns('FETCH_CURRENT_USER_TENANTS');
export const FETCH_CURRENT_USER_USER_TENANTS = ns(
  'FETCH_CURRENT_USER_USER_TENANTS'
);
export const FETCH_OFFICIAL_DEMO_TENANTS = ns('FETCH_OFFICIAL_DEMO_TENANTS');
export const SEARCH_TENANTS = ns('SEARCH_TENANTS');
export const RESET_TENANT_SELECTOR = ns('RESET_TENANT_SELECTOR');
export const SET_TENANT_COUNT = ns('SET_TENANT_COUNT');
export const AUTHORIZE_NEXT_TENANT = ns('AUTHORIZE_NEXT_TENANT');
export const UPDATE_USER_PROPERTIES = ns('UPDATE_USER_PROPERTIES');
export const UPDATE_USER_PROPERTIES_SUCCEEDED = getSuccessActionType(
  UPDATE_USER_PROPERTIES
);

export const TENANT_TYPE = {
  INFOSIGHT: 'infosight',
};

// TODO fix these Iam role maps there's no reason there should be three of them
/**
 * Map's IAM backend Roles to the values we should show in UI
 */
export const IAM_TO_UI_ROLE_MAP = {
  ADMIN: 'Admin',
  USER: 'User',
  THIRD_PARTY: 'Third Party',
};

/**
 * Value shown in UI to value we should send to IAM backend
 * We are only using this due to a limitation in the version of grommet we are using
 * remove this and use the selects valueKey and labelKey when available
 */
export const UI_TO_IAM_ROLE_MAP = {
  Admin: 'ADMIN',
  User: 'USER',
  'Third Party': 'THIRD_PARTY',
};

export const IAM_ROLES = {
  ADMIN: 'ADMIN',
  USER: 'USER',
  THIRD_PARTY: 'THIRD_PARTY',
};

/**
 * List of roles to show in the UI
 */
export const IAM_ROLES_LIST = Object.values(IAM_TO_UI_ROLE_MAP);

export const MAX_VISIBLE_TENANT_COUNT = 10;

export const OPT_IN_USER_PROPERTY_PREFIX = 'urn:shell:optin:';
