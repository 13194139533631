import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import Spinner from '@infosight/elmer/dist/components/Spinner';

/* SlowRedirect is a redirect that only occurs on a timer */
class SlowRedirect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirectNow: false,
    };
  }

  componentDidMount() {
    const { timeoutSeconds } = this.props;
    this.timeout = setTimeout(() => {
      this.setState({ redirectNow: true });
    }, timeoutSeconds * 1000);
  }

  componentWillUnmount() {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
  }

  render() {
    const { to } = this.props;
    const { redirectNow } = this.state;
    if (redirectNow) {
      return <Redirect to={to} />;
    }
    return <Spinner />;
  }
}

SlowRedirect.propTypes = {
  to: PropTypes.string.isRequired,
  timeoutSeconds: PropTypes.number,
};

SlowRedirect.defaultProps = {
  timeoutSeconds: 40,
};

export default SlowRedirect;
